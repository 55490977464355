/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '../../../utils';
import { getChange, getPercent } from '../../../data/models/Analytics';
import DataPoint from '../DataPoint/DataPoint';

const GuestsSeated = ({ data }) => {
  const { totalGuests, totalGuestsCompare } = data;

  const renderTotalGuests = (num) => {
    if (typeof num !== 'number') {
      return '-';
    }

    return formatNumber(num, 0);
  };

  const totalGuestsPercent = getPercent(totalGuests, totalGuestsCompare);
  const totalGuestsChange = getChange(totalGuests, totalGuestsCompare);

  return (
    <DataPoint
      change={totalGuestsChange}
      percent={totalGuestsPercent}
      title='Total customers'
      value={renderTotalGuests(totalGuests)}
    />
  );
};

GuestsSeated.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default GuestsSeated;
