/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { purple } from '@mui/material/colors';
import Chart from 'chart.js';
import moment from 'moment';
import { alpha } from '@mui/material/styles';
import { CardContent, Card, Typography } from '@mui/material';
import useStyles from './RedemptionTimesChartStyles';
import { devLog } from '../../../utils';

const RedemptionTimesChart = (props) => {
  const chartRef = useRef(null);
  const classes = useStyles();
  const [chartObj, setChartObj] = useState(null);

  const [timePeriods] = useState([
    {
      name: '5am - 7am',
      slug: 'earlyMorning',
      order: 1,
      start: moment.utc('05:00 am', 'HH:mm a'),
      end: moment.utc('06:59 am', 'HH:mm a'),
    },
    {
      name: '7am - 9am',
      slug: 'morning',
      order: 2,
      start: moment.utc('07:00 am', 'HH:mm a'),
      end: moment.utc('08:59 am', 'HH:mm a'),
    },
    {
      name: '9am - 11am',
      slug: 'lateMorning',
      order: 3,
      start: moment.utc('09:00 am', 'HH:mm a'),
      end: moment.utc('10:59 am', 'HH:mm a'),
    },
    {
      name: '11am - 1pm',
      slug: 'noon',
      order: 4,
      start: moment.utc('11:00 am', 'HH:mm a'),
      end: moment.utc('12:59 pm', 'HH:mm a'),
    },
    {
      name: '1pm - 3pm',
      slug: 'afternoon',
      order: 5,
      start: moment.utc('01:00 pm', 'HH:mm a'),
      end: moment.utc('02:59 pm', 'HH:mm a'),
    },
    {
      name: '3pm - 5pm',
      slug: 'lateAfternoon',
      order: 6,
      start: moment.utc('03:00 pm', 'HH:mm a'),
      end: moment.utc('04:59 pm', 'HH:mm a'),
    },
    {
      name: '5pm - 7pm',
      slug: 'earlyEvening',
      order: 7,
      start: moment.utc('05:00 pm', 'HH:mm a'),
      end: moment.utc('06:59 pm', 'HH:mm a'),
    },
    {
      name: '7pm - 9pm',
      slug: 'evening',
      order: 8,
      start: moment.utc('07:00 pm', 'HH:mm a'),
      end: moment.utc('08:59 pm', 'HH:mm a'),
    },
    {
      name: '9pm - 11pm',
      slug: 'night',
      order: 9,
      start: moment.utc('09:00 pm', 'HH:mm a'),
      end: moment.utc('10:59 pm', 'HH:mm a'),
    },
    {
      name: '11pm - 1am',
      slug: 'lateNight',
      order: 10,
      start: moment.utc('11:00 pm', 'HH:mm a'),
      end: moment.utc('11:59 pm', 'HH:mm a'),
    },
    {
      name: '11pm - 1am', // object is duplicated with start/end times in early morning
      slug: 'lateNight',
      order: 11,
      start: moment.utc('00:00 am', 'HH:mm a'),
      end: moment.utc('00:59 am', 'HH:mm a'),
    },
  ]);

  const [timeRedemptionLabels, setTimeRedemptionLabels] = useState([]);

  // Set Chart Labels
  useEffect(() => {
    const labels = timePeriods.reduce((a, p) => {
      if (!a.includes(p.name)) {
        a.push(p.name);
      }
      return a;
    }, []);
    setTimeRedemptionLabels(labels);
  }, [timePeriods]);

  // Reduce Redemptions into Times
  const timeRedemptions = props.redemptions.reduce((allTime, date) => {
    const { arrivalTime } = date;
    const time = moment.utc(arrivalTime, 'HH:mm a');

    if (!time.isValid) {
      devLog('error', 'Time redemptions', 'Invalid time');
      return allTime;
    }

    // return the times of the reservations
    timePeriods.map((period) => {
      if (time.isBetween(period.start, period.end, 'second', '[)')) {
        if (!allTime[period.slug]) {
          allTime[period.slug] = {
            total: 0,
            name: period.name,
            order: period.order,
          };
        }
        allTime[period.slug].total++;
      } else {
        // also log the times where there is no reservation
        if (!allTime[period.slug]) {
          allTime[period.slug] = {
            total: 0,
            name: period.name,
            order: period.order,
          };
        }
      }
      return null;
    });

    return allTime;
  }, []);

  // Prepare Redemptions into readable, sorted array
  const timeRedemptionArray = Object.keys(timeRedemptions)
    .map((key) => timeRedemptions[key])
    .sort((a, b) => a.order - b.order);
  const timeRedemptionValues = timeRedemptionArray.map((time) => time.total);

  // Initialize Chart
  useEffect(() => {
    const chart = new Chart(chartRef.current, {
      type: 'bar',
      data: {
        // Bring in data
        labels: timeRedemptionLabels,
        datasets: [
          {
            label: 'Redemption Trend',
            data: [],
            backgroundColor: alpha(purple[400], 0.5),
            borderWidth: 1,
            fill: false,
          },
        ],
      },
      options: {
        // Customize chart options
        hover: {
          intersect: false,
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                precision: 0,
                beginAtZero: true,
                padding: 15,
              },
            },
          ],
          xAxes: [
            {
              gridLines: false,
              ticks: {
                padding: 15,
              },
            },
          ],
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 8,
          },
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const label = `${tooltipItem.yLabel} Redeemed`;

              return label;
            },
          },
          // titleFontSize: 10,
          caretSize: 0,
          xPadding: 10,
          yPadding: 10,
        },
      },
    });
    setChartObj(chart);

    return () => {
      chart.destroy();
    };
  }, [timeRedemptionLabels]);

  // Update chart when data changes
  useEffect(() => {
    if (!chartObj) {
      return;
    }

    // only update if chartjs has been initialized
    if (!chartObj.data.datasets[0].data.hasOwnProperty('_chartjs')) {
      return;
    }

    if (JSON.stringify(timeRedemptionValues) !== JSON.stringify(chartObj.data.datasets[0].data)) {
      chartObj.data.datasets[0].data = timeRedemptionValues;
      chartObj.update();
    }
  }, [chartObj, timeRedemptionValues]);

  return (
    <Card>
      <CardContent>
        <Typography variant='subtitle1' className={classes.cardTitle}>
          {props.platform === 'eatclub' ? 'Redemption' : 'Order'} Times
        </Typography>
        <div className={classes.chartHeight} style={{ position: 'relative' }}>
          <canvas
            id='redemptionTimesChart'
            ref={chartRef}
            className={`${classes.chart} ${classes.chartHeight}`}
          />
        </div>
      </CardContent>
    </Card>
  );
};

RedemptionTimesChart.propTypes = {
  redemptions: PropTypes.array.isRequired,
};

export default RedemptionTimesChart;
