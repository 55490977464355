import React, { useEffect, useMemo, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Divider, Image } from '@eatclub-apps/ec-component-library';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { fetchObeeSettingsAction } from '../../../actions/obeeSettingsAction';
import CircleInCheck from '../../../assets/check_in_circle.svg';
import eatclubBookingsImage from '../../../assets/images/eatclub_bookings_image.png';
import TimelineIconSVG from '../../../assets/icon_timeline.svg';
import FloorplanIconSVG from '../../../assets/icon_floorplan.svg';
import PrinterIconSVG from '../../../assets/icon_printer.svg';
import AutoMessagingSVG from '../../../assets/icon_auto_messaging.svg';
import BrandedOptionsSVG from '../../../assets/icon_branded_options.svg';
import PowerfulSettingsSVG from '../../../assets/icon_powerful_settings.svg';
import TablePrioritySVG from '../../../assets/icon_table_priority.svg';
import TimeslotLimitsSVG from '../../../assets/icon_timeslot_limits.svg';
import WaitlistManagerSVG from '../../../assets/icon_waitlist_manager.svg';
import CelebrationSVG from '../../../assets/icon_celebration.svg';
import { canEditAll, formatDate, isEmpty } from '../../../utils/helpers';
import useStyles from './BookingsLandingStyles';
import RequestAccessModal from './RequestAccessModal';
import ConfirmedModal from './ConfirmedModal';
import PlanDetailsCard from './PlanDetailsCard';
import {
  // fetchObeePricingAction,
  obeeSlugExistsAction,
  onboardToObeeAction,
  requestObeeSignupAction,
} from '../../../actions/obeeSignupAction';
import SignUpModal from './SignUpModal';
import {
  obeeOnboardPropTypes,
  // obeePricingPropTypes,
  obeeRequestPropTypes,
  obeeUpgradePropTypes,
} from '../../../data/models/ObeeSignup';
import { userPropTypes } from '../../../data/models/User';
import { restaurantPropTypes } from '../../../data/models/Restaurant';

const overviewItems = [
  { id: 'save', value: 'Save time and automate bookings' },
  { id: 'minimise', value: 'Minimise no shows and cancellations' },
  { id: 'gain', value: 'Gain insightful data on your guests' },
];

const highlightItems = [
  {
    heading: 'Easy-to-use views to manage bookings',
    content: [
      {
        icon: <FloorplanIconSVG />,
        header: 'Floorplan',
        content: 'Manage bookings with a drag and drop, interactive timeline.',
      },
      {
        icon: <TimelineIconSVG />,
        header: 'Timeline',
        content: 'Manage bookings with a drag and drop, timeline of you day.',
      },
      {
        icon: <PrinterIconSVG />,
        header: 'Run sheet',
        content: 'A printable PDF & simple view of all your bookings for day.',
      },
    ],
  },
  {
    heading: 'Powerful, time saving automation',
    content: [
      {
        icon: <TablePrioritySVG />,
        header: 'Table priority',
        content: 'Automatically assign incoming bookings to your preferred tables.',
      },
      {
        icon: <AutoMessagingSVG />,
        header: 'Auto messaging',
        content: 'Automate key messages and reminders to minimise cancellations.',
      },
      {
        icon: <WaitlistManagerSVG />,
        header: 'Waitlist manager',
        content: 'Build a waitlist of guests and fill tables in the event of cancellations.',
      },
    ],
  },
  {
    heading: 'Customisable to your brand & operations',
    content: [
      {
        icon: <PowerfulSettingsSVG />,
        header: 'Powerful settings',
        content: 'Customise the platform around your venue’s operational needs.',
      },
      {
        icon: <BrandedOptionsSVG />,
        header: 'Branded options',
        content: 'Tailor the bookings widget & emails to your brand’s look and feel.',
      },
      {
        icon: <TimeslotLimitsSVG />,
        header: 'Booking limits',
        content: 'Control the number of guests and bookings you get for every timeslot.',
      },
    ],
  },
];

const BookingsLanding = ({
  onboardToObee,
  obeeRequest,
  user,
  requestObeeSignup,
  // obeePricing,
  // fetchObeePricing,
  obeeOnboard,
  restaurantActive,
  obeeUpgrade,
  fetchObeeSettings,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [requestAccess, setRequestAccess] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [openRequestConfirmedModal, setOpenRequestConfirmedModal] = useState(false);
  const [openOnboardConfirmedModal, setOpenOnboardConfirmedModal] = useState(false);
  const [openUpgradeConfirmedModal, setOpenUpgradeConfirmedModal] = useState(false);
  const [signUpVenue, setSignUpVenue] = useState(null);
  const [slugError, setSlugError] = useState(null);

  const { restaurant } = restaurantActive;

  // Fetch obee settings if signed up to obee
  // TODO flag API requests that can only be made if a restaurant is using obee
  useEffect(() => {
    if (restaurant.obeeEnabled) {
      fetchObeeSettings();
    }
  }, [fetchObeeSettings, restaurant.obeeEnabled]);

  const dividerStyles = {
    margin: '20px 0 40px 0',
  };

  const overviewItem = (item) => (
    <Box key={item.id} className={classes.overviewItem}>
      <CircleInCheck style={{ flexShrink: 0 }} />
      <Box className={classes.overviewText}>{item.value}</Box>
    </Box>
  );

  const requestConfirm = (premium, name, phone, email, comments) => {
    setConfirming(true);

    requestObeeSignup(premium, name, phone, email, comments);
  };

  const onboardConfirm = async (slug, operationsEmail, premium) => {
    setConfirming(true);

    try {
      if (slug.match(/[^a-zA-Z0-9-_]/g)) {
        unstable_batchedUpdates(() => {
          setConfirming(false);
          setSlugError('This is not a valid URL');
        });

        return;
      }

      const slugExists = await obeeSlugExistsAction(slug);

      if (slugExists) {
        unstable_batchedUpdates(() => {
          setConfirming(false);
          setSlugError('This URL already exists');
        });

        return;
      }

      onboardToObee(slug, operationsEmail, premium);
    } catch (error) {
      setConfirming(false);
    }
  };

  // TODO this seems incomplete?
  const upgradeConfirm = () => {
    setConfirming(true);

    unstable_batchedUpdates(() => {
      setConfirming(false);
      setSignUpVenue(null);
      setOpenUpgradeConfirmedModal(true);
    });

    // requestObeeSignup(premium, name, phone, email, comments);
  };

  const isStaff = useMemo(() => canEditAll(user), [user.userInfo?.userType]);

  const liteCtaLabel = useMemo(() => {
    if (restaurant.obeeEnabled) {
      return 'Plan activated';
    }

    return isStaff ? 'Sign up venue' : 'Request access';
  }, [restaurant.obeeEnabled, isStaff]);

  const premiumCtaLabel = useMemo(() => {
    if (isStaff) {
      if (restaurant.obeeEnabled) {
        return restaurant.premium ? 'Plan activated' : 'Upgrade venue';
      }
      return 'Sign up venue';
    }
    return 'Request access';
  }, [isStaff, restaurant.obeeEnabled, restaurant.premium]);

  useEffect(() => {
    if (confirming && obeeRequest.success) {
      unstable_batchedUpdates(() => {
        setConfirming(false);
        setRequestAccess(null);
        setOpenRequestConfirmedModal(true);
      });
    }
  }, [obeeRequest.success]);

  useEffect(() => {
    if (confirming && obeeRequest.error) {
      setConfirming(false);
    }
  }, [obeeRequest.error]);

  useEffect(() => {
    if (confirming && obeeOnboard.success) {
      unstable_batchedUpdates(() => {
        setConfirming(false);
        setSignUpVenue(null);
        setOpenOnboardConfirmedModal(true);
      });
    }
  }, [obeeOnboard.success]);

  useEffect(() => {
    if (confirming && obeeOnboard.error) {
      setConfirming(false);
    }
  }, [obeeOnboard.error]);

  useEffect(() => {
    if (confirming && obeeUpgrade.success) {
      unstable_batchedUpdates(() => {
        setConfirming(false);
        setSignUpVenue(null);
        setOpenUpgradeConfirmedModal(true);
      });
    }
  }, [obeeUpgrade.success]);

  useEffect(() => {
    if (confirming && obeeUpgrade.error) {
      setConfirming(false);
    }
  }, [obeeUpgrade.error]);

  return (
    <Box className={classes.container}>
      <Box className={classes.overviewContainer}>
        {overviewItems.map((item) => overviewItem(item))}
      </Box>

      {/* hero image */}
      <Image src={eatclubBookingsImage} alt='EatClub Bookings' height={962} width={1564} />

      <Box>
        <Box className={classes.highlightItemsHeader}>Product highlights</Box>
        {highlightItems.map((item) => (
          <Fragment key={item.heading}>
            <Box className={classes.highlightsSectionContainer}>
              <Box className={classes.highlightsSectionHeader}>{item.heading}</Box>
              <Box className={classes.highlightsSectionItems}>
                {item.content.map((highlight) => (
                  <Box key={highlight.header} className={classes.highlightItemContainer}>
                    <Box className={classes.highlightItemIcon}>{highlight.icon}</Box>
                    <Box className={classes.highlightItemHeader}>{highlight.header}</Box>
                    <Box className={classes.highlightItemContent}>{highlight.content}</Box>
                  </Box>
                ))}
              </Box>
            </Box>

            <Divider style={dividerStyles} />
          </Fragment>
        ))}
      </Box>

      <Box className={classes.plansContainer}>
        <PlanDetailsCard
          plan='Lite plan'
          // loading={obeePricing.pending}
          disabled={restaurant.obeeEnabled}
          // price={`+$${obeePricing.data.lite}`}
          price='+$10'
          topItems={['Bookings widget - EatClub branded', 'Automated emails - EatClub branded']}
          bottomItems={[
            'Run sheet',
            'Waitlist management',
            'Notifications',
            'Booking limits',
            'Credit card as security',
          ]}
          ctaLabel={liteCtaLabel}
          onRequest={() => (isStaff ? setSignUpVenue('lite') : setRequestAccess('lite'))}
        />

        <PlanDetailsCard
          highlightTop
          plan='Premium'
          // loading={obeePricing.pending}
          disabled={
            (restaurant.obeeEnabled && restaurant.premium) || (restaurant.obeeEnabled && isStaff)
          } // TODO: direct venue upgrading is not available till apis are there, but still allow venues to request an upgrade
          // price={`+$${obeePricing.data.premium}`}
          price='+$50'
          topItems={[
            'Branded bookings widget',
            'Branded & personalise emails',
            'Floor Plan + Timeline',
          ]}
          bottomItems={[
            'Run sheet',
            'Waitlist management',
            'Notifications',
            'Booking limits',
            'Credit card as security',
          ]}
          ctaLabel={premiumCtaLabel}
          onRequest={() => (isStaff ? setSignUpVenue('premium') : setRequestAccess('premium'))}
        />
      </Box>

      <RequestAccessModal
        plan={requestAccess}
        isOpen={!isEmpty(requestAccess)}
        onClose={() => setRequestAccess(null)}
        onConfirm={requestConfirm}
        loading={confirming}
      />

      <ConfirmedModal
        isOpen={openRequestConfirmedModal}
        onClose={() => setOpenRequestConfirmedModal(false)}
        heading='Thank you!'
        subHeading='Your request has been received and we will be in touch with you shortly.'
        ctaLabel='Back to site'
        onClick={() => setOpenRequestConfirmedModal(false)}
      />

      <SignUpModal
        plan={signUpVenue}
        isOpen={!isEmpty(signUpVenue)}
        onClose={() => setSignUpVenue(null)}
        onConfirm={onboardConfirm}
        onUpgrade={upgradeConfirm}
        loading={confirming}
        slugError={slugError}
        setSlugError={setSlugError}
        isUpgrade={restaurant.obeeEnabled && !restaurant.premium}
      />

      <ConfirmedModal
        isOpen={openOnboardConfirmedModal}
        onClose={() => setOpenOnboardConfirmedModal(false)}
        heading='Success, account created'
        subHeading={`A confirmation email has been sent to ${
          obeeOnboard.data.operationsEmail
        }. Go live date will be on the ${formatDate(
          obeeOnboard.data.obeeGoLiveDate,
          'Do of MMMM YYYY',
        )}`}
        ctaLabel='Access the bookings tool'
        onClick={() => history.push('/livebookings/runsheet')}
      />

      <ConfirmedModal
        isOpen={openUpgradeConfirmedModal}
        onClose={() => setOpenUpgradeConfirmedModal(false)}
        heading='Success!'
        subHeading={`${restaurant.name} has been upgraded to the EatClub Bookings premium plan.`}
        ctaLabel='Access the bookings tool'
        onClick={() => history.push('/livebookings/runsheet')}
        image={<CelebrationSVG />}
      />
    </Box>
  );
};

BookingsLanding.propTypes = {
  onboardToObee: PropTypes.func.isRequired,
  requestObeeSignup: PropTypes.func.isRequired,
  // fetchObeePricing: PropTypes.func.isRequired,
  // obeePricing: obeePricingPropTypes.isRequired,
  obeeRequest: obeeRequestPropTypes.isRequired,
  obeeOnboard: obeeOnboardPropTypes.isRequired,
  obeeUpgrade: obeeUpgradePropTypes.isRequired,
  user: userPropTypes.isRequired,
  restaurantActive: restaurantPropTypes.isRequired,
  fetchObeeSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  obeeRequest: state.obeeRequest,
  obeeOnboard: state.obeeOnboard,
  obeeUpgrade: state.obeeUpgrade,
  // obeePricing: state.obeePricing,
  restaurantActive: state.restaurantActive,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onboardToObee: onboardToObeeAction,
      requestObeeSignup: requestObeeSignupAction,
      // fetchObeePricing: fetchObeePricingAction,
      fetchObeeSettings: fetchObeeSettingsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BookingsLanding);
