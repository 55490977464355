import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Modal,
  CenteredContent,
  Button,
  InlineTextField,
  TextArea,
  Dropdown,
} from '@eatclub-apps/ec-component-library';
import { dealPropTypes } from '../../../data/models/Deal';
import { trackEvent } from '../../../utils/analytics';
import { getDayFromInt, getOfferTime } from '../../../utils/helpers';
import useStyles, { modalInputStyle, modalStyle } from './ModalStyles';
import Pencil from '../../../assets/pencil.svg';
import ModalSectionHeader from './ModalSectionHeader';

const RequestEditOfferModal = ({ isOpen, offer, onClose, onSave }) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const [editOfferReason, setEditOfferReason] = useState(null);
  const [editOfferAdditionalReason, setEditOfferAdditionalReason] = useState(null);

  const [contactName, setContactName] = useState(null);
  const [contactPhone, setContactPhone] = useState(null);

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: edit_offer_request');
    }
  }, [isOpen]);

  return (
    <Modal
      inputStyle={modalInputStyle}
      fullWidth={false}
      style={modalStyle}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
      footer={[
        <Button
          onClick={onClose}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='tertiary'
        >
          Cancel
        </Button>,
        <Button
          onClick={() =>
            onSave(editOfferReason, editOfferAdditionalReason, contactName, contactPhone)
          }
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='primary'
        >
          Request a call
        </Button>,
      ]}
    >
      {isOpen && (
        <Box className={classes.modalBody}>
          <Box className={classes.modalContent}>
            <Box display='flex' alignItems='center' style={{ gap: '8px' }}>
              <Pencil height='24px' width='24px' />
              <Box className={classes.modalHeader}>Edit offer</Box>
            </Box>

            <Box>
              <Box className={classes.inlineText}>
                <Box>
                  <Box className={classes.discountLabel}>{offer?.discount}</Box>
                </Box>
                <CenteredContent>
                  on {getDayFromInt(offer?.dayOfWeek)} {getOfferTime(offer)}
                  {offer.recurring ? ', repeats weekly' : ''}
                </CenteredContent>
              </Box>
            </Box>

            <Box style={{ maxWidth: '580px' }}>
              This offer was set up by your account manager. Request a call and they’ll be in touch
              shortly to help you make any changes.
            </Box>

            <Box>
              <ModalSectionHeader header='Let us know why' />

              <Box mt='20px' display='flex' flexDirection='column' style={{ gap: '15px' }}>
                <Dropdown
                  allowDeselect
                  items={[
                    { label: 'Restaurant became busy', value: 'busy' },
                    { label: 'Staffing issue', value: 'staffIssue' },
                    { label: "Time isn't suitable", value: 'badTime' },
                    { label: 'Offer percentage too high', value: 'offerTooHigh' },
                    { label: 'Other', value: 'other' },
                  ]}
                  onSelect={setEditOfferReason}
                  placeholder='Select a reason for editing the offer'
                  value={editOfferReason}
                />

                <TextArea
                  placeholder='Additional Reason?'
                  resizable={false}
                  value={editOfferAdditionalReason}
                  onChange={setEditOfferAdditionalReason}
                />
              </Box>
            </Box>

            <Box>
              <ModalSectionHeader header='Contact details' />

              <Box className={classes.impactRow} style={{ maxWidth: '300px' }}>
                <Box>Name</Box>
                <InlineTextField
                  value={contactName}
                  onChange={setContactName}
                  style={{
                    textFieldInner: { padding: '5px 10px' },
                    input: { width: '100%' },
                  }}
                  placeholder='Empty (optional)'
                  maxCharacters={24}
                />
              </Box>

              <Box className={classes.impactRow} style={{ maxWidth: '300px' }}>
                <Box>Phone</Box>
                <InlineTextField
                  value={contactPhone}
                  onChange={setContactPhone}
                  style={{
                    textFieldInner: { padding: '5px 10px' },
                    input: { width: '100%' },
                  }}
                  placeholder='Empty (optional)'
                  maxCharacters={24}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

RequestEditOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  offer: dealPropTypes.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default RequestEditOfferModal;
