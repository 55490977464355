/**
 * Formats the `minutes` to time. Eg, 600 > 6am.
 *
 * @param minutes Number of minutes
 * @param format Time format, default 'h:mm a'
 */
import moment from 'moment';

export const minutesToMoment = (minutes) => {
  if (minutes === null || minutes === undefined) {
    return moment().startOf('day');
  }

  return moment().startOf('day').add(minutes, 'minutes');
};

/**
 * @deprecated - use timeFromInt
 * @param minutes
 * @param format
 * @returns {string}
 */
export const minutesToTime = (minutes, format = 'h:mm a') =>
  minutesToMoment(minutes).format(format);
