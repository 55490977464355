import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal, Button, TextArea } from '@eatclub-apps/ec-component-library';
import { useDispatch } from 'react-redux';
import { endGoalAction } from '../../../actions/goalsAction';
import { dealsPropTypes } from '../../../data/models/Deal';
import { goalPropTypes } from '../../../data/models/Goal';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency, pluralise } from '../../../utils/helpers';
import useStyles, { modalInputStyle, modalStyle } from './ModalStyles';

const EndGoalModal = ({ isOpen, onClose, onSave, targetRevenue, goal, deals }) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const today = moment().startOf('day');
  const end = moment(goal?.endDate);
  const daysLeft = Math.max(0, end.diff(today, 'days')); // Max is to make sure we don't go negative

  const [endGoalReason, setEndGoalReason] = useState('');
  const [submitting, setSubmitting] = useState(false);

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: end_goal_modal');
    }
  }, [isOpen]);

  const save = () => {
    setSubmitting(true);
    trackEvent('button_click: confirm_end_goal');

    dispatch(endGoalAction(goal?.objectId, endGoalReason))
      .then(onSave)
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal
      inputStyle={modalInputStyle}
      fullWidth={false}
      style={modalStyle}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
      footer={[
        <Button
          onClick={onClose}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='tertiary'
        >
          Cancel
        </Button>,
        <Button
          disabled={submitting}
          loading={submitting}
          onClick={save}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='primary'
        >
          End my goal
        </Button>,
      ]}
    >
      <Box className={classes.modalBody}>
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeader}>End your current goal</Box>

          <Box mt='8px'>
            <Box style={{ maxWidth: '580px' }}>
              This will remove all of your offers from the EatClub Marketplace.
            </Box>
          </Box>

          <Box className={classes.modalContentSmall}>
            <Box className={classes.impactRowSmall}>
              <Box>Current revenue goal</Box>
              <Box className={classes.impactValue}>{formatCurrency(targetRevenue, false)}</Box>
            </Box>
            <Box className={classes.impactRowSmall}>
              <Box>Total Offers</Box>
              <Box className={classes.impactValue}>{deals?.data?.length}</Box>
            </Box>
            <Box className={classes.impactRowSmall}>
              <Box>Current total revenue</Box>
              <Box className={classes.impactValue}>{formatCurrency(goal?.revenue, false)}</Box>
            </Box>
            <Box className={classes.impactRowSmall}>
              <Box>Days left</Box>
              <Box className={classes.impactValue}>
                {daysLeft} {pluralise('day', daysLeft)}
              </Box>
            </Box>
          </Box>

          <Box>
            <Box className={classes.subheading}>Let us know why</Box>
            <Box mt='10px'>
              <TextArea
                placeholder="I'm ending my goal because..."
                resizable={false}
                value={endGoalReason}
                onChange={setEndGoalReason}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

EndGoalModal.defaultProps = {
  onSave: () => {},
};

EndGoalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  targetRevenue: PropTypes.number.isRequired,
  goal: goalPropTypes.isRequired,
  deals: dealsPropTypes.isRequired,
};

export default EndGoalModal;
