/* eslint-disable */
import React from 'react';
import { ListItem, ListItemIcon, ListItemText, List } from '@mui/material';
import { SubdirectoryArrowRight } from '@mui/icons-material';
import MenuChoice from './MenuChoice';

const MenuChoices = ({ option }) => {
  const { choices } = option;

  return (
    <React.Fragment key={option.objectId}>
      <ListItem>
        <ListItemIcon>
          <SubdirectoryArrowRight />
        </ListItemIcon>
        <ListItemText primary={option.optionTitle} secondary={option.optionDescription} />
      </ListItem>
      {choices.length > 0 && (
        <List component='div' disablePadding>
          {choices.map((choice, index) => (
            <MenuChoice key={index} choice={choice} optionId={option.objectId} index={index} />
          ))}
        </List>
      )}
    </React.Fragment>
  );
};

export default MenuChoices;
