import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import routes from './Routes';

// Routes
import NotFound from '../components/notFound/NotFound';

const SwitchRoutes = ({ activeRestaurant }) => {
  // NOTE: Do not show Deals page if only platform is whitelabel
  const whitelabel = activeRestaurant.platform === 'whitelabel';
  const { obeeEnabled, premium, ecPayEnabled } = activeRestaurant;

  const getDefaultRoute = () => {
    if (obeeEnabled) {
      if (premium) {
        return '/livebookings/floorplan';
      }

      return '/livebookings/runsheet';
    }

    return '/analytics';
  };

  const defaultRoute = getDefaultRoute();

  return (
    <Switch>
      {/* Default route, not necessarily analytics */}
      <Route exact path={['/', '/index.html']}>
        <Redirect to={defaultRoute} />
      </Route>
      {routes.map((route) => {
        if (route.blockWhiteLabel && whitelabel) {
          return (
            <Route key={route.path} exact={route.exact} path={route.path}>
              <Redirect to='/' />
            </Route>
          );
        }

        if (route.ecPayEnabledRequired && !ecPayEnabled) {
          return (
            <Route key={route.path} exact={route.exact} path={route.path}>
              <Redirect to='/' />
            </Route>
          );
        }

        if (route.obeeEnabledRequired) {
          if (!obeeEnabled) {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                <Redirect to='/livebookings' />
              </Route>
            );
          }

          if (route.premiumRequired && !premium) {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                <route.premiumRequired />
              </Route>
            );
          }

          if (route.path === '/livebookings' && premium) {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                <Redirect to='/livebookings/runsheet' />
              </Route>
            );
          }
        }

        return (
          <Route key={route.path} exact={route.exact} path={route.path}>
            <route.main />
          </Route>
        );
      })}
      {/* Route doesnt exist in Routes */}
      <Route path='*'>
        <NotFound />
      </Route>
    </Switch>
  );
};

SwitchRoutes.propTypes = {
  activeRestaurant: PropTypes.shape({
    platform: PropTypes.string,
    obeeEnabled: PropTypes.bool,
    premium: PropTypes.bool,
    ecPayEnabled: PropTypes.bool,
  }).isRequired,
};

export default SwitchRoutes;
