import React from 'react';
import PropTypes from 'prop-types';

/**
 * @author https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 */
export const ConditionalWrapper = ({ children, condition, wrapper }) =>
  condition ? wrapper(children) : <>{children}</>;

ConditionalWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  condition: PropTypes.bool.isRequired,
  wrapper: PropTypes.func.isRequired,
};
