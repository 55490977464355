/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Tabs,
  Tab,
  Paper,
  Box,
  Typography,
  Card,
  CardContent,
  Link as MuiLink,
  Chip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { clearMenusAction, fetchMenusAction } from '../../actions/menusAction';
import { usePageViews } from '../../utils/analytics';
import MenuTabPanelContainer from './MenuTabPanelContainer';
import LoadingBox from '../loading/LoadingBox';
import useStyles from './MenusStyles';

const Menu = ({
  clearMenusAction,
  fetchMenusAction,
  menus,
  activeRestaurant,
  userId,
  userType,
}) => {
  const classes = useStyles();
  usePageViews();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    fetchMenusAction(activeRestaurant.objectId, activeRestaurant.menuVersion, userId, userType);

    return () => {
      clearMenusAction();
    };
  }, [
    clearMenusAction,
    fetchMenusAction,
    activeRestaurant.objectId,
    activeRestaurant.version,
    userId,
    userType,
  ]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    // NOTE: ensure at least menu is always selected

    if (menus.data.length === 0) {
      return;
    }

    if (activeTab >= menus.data.length) {
      setActiveTab(menus.data.length - 1);
    }
  }, [activeTab, menus.data.length]);

  if (menus.fetching) {
    return (
      <div style={{ flexGrow: 1 }}>
        <Paper>
          <LoadingBox bars={false} />
        </Paper>
      </div>
    );
  }

  if (menus.data.length === 0) {
    return (
      <div style={{ flexGrow: 1 }}>
        <Card>
          <CardContent>
            <Typography style={{ marginBottom: 0 }}>
              There were no menus found. Please{' '}
              <MuiLink component={Link} to='/support?discussion=2' color='primary'>
                contact your Account Manager
              </MuiLink>{' '}
              to create a menu.
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }

  const minutesToTime = (minutes, format = 'h:mm a') =>
    moment().startOf('day').add(minutes, 'minutes').format(format);

  return (
    <div style={{ flexGrow: 1 }}>
      <Paper>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Tabs
            variant='scrollable'
            scrollButtons={activeTab < menus.data.length}
            value={activeTab}
            indicatorColor='primary'
            textColor='primary'
            onChange={handleChange}
          >
            {menus.data.map((menu, index) => {
              const active = index === activeTab;

              const typeText = !menu.type
                ? `Takeaway + Dine in`
                : menu.type === 'takeaway'
                  ? 'Takeaway'
                  : 'Dine In';

              const isTimeLimited = menu.startTime > -1 && menu.endTime > -1;

              const startTime = minutesToTime(menu.startTime);
              const endTime = minutesToTime(menu.endTime);

              return (
                <Tab
                  className={classes.tab}
                  key={menu.objectId}
                  label={
                    <div>
                      <div>
                        {menu.menuTitle} {!menu.enabled && <small>(Disabled)</small>}
                      </div>
                      <div>
                        <small>
                          {isTimeLimited ? (
                            <>
                              {startTime} - {endTime}
                            </>
                          ) : (
                            'All Day'
                          )}
                        </small>
                      </div>
                    </div>
                  }
                  icon={
                    <Box display='flex' flexDirection='column' style={{ marginTop: 6 }}>
                      <Chip
                        style={{
                          height: '18px',
                          fontSize: '0.625rem',
                        }}
                        color={active ? 'primary' : 'secondary'}
                        size='small'
                        label={typeText}
                      />
                    </Box>
                  }
                />
              );
            })}
          </Tabs>
        </Box>
      </Paper>

      {menus.data.map((menu, index) => (
        <MenuTabPanelContainer key={menu.objectId} value={activeTab} menu={menu} index={index} />
      ))}
    </div>
  );
};

const getMenusByPlatform = (menus, platform) => {
  const newData = menus.data.filter((menu) => (!menu.platform ? true : menu.platform === platform));

  return {
    ...menus,
    data: newData,
  };
};

Menu.propTypes = {
  clearMenusAction: PropTypes.func.isRequired,
  fetchMenusAction: PropTypes.func.isRequired,
  menus: PropTypes.shape({}).isRequired,
  activeRestaurant: PropTypes.shape({}).isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  menus: getMenusByPlatform(state.menus, state.platform.platform),
  platform: state.platform.platform,
  activeRestaurant: state.restaurantActive.restaurant,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearMenusAction,
      fetchMenusAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
