import { Box } from '@mui/material';
import React from 'react';
import useStyles from './BookingsLandingStyles';

const BookingsLandingPageTitle = () => {
  const classes = useStyles();

  return (
    <Box className={classes.pageTitleContainer}>
      <Box>
        <Box className={classes.introducingText}>INTRODUCING</Box>
        <Box className={classes.pageTitleHeaderContainer}>
          <Box className={classes.pageTitleHeader}>EatClub Bookings</Box>
        </Box>
      </Box>
      <Box className={classes.pageTitleBlurb}>
        Time to blend your bookings diary! EatClub now provides market leading software to manage
        bookings online.
      </Box>
    </Box>
  );
};

export default BookingsLandingPageTitle;
