import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory, matchPath } from 'react-router-dom';
import { AppBar, IconButton, Toolbar, Typography, Box } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material/';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@eatclub-apps/ec-component-library';
import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';
import Loading from '../loading/Loading';
import { breadcrumbsPropTypes } from '../../../reducers/breadcrumbsReducer';
import routes from '../../../routes/Routes';
import LeftCaret from '../../../assets/left_caret.svg';
import LogoImage from '../../../assets/logoEatClub.svg';
import useStyles from './HeaderStyles';
import { trackEvent } from '../../../utils/analytics';

const AppHeader = ({ breadcrumbs, children, showDrawer, premium }) => {
  const location = useLocation();
  const classes = useStyles();
  const ref = useRef(null);
  const history = useHistory();

  const route = routes.find((routeToFind) => matchPath(location.pathname, routeToFind));
  const hideHeader = route?.premiumRequired && !premium;
  const pageTitle = route?.pageTitle || null;
  const customPageTitle = route?.customPageTitle || null;
  const headerAction = route?.action;
  const headerBackNavigation = route?.backNavigation;

  const hasBreadcrumbs = breadcrumbs?.data?.length > 0;
  const hasHeaderContent = pageTitle || headerAction || headerBackNavigation || customPageTitle;

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.containerInner}>
          <Box className={classes.mobileHeader}>
            <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={showDrawer}>
              <MenuIcon />
            </IconButton>
            <LogoImage className={classes.menuLogo} />
          </Box>
          {!hideHeader && (
            <AppBar ref={ref} position='static' className={classes.appBar} enableColorOnDark>
              <Toolbar className={classes.headerToolbar}>
                <Box
                  className={classes.header}
                  style={{ marginTop: hasHeaderContent || hasBreadcrumbs ? '80px' : '0' }}
                >
                  {(hasBreadcrumbs || hasHeaderContent) && (
                    <Box style={{ width: '100%' }}>
                      {hasBreadcrumbs && (
                        <Box
                          className={classes.breadcrumbContainer}
                          style={{ paddingBottom: pageTitle || headerAction ? '60px' : '30px' }}
                        >
                          <Breadcrumbs />
                        </Box>
                      )}

                      {hasHeaderContent && (
                        <Box className={classes.titleContainer}>
                          {(customPageTitle && customPageTitle()) || (
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                              {headerBackNavigation && (
                                <Box
                                  className={classes.headerBackNavigation}
                                  style={{
                                    marginBottom: pageTitle ? '20px' : '0',
                                    cursor: 'pointer',
                                  }} // Add extra space if we're showing a title too
                                  onClick={() => history.push(headerBackNavigation.route)}
                                >
                                  <Box className={classes.backArrowContainer}>
                                    <LeftCaret
                                      style={{
                                        position: 'absolute',
                                        left: '4px',
                                        top: '3px',
                                        color: '#000000',
                                      }}
                                    />
                                  </Box>
                                  <Box className={classes.backNavigationLabel}>
                                    {headerBackNavigation?.label}
                                  </Box>
                                </Box>
                              )}
                              <Typography variant='h1' className={classes.title}>
                                {pageTitle}
                              </Typography>
                            </Box>
                          )}
                          {headerAction && (
                            <Button
                              type='secondary'
                              className={classes.headerButton}
                              style={{ button: { marginBottom: '10px' } }}
                              text={headerAction?.label}
                              onClick={() => {
                                trackEvent(`header_button_click: ${headerAction?.label}`);
                                if (headerAction?.route) {
                                  history.push(headerAction?.route);
                                } else {
                                  headerAction?.onClick();
                                }
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Toolbar>
              <Loading />
            </AppBar>
          )}
          {children}
        </Box>
      </Box>
    </>
  );
};

AppHeader.defaultProps = {
  premium: false,
};

AppHeader.propTypes = {
  premium: PropTypes.bool,
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  showDrawer: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  premium: state.restaurantActive.restaurant?.premium,
  breadcrumbs: state.breadcrumbs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
