import { Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { opportunitiesPropTypes } from '../../../reducers/dealDailyOpposReducer';
import { pluralise } from '../../../utils/helpers';
import TopOpportunity from '../Opportunity/TopOpportunity';
import useStyles from './OffersStyles';

const TopOpportunities = ({ opportunities }) => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.opportunitiesHeaderContainer}>
        <Box className={classes.opportunitiesHeader}>
          Daily {pluralise('opportunity', opportunities?.length, 'opportunities')}
        </Box>
      </Box>
      <Divider className={classes.oppoTitleDivider} />
      {opportunities?.map((opportunity, index) => (
        <Box>
          <TopOpportunity opportunity={opportunity} number={index + 1} />
          {index + 1 < opportunities.length && <Divider className={classes.oppoDivider} />}
        </Box>
      ))}
    </Box>
  );
};

TopOpportunities.defaultProps = {};

TopOpportunities.propTypes = {
  opportunities: PropTypes.arrayOf(opportunitiesPropTypes).isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopOpportunities);
