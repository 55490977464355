import { API } from 'aws-amplify';
import store from '../store';
import { devLog } from '../utils';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';

export const apiCall = (query, variables, addRestId = false) => {
  // Get state here so it doesn't have to be passed in from every component
  const state = store?.getState();
  const restId = state.restaurantActive.restaurant.objectId;
  const userId = state.user.userInfo.objectId;
  const { userType } = state.user.userInfo;

  const variablesWithExtra = {
    ...variables,
    ...(addRestId ? { restId } : {}),
  };

  return API.graphql(
    {
      query,
      variables: variablesWithExtra,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    },
    {
      'user-id': userId,
      'user-type': userType,
      'rest-id': restId,
      'app-version': getAppVersion(),
    },
  ).catch((error) => {
    throw new Error(error.errors[0].message);
  });
};

export const handleDeleteResponse = (response, action, model, itemId, dispatch) => {
  // Check for error message in response
  if (response?.error) {
    devLog('error', model, response?.error);

    dispatch({
      type: `${action}_FAILURE`,
      payload: `Unable to retrieve ${model}: ${response?.error}`,
    });
    return;
  }

  const data = response?.data?.[model];

  devLog('success', model, data);

  dispatch({
    type: `${action}_SUCCESS`,
    payload: itemId,
  });
};

export const handleResponse = (response, action, model, dispatch, extraValues = {}) => {
  // Check for error message in response
  if (response?.error) {
    devLog('error', model, response?.error);

    dispatch({
      type: `${action}_FAILURE`,
      payload: `Unable to retrieve ${model}: ${response?.error}`,
    });
    return;
  }

  // Responses are typically held by their resource name
  let data = response?.data;
  if (model) {
    data = response?.data?.[model];
  }

  devLog('success', model, data);

  dispatch({
    type: `${action}_SUCCESS`,
    payload: { data, ...extraValues },
  });
};

export const beginAction = (action, dispatch, object) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: action,
  });

  dispatch({
    type: `${action}_PENDING`,
    payload: {
      data: object,
    },
  });
};

export const endAction = (action, dispatch) => {
  dispatch({
    type: type.REMOVE_ID_APP_LOADING,
    payload: action,
  });
};

export const handleFailure = (error, action, model, dispatch) => {
  devLog('error', model, error);
  dispatch({
    type: `${action}_FAILURE`,
    payload: `Unable to retrieve obeeBookings: ${error}`,
  });
};

export const addMessage = (action, dispatch, context = 'success', message) => {
  if (message) {
    dispatch({
      type: type.ADD_MESSAGE,
      payload: {
        id: `${action}_${context}_${new Date().getTime()}`,
        message,
        severity: context,
      },
    });
  }
};

export const deleteItemAction = (
  action,
  query,
  variables,
  objectId, // The ID of the deleted object (so we can pass to reducer
  model,
  includeRestId, // Whether to inject the restId into the variables data
  dispatch,
) => {
  beginAction(action, dispatch, objectId);

  (async () => {
    try {
      const response = await apiCall(query, variables, includeRestId);
      handleDeleteResponse(response, action, model, objectId, dispatch);
    } catch (error) {
      handleFailure(error, action, model, dispatch);
    } finally {
      endAction(action, dispatch);
    }
  })();
};

export const makeApiAction = (
  action,
  query,
  variables,
  object, // The object being stored (e.g. so we can add it to the state)
  model,
  includeRestId, // Whether to inject the restId into the variables data
  dispatch,
  successMessage = null, // If provided, show a log message on success
  extraValues = null, // Extra values we might want to return to the reducer
  skipLoading = false, // Whether to prevent setting the app into a loading state. e.g. when polling
  errorMessage = null, // If provided, show a log message on error
) => {
  if (!skipLoading) {
    beginAction(action, dispatch, object);
  }

  (async () => {
    try {
      if (query) {
        const response = await apiCall(query, variables, includeRestId);
        handleResponse(response, action, model, dispatch, extraValues);
        if (successMessage) {
          addMessage(action, dispatch, 'success', successMessage);
        }
      }
    } catch (error) {
      handleFailure(error, action, model, dispatch);
      if (errorMessage) {
        addMessage(action, dispatch, 'error', errorMessage);
      }
    } finally {
      if (!skipLoading) {
        endAction(action, dispatch);
      }
    }
  })();
};
