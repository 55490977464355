import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './AppContainerStyles';

const AppContainer = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

AppContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppContainer;
