import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import { Button, Typography, Box } from '@mui/material';
import { Launch } from '@mui/icons-material';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useStyles from './TableStyles';
import { fetchInvoicesAction } from '../../actions/invoicesAction';
import { formatNumber, sortByDate } from '../../utils';
import { trackEvent } from '../../utils/analytics';

const BillingTable = ({ invoices, fetchInvoices, restId, userId, userType }) => {
  const classes = useStyles();
  const [tableInvoices, setTableInvoices] = useState([]);

  useEffect(() => {
    if (invoices.invoicesFetching || invoices.invoicesError) {
      return;
    }
    const invoicesObject = invoices.invoicesData.map((invoice) => {
      const date = moment(invoice.invoiceCreated).format(import.meta.env.VITE_DATE_FORMAT);
      const dueDate = moment(invoice.invoiceDueDate).format(import.meta.env.VITE_DATE_FORMAT);
      const object = {
        invoiceNumber: invoice.invoiceNumber,
        invoiceDate: date,
        invoiceDueDate: dueDate,
        invoiceUrl: invoice.invoiceUrl,
        invoiceId: invoice.invoiceId,
        invoiceTotal: invoice.invoiceTotal,
        invoiceOutstanding: null,
      };
      return object;
    });
    setTableInvoices(invoicesObject);
  }, [invoices.invoicesData, invoices.invoicesError, invoices.invoicesFetching]);

  useEffect(() => {
    if (!invoices.invoicesShouldFetch) {
      return;
    }
    fetchInvoices(restId, userId, userType);
  }, [fetchInvoices, invoices.invoicesShouldFetch, restId]);

  const renderDeal = (rowData) => {
    if (!rowData.invoiceNumber || !rowData.invoiceUrl) {
      return null;
    }

    return (
      <Button
        onClick={() => trackEvent('button_click: open_invoice')}
        variant='text'
        size='small'
        color='primary'
        target='_blank'
        href={rowData.invoiceUrl}
      >
        <Launch style={{ marginRight: 6 }} /> {rowData.invoiceNumber}
      </Button>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        title: 'Invoice #',
        field: 'invoiceNumber',
        render: (rowData) => renderDeal(rowData),
      },
      {
        title: 'Date',
        field: 'invoiceDate',
        customSort: (invoice1, invoice2) => sortByDate(invoice2.invoiceDate, invoice1.invoiceDate),
      },
      {
        title: 'Due Date',
        field: 'invoiceDueDate',
        customSort: (invoice1, invoice2) => sortByDate(invoice2.invoiceDate, invoice1.invoiceDate),
      },
      {
        title: 'Total Invoice Amount',
        field: 'invoiceTotal',
        render: (rowData) => `$${formatNumber(rowData.invoiceTotal)}`,
      },
      // // todo: implement webhooks to auto update this field
      // {
      //   title: "Total Balance Outstanding",
      //   field: "outstandingTotal",
      //   type: "currency",
      // },
    ],
    [],
  );

  return (
    <Box className={classes.tableStyles}>
      <MaterialTable
        isLoading={invoices.invoicesFetching}
        title={
          <>
            <Typography variant='h6' display='inline' style={{ marginRight: '.25rem' }}>
              Monthly invoices for offline vouchers
            </Typography>
            <Typography variant='h6' color='textSecondary' display='inline'>
              (payment by customer in-store)
            </Typography>
          </>
        }
        columns={tableColumns}
        data={tableInvoices}
        options={{
          emptyRowsWhenPaging: false,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          padding: 'dense',
          search: false,
          draggable: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No invoices to display',
          },
        }}
        style={{ marginBottom: '0.5rem' }}
      />
      <Typography variant='caption'>
        Please note these order charges are invoiced every month - approximately 7 days after the
        end of every month and charged via direct debit 7 days later.
      </Typography>
    </Box>
  );
};

BillingTable.propTypes = {
  fetchInvoices: PropTypes.func.isRequired,
  invoices: PropTypes.shape({
    invoicesData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    invoicesError: PropTypes.bool.isRequired,
    invoicesErrorMessage: PropTypes.string.isRequired,
    invoicesFetching: PropTypes.bool.isRequired,
    invoicesShouldFetch: PropTypes.bool.isRequired,
  }).isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  invoices: state.invoices,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchInvoices: fetchInvoicesAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BillingTable);
