/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useStyles from './CreateDealsStyles';
import { discountsAllDay, discountsTimeLimited } from '../../../constants';
import { isStaffUser } from '../../../utils/helpers';

const DealDiscount = ({ newDeal, setNewDeal, user }) => {
  const { discount } = newDeal;
  const { type } = newDeal;

  const classes = useStyles();

  const handleChange = (event) => {
    setNewDeal({
      ...newDeal,
      discount: { ...newDeal.discount, value: event.target.value },
      quantity: { ...newDeal.quantity, enabled: true },
    });
  };

  // note: allow staff to add 15% off time limited deals
  const additionalDiscountsTimeLimited = isStaffUser(user) ? ['15% Off'] : [];

  const options =
    type.value === 'allDay'
      ? discountsAllDay
      : [...additionalDiscountsTimeLimited, ...discountsTimeLimited];

  useEffect(() => {
    const optionIndex = options.findIndex((option) => option === discount.value);

    if (optionIndex === -1 && discount.value !== '') {
      setNewDeal({ ...newDeal, discount: options[0] });
    }
  }, [discount.value, newDeal, options, setNewDeal]);

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id='discount-select' style={{ backgroundColor: '#fff' }}>
          Deal Offered
        </InputLabel>
        <Select labelId='discount-select' value={discount.value} onChange={handleChange}>
          {options.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DealDiscount);
