import { makeStyles } from '@mui/styles';
import { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';

const getBackgroundColor = (status) => {
  if (status === 'cancelled') {
    return COLORS.DISABLED_GOAL_CARD;
  }
  if (status === 'disabled') {
    return COLORS.DISABLED_GOAL_CARD;
  }

  if (status === 'complete') {
    return COLORS.OFFER_CARD_COMPLETED_BACKGROUND;
  }

  return COLORS.WHITE;
};

const getFontColor = (status) => {
  if (status === 'cancelled') {
    return COLORS.DISABLED_TEXT;
  }

  if (status === 'disabled') {
    return COLORS.DISABLED_TEXT;
  }

  return COLORS.BODY_TEXT;
};

const getBarColor = (status) => {
  if (status === 'complete') {
    return COLORS.OFFER_CARD_STATUS_BAR_COMPLETED;
  }

  if (status === 'active') {
    return COLORS.OFFER_CARD_STATUS_BAR_ACTIVE;
  }

  if (status === 'disabled') {
    return COLORS.NIMBUS;
  }

  return COLORS.CLOUD;
};

const labelTypes = {
  GREEN: 'green',
  RED: 'red',
  GRAY: 'gray',
  WHITE: 'white',
  YELLOW: 'yellow',
  BLUE: 'blue',
};

const typeMap = {
  active: labelTypes.RED,
  complete: labelTypes.GREEN,
  live_later: labelTypes.GRAY,
  disabled: labelTypes.GRAY,
  cancelled: labelTypes.WHITE,
  upcoming: labelTypes.GRAY,
};

const getLabelType = (status) => {
  if (Object.keys(typeMap).includes(status)) {
    return typeMap[status];
  }

  return labelTypes.WHITE;
};

const getThemeForType = (status) => {
  const type = getLabelType(status);
  const themes = {
    green: {
      color: COLORS.WHITE,
      backgroundColor: COLORS.GREEN_LABEL,
    },
    red: {
      color: COLORS.DISABLED,
      backgroundColor: COLORS.GRAPEFRUIT,
    },
    gray: {
      color: COLORS.BODY_TEXT,
      backgroundColor: COLORS.CLOUD,
    },
    white: {
      color: '#313131',
    },
    yellow: {
      color: COLORS.CHARCOAL,
    },
    blue: {
      color: COLORS.CHARCOAL,
    },
  };

  if (!Object.keys(themes).includes(type)) {
    return themes.gray;
  }

  return themes[type];
};

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    borderRadius: '8px',
    width: '100%',
    backgroundColor: getBackgroundColor(props?.status),
    position: 'relative',
    ...theme.eatclub.typography.body.bodyText,
  }),
  innerContainer: (props) => ({
    padding: '13px 16px 13px 24px',
    borderRadius: '8px',
    border: '1px solid #DCDCDB',
    width: '100%',
    backgroundColor: getBackgroundColor(props?.status),
    ...theme.eatclub.typography.body.bodyText,
    color: getFontColor(props?.status),
    gap: '8px 24px',
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      padding: '13px 20px',
    },
  }),
  leftBar: (props) => ({
    position: 'absolute',
    backgroundColor: getBarColor(props?.status),
    height: '100%',
    width: '8px',
    borderRadius: '8px',

    [theme.breakpoints.down('xs')]: {
      width: '4px',
    },
  }),
  predictionLabel: {
    fontSize: '12px',
    color: '#6E6E6D',
    textTransform: 'uppercase',
    letterSpacing: '0.13em',
    minWidth: '140px',
    marginBottom: '4px',
  },
  predictionValue: {
    fontWeight: FONT_WEIGHTS.MEDIUM,
    lineHeight: '1',
  },
  headingRow: {
    display: 'inline-flex',
    gap: '10px',
    justifyContent: 'flex-start',
    minWidth: '200px',
  },
  statusAndPeakContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    ...theme.eatclub.typography.body.PXS,
  },
  peak: {
    border: `1px solid ${COLORS.SMOKE}`,
    color: COLORS.SMOKE,
    padding: '0 5px',
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    lineHeight: '22px',
  },
  details: {
    display: 'flex',
    gap: '16px',
    marginTop: '10px',
    lineHeight: '20px',
    color: '#6E6E6D',
    alignItems: 'center',
  },
  detail: {
    display: 'inline-flex',
    gap: '5px',
  },
  iconBackground: {
    height: '23px',
    width: '23px',
    backgroundColor: COLORS.CLOUD,
    borderRadius: '14px',
    position: 'relative',
  },
  goalTarget: {
    ...theme.small,
    color: COLORS.SMOKE,
    fontSize: '12px',
    lineHeight: '15px',
    marginTop: '12px',

    [theme.breakpoints.down('xs')]: {
      marginTop: '4px',
    },
  },
  actionMenu: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  predictionsContainer: {
    height: '50px',
    marginTop: '6px',

    [theme.breakpoints.down('xs')]: {
      marginTop: '0',
    },
  },
  statusLabel: (props) => ({
    ...getThemeForType(props?.status),
    ...theme.eatclub.typography.body.labelText,
    padding: '6px 7px',
    borderRadius: '4px',
    fontSize: '12px',
    lineHeight: '12px',
    whiteSpace: 'nowrap',
  }),
  subHeading: {
    ...theme.eatclub.typography.body.subheading,
  },
}));

export default useStyles;
