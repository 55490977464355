import * as type from '../actions/types';

const initialState = {
  sendSupportEmailPending: false,
  sendSupportEmailSuccess: false,
  sendSupportEmailError: false,
  sendSupportEmailErrorMessage: '',

  sendBillingEmailPending: false,
  sendBillingEmailSuccess: false,
  sendBillingEmailError: false,
  sendBillingEmailErrorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SEND_SUPPORT_EMAIL_PENDING:
      return {
        ...state,
        sendSupportEmailPending: true,
        sendSupportEmailSuccess: false,
        sendSupportEmailError: false,
        sendSupportEmailErrorMessage: '',
      };
    case type.SEND_SUPPORT_EMAIL_SUCCESS:
      return {
        ...state,
        sendSupportEmailPending: false,
        sendSupportEmailSuccess: true,
      };
    case type.SEND_SUPPORT_EMAIL_FAILURE:
      return {
        ...state,
        sendSupportEmailPending: false,
        sendSupportEmailError: true,
        sendSupportEmailErrorMessage: action.payload,
      };
    case type.CLEAR_SUPPORT_EMAIL_SUCCESS:
      return {
        ...state,
        sendSupportEmailPending: false,
        sendSupportEmailSuccess: false,
        sendSupportEmailError: false,
        sendSupportEmailErrorMessage: '',
      };

    case type.SEND_BILLING_EMAIL_PENDING:
      return {
        ...state,
        sendBillingEmailPending: true,
        sendBillingEmailSuccess: false,
        sendBillingEmailError: false,
        sendBillingEmailErrorMessage: '',
      };
    case type.SEND_BILLING_EMAIL_SUCCESS:
      return {
        ...state,
        sendBillingEmailPending: false,
        sendBillingEmailSuccess: true,
      };
    case type.SEND_BILLING_EMAIL_FAILURE:
      return {
        ...state,
        sendBillingEmailPending: false,
        sendBillingEmailError: true,
        sendBillingEmailErrorMessage: action.payload,
      };
    case type.CLEAR_BILLING_EMAIL_SUCCESS:
      return {
        ...state,
        sendBillingEmailPending: false,
        sendBillingEmailSuccess: false,
        sendBillingEmailError: false,
        sendBillingEmailErrorMessage: '',
      };

    default:
      return state;
  }
};
