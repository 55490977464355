/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button, useMediaQuery, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RemoveShoppingCart, ShoppingCart } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { toggleMenuItemStockAction } from '../../actions/menuItemStockAction';
import useStyles from './MenusStyles';
import { trackEvent } from '../../utils/analytics';

const MenuItemStock = ({ item, toggleMenuItemStockAction, restId, userId, userType }) => {
  const classes = useStyles();

  const theme = useTheme();
  const breakpointMdDown = useMediaQuery(theme.breakpoints.down('sm'));

  const isDisabledToday = (disabledDate) =>
    moment(disabledDate, 'YYYY-MM-DD').isSame(moment(), 'day');

  const inStock = !isDisabledToday(item.disableDate);

  const toggleInStock = () => {
    trackEvent('button_click: toggle_menu_item_stock');
    if (inStock) {
      toggleMenuItemStockAction(restId, item.objectId, false, userId, userType);
      return;
    }

    toggleMenuItemStockAction(restId, item.objectId, true, userId, userType);
  };

  // MOBILE BUTTON
  if (breakpointMdDown) {
    return (
      <IconButton
        size='small'
        color={inStock ? 'default' : 'primary'}
        className={inStock ? '' : 'listButtonDisabled'}
        onClick={toggleInStock}
        title='Toggle stock level'
      >
        {inStock ? <ShoppingCart fontSize='small' /> : <RemoveShoppingCart fontSize='small' />}
      </IconButton>
    );
  }

  // DESKTOP BUTTON
  return (
    <Button
      variant='text'
      size='small'
      startIcon={inStock ? <ShoppingCart /> : <RemoveShoppingCart />}
      className={`${classes.listButton} ${inStock ? '' : 'listButtonDisabled'}`}
      onClick={toggleInStock}
      title='Toggle stock level'
    >
      {inStock ? 'In Stock' : 'Out of Stock'}
    </Button>
  );
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ toggleMenuItemStockAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemStock);
