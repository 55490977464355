import * as type from '../actions/types';

const initialState = {
  numberOfFavs: 0,
  error: false,
  errorMessage: '',
  shouldFetch: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_FAVOURITES_SUCCESS:
      return {
        ...state,
        numberOfFavs: Number(action.payload),
        error: false,
        errorMessage: '',
        shouldFetch: false,
      };
    case type.FETCH_FAVOURITES_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
      };

    // if restaurant changes then reset invoices
    case type.SET_ACTIVE_RESTAURANT:
      return {
        ...state,
        shouldFetch: true,
      };

    default:
      return state;
  }
};
