/**
 * Sort by Date
 *
 * @param date1 first date
 * @param date2 next date
 * @param format format of dates
 */
import moment from 'moment';

export const sortByDate = (date1, date2, format = import.meta.env.VITE_DATE_FORMAT) => {
  const momentDate1 = moment(date1, format);
  const momentDate2 = moment(date2, format);

  if (momentDate1.isBetween(momentDate2)) {
    return -1;
  }
  if (momentDate1.isAfter(momentDate2)) {
    return 1;
  }
  return 0;
};
