/**
 * Sort Transactions by Created Date, and Arrival Time
 *
 * @param transaction1 first transaction
 * @param transaction2 next transaction
 */

import moment from 'moment';

export const sortTransactionsByDate = (transaction1, transaction2, direction = 'asc') => {
  const time1 = moment(transaction1.arrivalTime, 'h:mm A');
  const time2 = moment(transaction2.arrivalTime, 'h:mm A');

  const date1 = moment(transaction1.created)
    .startOf('day')
    .add(time1.hours(), 'hours')
    .add(time1.minutes(), 'minutes');

  const date2 = moment(transaction2.created)
    .startOf('day')
    .add(time2.hours(), 'hours')
    .add(time2.minutes(), 'minutes');

  if (date1.isBefore(date2)) {
    return direction === 'asc' ? -1 : 1;
  }
  if (date1.isAfter(date2)) {
    return direction === 'asc' ? 1 : -1;
  }
  return 0;
};
