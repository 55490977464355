import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { statusValues } from '../../../data/models/Goal';
import { getLabelForValue } from '../../../utils/helpers';
import StatusLabel, { labelTypes } from '../../label/Label';
import useStyles from './GoalAnalyticsStyles';

const GoalStatus = ({ status }) => {
  const classes = useStyles();

  const typeMap = {
    active: labelTypes.YELLOW,
    complete: labelTypes.GREEN,
    ontrack: labelTypes.OLIVE,
    offtrack: labelTypes.ORANGE,
  };

  const getLabelType = () => {
    if (Object.keys(typeMap).includes(status)) {
      return typeMap[status];
    }

    return labelTypes.WHITE;
  };

  return (
    <StatusLabel
      label={getLabelForValue(status, statusValues)} // TODO probably have this on the reducer
      type={getLabelType()}
      style={{ height: '26px' }}
    />
  );
};

GoalStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GoalStatus);
