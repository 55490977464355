import { formatNumber } from '../../utils';

/**
 * Gets the percentage difference between `value` and `valueCompare`
 * @param {*} value
 * @param {*} valueCompare
 * @returns
 */
export const getPercent = (value, valueCompare) => {
  if (typeof value !== 'number' || typeof valueCompare !== 'number') {
    return '-';
  }

  if (value > 0 && valueCompare === 0) {
    // cap to 100% increase
    return 100;
  }

  let num = (value - valueCompare) * 100;
  num /= valueCompare !== 0 ? valueCompare : 1;
  num = Math.round(num * 100) / 100;
  num = Math.abs(num);

  return num;
};

/**
 * Gets the change type between `value` and `valueCompare`
 * @param {*} value
 * @param {*} valueCompare
 * @returns
 */
export const getChange = (value, valueCompare) => {
  if (typeof value !== 'number' || typeof valueCompare !== 'number') {
    return '-';
  }

  if (value < valueCompare) {
    return 'loss';
  }
  if (value > valueCompare) {
    return 'gain';
  }
  return '-';
};

/**
 * Formats `value` as a readable currency data point
 * @param {*} value
 * @returns
 */
export const renderValue = (value) => {
  if (typeof value !== 'number') {
    return '-';
  }

  return `$${formatNumber(value, 0)}`;
};

/**
 * Gets the percentage from the bookings discount string
 * @param {string} discount e.g. "30% off"
 * @returns
 *
 * @example
 * getPercentageFromDiscount("40% off")
 * // returns 0.4
 */
export const getPercentageFromDiscount = (discount) => {
  const str = discount.slice(0, -5); // slice off "% off"
  return parseInt(str, 10) / 100;
};

/**
 * Gets the total guests from a list of bookings
 * @param {*} bookings
 * @returns {number}
 */
export const getTotalGuests = (bookings) =>
  bookings.reduce((accumulator, booking) => accumulator + booking.partySize, 0) ?? 0;

/**
 * Filters out the takeaway bookings
 * @param {*} bookings
 * @returns List of dine-in bookings
 */
export const getDineInBookings = (bookings) => bookings.filter((booking) => !booking.takeaway);

/**
 * Filters out the dine-in bookings
 * @param {*} bookings
 * @returns List of takeaway bookings
 */
export const getTakeawayBookings = (bookings) => bookings.filter((booking) => booking.takeaway);

/**
 * Returns `true` if the booking is online (offline bookings don't have a `total`)
 * @param {*} booking
 * @returns {boolean}
 */
export const isOnlineBooking = (booking) => parseFloat(booking.revenue) > 0;

/**
 * Filters out the offline bookings
 * @param {*} bookings
 * @returns List of online bookings
 */
export const getOnlineBookings = (bookings) => bookings.filter(isOnlineBooking);

/**
 * Filters out the online bookings
 * @param {*} bookings
 * @returns List of offline bookings
 */
export const getOfflineBookings = (bookings) =>
  bookings.filter((booking) => !isOnlineBooking(booking));

/**
 * Accumulates the rev before offer (`origTotal`) of a list of booking
 * @param {number} accumulator
 * @param {*} booking
 * @returns
 */
export const accumulateOrigTotal = (accumulator, booking) =>
  accumulator + parseFloat(booking.origTotal);

/**
 * Accumulates the rev before offer of a list of bookings
 * @param {*} bookings
 * @returns {number}
 */
export const getOnlineRevenueBeforeOffer = (bookings) =>
  bookings.reduce(accumulateOrigTotal, 0) ?? 0;

/**
 * Accumulates the rev after offer (`total`) of a list of booking
 * @param {number} accumulator
 * @param {*} booking
 * @returns
 */
export const accumulateTotal = (accumulator, booking) => accumulator + parseFloat(booking.revenue);

/**
 * Accumulates the rev after offer of a list of bookings
 * @param {*} bookings
 * @returns {number}
 */
export const getOnlineRevenueAfterOffer = (bookings) => bookings.reduce(accumulateTotal, 0) ?? 0;

/**
 * Accumulates the offline rev after offer  of a list of booking
 * @param {number} accumulator
 * @param {*} booking
 * @param {number} averageValue
 * @returns
 */
export const accumulateOfflineRevenue = (accumulator, booking, averageValue) =>
  accumulator + averageValue * (1 - getPercentageFromDiscount(booking.discount));

/**
 * Accumulates the offline rev after offer of a list of bookings
 * @param {*} bookings
 * @returns {number}
 */
export const getOfflineRevenueAfterOffer = (bookings, averageValue) =>
  bookings.reduce(
    (accumulator, booking) => accumulateOfflineRevenue(accumulator, booking, averageValue),
    0,
  ) ?? 0;
