import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    textAlign: 'left',
  },
  postedOpportunityRow: {
    display: 'flex',
    alignItems: 'stretch',
    width: '100%',
    flexWrap: 'wrap',
    border: '1px solid #E4E4E4',
    borderRadius: '8px',
  },
  checkContainer: {
    position: 'absolute',
    width: '49px',
    height: '49px',
    backgroundColor: '#619260',
    borderRadius: '49px',
    color: '#FFFFFF',
    right: '15px',
    top: '-24px',
  },
  checkBackground: {
    backgroundColor: '#619260',
    width: '7px',
    borderRadius: '8px',
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: ' column',
    gap: '20px',
    padding: '24px 40px',
    textAlign: 'center',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
}));

export default useStyles;
