import * as type from '../actions/types';

const initialState = {
  data: [],
  error: '',
  pendingOppos: [],
  pending: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.POST_DAILY_OPPO_PENDING:
      return {
        ...state,
        pendingOppos: [...state.pendingOppos, action.payload.oppoId],
        pending: true,
        error: '',
      };
    case type.POST_DAILY_OPPO_SUCCESS:
      const newPendingOppos = state.pendingOppos.filter((oppo) => action.payload.oppoId !== oppo);
      return {
        ...state,
        data: action.payload,
        pendingOppos: newPendingOppos,
        pending: newPendingOppos?.length > 0,
        success: true,
      };
    case type.POST_DAILY_OPPO_FAILURE:
      return {
        ...state,
        pendingOppos: [],
        pending: false,
        error: action.payload,
      };

    case type.CLEAR_POST_DAILY_OPPO:
      return {
        ...state,
        data: [],
        pendingOppos: [],
        pending: false,
        error: '',
        success: false,
      };

    default:
      return state;
  }
};
