import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import useStyles from './LabelStyles';

export const labelTypes = {
  GREEN: 'green',
  RED: 'red',
  GRAY: 'gray',
  WHITE: 'white',
  YELLOW: 'yellow',
  BLUE: 'blue',
  OLIVE: 'olive',
  ORANGE: 'orange',
};

// TODO add this to the component library
const Label = ({ label, type, rounded, style }) => {
  const classes = useStyles({ type, rounded, style });
  return <Box className={classes.label}>{label}</Box>;
};

Label.defaultProps = {
  label: '',
  type: 'gray',
  rounded: false,
  style: {},
};

Label.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  rounded: PropTypes.bool,
  style: PropTypes.shape({}),
};

export default Label;
