import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

const OrderRefundConfirmDialog = ({
  title,
  description,
  openDialog,
  handleCloseDialog,
  confirmAction,
}) => {
  const submit = () => {
    confirmAction();
    handleCloseDialog();
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        keepMounted={false}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={submit} color='primary' autoFocus>
            Yes, refund
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

OrderRefundConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.element.isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
};

export default OrderRefundConfirmDialog;
