import * as type from './types';

export const setActiveRestaurantIdAction = (restaurantId) => (dispatch) => {
  dispatch({
    type: type.SET_ACTIVE_RESTAURANT_ID,
    payload: restaurantId,
  });
};

export const setActiveRestaurantAction = (restaurant) => (dispatch) => {
  dispatch({
    type: type.SET_ACTIVE_RESTAURANT,
    payload: restaurant,
  });
};
