/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Box } from '@mui/material';
import { connect } from 'react-redux';

const Loading = ({ loading }) => {
  const { loadingIds } = loading;
  return (
    <>
      {loadingIds.length > 0 && (
        <Box style={{ marginTop: '-4px' }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

Loading.propTypes = {
  loading: PropTypes.shape({
    loadingIds: PropTypes.arrayOf(PropTypes.string),
    hasCompletedInitialLoad: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
});

export default connect(mapStateToProps, null)(Loading);
