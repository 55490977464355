import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { flagMenuIssue } from '../graphql/mutations';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

export const flagMenuAction =
  (restId, menuId, flagReason, comment = null, userId, userType) =>
  (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'MENU_FLAG',
    });

    dispatch({
      type: type.FLAG_MENU_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: flagMenuIssue,
            variables: {
              restId,
              menuId,
              flagReason,
              comment,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        devLog('success', 'menu item flag', response.data.flagMenuIssue);

        dispatch({
          type: type.FLAG_MENU_SUCCESS,
          payload: { updatedMenu: response.data.flagMenuIssue },
        });
      } catch (error) {
        devLog('error', 'menu item flag', error);

        dispatch({
          type: type.FLAG_MENU_FAILURE,
          payload: `Unable to report menu issue: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'MENU_FLAG',
        });
      }
    })();
  };

export const clearFlagMenuAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_FLAG_MENU,
  });
};
