/* eslint-disable */
import React, { useState } from 'react';
import {
  ListItem,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Divider,
  CircularProgress,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useStyles from './TableOrderStyles';
import TableOrderBooking from './TableOrderBooking';

const TableOrder = ({
  activeOrderId,
  handleCloseOrder,
  handleToggleOrder,
  order,
  orderFetching,
  isLast,
  mode,
  handleClickRefundItem,
}) => {
  const classes = useStyles();

  const orderId = order.objectId.slice(-6);
  const orderTime = moment(order.created).format('hh:mm A');

  const open = activeOrderId === order.objectId;
  const [orderEntering, setOrderEntering] = useState(false);

  const [orderLoading, setOrderLoading] = useState(false);

  return (
    <>
      <ListItem
        style={{ paddingRight: '50px' }}
        button
        onClick={() => !orderFetching && handleToggleOrder(order.objectId)}
      >
        <Box display='flex' justifyContent='space-between' style={{ flexGrow: 1 }}>
          <Box display='flex' alignItems='center'>
            <Typography
              display='inline'
              color='secondary'
              variant='body1'
              style={{ marginRight: '1rem' }}
            >
              #{orderId} - {orderTime}
            </Typography>
            <Typography
              display='inline'
              color='secondary'
              variant='subtitle1'
              style={{ marginRight: '1rem' }}
            >
              {order.numItems} {order.numItems > 1 ? 'items' : 'item'}
            </Typography>
          </Box>

          <Box>
            <Typography
              display='inline'
              color='secondary'
              variant='body1'
              style={{ padding: '0 1rem' }}
            >
              ${order.total}
            </Typography>
          </Box>
        </Box>

        <ListItemSecondaryAction style={{ right: 0 }}>
          <IconButton
            style={{ marginRight: '1rem' }}
            size='small'
            onClick={() => !orderFetching && handleToggleOrder(order.objectId)}
          >
            {orderLoading ? (
              <CircularProgress color='primary' size={22} />
            ) : (
              <ExpandMore className={`${classes.icon} ${open ? 'opened' : ''}`} />
            )}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <TableOrderBooking
        open={open}
        orderId={order.objectId}
        handleClose={handleCloseOrder}
        setOrderEntering={setOrderEntering}
        orderEntering={orderEntering}
        setOrderLoading={setOrderLoading}
        orderLoading={orderLoading}
        mode={mode}
        handleClickRefundItem={handleClickRefundItem}
      />
      {!isLast && <Divider />}
    </>
  );
};

const mapStateToProps = (state) => ({
  orderFetching: state.order.orderFetching,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TableOrder);
