import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    backgroundColor: theme.palette.secondary.dark,
    color: '#fff',
    padding: theme.spacing(1),
  },
}));

export default useStyles;
