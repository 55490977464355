import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CleanTextField, CleanDropdown } from '@eatclub-apps/ec-bookings-library';
import { countries, regions } from '../../utils/valueLists';
import useStyles, { dropdownStyles, textFieldStyles } from './ProfileStyles';
import FacebookIconSVG from '../../assets/icon_facebook.svg';
import TwitterIconSVG from '../../assets/icon_twitter.svg';
import InstaIconSVG from '../../assets/icon_insta.svg';
import { restaurantActivePropTypes } from '../../data/models/Restaurant';
import { saveRestaurantAction } from '../../actions/restaurantAction';

const Profile = ({ restaurantActive, saveRestaurant }) => {
  const classes = useStyles();

  const [name, setName] = useState(restaurantActive.restaurant.name || '');
  const [address, setAddress] = useState(restaurantActive.restaurant.address1 || '');
  const [city, setCity] = useState(restaurantActive.restaurant.region || '');
  const [state, setState] = useState(restaurantActive.restaurant.state || '');
  const [postcode, setPostcode] = useState(restaurantActive.restaurant.postcode || '');
  const [country, setCountry] = useState('Australia');
  const [phone, setPhone] = useState(restaurantActive.restaurant.phone || '');
  const [website, setWebsite] = useState(restaurantActive.restaurant.website || '');
  const [abn, setAbn] = useState(restaurantActive.restaurant.abn || '');
  const [tradingName, setTradingName] = useState(restaurantActive.restaurant.tradingName || '');
  const [instagram, setInstagram] = useState(restaurantActive.restaurant.instagramLink || '');
  const [facebook, setFacebook] = useState(restaurantActive.restaurant.facebookLink || '');
  const [twitter, setTwitter] = useState(restaurantActive.restaurant.twitterLink || '');

  useEffect(() => {
    setName(restaurantActive.restaurant.name || '');
    setAddress(restaurantActive.restaurant.address1 || '');
    setCity(restaurantActive.restaurant.region || '');
    setState(restaurantActive.restaurant.state || '');
    setPostcode(restaurantActive.restaurant.postcode || '');
    setCountry('Australia');
    setPhone(restaurantActive.restaurant.phone || '');
    setWebsite(restaurantActive.restaurant.website || '');
    setAbn(restaurantActive.restaurant.abn || '');
    setTradingName(restaurantActive.restaurant.tradingName || '');
    setInstagram(restaurantActive.restaurant.instagramLink || '');
    setFacebook(restaurantActive.restaurant.facebookLink || '');
    setTwitter(restaurantActive.restaurant.twitterLink || '');
  }, [restaurantActive.restaurant]);

  /**
   * Update the profile settings
   * Happens on blur when editing
   */
  const updateProfile = (fieldToUpdate, newValue) => {
    // uncomment when apis are done
    // if (isEmpty(restaurantActive.restaurant[fieldToUpdate]) && isEmpty(newValue)) {
    //   return;
    // }
    // if (restaurantActive.restaurant[fieldToUpdate] !== newValue) {
    //   saveRestaurant({ ...restaurantActive.restaurant, [fieldToUpdate]: newValue });
    // }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.twoColumn}>
        <Box className={classes.settings}>
          <CleanTextField
            label='Venue name'
            value={name}
            onChange={setName}
            style={textFieldStyles}
            onBlur={(e) => updateProfile('name', e.target.value)}
            disabled
          />
          <CleanTextField
            label='Address'
            value={address}
            onChange={setAddress}
            style={textFieldStyles}
            onBlur={(e) => updateProfile('address1', e.target.value)}
            disabled
          />
          <CleanTextField
            label='City'
            value={city}
            onChange={setCity}
            style={textFieldStyles}
            onBlur={(e) => updateProfile('region', e.target.value)}
            disabled
          />
          <CleanDropdown
            label='State / Territory'
            items={regions()}
            value={state}
            onSelect={(value) => {
              setState(value);

              updateProfile('state', value);
            }}
            style={dropdownStyles}
            disabled
          />
          <CleanTextField
            label='Postcode'
            value={postcode}
            onChange={setPostcode}
            style={textFieldStyles}
            onBlur={(e) => updateProfile('postcode', e.target.value)}
            disabled
          />
          <CleanDropdown
            label='Country'
            items={countries()}
            value={country}
            style={dropdownStyles}
            disabled
            onBlur={(e) => updateProfile('country', e.target.value)}
          />
          <CleanTextField
            label='Phone'
            value={phone}
            onChange={setPhone}
            style={textFieldStyles}
            onBlur={(e) => updateProfile('phone', e.target.value)}
            disabled
          />
          <CleanTextField
            label='Website'
            value={website}
            onChange={setWebsite}
            style={textFieldStyles}
            onBlur={(e) => updateProfile('website', e.target.value)}
            disabled
          />
          <CleanTextField
            label='ABN'
            value={abn}
            onChange={setAbn}
            style={textFieldStyles}
            onBlur={(e) => updateProfile('abn', e.target.value)}
            disabled
          />
          <CleanTextField
            label='Company or Trading Name'
            value={tradingName}
            onSelect={setTradingName}
            style={textFieldStyles}
            onBlur={(e) => updateProfile('tradingName', e.target.value)}
            disabled
          />
        </Box>
        <Box className={classes.settings}>
          <CleanTextField
            label={
              <Box className={classes.iconLabel}>
                <InstaIconSVG style={{ width: '20px' }} />
                <Box>Instagram</Box>
              </Box>
            }
            value={instagram}
            onChange={setInstagram}
            style={textFieldStyles}
            onBlur={(e) => updateProfile('instagramLink', e.target.value)}
            disabled
          />
          <CleanTextField
            label={
              <Box className={classes.iconLabel}>
                <FacebookIconSVG style={{ width: '20px' }} />
                <Box>Facebook</Box>
              </Box>
            }
            value={facebook}
            onChange={setFacebook}
            style={textFieldStyles}
            onBlur={(e) => updateProfile('facebookLink', e.target.value)}
            disabled
          />
          <CleanTextField
            label={
              <Box className={classes.iconLabel}>
                <TwitterIconSVG style={{ width: '20px' }} />
                <Box>Twitter</Box>
              </Box>
            }
            value={twitter}
            onChange={setTwitter}
            style={textFieldStyles}
            onBlur={(e) => updateProfile('twitterLink', e.target.value)}
            disabled
          />
        </Box>
      </Box>
    </Box>
  );
};

Profile.propTypes = {
  restaurantActive: restaurantActivePropTypes.isRequired,
  saveRestaurant: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  restaurantActive: state.restaurantActive,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveRestaurant: saveRestaurantAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
