/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, Typography } from '@mui/material';
import OrderItem from './OrderItem';
import RefundOrderItem from './RefundOrderItem';

const OrderCategory = ({ category, catIndex, mode, handleClickRefundItem }) => (
  <>
    <ListItem style={{ marginTop: catIndex !== 0 ? '0.5rem' : 0 }}>
      <Typography variant='subtitle2' color='primary'>
        {category.category}
      </Typography>
    </ListItem>

    {category.items.map((item, index) => (
      <React.Fragment key={index}>
        {mode === 'refund' ? (
          <RefundOrderItem item={item} handleClickRefundItem={handleClickRefundItem} />
        ) : (
          <OrderItem item={item} />
        )}
      </React.Fragment>
    ))}
  </>
);

OrderCategory.propTypes = {
  category: PropTypes.object.isRequired,
};

export default OrderCategory;
