/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Input, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ComparePercent from '../comparePercent/ComparePercent';

const DataPoint = ({ change, editable, handleSubmit, percent, saving, title, value }) => {
  const theme = useTheme();
  const [newValue, setNewValue] = useState(value);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      const num = parseInt(e.target.value, 10);
      setNewValue(Number.isNaN(num) ? 0 : num);
    }
  };

  return (
    <Grid item lg={12} sm={12} xs={12}>
      <Box
        style={{
          alignItems: 'center',
          display: 'grid',
          gap: '12px',
          gridTemplateColumns: '1fr 1fr',
          height: '56px',
        }}
      >
        <Typography variant='subtitle1' style={{ lineHeight: '1.5', width: '100px' }}>
          {title}
        </Typography>

        <Box style={{ display: 'flex', gap: '8px', justifyContent: 'space-between' }}>
          {editable ? (
            <Input
              color='secondary'
              endAdornment={
                value !== newValue && (
                  <Button
                    color='secondary'
                    disabled={saving}
                    onClick={() => handleSubmit(newValue)}
                    size='small'
                    type='submit'
                    variant='outlined'
                  >
                    SET
                  </Button>
                )
              }
              fullWidth
              onChange={handleChange}
              startAdornment='$'
              style={{ fontSize: theme.typography.h5.fontSize, fontWeight: 500 }}
              value={newValue}
            />
          ) : (
            <Typography variant='h5' style={{ fontWeight: 500 }}>
              {value}
            </Typography>
          )}

          {percent !== undefined && change !== undefined && (
            <ComparePercent percent={percent} change={change} />
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default DataPoint;
