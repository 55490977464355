import { makeStyles } from '@mui/styles';
import theme from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
  },
  tabBar: {
    display: 'flex',
    gap: 0,
    maxWidth: '100%',
    overflowX: 'auto',
    paddingBottom: '10px',
  },
  offerDay: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  dayName: {
    ...theme.eatclub.typography.heading.H5,
    fontWeight: 'bold',
  },
  notificationMessage: {
    backgroundColor: '#F9F9F9',
    width: '100%',
    padding: '16px',
    marginTop: '16px',
    marginBottom: '32px',
    fontSize: '18px',
    color: '#6E6E6D',
    borderRadius: '4px',
    lineHeight: '24px',
  },
}));

export default useStyles;
