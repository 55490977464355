export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const SET_LOGOUT = 'SET_LOGOUT';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';

export const FETCH_SSO_CREDS_PENDING = 'FETCH_SSO_CREDS_PENDING';
export const FETCH_SSO_CREDS_SUCCESS = 'FETCH_SSO_CREDS_SUCCESS';
export const FETCH_SSO_CREDS_FAILURE = 'FETCH_SSO_CREDS_FAILURE';

export const SIGNIN_PENDING = 'SIGNIN_PENDING';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';
export const CLEAR_SIGNIN = 'CLEAR_SIGNIN';

export const FETCH_FAVOURITES_PENDING = 'FETCH_FAVOURITES_PENDING';
export const FETCH_FAVOURITES_SUCCESS = 'FETCH_FAVOURITES_SUCCESS';
export const FETCH_FAVOURITES_FAILURE = 'FETCH_FAVOURITES_FAILURE';

export const UPDATE_AVERAGE_BOOKING_VALUE_PENDING = 'UPDATE_AVERAGE_BOOKING_VALUE_PENDING';
export const UPDATE_AVERAGE_BOOKING_VALUE_SUCCESS = 'UPDATE_AVERAGE_BOOKING_VALUE_SUCCESS';
export const UPDATE_AVERAGE_BOOKING_VALUE_FAILURE = 'UPDATE_AVERAGE_BOOKING_VALUE_FAILURE';

export const UPDATE_AVERAGE_ORDER_VALUE_PENDING = 'UPDATE_AVERAGE_ORDER_VALUE_PENDING';
export const UPDATE_AVERAGE_ORDER_VALUE_SUCCESS = 'UPDATE_AVERAGE_ORDER_VALUE_SUCCESS';
export const UPDATE_AVERAGE_ORDER_VALUE_FAILURE = 'UPDATE_AVERAGE_ORDER_VALUE_FAILURE';

export const FETCH_RESTAURANTS_PENDING = 'FETCH_RESTAURANTS_PENDING';
export const FETCH_RESTAURANTS_SUCCESS = 'FETCH_RESTAURANTS_SUCCESS';
export const FETCH_RESTAURANTS_FAILURE = 'FETCH_RESTAURANTS_FAILURE';
export const CLEAR_RESTAURANTS = 'CLEAR_RESTAURANTS';

export const FETCH_AFFILIATED_RESTAURANTS_PENDING = 'FETCH_AFFILIATED_RESTAURANTS_PENDING';
export const FETCH_AFFILIATED_RESTAURANTS_SUCCESS = 'FETCH_AFFILIATED_RESTAURANTS_SUCCESS';
export const FETCH_AFFILIATED_RESTAURANTS_FAILURE = 'FETCH_AFFILIATED_RESTAURANTS_FAILURE';

export const SAVE_RESTAURANT_PENDING = 'SAVE_RESTAURANT_PENDING';
export const SAVE_RESTAURANT_SUCCESS = 'SAVE_RESTAURANT_SUCCESS';
export const SAVE_RESTAURANT_FAILURE = 'SAVE_RESTAURANT_FAILURE';

export const FETCH_DEAL_PENDING = 'FETCH_DEAL_PENDING';
export const FETCH_DEAL_SUCCESS = 'FETCH_DEAL_SUCCESS';
export const FETCH_DEAL_FAILURE = 'FETCH_DEAL_FAILURE';
export const CLEAR_DEAL = 'CLEAR_DEAL';

export const FETCH_DEALS_PENDING = 'FETCH_DEALS_PENDING';
export const FETCH_DEALS_SUCCESS = 'FETCH_DEALS_SUCCESS';
export const FETCH_DEALS_FAILURE = 'FETCH_DEALS_FAILURE';
export const CLEAR_DEALS = 'CLEAR_DEALS';

export const FETCH_DEALS_BY_IDS_PENDING = 'FETCH_DEALS_BY_IDS_PENDING';
export const FETCH_DEALS_BY_IDS_SUCCESS = 'FETCH_DEALS_BY_IDS_SUCCESS';
export const FETCH_DEALS_BY_IDS_FAILURE = 'FETCH_DEALS_BY_IDS_FAILURE';
export const CLEAR_DEALS_BY_IDS = 'CLEAR_DEALS_BY_IDS';

export const FETCH_DEALS_BY_DAY_PENDING = 'FETCH_DEALS_BY_DAY_PENDING';
export const FETCH_DEALS_BY_DAY_SUCCESS = 'FETCH_DEALS_BY_DAY_SUCCESS';
export const FETCH_DEALS_BY_DAY_FAILURE = 'FETCH_DEALS_BY_DAY_FAILURE';
export const SET_SELECTED_DAY = 'SET_SELECTED_DAY';

export const CREATE_DEAL_SUCCESS = 'CREATE_DEAL_SUCCESS';

export const DELETE_DEAL_PENDING = 'DELETE_DEAL_PENDING';
export const DELETE_DEAL_SUCCESS = 'DELETE_DEAL_SUCCESS';
export const DELETE_DEAL_FAILURE = 'DELETE_DEAL_FAILURE';
export const CLEAR_DELETE_DEAL = 'CLEAR_DELETE_DEAL';

export const DISABLE_DEAL_PENDING = 'DISABLE_DEAL_PENDING';
export const DISABLE_DEAL_SUCCESS = 'DISABLE_DEAL_SUCCESS';
export const DISABLE_DEAL_FAILURE = 'DISABLE_DEAL_FAILURE';
export const CLEAR_DISABLE_DEAL = 'CLEAR_DISABLE_DEAL';

export const ENABLE_DEAL_PENDING = 'ENABLE_DEAL_PENDING';
export const ENABLE_DEAL_SUCCESS = 'ENABLE_DEAL_SUCCESS';
export const ENABLE_DEAL_FAILURE = 'ENABLE_DEAL_FAILURE';
export const CLEAR_ENABLE_DEAL = 'CLEAR_ENABLE_DEAL';

export const INCREASE_DEAL_PENDING = 'INCREASE_DEAL_PENDING';
export const INCREASE_DEAL_SUCCESS = 'INCREASE_DEAL_SUCCESS';
export const INCREASE_DEAL_FAILURE = 'INCREASE_DEAL_FAILURE';
export const CLEAR_INCREASE_DEAL = 'CLEAR_INCREASE_DEAL';

export const UPDATE_DEAL_PENDING = 'UPDATE_DEAL_PENDING';
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS';
export const UPDATE_DEAL_FAILURE = 'UPDATE_DEAL_FAILURE';
export const CLEAR_UPDATE_DEAL = 'CLEAR_UPDATE_DEAL';

export const HELP_DEAL_PENDING = 'HELP_DEAL_PENDING';
export const HELP_DEAL_SUCCESS = 'HELP_DEAL_SUCCESS';
export const HELP_DEAL_FAILURE = 'HELP_DEAL_FAILURE';
export const CLEAR_HELP_DEAL = 'CLEAR_HELP_DEAL';

export const FETCH_RESTAURANT_RATINGS_PENDING = 'FETCH_RESTAURANT_RATINGS_PENDING';
export const FETCH_RESTAURANT_RATINGS_SUCCESS = 'FETCH_RESTAURANT_RATINGS_SUCCESS';
export const FETCH_RESTAURANT_RATINGS_FAILURE = 'FETCH_RESTAURANT_RATINGS_FAILURE';

export const SET_SEARCH_DATES = 'SET_SEARCH_DATES';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';

export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_ID_APP_LOADING = 'SET_ID_APP_LOADING';
export const REMOVE_ID_APP_LOADING = 'REMOVE_ID_APP_LOADING';
export const CLEAR_APP_LOADING = 'CLEAR_APP_LOADING';

export const SET_APP_ERROR = 'SET_APP_ERROR';
export const REMOVE_APP_ERROR = 'REMOVE_APP_ERROR';
export const CLEAR_APP_ERROR = 'CLEAR_APP_ERROR';

export const SEND_SUPPORT_EMAIL_PENDING = 'SEND_SUPPORT_EMAIL_PENDING';
export const SEND_SUPPORT_EMAIL_SUCCESS = 'SEND_SUPPORT_EMAIL_SUCCESS';
export const SEND_SUPPORT_EMAIL_FAILURE = 'SEND_SUPPORT_EMAIL_FAILURE';
export const CLEAR_SUPPORT_EMAIL_SUCCESS = 'CLEAR_SUPPORT_EMAIL_SUCCESS';

export const SEND_BILLING_EMAIL_PENDING = 'SEND_BILLING_EMAIL_PENDING';
export const SEND_BILLING_EMAIL_SUCCESS = 'SEND_BILLING_EMAIL_SUCCESS';
export const SEND_BILLING_EMAIL_FAILURE = 'SEND_BILLING_EMAIL_FAILURE';
export const CLEAR_BILLING_EMAIL_SUCCESS = 'CLEAR_BILLING_EMAIL_SUCCESS';

export const FETCH_RESTAURANT_PENDING = 'FETCH_RESTAURANT_PENDING';
export const FETCH_RESTAURANT_SUCCESS = 'FETCH_RESTAURANT_SUCCESS';
export const FETCH_RESTAURANT_FAILURE = 'FETCH_RESTAURANT_FAILURE';

export const FETCH_ANALYTIC_BOOKINGS_PENDING = 'FETCH_ANALYTIC_BOOKINGS_PENDING';
export const FETCH_ANALYTIC_BOOKINGS_SUCCESS = 'FETCH_ANALYTIC_BOOKINGS_SUCCESS';
export const FETCH_ANALYTIC_BOOKINGS_FAILURE = 'FETCH_ANALYTIC_BOOKINGS_FAILURE';

export const FETCH_COMPARE_ANALYTIC_BOOKINGS_PENDING = 'FETCH_COMPARE_ANALYTIC_BOOKINGS_PENDING';
export const FETCH_COMPARE_ANALYTIC_BOOKINGS_SUCCESS = 'FETCH_COMPARE_ANALYTIC_BOOKINGS_SUCCESS';
export const FETCH_COMPARE_ANALYTIC_BOOKINGS_FAILURE = 'FETCH_COMPARE_ANALYTIC_BOOKINGS_FAILURE';

export const FETCH_TRANSACTION_BOOKINGS_PENDING = 'FETCH_TRANSACTION_BOOKINGS_PENDING';
export const FETCH_TRANSACTION_BOOKINGS_SUCCESS = 'FETCH_TRANSACTION_BOOKINGS_SUCCESS';
export const FETCH_TRANSACTION_BOOKINGS_FAILURE = 'FETCH_TRANSACTION_BOOKINGS_FAILURE';

export const FETCH_RESERVATIONS = 'FETCH_RESERVATIONS';
export const FETCH_RESERVATIONS_PENDING = 'FETCH_RESERVATIONS_PENDING';
export const FETCH_RESERVATIONS_SUCCESS = 'FETCH_RESERVATIONS_SUCCESS';
export const FETCH_RESERVATIONS_FAILURE = 'FETCH_RESERVATIONS_FAILURE';

export const FETCH_COMPARE_RESERVATIONS = 'FETCH_COMPARE_RESERVATIONS';
export const FETCH_COMPARE_RESERVATIONS_PENDING = 'FETCH_COMPARE_RESERVATIONS_PENDING';
export const FETCH_COMPARE_RESERVATIONS_SUCCESS = 'FETCH_COMPARE_RESERVATIONS_SUCCESS';
export const FETCH_COMPARE_RESERVATIONS_FAILURE = 'FETCH_COMPARE_RESERVATIONS_FAILURE';

export const FETCH_INVOICES_PENDING = 'FETCH_INVOICES_PENDING';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';

export const FETCH_PAYOUTS_PENDING = 'FETCH_PAYOUTS_PENDING';
export const FETCH_PAYOUTS_SUCCESS = 'FETCH_PAYOUTS_SUCCESS';
export const FETCH_PAYOUTS_FAILURE = 'FETCH_PAYOUTS_FAILURE';
export const CLEAR_PAYOUTS = 'CLEAR_PAYOUTS';

export const FETCH_PAYOUTS_WHITELABEL_PENDING = 'FETCH_PAYOUTS_WHITELABEL_PENDING';
export const FETCH_PAYOUTS_WHITELABEL_SUCCESS = 'FETCH_PAYOUTS_WHITELABEL_SUCCESS';
export const FETCH_PAYOUTS_WHITELABEL_FAILURE = 'FETCH_PAYOUTS_WHITELABEL_FAILURE';
export const CLEAR_PAYOUTS_WHITELABEL = 'CLEAR_PAYOUTS_WHITELABEL';

export const FETCH_ORDER_PENDING = 'FETCH_ORDER_PENDING';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
export const CLEAR_ORDER = 'CLEAR_ORDER';

export const FETCH_MENUS_PENDING = 'FETCH_MENUS_PENDING';
export const FETCH_MENUS_SUCCESS = 'FETCH_MENUS_SUCCESS';
export const FETCH_MENUS_FAILURE = 'FETCH_MENUS_FAILURE';
export const CLEAR_MENUS = 'CLEAR_MENUS';

export const FETCH_MENU_ITEMS_PENDING = 'FETCH_MENU_ITEMS_PENDING';
export const FETCH_MENU_ITEMS_SUCCESS = 'FETCH_MENU_ITEMS_SUCCESS';
export const FETCH_MENU_ITEMS_FAILURE = 'FETCH_MENU_ITEMS_FAILURE';
export const CLEAR_MENU_ITEMS = 'CLEAR_MENU_ITEMS';

export const FETCH_CATEGORIES_PENDING = 'FETCH_CATEGORIES_PENDING';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES';

export const FETCH_MENU_ITEM_OPTIONS_PENDING = 'FETCH_MENU_ITEM_OPTIONS_PENDING';
export const FETCH_MENU_ITEM_OPTIONS_SUCCESS = 'FETCH_MENU_ITEM_OPTIONS_SUCCESS';
export const FETCH_MENU_ITEM_OPTIONS_FAILURE = 'FETCH_MENU_ITEM_OPTIONS_FAILURE';
export const CLEAR_MENU_ITEM_OPTIONS = 'CLEAR_MENU_ITEM_OPTIONS';

export const FLAG_MENU_PENDING = 'FLAG_MENU_PENDING';
export const FLAG_MENU_SUCCESS = 'FLAG_MENU_SUCCESS';
export const FLAG_MENU_FAILURE = 'FLAG_MENU_FAILURE';
export const CLEAR_FLAG_MENU = 'CLEAR_FLAG_MENU';

export const TOGGLE_MENU_ITEM_STOCK_PENDING = 'TOGGLE_MENU_ITEM_STOCK_PENDING';
export const TOGGLE_MENU_ITEM_STOCK_SUCCESS = 'TOGGLE_MENU_ITEM_STOCK_SUCCESS';
export const TOGGLE_MENU_ITEM_STOCK_FAILURE = 'TOGGLE_MENU_ITEM_STOCK_FAILURE';
export const CLEAR_TOGGLE_MENU_ITEM_STOCK = 'CLEAR_TOGGLE_MENU_ITEM_STOCK';

export const TOGGLE_MENU_CHOICE_STOCK_PENDING = 'TOGGLE_MENU_CHOICE_STOCK_PENDING';
export const TOGGLE_MENU_CHOICE_STOCK_SUCCESS = 'TOGGLE_MENU_CHOICE_STOCK_SUCCESS';
export const TOGGLE_MENU_CHOICE_STOCK_FAILURE = 'TOGGLE_MENU_CHOICE_STOCK_FAILURE';
export const CLEAR_TOGGLE_MENU_CHOICE_STOCK = 'CLEAR_TOGGLE_MENU_CHOICE_STOCK';

export const TOGGLE_PRODUCT_STOCK_PENDING = 'TOGGLE_PRODUCT_STOCK_PENDING';
export const TOGGLE_PRODUCT_STOCK_SUCCESS = 'TOGGLE_PRODUCT_STOCK_SUCCESS';
export const TOGGLE_PRODUCT_STOCK_FAILURE = 'TOGGLE_PRODUCT_STOCK_FAILURE';
export const CLEAR_TOGGLE_PRODUCT_STOCK = 'CLEAR_TOGGLE_PRODUCT_STOCK';

export const TOGGLE_CHOICE_STOCK_PENDING = 'TOGGLE_CHOICE_STOCK_PENDING';
export const TOGGLE_CHOICE_STOCK_SUCCESS = 'TOGGLE_CHOICE_STOCK_SUCCESS';
export const TOGGLE_CHOICE_STOCK_FAILURE = 'TOGGLE_CHOICE_STOCK_FAILURE';
export const CLEAR_TOGGLE_CHOICE_STOCK = 'CLEAR_TOGGLE_CHOICE_STOCK';

export const FLAG_ORDER_PENDING = 'FLAG_ORDER_PENDING';
export const FLAG_ORDER_SUCCESS = 'FLAG_ORDER_SUCCESS';
export const FLAG_ORDER_FAILURE = 'FLAG_ORDER_FAILURE';
export const CLEAR_FLAG_ORDER = 'CLEAR_FLAG_ORDER';

export const REFUND_PENDING = 'REFUND_PENDING';
export const REFUND_SUCCESS = 'REFUND_SUCCESS';
export const REFUND_FAILURE = 'REFUND_FAILURE';
export const CLEAR_REFUND = 'CLEAR_REFUND';

export const SET_ACTIVE_RESTAURANT = 'SET_ACTIVE_RESTAURANT';
export const SET_ACTIVE_RESTAURANT_ID = 'SET_ACTIVE_RESTAURANT_ID';

export const SET_PLATFORM = 'SET_PLATFORM';

export const SUB_EVENTS_PENDING = 'SUB_EVENTS_PENDING';
export const SUB_EVENTS_SUCCESS = 'SUB_EVENTS_SUCCESS';
export const SUB_EVENTS_FAILURE = 'SUB_EVENTS_FAILURE';
export const UNSUB_EVENTS = 'UNSUB_EVENTS';

export const SUB_NEW_BOOKING_SUCCESS = 'SUB_NEW_BOOKING_SUCCESS';

export const CONTACT_BILLING_PENDING = 'CONTACT_BILLING_PENDING';
export const CONTACT_BILLING_SUCCESS = 'CONTACT_BILLING_SUCCESS';
export const CONTACT_BILLING_FAILURE = 'CONTACT_BILLING_FAILURE';
export const CLEAR_CONTACT_BILLING = 'CLEAR_CONTACT_BILLING';

export const CONTACT_BDM_PENDING = 'CONTACT_BDM_PENDING';
export const CONTACT_BDM_SUCCESS = 'CONTACT_BDM_SUCCESS';
export const CONTACT_BDM_FAILURE = 'CONTACT_BDM_FAILURE';
export const CLEAR_CONTACT_BDM = 'CLEAR_CONTACT_BDM';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const POST_OPPO_PENDING = 'POST_OPPO_PENDING';
export const POST_OPPO_SUCCESS = 'POST_OPPO_SUCCESS';
export const POST_OPPO_FAILURE = 'POST_OPPO_FAILURE';

export const FETCH_DEAL_PREDICT_PENDING = 'FETCH_DEAL_PREDICT_PENDING';
export const FETCH_DEAL_PREDICT_SUCCESS = 'FETCH_DEAL_PREDICT_SUCCESS';
export const FETCH_DEAL_PREDICT_FAILURE = 'FETCH_DEAL_PREDICT_FAILURE';
export const CLEAR_DEAL_PREDICT = 'CLEAR_DEAL_PREDICT';

export const FETCH_DAILY_OPPOS_PENDING = 'FETCH_DAILY_OPPOS_PENDING';
export const FETCH_DAILY_OPPOS_SUCCESS = 'FETCH_DAILY_OPPOS_SUCCESS';
export const FETCH_DAILY_OPPOS_FAILURE = 'FETCH_DAILY_OPPOS_FAILURE';
export const CLEAR_DAILY_OPPOS = 'CLEAR_DAILY_OPPOS';

export const POST_DAILY_OPPO_PENDING = 'POST_DAILY_OPPO_PENDING';
export const POST_DAILY_OPPO_SUCCESS = 'POST_DAILY_OPPO_SUCCESS';
export const POST_DAILY_OPPO_FAILURE = 'POST_DAILY_OPPO_FAILURE';
export const CLEAR_POST_DAILY_OPPO = 'CLEAR_POST_DAILY_OPPO';

export const UPDATE_DAILY_OPPO_PENDING = 'UPDATE_DAILY_OPPO_PENDING';
export const UPDATE_DAILY_OPPO_SUCCESS = 'UPDATE_DAILY_OPPO_SUCCESS';
export const UPDATE_DAILY_OPPO_FAILURE = 'UPDATE_DAILY_OPPO_FAILURE';
export const CLEAR_UPDATE_DAILY_OPPO = 'CLEAR_UPDATE_DAILY_OPPO';

export const POST_ALL_DAILY_OPPOS_PENDING = 'POST_ALL_DAILY_OPPOS_PENDING';
export const POST_ALL_DAILY_OPPOS_SUCCESS = 'POST_ALL_DAILY_OPPOS_SUCCESS';
export const POST_ALL_DAILY_OPPOS_FAILURE = 'POST_ALL_DAILY_OPPOS_FAILURE';
export const CLEAR_POST_ALL_DAILY_OPPOS = 'CLEAR_POST_ALL_DAILY_OPPOS';

export const FETCH_DAILY_ORDER_SUMMARIES_PENDING = 'FETCH_DAILY_ORDER_SUMMARIES_PENDING';
export const FETCH_DAILY_ORDER_SUMMARIES_SUCCESS = 'FETCH_DAILY_ORDER_SUMMARIES_SUCCESS';
export const FETCH_DAILY_ORDER_SUMMARIES_FAILURE = 'FETCH_DAILY_ORDER_SUMMARIES_FAILURE';
export const CLEAR_DAILY_ORDER_SUMMARIES = 'CLEAR_DAILY_ORDER_SUMMARIES';

export const FETCH_MONTHLY_ORDER_SUMMARIES_PENDING = 'FETCH_MONTHLY_ORDER_SUMMARIES_PENDING';
export const FETCH_MONTHLY_ORDER_SUMMARIES_SUCCESS = 'FETCH_MONTHLY_ORDER_SUMMARIES_SUCCESS';
export const FETCH_MONTHLY_ORDER_SUMMARIES_FAILURE = 'FETCH_MONTHLY_ORDER_SUMMARIES_FAILURE';
export const CLEAR_MONTHLY_ORDER_SUMMARIES = 'CLEAR_MONTHLY_ORDER_SUMMARIES';

export const CONFIRM_BOOKING_PENDING = 'CONFIRM_BOOKING_PENDING';
export const CONFIRM_BOOKING_SUCCESS = 'CONFIRM_BOOKING_SUCCESS';
export const CONFIRM_BOOKING_FAILURE = 'CONFIRM_BOOKING_FAILURE';
export const CLEAR_CONFIRM_BOOKING = 'CLEAR_CONFIRM_BOOKING';

// Request to calls
export const REQUEST_CALL_PENDING = 'REQUEST_CALL_PENDING';
export const REQUEST_CALL_SUCCESS = 'REQUEST_CALL_SUCCESS';
export const REQUEST_CALL_FAILURE = 'REQUEST_CALL_FAILURE';
export const CLEAR_REQUEST_CALL = 'CLEAR_REQUEST_CALL';

export const REQUEST_OFFER_EDIT_PENDING = 'REQUEST_OFFER_EDIT_PENDING';
export const REQUEST_OFFER_EDIT_SUCCESS = 'REQUEST_OFFER_EDIT_SUCCESS';
export const REQUEST_OFFER_EDIT_FAILURE = 'REQUEST_OFFER_EDIT_FAILURE';

export const REQUEST_OFFER_DELETE_PENDING = 'REQUEST_OFFER_DELETE_PENDING';
export const REQUEST_OFFER_DELETE_SUCCESS = 'REQUEST_OFFER_DELETE_SUCCESS';
export const REQUEST_OFFER_DELETE_FAILURE = 'REQUEST_OFFER_DELETE_FAILURE';

export const REQUEST_GOAL_DELETE_PENDING = 'REQUEST_GOAL_DELETE_PENDING';
export const REQUEST_GOAL_DELETE_SUCCESS = 'REQUEST_GOAL_DELETE_SUCCESS';
export const REQUEST_GOAL_DELETE_FAILURE = 'REQUEST_GOAL_DELETE_FAILURE';

export const OBEE_REQUEST_PENDING = 'OBEE_REQUEST_PENDING';
export const OBEE_REQUEST_SUCCESS = 'OBEE_REQUEST_SUCCESS';
export const OBEE_REQUEST_FAILURE = 'OBEE_REQUEST_FAILURE';
export const CLEAR_OBEE_REQUEST = 'CLEAR_OBEE_REQUEST';

export const OBEE_UPGRADE_PENDING = 'OBEE_UPGRADE_PENDING';
export const OBEE_UPGRADE_SUCCESS = 'OBEE_UPGRADE_SUCCESS';
export const OBEE_UPGRADE_FAILURE = 'OBEE_UPGRADE_FAILURE';
export const CLEAR_OBEE_UPGRADE = 'CLEAR_OBEE_UPGRADE';

export const OBEE_ONBOARD_PENDING = 'OBEE_ONBOARD_PENDING';
export const OBEE_ONBOARD_SUCCESS = 'OBEE_ONBOARD_SUCCESS';
export const OBEE_ONBOARD_FAILURE = 'OBEE_ONBOARD_FAILURE';
export const CLEAR_OBEE_ONBOARD = 'CLEAR_OBEE_ONBOARD';

export const OBEE_PRICING_PENDING = 'OBEE_PRICING_PENDING';
export const OBEE_PRICING_SUCCESS = 'OBEE_PRICING_SUCCESS';
export const OBEE_PRICING_FAILURE = 'OBEE_PRICING_FAILURE';

// Breadcrumbs
export const CLEAR_BREADCRUMBS = 'CLEAR_BREADCRUMBS';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const ADD_BREADCRUMB = 'ADD_BREADCRUMB';
export const POP_BREADCRUMB = 'POP_BREADCRUMB';

// Goals
export const FETCH_GOALS_PENDING = 'FETCH_GOALS_PENDING';
export const FETCH_GOALS_SUCCESS = 'FETCH_GOALS_SUCCESS';
export const FETCH_GOALS_FAILURE = 'FETCH_GOALS_FAILURE';

export const START_GOAL_PENDING = 'START_GOAL_PENDING';
export const START_GOAL_SUCCESS = 'START_GOAL_SUCCESS';
export const START_GOAL_FAILURE = 'START_GOAL_FAILURE';

export const EDIT_GOAL_PENDING = 'EDIT_GOAL_PENDING';
export const EDIT_GOAL_SUCCESS = 'EDIT_GOAL_SUCCESS';
export const EDIT_GOAL_FAILURE = 'EDIT_GOAL_FAILURE';

export const END_GOAL_PENDING = 'END_GOAL_PENDING';
export const END_GOAL_SUCCESS = 'END_GOAL_SUCCESS';
export const END_GOAL_FAILURE = 'END_GOAL_FAILURE';

export const DELETE_GOAL_PENDING = 'DELETE_GOAL_PENDING';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DELETE_GOAL_FAILURE = 'DELETE_GOAL_FAILURE';

export const CLEAR_GOALS = 'CLEAR_GOALS';

// Generated Offers
export const FETCH_GENERATED_OFFERS_PENDING = 'FETCH_GENERATED_OFFERS_PENDING';
export const FETCH_GENERATED_OFFERS_SUCCESS = 'FETCH_GENERATED_OFFERS_SUCCESS';
export const FETCH_GENERATED_OFFERS_FAILURE = 'FETCH_GENERATED_OFFERS_FAILURE';
export const CLEAR_GENERATED_OFFERS = 'CLEAR_GENERATED_OFFERS';

// Potential Revenues
export const FETCH_POTENTIAL_REVENUES_PENDING = 'FETCH_POTENTIAL_REVENUES_PENDING';
export const FETCH_POTENTIAL_REVENUES_SUCCESS = 'FETCH_POTENTIAL_REVENUES_SUCCESS';
export const FETCH_POTENTIAL_REVENUES_FAILURE = 'FETCH_POTENTIAL_REVENUES_FAILURE';
export const CLEAR_POTENTIAL_REVENUES = 'CLEAR_POTENTIAL_REVENUES';

// Potential Takeaway Revenues
export const FETCH_POTENTIAL_TAKEAWAY_REVENUES_PENDING =
  'FETCH_POTENTIAL_TAKEAWAY_REVENUES_PENDING';
export const FETCH_POTENTIAL_TAKEAWAY_REVENUES_SUCCESS =
  'FETCH_POTENTIAL_TAKEAWAY_REVENUES_SUCCESS';
export const FETCH_POTENTIAL_TAKEAWAY_REVENUES_FAILURE =
  'FETCH_POTENTIAL_TAKEAWAY_REVENUES_FAILURE';
export const CLEAR_POTENTIAL_TAKEAWAY_REVENUES = 'CLEAR_POTENTIAL_TAKEAWAY_REVENUES';

// Potential Dine In Revenues
export const FETCH_POTENTIAL_DINE_IN_REVENUES_PENDING = 'FETCH_POTENTIAL_DINE_IN_REVENUES_PENDING';
export const FETCH_POTENTIAL_DINE_IN_REVENUES_SUCCESS = 'FETCH_POTENTIAL_DINE_IN_REVENUES_SUCCESS';
export const FETCH_POTENTIAL_DINE_IN_REVENUES_FAILURE = 'FETCH_POTENTIAL_DINE_IN_REVENUES_FAILURE';
export const CLEAR_POTENTIAL_DINE_IN_REVENUES = 'CLEAR_POTENTIAL_DINE_IN_REVENUES';

// Deals for goal
export const FETCH_DEALS_FOR_GOAL_PENDING = 'FETCH_DEALS_FOR_GOAL_PENDING';
export const FETCH_DEALS_FOR_GOAL_SUCCESS = 'FETCH_DEALS_FOR_GOAL_SUCCESS';
export const FETCH_DEALS_FOR_GOAL_FAILURE = 'FETCH_DEALS_FOR_GOAL_FAILURE';

export const EDIT_OFFER_PENDING = 'EDIT_OFFER_PENDING';
export const EDIT_OFFER_SUCCESS = 'EDIT_OFFER_SUCCESS';
export const EDIT_OFFER_FAILURE = 'EDIT_OFFER_FAILURE';

export const DISABLE_OFFER_FOR_DATE_PENDING = 'DISABLE_OFFER_FOR_DATE_PENDING';
export const DISABLE_OFFER_FOR_DATE_SUCCESS = 'DISABLE_OFFER_FOR_DATE_SUCCESS';
export const DISABLE_OFFER_FOR_DATE_FAILURE = 'DISABLE_OFFER_FOR_DATE_FAILURE';

export const RE_ENABLE_OFFER_FOR_DATE_PENDING = 'RE_ENABLE_OFFER_FOR_DATE_PENDING';
export const RE_ENABLE_OFFER_FOR_DATE_SUCCESS = 'RE_ENABLE_OFFER_FOR_DATE_SUCCESS';
export const RE_ENABLE_OFFER_FOR_DATE_FAILURE = 'RE_ENABLE_OFFER_FOR_DATE_FAILURE';

export const DELETE_OFFER_PENDING = 'DELETE_OFFER_PENDING';
export const DELETE_OFFER_SUCCESS = 'DELETE_OFFER_SUCCESS';
export const DELETE_OFFER_FAILURE = 'DELETE_OFFER_FAILURE';

export const DELETE_ALL_OFFERS_PENDING = 'DELETE_ALL_OFFERS_PENDING';
export const DELETE_ALL_OFFERS_SUCCESS = 'DELETE_ALL_OFFERS_SUCCESS';
export const DELETE_ALL_OFFERS_FAILURE = 'DELETE_ALL_OFFERS_FAILURE';

// Active deals for restaurant
export const FETCH_ACTIVE_DEALS_FOR_RESTAURANT_PENDING =
  'FETCH_ACTIVE_DEALS_FOR_RESTAURANT_PENDING';
export const FETCH_ACTIVE_DEALS_FOR_RESTAURANT_SUCCESS =
  'FETCH_ACTIVE_DEALS_FOR_RESTAURANT_SUCCESS';
export const FETCH_ACTIVE_DEALS_FOR_RESTAURANT_FAILURE =
  'FETCH_ACTIVE_DEALS_FOR_RESTAURANT_FAILURE';

// Active OFFERS for restaurant. Has the offers grouped under dinein/takeaway
export const FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_PENDING =
  'FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_PENDING';
export const FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_SUCCESS =
  'FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_SUCCESS';
export const FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_FAILURE =
  'FETCH_ACTIVE_OFFERS_FOR_RESTAURANT_FAILURE';

// OFFERS for Goal. Has the offers grouped under dinein/takeaway
export const FETCH_OFFERS_FOR_GOAL_PENDING = 'FETCH_OFFERS_FOR_GOAL_PENDING';
export const FETCH_OFFERS_FOR_GOAL_SUCCESS = 'FETCH_OFFERS_FOR_GOAL_SUCCESS';
export const FETCH_OFFERS_FOR_GOAL_FAILURE = 'FETCH_OFFERS_FOR_GOAL_FAILURE';

export const CLOSE_RESTAURANT_PENDING = 'CLOSE_RESTAURANT_PENDING';
export const CLOSE_RESTAURANT_SUCCESS = 'CLOSE_RESTAURANT_SUCCESS';
export const CLOSE_RESTAURANT_FAILURE = 'CLOSE_RESTAURANT_FAILURE';

export const REOPEN_RESTAURANT_PENDING = 'REOPEN_RESTAURANT_PENDING';
export const REOPEN_RESTAURANT_SUCCESS = 'REOPEN_RESTAURANT_SUCCESS';
export const REOPEN_RESTAURANT_FAILURE = 'REOPEN_RESTAURANT_FAILURE';

// Settlements
export const FETCH_SETTLEMENTS_PENDING = 'FETCH_SETTLEMENTS_PENDING';
export const FETCH_SETTLEMENTS_SUCCESS = 'FETCH_SETTLEMENTS_SUCCESS';
export const FETCH_SETTLEMENTS_FAILURE = 'FETCH_SETTLEMENTS_FAILURE';

// Integrations
export const FETCH_OBEE_INTEGRATIONS_PENDING = 'FETCH_OBEE_INTEGRATIONS_PENDING';
export const FETCH_OBEE_INTEGRATIONS_SUCCESS = 'FETCH_OBEE_INTEGRATIONS_SUCCESS';
export const FETCH_OBEE_INTEGRATIONS_FAILURE = 'FETCH_OBEE_INTEGRATIONS_FAILURE';

export const ENABLE_RESERVE_WITH_GOOGLE_PENDING = 'ENABLE_RESERVE_WITH_GOOGLE_PENDING';
export const ENABLE_RESERVE_WITH_GOOGLE_SUCCESS = 'ENABLE_RESERVE_WITH_GOOGLE_SUCCESS';
export const ENABLE_RESERVE_WITH_GOOGLE_FAILURE = 'ENABLE_RESERVE_WITH_GOOGLE_FAILURE';

export const DISABLE_RESERVE_WITH_GOOGLE_PENDING = 'DISABLE_RESERVE_WITH_GOOGLE_PENDING';
export const DISABLE_RESERVE_WITH_GOOGLE_SUCCESS = 'DISABLE_RESERVE_WITH_GOOGLE_SUCCESS';
export const DISABLE_RESERVE_WITH_GOOGLE_FAILURE = 'DISABLE_RESERVE_WITH_GOOGLE_FAILURE';

// API requests
export const ADD_API_REQUEST = 'ADD_API_REQUEST';
export const REMOVE_API_REQUEST = 'REMOVE_API_REQUEST';
