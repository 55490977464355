import moment from 'moment';
import * as type from '../actions/types';

const initialState = {
  startDate: moment().subtract(7, 'd').startOf('day'),
  endDate: moment().subtract(1, 'd').startOf('day'),
  dateRange: 7,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SET_SEARCH_DATES:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case type.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };
    default:
      return state;
  }
};
