import { makeStyles } from '@mui/styles';
import { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#F5F2ED',
    height: '100%',
  },
  sidebarNavigation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  list: {
    fontSize: '22px',
  },
  navText: {
    '& > span': {
      fontSize: '22px',
    },
  },
  subItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  expandArrow: {
    marginTop: '3px',
    color: COLORS.BLACK,
    transitionDuration: '0.1s',
  },
  hideNavigationContainer: {
    padding: '12px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  divider: {
    marginLeft: '12px',
    marginRight: '12px',
  },
  versionNumber: {
    padding: '12px 24px',
    color: COLORS.NIMBUS,
  },
  hideNavigation: {
    padding: '12px',
    ...theme.eatclub.typography.body.PXS,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
    // color: '#6e6e6d',
    color: '#313131',
    display: 'flex',
    gap: '12px',
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#EEEAE3',
      borderRadius: '12px',
      color: '#000000',
    },
  },
  staffAlert: {
    background: COLORS.STAFF_BLUE,
    borderRadius: '8px',
    padding: '12px',
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
}));

export default useStyles;
