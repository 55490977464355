import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import { Chip, TablePagination, Box, Typography } from '@mui/material';
import BookingsTablePagination from './BookingsTablePagination';
import useStyles from './BookingsStyles';
import TableOrdersDialog from './TableOrdersDialog';
import TableOrdersButton from './TableOrdersButton';
import OrderFlagButton from './OrderFlagButton';
import OrderOptions from './OrderOptions';
import BookingsTableFilters from './BookingsTableFilter';
import { formatNumber, sortTransactionsByDate } from '../../utils';
import { platformMap } from '../../constants';

const getBookingsTablePagination = ({ count, page, rowsPerPage, onPageChange }, bookings) => (
  <BookingsTablePagination
    count={count}
    page={page}
    rowsPerPage={rowsPerPage}
    onPageChange={onPageChange}
    bookings={bookings}
  />
);

const BookingsTable = (props) => {
  const { bookings } = props;
  const classes = useStyles();

  const [activeBooking, setActiveBooking] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const renderName = (rowData) => (
    <Box
      display='flex'
      alignItems='center'
      title={rowData.new ? 'New Booking' : ''}
      style={{ fontSize: '14px' }}
    >
      {rowData.new && (
        <Typography
          style={{
            position: 'relative',
            marginLeft: '-10px',
            marginRight: '4px',
          }}
          color='primary'
        >
          &bull;
        </Typography>
      )}
      <span style={{ marginLeft: '4px' }}>{rowData.reservationName}</span>
    </Box>
  );

  const renderDeal = (rowData) => {
    if (!rowData.deal) {
      return null;
    }

    return (
      <div>
        <Chip label={rowData.deal} color='primary' variant='outlined' />
      </div>
    );
  };

  const renderPromoDeal = (rowData) => {
    if (!rowData.promoDeal) {
      return null;
    }

    return (
      <div>
        <Chip label={rowData.promoDeal} color='secondary' />
      </div>
    );
  };

  const renderTransactionTotal = (rowData) => {
    if (!rowData.total) {
      return null;
    }

    return (
      <>
        ${formatNumber(rowData.total)}{' '}
        {rowData.refundAmount && (
          <Typography color='textSecondary' variant='body2' display='inline'>
            ({rowData.refundType === 'partial' ? 'P' : ''}R)
          </Typography>
        )}
      </>
    );
  };

  const renderActions = (rowData) => (
    <Box display='flex' alignItems='center'>
      <div style={{ width: 48 }}>
        {rowData.order ? (
          rowData.order
        ) : (
          <>
            {rowData.tableNum && rowData.tableOrders && (
              <TableOrdersButton
                setOpenDialog={setOpenDialog}
                setActiveBooking={setActiveBooking}
                booking={rowData}
              />
            )}
          </>
        )}
      </div>
      <div style={{ width: 48 }}>
        <OrderFlagButton booking={rowData} />
        {rowData.platform === platformMap.whitelabel.value && !rowData.refundAmount && (
          <OrderOptions order={rowData} />
        )}
      </div>
    </Box>
  );

  const tableColumns = useMemo(
    () => [
      {
        title: 'Name',
        field: 'reservationName',
        filtering: false,
        render: (rowData) => renderName(rowData),
      },
      {
        title: 'Arrival',
        field: 'arrivalDateTime',
        filtering: false,
        customSort: (a, b) => sortTransactionsByDate(a, b, 'asc'),
      },
      {
        title: 'Type',
        field: 'type',
        filtering: true,
        lookup: {
          inAppOrder: 'In-App Order',
          dineIn: 'Dine-In',
          takeAway: 'Takeaway',
          tableOrder: 'Table Order',
        },
        filterPlaceholder: 'Filter',
      },
      { title: 'Party', field: 'partySize', type: 'numeric', filtering: false },
      {
        title: 'Percent',
        field: 'deal',
        render: (rowData) => renderDeal(rowData),
        filtering: false,
      },
      {
        title: 'Promo',
        field: 'promoDeal',
        render: (rowData) => renderPromoDeal(rowData),
        filtering: false,
      },
      {
        title: 'Transaction Total',
        field: 'total',
        render: (rowData) => renderTransactionTotal(rowData),
        filtering: false,
      },
      {
        title: 'Platform',
        field: 'platform',
        render: (rowData) => platformMap[rowData.platform].name,
        filtering: true,
        lookup: {
          eatclub: 'EatClub',
          whitelabel: 'White Label',
        },
        filterPlaceholder: 'Filter',
      },
      {
        title: 'Status',
        field: 'status',
        filtering: false,
      },
      {
        title: '',
        field: 'tableOrder',
        filtering: false,
        searchable: false,
        render: (rowData) => renderActions(rowData),
      },
    ],
    [],
  );

  const getMinBodyHeight = useCallback(() => {
    if (bookings.length === 0) {
      return '145px';
    }

    if (bookings.length < 3) {
      return `calc(${bookings.length * 60}px + 111px`;
    }

    return '300px';
  }, [bookings]);

  return (
    <>
      <div className={classes.tableStyles}>
        <MaterialTable
          title='Transactions'
          columns={tableColumns}
          data={bookings}
          options={{
            emptyRowsWhenPaging: false,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            padding: 'dense',
            filtering: true,
            draggable: false,
            minBodyHeight: getMinBodyHeight(),
            maxBodyHeight: 'calc( 100vh - 370px)',
            headerStyle: {
              position: 'sticky',
              top: 0,
              zIndex: 99,
            },
            labelRowsPerPage: '',
          }}
          style={{ marginBottom: '8px' }}
          components={{
            // eslint-disable-next-line no-shadow,react/no-unstable-nested-components
            Pagination: (props) => (
              <TablePagination
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                className={classes.tablePagination}
                labelRowsPerPage=''
                ActionsComponent={(paginationProps) =>
                  getBookingsTablePagination(paginationProps, bookings)
                }
              />
            ),
            // eslint-disable-next-line react/prop-types,react/no-unstable-nested-components
            FilterRow: ({ columns, onFilterChanged }) => (
              <BookingsTableFilters columns={columns} onFilterChanged={onFilterChanged} />
            ),
          }}
        />
      </div>
      {Object.keys(activeBooking).length > 0 && (
        <TableOrdersDialog
          activeBooking={activeBooking}
          openDialog={openDialog}
          onClose={handleClose}
        />
      )}
    </>
  );
};

BookingsTable.propTypes = {
  bookings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default BookingsTable;
