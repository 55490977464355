import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import { requestCallAction } from '../../actions/requestCallAction';
import { useQuery } from '../../utils';
import { AppLogo } from '../../constants/AppLogo';
import Error from '../error/Error';
import { regexObjectId } from '../../constants/formats';

const CallRequest = ({ requestCall, callRequest }) => {
  const { pending, success, error } = callRequest;
  const { bookingId } = useParams();

  const query = useQuery();
  const restId = query.get('restaurantId');
  const [idError, setIdError] = useState('');

  useEffect(() => {
    if (!bookingId) {
      return;
    }

    if (!regexObjectId.test(bookingId)) {
      setIdError('Invalid offer ID');
      return;
    }

    requestCall(bookingId, restId);
  }, []);

  const renderContent = () => {
    if (idError) {
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Typography variant='h6'>{idError}</Typography>
        </Box>
      );
    }

    if (pending) {
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <CircularProgress color='primary' size={22} style={{ marginRight: '1rem' }} />
          <Typography variant='h6'>Submitting call request</Typography>
        </Box>
      );
    }

    if (success) {
      return (
        <Box style={{ textAlign: 'center' }}>
          <Box style={{ marginBottom: '1rem' }}>
            <Typography variant='h6'>Request made</Typography>
            <Typography>A call has been requested. You should hear from EatClub soon.</Typography>
          </Box>

          <Button component={Link} to='/' color='primary' variant='contained'>
            Return to Partner Portal
          </Button>
        </Box>
      );
    }

    return null;
  };

  return (
    <Container maxWidth='sm'>
      <Box justifyContent='center' style={{ padding: '3rem 0 2rem', textAlign: 'center' }}>
        <Box height='35px'>
          <AppLogo style={{ height: 35 }} />
        </Box>
      </Box>

      <Card>
        <CardContent>
          {Boolean(error) && <Error error={error} message={error} />}
          {renderContent()}
        </CardContent>
      </Card>
    </Container>
  );
};

CallRequest.propTypes = {
  requestCall: PropTypes.func.isRequired,
  callRequest: PropTypes.shape({
    // data: PropTypes.arrayOf(),
    pending: PropTypes.bool,
    error: PropTypes.string,
    success: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({ callRequest: state.requestCall });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestCall: requestCallAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CallRequest);
