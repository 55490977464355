import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { flagNoShow, flagPartySize, flagCustomerIssue, flagOrderIssue } from '../graphql/mutations';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

export const flagNoShowAction = (objectId, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'ORDER_FLAG',
  });
  dispatch({
    type: type.FLAG_ORDER_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: flagNoShow,
          variables: {
            bookingId: objectId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'flag order', response.data.flagNoShow);

      dispatch({
        type: type.FLAG_ORDER_SUCCESS,
        payload: { updatedOrder: response.data.flagNoShow },
      });
    } catch (error) {
      devLog('error', 'flag order', error);

      dispatch({
        type: type.FLAG_ORDER_FAILURE,
        payload: `Unable to flag no show: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'ORDER_FLAG',
      });
    }
  })();
};

export const flagPartySizeAction =
  (objectId, partySize, restId, userId, userType) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'ORDER_FLAG',
    });
    dispatch({
      type: type.FLAG_ORDER_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: flagPartySize,
            variables: {
              bookingId: objectId,
              partySize,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        devLog('success', 'flag order party size', response.data.flagPartySize);

        dispatch({
          type: type.FLAG_ORDER_SUCCESS,
          payload: { updatedOrder: response.data.flagPartySize },
        });
      } catch (error) {
        devLog('error', 'flag order party size', error);

        dispatch({
          type: type.FLAG_ORDER_FAILURE,
          payload: `Unable to flag order party size: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'ORDER_FLAG',
        });
      }
    })();
  };

export const flagCustomerIssueAction =
  (objectId, comment, restId, userId, userType) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'ORDER_FLAG',
    });
    dispatch({
      type: type.FLAG_ORDER_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: flagCustomerIssue,
            variables: {
              bookingId: objectId,
              comment,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        devLog('success', 'flag order customer issue', response.data.flagCustomerIssue);

        dispatch({
          type: type.FLAG_ORDER_SUCCESS,
          payload: { updatedOrder: response.data.flagCustomerIssue },
        });
      } catch (error) {
        devLog('error', 'flag order customer issue', error);

        dispatch({
          type: type.FLAG_ORDER_FAILURE,
          payload: `Unable to flag order customer issue: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'ORDER_FLAG',
        });
      }
    })();
  };

export const flagIAOAction = (objectId, comment, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'ORDER_FLAG',
  });
  dispatch({
    type: type.FLAG_ORDER_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: flagOrderIssue,
          variables: {
            bookingId: objectId,
            comment,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'flag order IAO', response.data.flagCustomerIssue);

      dispatch({
        type: type.FLAG_ORDER_SUCCESS,
        payload: { updatedOrder: response.data.flagOrderIssue },
      });
    } catch (error) {
      devLog('error', 'flag order IAO', error);

      dispatch({
        type: type.FLAG_ORDER_FAILURE,
        payload: `Unable to flag IAO order: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'ORDER_FLAG',
      });
    }
  })();
};

export const clearFlagOrderAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_FLAG_ORDER,
  });
};
