import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { usePageViews } from '../../utils/analytics';
import useStyles from './DealsStyles';
import DealsTable from './DealsTable';
import DealsTimesChart from './DealsTimesChart';
import DealSelectDay from './selectDay/SelectDay';
import { fetchDealsByDayAction } from '../../actions/dealsByDayAction';

const Deals = ({
  activeRestaurant,
  fetchDealsByDay,
  selectedDay,
  restId,
  userId,
  userType,
  dealsByDay,
}) => {
  const classes = useStyles();

  usePageViews();

  useEffect(() => {
    if (!dealsByDay.shouldFetch) {
      return;
    }

    fetchDealsByDay(activeRestaurant.objectId, selectedDay, restId, userId, userType);
  }, [activeRestaurant.objectId, selectedDay, fetchDealsByDay, dealsByDay.shouldFetch]);

  return (
    <div>
      <Box className={classes.selectDayButton}>
        <DealSelectDay />
      </Box>

      <Box className={classes.mb2}>
        <DealsTable selectedDay={selectedDay} />
      </Box>

      <Paper className={classes.paper}>
        <Typography variant='subtitle1' className={classes.mb1}>
          Times
        </Typography>
        <DealsTimesChart />
      </Paper>
    </div>
  );
};

Deals.propTypes = {
  activeRestaurant: PropTypes.shape({
    objectId: PropTypes.string,
  }).isRequired,
  dealsByDay: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    dataBookings: PropTypes.arrayOf(PropTypes.shape({})),
    fetching: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    shouldFetch: PropTypes.bool,
    selectedDay: PropTypes.number,
  }).isRequired,
  fetchDealsByDay: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  selectedDay: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  selectedDay: state.dealsByDay.selectedDay,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
  dealsByDay: state.dealsByDay,
  activeRestaurant: state.restaurantActive.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchDealsByDay: fetchDealsByDayAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Deals);
