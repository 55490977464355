import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  td: {
    padding: '6px 24px 6px 16px',
    backgroundColor: '#f9f9f9',
    minWidth: 120,
    top: 61,
    zIndex: 10,
    position: 'sticky',
  },
}));

export default useStyles;
