import * as type from '../actions/types';
import { sortDealsByLightningByStartTime } from '../utils';
import { updateItemInArray, deleteItemInArray } from './reducerHelper';

const initialState = {
  data: [],
  dataBookings: [],
  fetching: true,
  error: false,
  errorMessage: '',
  shouldFetch: true,
  selectedDay: -1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.POST_DAILY_OPPO_SUCCESS:
      return {
        ...state,
        shouldFetch: true,
        error: false,
        errorMessage: '',
      };

    case type.FETCH_DEALS_BY_DAY_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    case type.FETCH_DEALS_BY_DAY_SUCCESS: {
      const { deals, bookings } = action.payload;

      const sortedDeals = Array.from(deals).sort((deal1, deal2) =>
        sortDealsByLightningByStartTime(deal1, deal2),
      );

      return {
        ...state,
        data: sortedDeals,
        dataBookings: bookings,
        fetching: false,
        error: false,
        errorMessage: '',
        shouldFetch: false,
      };
    }
    case type.FETCH_DEALS_BY_DAY_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        fetching: false,
      };

    case type.FETCH_RESTAURANT_SUCCESS: {
      const { todayByTimezone } = action.payload;

      // NOTE: set initial selectedDay relative to timezone
      const selectedDay = state.selectedDay === -1 ? todayByTimezone : state.selectedDay;

      return {
        ...state,
        selectedDay,
      };
    }

    case type.SET_ACTIVE_RESTAURANT:
      return {
        ...state,
        shouldFetch: true,
      };

    case type.SET_SELECTED_DAY:
      return {
        ...state,
        selectedDay: action.payload,
        shouldFetch: true,
      };

    case type.CREATE_DEAL_SUCCESS: {
      const { data } = action.payload;
      const newData = [data[0], ...state.data];

      return {
        ...state,
        data: newData,
      };
    }

    case type.DISABLE_DEAL_SUCCESS: {
      const { disabledDeal } = action.payload;

      const newData = updateItemInArray(state.data, 'objectId', disabledDeal.objectId, (item) => ({
        ...item,
        ...disabledDeal,
      }));

      return {
        ...state,
        data: newData,
      };
    }

    case type.DELETE_DEAL_SUCCESS: {
      const { deletedDeal } = action.payload;

      const newData = deleteItemInArray(state.data, 'objectId', deletedDeal.objectId);

      return {
        ...state,
        data: newData,
      };
    }

    case type.UPDATE_DEAL_SUCCESS: {
      const { updatedDeal } = action.payload;

      const newData = updateItemInArray(state.data, 'objectId', updatedDeal.objectId, (deal) => ({
        ...deal,
        ...updatedDeal,
      }));

      return {
        ...state,
        data: newData,
      };
    }

    case type.INCREASE_DEAL_SUCCESS: {
      const { editedDeal } = action.payload;

      const newData = updateItemInArray(state.data, 'objectId', editedDeal.objectId, (deal) => ({
        ...deal,
        ...editedDeal,
      }));

      return {
        ...state,
        data: newData,
      };
    }

    case type.HELP_DEAL_SUCCESS: {
      const { updatedDeal } = action.payload;

      const newData = updateItemInArray(state.data, 'objectId', updatedDeal.objectId, (deal) => ({
        ...deal,
        ...updatedDeal,
      }));

      return {
        ...state,
        data: newData,
      };
    }

    default:
      return state;
  }
};
