/* eslint-disable */
import React, { useState } from 'react';
import { FormLabel, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import useStyles from './CreateDealsStyles';
import Popover from '../../popover/Popover';

const DealFrequency = ({ newDeal, setNewDeal, mode }) => {
  const { frequency } = newDeal;
  const classes = useStyles();

  const handleChange = (event) => {
    setNewDeal({
      ...newDeal,
      frequency: { ...newDeal.frequency, value: event.target.value },
      type: { ...newDeal.type, enabled: true },
      days: { ...newDeal.days, enabled: false, values: [] },
      startDate: { ...newDeal.startDate, errors: { startDate: '' } },
      time: { ...newDeal.time, errors: { start: '', end: '' } },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  return (
    <>
      <FormControl
        variant='standard'
        component='fieldset'
        disabled={mode === 'create'}
        onMouseEnter={mode === 'create' ? handlePopoverOpen : undefined}
        onMouseLeave={mode === 'create' ? handlePopoverClose : undefined}
      >
        <FormLabel component='legend' className={classes.mb1}>
          Select Deal Frequency:
        </FormLabel>
        <RadioGroup
          aria-label='frequency'
          name='frequency'
          value={frequency.value}
          onChange={handleChange}
          row
        >
          <FormControlLabel
            value='oneTime'
            control={<Radio color={frequency.value === 'oneTime' ? 'primary' : 'secondary'} />}
            label='One Time'
          />
          <FormControlLabel
            value='recurring'
            control={<Radio color={frequency.value === 'recurring' ? 'primary' : 'secondary'} />}
            label='Recurring'
          />
        </RadioGroup>
      </FormControl>
      <Popover
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        open={openPopover}
        text='Unable to edit in an existing deal'
        anchorOriginVertical='center'
        anchorOriginHorizontal='left'
        transformOriginHorizontal='left'
      />
    </>
  );
};

export default DealFrequency;
