import * as type from '../actions/types';
import { updateItemInArray } from './reducerHelper';

const initialState = {
  data: [],
  fetching: true,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_MENUS_PENDING: {
      return {
        ...state,
        data: [],
        fetching: true,
        error: false,
        errorMessage: false,
      };
    }
    case type.FETCH_MENUS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }
    case type.FETCH_MENUS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.SET_ACTIVE_RESTAURANT_ID: {
      return {
        data: [],
        fetching: true,
        error: false,
        errorMessage: '',
      };
    }

    case type.FLAG_MENU_SUCCESS: {
      const { updatedMenu } = action.payload;

      const newData = updateItemInArray(state.data, 'objectId', updatedMenu.objectId, (item) => ({
        ...item,
        issueId: updatedMenu.issueId,
      }));

      return {
        ...state,
        data: newData,
      };
    }

    default:
      return state;
  }
};
