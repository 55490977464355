import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, CenteredContent } from '@eatclub-apps/ec-component-library';
import { postDailyOppoAction } from '../../../actions/dealDailyOpposPostAction';
import {
  opportunitiesPropTypes,
  opportunityPropTypes,
} from '../../../reducers/dealDailyOpposReducer';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency } from '../../../utils/helpers';
import { OpportunityDetails } from './OpportunityDetails';
import EstimateLabel from './EstimateLabel';
import { LargeNumber } from './LargeNumber';
import useStyles from './OpportunityCardStyles';

const TopOpportunity = ({
  opportunity,
  number,
  dealDailyOppoPost,
  restId,
  userId,
  userType,
  postDailyOppo,
}) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('xs'));
  // const { track } = useAnalytics();

  const postOppo = () => {
    trackEvent(`button_click: post_opportunity`);
    postDailyOppo(
      opportunity?.objectId,
      opportunity?.discount,
      'partnerPortal',
      restId,
      userId,
      userType,
    );
  };

  const styles = {
    buttonContainer: {
      alignItems: 'flex-end',
      height: '100%',
      paddingTop: '10px',
    },
    centeredContent: {
      height: '100%',
    },
  };

  return (
    <Box className={classes.topOpportunityRow} display='flex'>
      {!breakpointSmDown && (
        <LargeNumber number={number} style={{ marginTop: '8px', marginRight: '30px' }} />
      )}

      <Box className={classes.opportunityDetailsContainer}>
        <Box mr='auto'>
          <OpportunityDetails opportunity={opportunity} number={number} />
        </Box>

        <Box className={classes.estimatesContainer}>
          <Box className={classes.labelsContainer}>
            <CenteredContent style={styles.centeredContent}>
              <EstimateLabel type='Customers' value={opportunity?.prediction} />
            </CenteredContent>
          </Box>

          <Box className={classes.labelsContainer}>
            <CenteredContent style={styles.centeredContent}>
              <EstimateLabel
                type='Revenue'
                value={formatCurrency(opportunity?.revenueExpected, false)}
              />
            </CenteredContent>
          </Box>
        </Box>

        {/* Post now button */}
        <Box className={classes.postButtonContainer}>
          <CenteredContent style={styles.buttonContainer}>
            <Button
              disabled={dealDailyOppoPost.pending}
              loading={dealDailyOppoPost.pendingOppos.includes(opportunity?.objectId)}
              type='secondary'
              onClick={postOppo}
            >
              Post now
            </Button>
          </CenteredContent>
        </Box>
      </Box>
    </Box>
  );
};

TopOpportunity.defaultProps = {
  number: 1,
};

TopOpportunity.propTypes = {
  postDailyOppo: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  dealDailyOppoPost: opportunitiesPropTypes.isRequired,
  opportunity: opportunityPropTypes.isRequired,
  number: PropTypes.number, // The number we show next to the row
};

const mapStateToProps = (state) => ({
  dealDailyOppoPost: state.dealDailyOppoPost,
  oppoPost: state.dealDailyOppoPost,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ postDailyOppo: postDailyOppoAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopOpportunity);
