import { API } from 'aws-amplify';
import store from '../store';
import { devLog } from '../utils';
import { generateRandomString, getAppVersion } from '../utils/helpers';
import * as type from './types';

export const apiCall = (query, variables, addRestId = false) => {
  // Get state here so it doesn't have to be passed in from every component
  const state = store?.getState();
  const restId = state.restaurantActive.restaurant.objectId;
  const userId = state.user.userInfo.objectId;
  const { userType } = state.user.userInfo;

  const variablesWithExtra = {
    ...variables,
    ...(addRestId ? { restId } : {}),
  };

  return API.graphql(
    {
      query,
      variables: variablesWithExtra,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    },
    {
      'user-id': userId,
      'user-type': userType,
      'rest-id': restId,
      'app-version': getAppVersion(),
    },
  );
};

export const handleResponse = (
  response,
  action,
  model,
  dispatch,
  tempId,
  variables = {},
  extraValues = {},
) => {
  // Check for error message in response
  if (response?.error) {
    devLog('error', model, response?.error);

    dispatch({
      type: `${action}_FAILURE`,
      payload: `Unable to retrieve ${model}: ${response?.error}`,
    });
    return;
  }

  // Responses are typically held by their resource name
  let data = response?.data;
  if (model) {
    data = response?.data?.[model];
  }

  devLog('success', model, data);

  dispatch({
    type: `${action}_SUCCESS`,
    payload: {
      data,
      variables,
      tempId,
      ...extraValues,
    },
  });
};

export const beginAction = (action, dispatch, object, tempId, requestId) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `${action}_${requestId}`,
  });

  dispatch({
    type: `${action}_PENDING`,
    payload: {
      data: object,
      tempId,
    },
  });
};

export const endAction = (action, dispatch, requestId) => {
  dispatch({
    type: type.REMOVE_ID_APP_LOADING,
    payload: `${action}_${requestId}`,
  });
};

export const handleFailure = (error, action, model, dispatch) => {
  devLog('error', model, error);
  dispatch({
    type: `${action}_FAILURE`,
    payload: `Unable to retrieve obeeBookings: ${error}`,
  });
};

export const addMessage = (action, dispatch, context = 'success', message) => {
  if (message) {
    dispatch({
      type: type.ADD_MESSAGE,
      payload: {
        id: `${action}_SUCCESS_${new Date().getTime()}`,
        message,
        severity: context,
      },
    });
  }
};

/**
 *
 * @param {string} id unique identifier of the request
 * @param {int} timestamp when the request was made in ms
 * @param {string} model model (query) identifier of the request
 * @param {Promise} request promise instance of the api call
 * @param {*} dispatch
 */
const addRequest = (id, timestamp, model, request, dispatch) => {
  dispatch({
    type: type.ADD_API_REQUEST,
    payload: { id, timestamp, model, request },
  });
};

/**
 *
 * @param {string} model model (query) identifier of the request
 * @param {int} now when the request was made in ms
 */
const cancelRequests = (model, now) => {
  const state = store?.getState();
  const { apiRequests } = state;

  apiRequests.forEach((apiRequest) => {
    if (apiRequest.model === model && now > apiRequest.timestamp) {
      devLog('info', 'cancelling api request', apiRequest);
      API.cancel(apiRequest.request);
    }
  });
};

/**
 *
 * @param {string} id unique identifier of the request
 * @param {*} dispatch
 */
const removeRequest = (id, dispatch) => {
  dispatch({
    type: type.REMOVE_API_REQUEST,
    payload: id,
  });
};

export const makeApiAction = (
  action,
  query,
  variables,
  pendingObject, // The object being stored (e.g. so we can add it to the state)
  model,
  includeRestId, // Whether to inject the restId into the variables data
  dispatch, // TODO remove this
  successMessage = null, // If provided, show a log message on success
  extraValues = {}, // Extra values we might want to return to the reducer
  skipLoading = false, // Whether to prevent setting the app into a loading state. e.g. when polling
  errorMessage = null, // If provided, show a log message on error
  cancelStaleApiRequests = false, // Whether to cancel stale api requests of the same `model`
) => {
  const tempId = generateRandomString(8);
  const requestId = generateRandomString(16);

  if (!skipLoading) {
    beginAction(action, dispatch, pendingObject, tempId, requestId);
  }

  const request = apiCall(query, variables || {}, includeRestId);

  if (cancelStaleApiRequests) {
    const now = new Date().getTime();
    cancelRequests(model, now);

    addRequest(requestId, now, model, request, dispatch); // note: store the request meta data in reducer for stale api cancelling
  }

  (async () => {
    try {
      if (query) {
        const response = await request;
        handleResponse(response, action, model, dispatch, tempId, variables || {}, extraValues);
        if (successMessage) {
          addMessage(action, dispatch, 'success', successMessage);
        }
      }
    } catch (error) {
      if (API.isCancel(error)) {
        devLog('info', 'api request cancelled', { id: requestId, model });
      } else {
        handleFailure(error?.errors?.[0]?.message || error, action, model, dispatch, tempId);
        addMessage(
          action,
          dispatch,
          'error',
          errorMessage || `An error occurred ${model && `for ${model}`}`,
        );
      }
    } finally {
      if (cancelStaleApiRequests) {
        removeRequest(requestId, dispatch);
      }

      if (!skipLoading) {
        endAction(action, dispatch, requestId);
      }
    }
  })();
};
