import PropTypes from 'prop-types';
import * as type from '../actions/types';

const initialState = {
  fetching: false,
  data: {
    // restId: null,
    // offPeakOptimized: null,
  },
  error: false,
  errorMessage: '',
  success: false,
};

// Export prop types for easy import in components that use this data
export const potentialRevenuePropTypes = PropTypes.shape({
  restId: PropTypes.string,
  offPeakOptimized: PropTypes.number,
  lowerOffersOptimized: PropTypes.number,
  maxRevenue: PropTypes.shape({
    type: '',
    amount: 0,
    customersLower: 0,
    customersUpper: 0,
  }), // Store the type that has the highest and its value
});

export const potentialRevenuesPropTypes = PropTypes.shape({
  data: potentialRevenuePropTypes,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool,
  fetching: PropTypes.bool,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_POTENTIAL_REVENUES_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        success: false,
        errorMessage: '',
      };
    case type.FETCH_POTENTIAL_REVENUES_SUCCESS:
      // Store which deal had the highest potential revenue
      let maxRevenue = {
        type: 'offPeak',
        amount: action.payload?.offPeakOptimized,
        amountLower: action.payload?.offPeakRevenueLower,
        amountUpper: action.payload?.offPeakRevenueUpper,
        customersLower: action.payload?.offPeakCustomersLower,
        customersUpper: action.payload?.offPeakCustomersUpper,
      };

      if (action.payload?.lowerOffersOptimized > action.payload?.offPeakOptimized) {
        maxRevenue = {
          type: 'lowerOffers',
          amount: action.payload?.lowerOffersOptimized,
          amountLower: action.payload?.lowerOffersRevenueLower,
          amountUpper: action.payload?.lowerOffersRevenueUpper,
          customersLower: action.payload?.lowerOffersCustomersLower,
          customersUpper: action.payload?.lowerOffersCustomersUpper,
        };
      }

      return {
        ...state,
        fetching: false,
        data: {
          ...action.payload,
          maxRevenue,
        },
        error: false,
        errorMessage: '',
        success: true,
      };
    case type.FETCH_POTENTIAL_REVENUES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };

    case type.CLEAR_POTENTIAL_REVENUES:
      return {
        ...state,
        data: {},
        error: false,
        errorMessage: '',
        fetching: false,
        success: false,
      };
    default:
      return state;
  }
};
