// TODO experimenting with having models to represent the different objects.
// This will hopefully allow re-usability of data (such as proptypes) and logic (such as data mapping)

// Export prop types for easy import in components that use this data
import PropTypes from 'prop-types';
import { nullIfNegative } from '../../utils/helpers';

export const obeeSettingPropTypes = PropTypes.shape({
  daysInAdvance: PropTypes.number,
  systemTimeInterval: PropTypes.number,
  bookingsDuration: PropTypes.number,
  waitlist: PropTypes.bool,
  abHighchairs: PropTypes.bool,
  abVaccination: PropTypes.bool, // depreciated
  abTakenBy: PropTypes.string,
  abConfirmation: PropTypes.bool, // depreciated
  ccCapture: PropTypes.bool,
  ccNoShowFee: PropTypes.number,
  ccPaymentPolicy: PropTypes.string,
  ccWaitlistShow: PropTypes.string,
  ccWaitlistPolicy: PropTypes.string,
  ccAddBookings: PropTypes.string,
  terms: PropTypes.string,
  logo: PropTypes.string,
  heroImage: PropTypes.string,
  favicon: PropTypes.string,
  stepperColor: PropTypes.string,
  iconBgColor: PropTypes.string,
  datePickerColor: PropTypes.string,
  linkColor: PropTypes.string,
  availableTimesColor: PropTypes.string,
  formBgColor: PropTypes.string,
  venueMessage: PropTypes.string,
  disabledAccess: PropTypes.string,
  dressCode: PropTypes.string,
  kidFriendly: PropTypes.string,
  kidsPlayArea: PropTypes.string,
  byo: PropTypes.string,
  corkage: PropTypes.string,
  cakeage: PropTypes.string,
  showHighchairs: PropTypes.bool,
  groupSizeLimit: PropTypes.number,
  groupSizeMessage: PropTypes.string,
  areaPhotos: PropTypes.arrayOf(PropTypes.string),
  slug: PropTypes.string,
  openingHoursStyle: PropTypes.string,
  cutoffMode: PropTypes.string,
  cutoffValue: PropTypes.string,
});

export const obeeSettingsPropTypes = PropTypes.shape({
  apiData: obeeSettingPropTypes,
  data: obeeSettingPropTypes,
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

export const ccAddBookingsValues = {
  off: 'off',
  optional: 'optional',
  mandatory: 'mandatory',
};

/**
 * sets obeeSettings with some default data values if the values are null, acts as a safeguard if the DB entries are null
 * @param {*} settings obee settings retrieved from the DB
 * @returns obee settings with defaults set if some values are null
 */
export const getData = (settings) => {
  const settingsData = {
    ...settings,
    daysInAdvance: nullIfNegative(settings.daysInAdvance) || 60, // Fallback to 60 days.
    bookingsDuration: nullIfNegative(settings.bookingsDuration) || 60, // Fallback to 60 mins.
    systemTimeInterval: nullIfNegative(settings.systemTimeInterval) || 15, // Fallback to 15 mins.
    abHighchairs: settings.abHighchairs || false, // Fallback to false.
    abTakenBy: settings.abTakenBy || 'off', // Fallback to off.
    ccCapture: settings.ccCapture || false, // Fallback to false.
    ccNoShowFee: nullIfNegative(settings.ccNoShowFee) || 0, // Fallback to ($)0.
    ccPaymentPolicy: settings.ccPaymentPolicy || '', // Fallback to empty.
    ccWaitlistShow: settings.ccWaitlistShow || 'off', // Fallback to off.
    ccWaitlistPolicy: settings.ccWaitlistPolicy || '', // Fallback to empty.
    ccAddBookings: settings.ccAddBookings || 'off', // Fallback to off.
    terms: settings.terms || '', // Fallback to empty.
    cutoffMode: settings.cutoffMode || 'off',
  };

  return settingsData;
};

/**
 * Whether to display the field in the modals
 * @param {string} settingValue
 * @returns {boolean}
 */
export const showField = (settingValue) => ['optional', 'mandatory'].includes(settingValue);

export const OBSettingsInputKeys = () => [
  'daysInAdvance',
  'systemTimeInterval',
  'bookingsDuration',
  'waitlist',
  'abHighchairs',
  'abVaccination',
  'abTakenBy',
  'abConfirmation',
  'ccCapture',
  'ccNoShowFee',
  'ccPaymentPolicy',
  'ccWaitlistShow',
  'ccWaitlistPolicy',
  'ccAddBookings',
  'terms',
  'logo',
  'heroImage',
  'favicon',
  'stepperColor',
  'iconBgColor',
  'datePickerColor',
  'linkColor',
  'availableTimesColor',
  'formBgColor',
  'venueMessage',
  'disabledAccess',
  'dressCode',
  'kidFriendly',
  'kidsPlayArea',
  'byo',
  'corkage',
  'cakeage',
  'showHighchairs',
  'groupSizeLimit',
  'groupSizeMessage',
  'areaPhotos',
  'openingHoursStyle',
  'cutoffMode',
  'cutoffValue',
];

export const getCutoffModeLabel = (cutoffMode) => {
  switch (cutoffMode) {
    case 'set_time':
      return 'Fixed cut-off';
    case 'hours_before':
      return 'Sessional cut-off';
    case 'rolling':
      return 'Rolling cut-off';
    default:
      return '';
  }
};
