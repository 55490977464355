import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { Repeat } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DailyOpposDialog from './DailyOpposDialog';

const DailyOpposButton = ({ showOppos }) => {
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  if (!showOppos) {
    return null;
  }

  return (
    <>
      <Button
        style={{ backgroundColor: '#f9a32c', color: '#fff' }}
        variant='contained'
        onClick={handleOpenDialog}
      >
        <Repeat style={{ marginRight: '.5rem' }} /> View Daily Opportunities
      </Button>

      <DailyOpposDialog open={open} handleCloseDialog={handleCloseDialog} />
    </>
  );
};

DailyOpposButton.propTypes = {
  showOppos: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({ showOppos: state.restaurantActive.restaurant.showOppos });
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DailyOpposButton);
