import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    mb_sm_2: {
      marginBottom: theme.spacing(4),
    },
  },
  paper: {
    padding: '1rem',
    height: '6.5rem',
    borderRadius: '20px',
    width: '100%',
  },
  paperButton: {
    padding: '1rem',
    height: '6rem',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  buttonBase: {
    width: '100%',
    borderRadius: '20px',
  },
  disabled: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default useStyles;
