// Workaround for Vite not having a global variable
window.global ||= window;

window.bookingsConfig = {
  dateFormat: import.meta.env.VITE_DATE_FORMAT,
  urlPrefix: import.meta.env.VITE_URL_PREFIX,
  routePrefix: import.meta.env.VITE_ROUTE_PREFIX || '',
  mode: import.meta.env.VITE_MODE,
  logApiSuccesses: import.meta.env?.VITE_LOG_API_SUCCESSES || false,
  stripeApiKey: import.meta.env?.VITE_STRIPE_API_KEY ?? '',
  pinPaymentsUrl: import.meta.env?.VITE_PIN_PAYMENTS_URL ?? '',
  bookingsBranding: import.meta.env?.VITE_BOOKINGS_BRANDING ?? 'eatclub',
  bookingWidgetBaseUrl: import.meta.env?.VITE_BOOKING_WIDGET_BASE_URL ?? '',
  faqsUrl: import.meta.env?.VITE_FAQS_URL ?? '',
  obeeApiPrefix: import.meta.env?.VITE_OBEE_API_PREFIX ?? '',
  obeeApiUrl: import.meta.env?.VITE_OBEE_API_URL ?? '',
  lightspeedClientId: import.meta.env?.VITE_LIGHTSPEED_CLIENT_ID || '',
  editAll: import.meta.env?.VITE_EDIT_ALL || false,
  editNone: import.meta.env?.VITE_EDIT_NONE || false,
  legacyVenuesURL: import.meta.env?.VITE_LEGACY_VENUES_URL || '', // Only used by ECB
};
