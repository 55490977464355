import PropTypes from 'prop-types';

export const predictionPropTypes = PropTypes.shape({
  bookingsExpected: PropTypes.number,
  bookingsLower: PropTypes.number,
  bookingsPrediction: PropTypes.number,
  bookingsUpper: PropTypes.number,
  commissionExpected: PropTypes.number,
  commissionLower: PropTypes.number,
  commissionUpper: PropTypes.number,
  contributionADExpected: PropTypes.number,
  contributionADLower: PropTypes.number,
  contributionADUpper: PropTypes.number,
  contributionExpected: PropTypes.number,
  contributionLower: PropTypes.number,
  contributionUpper: PropTypes.number,
  discountExpected: PropTypes.number,
  discountLower: PropTypes.number,
  discountUpper: PropTypes.number,
  foodCostExpected: PropTypes.number,
  foodCostLower: PropTypes.number,
  foodCostUpper: PropTypes.number,
  partySizeExpected: PropTypes.number,
  partySizeLower: PropTypes.number,
  partySizeMonthlyExpected: PropTypes.number,
  partySizeMonthlyLower: PropTypes.number,
  partySizeMonthlyUpper: PropTypes.number,
  partySizePrediction: PropTypes.number,
  partySizeUpper: PropTypes.number,
  prediction: PropTypes.string,
  result: PropTypes.string,
  revenueExpected: PropTypes.number,
  revenueLower: PropTypes.number,
  revenueUpper: PropTypes.number,
  targetVal: PropTypes.number,
  targetValLower: PropTypes.number,
  targetValMonthly: PropTypes.number,
  targetValMonthlyLower: PropTypes.number,
  targetValMonthlyUpper: PropTypes.number,
  targetValUpper: PropTypes.number,
});

export const predictionsPropTypes = PropTypes.shape({
  predict: PropTypes.shape({
    data: predictionPropTypes,
    pending: PropTypes.bool,
    error: PropTypes.string,
    success: PropTypes.bool,
  }).isRequired,
});
