import * as type from '../actions/types';

const initialState = {
  data: {},
  error: '',
  pending: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.OBEE_ONBOARD_PENDING:
      return {
        ...state,
        pending: true,
        error: '',
      };
    case type.OBEE_ONBOARD_SUCCESS:
      const { obeeGoLiveDate, operationsEmail } = action.payload;
      return {
        ...state,
        data: { obeeGoLiveDate, operationsEmail },
        pending: false,
        success: true,
      };
    case type.OBEE_ONBOARD_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    case type.CLEAR_OBEE_ONBOARD:
      return {
        ...state,
        data: {},
        pending: false,
        success: false,
        error: '',
      };

    default:
      return state;
  }
};
