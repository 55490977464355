import * as type from './types';

export const setAppErrorAction = (errorName, errorMessage) => (dispatch) => {
  dispatch({
    type: type.SET_APP_ERROR,
    payload: { errorName, errorMessage },
  });
};

export const removeAppErrorAction = (errorName) => (dispatch) => {
  dispatch({
    type: type.REMOVE_APP_ERROR,
    payload: errorName,
  });
};

export const clearAppErrorAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_APP_ERROR,
  });
};
