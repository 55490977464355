/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearAppErrorAction } from '../../actions/errorAction';

const ErrorApp = ({ errors, clearAppErrorAction }) => {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (errors.allErrors.length > 0) {
      setShowError(true);

      setTimeout(() => {
        setShowError(false);
        clearAppErrorAction();
      }, 6000);
    }
  }, [clearAppErrorAction, errors.allErrors.length]);

  return (
    <>
      <Snackbar open={showError} autoHideDuration={6000}>
        <div>
          <Alert elevation={3} severity='error'>
            <>
              {errors.allErrors.map((error, index) => (
                <Typography key={index}>{error}</Typography>
              ))}
            </>
          </Alert>
        </div>
      </Snackbar>
    </>
  );
};

ErrorApp.propTypes = {
  errors: PropTypes.shape({ allErrors: PropTypes.arrayOf(PropTypes.string) }).isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.error,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ clearAppErrorAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ErrorApp);
