import { makeStyles } from '@mui/styles';
import { BORDER_RADII, COLORS, SPACING } from '../../EatClubTheme';

const getThemeForType = (type) => {
  const themes = {
    green: {
      backgroundColor: COLORS.GREEN_LABEL,
      color: COLORS.CLOUD,
    },
    red: {
      color: COLORS.PRIMARY_RED_MATTE,
      backgroundColor: COLORS.GRAPEFRUIT,
    },
    gray: {
      color: COLORS.CONFIRMED_GREEN_TEXT,
      backgroundColor: COLORS.CLOUD,
    },
    white: {
      color: '#313131',
      backgroundColor: COLORS.WHITE,
      border: '1px solid #E4E4E4',
      borderRadius: '4px',
    },
    yellow: {
      backgroundColor: '#F8F0E3',
      color: COLORS.CHARCOAL,
    },
    blue: {
      backgroundColor: '#DEE8E6',
      color: COLORS.CHARCOAL,
    },
    olive: {
      backgroundColor: COLORS.OLIVE_2,
      color: COLORS.WHITE,
    },
    orange: {
      backgroundColor: COLORS.ORANGE,
      color: COLORS.CHARCOAL,
    },
  };

  if (!Object.keys(themes).includes(type)) {
    return themes.gray;
  }

  return themes[type];
};

const useStyles = makeStyles((theme) => ({
  label: (props) => ({
    padding: props?.rounded ? `${SPACING.XS} ${SPACING.M}` : `${SPACING.XXS} ${SPACING.XS}`,
    borderRadius: props?.rounded ? '50px' : BORDER_RADII.S,
    ...getThemeForType(props?.type),
    ...theme.eatclub.typography.body.labelText,
    whiteSpace: 'nowrap',
    ...props?.style,
  }),
}));

export default useStyles;
