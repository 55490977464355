import { API } from 'aws-amplify';
import { potentialOffers } from '../graphql/queries';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

export const fetchGeneratedOffersAction =
  (restId, strategy, target, filter, userId, userType) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'FETCH_GENERATED_OFFERS',
    });

    dispatch({
      type: type.FETCH_GENERATED_OFFERS_PENDING,
    });

    const getData = async () => {
      try {
        const response = await API.graphql(
          {
            query: potentialOffers,
            variables: {
              restId,
              strategy,
              target,
              filter,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        const generatedOffers = response.data?.potentialOffers;

        devLog('success', 'generatedOffers', generatedOffers);

        dispatch({
          type: type.FETCH_GENERATED_OFFERS_SUCCESS,
          payload: generatedOffers,
        });
      } catch (error) {
        devLog('error', 'generatedOffers', error);

        dispatch({
          type: type.FETCH_GENERATED_OFFERS_FAILURE,
          payload: `Unable to retrieve generatedOffers: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'FETCH_GENERATED_OFFERS',
        });
      }
    };
    getData();
  };

// export const clearRevenueEstimateAction = () => (dispatch) => {
//   dispatch({
//     type: type.CLEAR_REVENUE_ESTIMATE,
//   });
// };
