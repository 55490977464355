import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: '16px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxWidth: '350px',
  },
  fakeTextField: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: COLORS.FAKE_TEXT_FIELD,
    padding: '16px',
    borderRadius: '4px',
  },
  formFooter: {
    display: 'flex',
    flexGrow: '1',
    alignSelf: 'flex-end',
    position: 'fixed',
  },
  footerButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: '28px',
    right: '28px',
  },
}));

export default useStyles;
