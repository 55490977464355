import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  icon: {
    transition: 'all 200ms ease',
    '&.opened': {
      transform: 'rotate(180deg)',
    },
  },
}));

export default useStyles;
