import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goalPropTypes } from '../../../data/models/Goal';
import { offerPropTypes } from '../../../reducers/generatedOffersReducer';
import { trackEvent } from '../../../utils/analytics';
import { getDayOccurrences } from '../../../utils/helpers';
import OfferCard from '../OfferCard/OfferCard';
import DayTab from './DayTab';
import useStyles from './OffersByDayStyles';

const OffersByDay = ({ offers, goal }) => {
  const classes = useStyles();
  const [selectedDay, setSelectedDay] = useState(null);
  const [offersByDay, setOffersByDay] = useState([]);
  const [repeatDays, setRepeatDays] = useState({});

  const extractNumberFromString = (str) => {
    return parseInt(str.replace(/^\D+/g, ''), 10);
  };

  const sortOffers = (offersToSort) => {
    const currentlyActiveOffers = offersToSort
      .filter((offer) => offer?.status === 'active')
      .map((offer) => ({ ...offer, discountAsInt: extractNumberFromString(offer?.discount) }))
      .sort((a, b) => a?.startTime - b?.startTime)
      .sort((a, b) => b?.discountAsInt - a?.discountAsInt);

    const offersForDay = offersToSort
      .filter((offer) => offer?.status !== 'active')
      .map((offer) => ({ ...offer, discountAsInt: extractNumberFromString(offer?.discount) }))
      .sort((a, b) => a?.startTime - b?.startTime)
      .sort((a, b) => b?.discountAsInt - a?.discountAsInt);

    return [...currentlyActiveOffers, ...offersForDay];
  };

  useEffect(() => {
    const daysToShow = 7; // How many days in advance, including today, we will show
    const upcomingDays = [...Array.from({ length: daysToShow })].map((_, i) =>
      moment().startOf('day').add(i, 'days'),
    );

    const getDayRelativeToToday = (day, daysInAdvance) => {
      if (daysInAdvance === 0) {
        return `${day}: Today`;
      }

      if (daysInAdvance === 1) {
        return `${day}: Tomorrow`;
      }

      return day;
    };

    const newOffersByDay = upcomingDays.map((dayAsMoment, i) => {
      const dayOfWeek = parseInt(dayAsMoment?.format('d'), 10);

      return {
        dayOfWeek,
        tabLabel: dayAsMoment.format('ddd Do'),
        momentDate: dayAsMoment,
        listLabel: getDayRelativeToToday(dayAsMoment.format('dddd'), i),
        offers: sortOffers(offers.filter((offer) => offer?.dayOfWeek % 7 === dayOfWeek)),
      };
    });

    // Switch back to all if no offers for that day
    if (selectedDay && newOffersByDay[selectedDay].length === 0) {
      setSelectedDay(null);
    }

    setOffersByDay(newOffersByDay);
  }, [offers]);

  // Get remaining day count for offers
  useEffect(() => {
    const repeatDays = getDayOccurrences(moment(), goal?.endDate);
    setRepeatDays(repeatDays);
  }, [goal]);

  return (
    <Box
      className={classes.container}
      style={{ minHeight: `${Math.min(900, offers.length * 150)}px` }}
    >
      <Box className={classes.tabBar}>
        <DayTab
          key='tab-All'
          day='All'
          selected={selectedDay === null}
          onClick={() => {
            trackEvent('button_click: offers_by_day_set_all');
            setSelectedDay(null);
          }}
          itemAmount={offers.length}
        />
        {offersByDay
          .filter((day) => day?.offers?.length > 0)
          .map((day) => (
            <DayTab
              key={`tab-${day?.tabLabel}`}
              day={day?.tabLabel}
              selected={selectedDay === day?.dayOfWeek}
              onClick={() => {
                trackEvent(`button_click: offers_by_day_set_day_${day?.dayOfWeek}`);
                setSelectedDay(day?.dayOfWeek);
              }}
              itemAmount={day?.offers?.length}
            />
          ))}
      </Box>
      <Box>
        {offersByDay
          .filter(
            (day) =>
              (selectedDay === null || day?.dayOfWeek === selectedDay) && day?.offers.length > 0,
          )
          .map((day) => (
            <Box key={`offerDay-${day?.tabLabel}`} className={classes.offerDay}>
              <Box className={classes.dayName}>{day?.listLabel}</Box>
              {day?.offers.map((offer) => (
                <Box
                  key={`offer-${offer?.id}-${offer?.startTime}-${offer?.endTime}-${offer?.dayOfWeek}`}
                >
                  <OfferCard offer={offer} occurrences={repeatDays?.[offer?.dayOfWeek]} />
                </Box>
              ))}
            </Box>
          ))}
      </Box>
    </Box>
  );
};

OffersByDay.defaultProps = {
  goal: null,
  offers: [],
};

OffersByDay.propTypes = {
  offers: PropTypes.arrayOf(offerPropTypes),
  goal: goalPropTypes,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OffersByDay);
