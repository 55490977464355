import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    color: '#A5A5A5',
    // paddingLeft: '8px',
    margin: '-10px',
    padding: '10px',
    paddingLeft: '18px',
  },

  list: {
    padding: '0',
  },
  menuItem: {
    margin: '8px',
    borderRadius: '8px',
    minWidth: '260px',
  },
  menuItemContent: {
    display: 'flex',
    gap: '10px',
    marginLeft: '-10px',
    ...theme.eatclub.typography.body.PL,
    lineHeight: '32px',
  },
  // menuItem: {
  //   'button:hover': {
  //     backgroundColor: 'F0F0F0',
  //   },
  // },
}));

export default useStyles;
