/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearMenuItemsAction, fetchMenuItemsAction } from '../../actions/menuItemsAction';
import MenuCategories from './MenuCategories';
import FlagButton from './FlagButton';
import LoadingBox from '../loading/LoadingBox';

const MenuItemsLoading = () => <LoadingBox />;

const MenuTabPanel = ({
  clearMenuItemsAction,
  fetchMenuItemsAction,
  menu,
  menuItems,
  restId,
  userId,
  userType,
}) => {
  const [menuItemsByCategory, setMenuItemsByCategory] = useState([]);

  // fetch menu items for v1 menus
  useEffect(() => {
    fetchMenuItemsAction(menu.objectId, restId, userId, userType);

    // remove menu items on unmount
    return () => {
      clearMenuItemsAction();
    };
  }, [clearMenuItemsAction, fetchMenuItemsAction, menu.objectId]);

  useEffect(() => {
    if (menuItems.fetching || menuItems.error) {
      return;
    }

    if (menuItems.data === null) {
      setMenuItemsByCategory([]);
      return;
    }

    const menuItemsByCategoryReduced = menuItems.data.reduce((accumByCategory, menuItem) => {
      // check if menuItem's category is already reduced
      const menuItemIndex = accumByCategory.findIndex(
        (item) => item.category === menuItem.category,
      );

      // store initital menuItem
      if (menuItemIndex === -1) {
        accumByCategory.push({
          category: menuItem.category,
          menuItems: [menuItem],
        });
      } else {
        accumByCategory[menuItemIndex].menuItems.push(menuItem);
      }
      return accumByCategory;
    }, []);
    setMenuItemsByCategory(menuItemsByCategoryReduced);
  }, [menuItems.data, menuItems.error, menuItems.fetching]);

  return (
    <>
      {menuItems.fetching ? (
        <Paper style={{ marginTop: '1rem' }}>
          <MenuItemsLoading />
        </Paper>
      ) : (
        <>
          <Paper style={{ marginTop: '1rem', marginBottom: '4rem' }}>
            <MenuCategories menuItemsByCategory={menuItemsByCategory} />
          </Paper>
          <FlagButton menu={menu} />
        </>
      )}
    </>
  );
};

MenuTabPanel.propTypes = {
  clearMenuItemsAction: PropTypes.func.isRequired,
  fetchMenuItemsAction: PropTypes.func.isRequired,
  menuItems: PropTypes.shape({}).isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  menuItems: state.menuItems,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clearMenuItemsAction, fetchMenuItemsAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuTabPanel);
