import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Menu, MenuItem, Box, Divider } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionMenuVertical from '../../../assets/action_menu_vertical.svg';
import useStyles from './ActionMenuStyles';

const ITEM_HEIGHT = 48;

const ActionMenu = ({ options, disabled }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    if (disabled) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOption = (action) => {
    action();
    handleCloseMenu();
  };

  return (
    <Box>
      <Box
        className={classes.container}
        style={{
          cursor: disabled ? 'default' : 'pointer',
        }}
        onClick={(e) => handleOpenMenu(e)}
      >
        <ActionMenuVertical />
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={() => handleCloseMenu()}
        autoFocus={false}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            borderRadius: '8px',
          },
        }}
        MenuListProps={{
          style: { padding: '0' },
        }}
      >
        {options.map((option) =>
          option?.type === 'divider' ? (
            // eslint-disable-next-line react/no-array-index-key
            <Divider />
          ) : (
            <MenuItem
              key={option.name}
              onClick={() => handleClickOption(option.onClick)}
              autoFocus={false}
              className={classes.menuItem}
            >
              <Box className={classes.menuItemContent}>
                <Box>{option?.icon}</Box>
                <Box>{option.name}</Box>
              </Box>
            </MenuItem>
          ),
        )}
      </Menu>
    </Box>
  );
};

ActionMenu.defaultProps = {
  options: [],
  disabled: false,
};

ActionMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  selectedDay: state.dealsByDay.selectedDay,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ActionMenu);
