import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  restaurantSwitcherContainer: {
    display: 'flex',
    backgroundColor: '#ECE7DF',
    borderRadius: '100px',
    height: '55px',
    alignItems: 'center',
    padding: '10px 20px 10px 10px',
    margin: '20px 24px 12px 0',
    color: COLORS.CHARCOAL,
    gap: '10px',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'relative',
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  restaurantName: {
    ...theme.eatclub.typography.heading.H4,
    width: '100%',
    flexGrow: 1,
  },
  restaurantDropdownArrow: {
    color: COLORS.BLACK,
    transitionDuration: '0.1s',
    flexShrink: 0,
  },
  accountMenuContent: {
    pointerEvents: 'auto',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    ...theme.eatclub.typography.body.PL,
    lineHeight: 'normal',
  },
  navigationLinks: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    // fontSize: '15px', // Bigger font is better for touch devices here
  },
  activeNavigationLink: {
    padding: '8px 12px',
    borderRadius: '6px',
    cursor: 'default',
    userSelect: 'none',
    backgroundColor: COLORS.LINEN,
  },
  navigationLink: {
    padding: '8px 12px',
    borderRadius: '6px',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    gap: '16px',
    '&:hover': {
      backgroundColor: COLORS.LINEN,
    },
  },
  divider: {
    padding: '12px 0',
  },
  logoContainer: {
    height: '70px',
    width: '70px',
    borderRadius: '8px',
    background: '#EAD16A',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexShrink: 0,
  },
  restaurantAddress: {
    fontSize: '15px',
    color: COLORS.SMOKE,
  },
}));

export default useStyles;
