import { API } from 'aws-amplify';
import {
  requestCallForBooking,
  requestGoalDelete,
  requestOfferDelete,
  requestOfferEdit,
} from '../graphql/mutations';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { devLog } from '../utils';

export const requestCallAction = (bookingId, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'REQUEST_CALL',
  });
  dispatch({
    type: type.REQUEST_CALL_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: requestCallForBooking,
          variables: {
            bookingId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'request call', response.data);

      dispatch({
        type: type.REQUEST_CALL_SUCCESS,
        payload: { restId, bookingId },
      });
    } catch (error) {
      devLog('error', 'request call', error);

      dispatch({
        type: type.REQUEST_CALL_FAILURE,
        payload: `${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'REQUEST_CALL',
      });
    }
  })();
};

export const clearRequestCallAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_REQUEST_CALL,
  });
};

export const requestOfferEditAction =
  (restId, userId, userType, dealIds, reason, reasonMessage, contact, phone) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'REQUEST_OFFER_EDIT',
    });
    dispatch({
      type: type.REQUEST_OFFER_EDIT_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: requestOfferEdit,
            variables: {
              dealIds,
              reason,
              reasonMessage,
              contact,
              phone,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        const parsedResponse = JSON.parse(response?.data?.requestOfferEdit);

        devLog('success', 'request offer edit', parsedResponse);

        dispatch({
          type: type.REQUEST_OFFER_EDIT_SUCCESS,
        });

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `REQUEST_OFFER_EDIT_SUCCESS_${new Date().getTime()}`,
            message: parsedResponse?.message,
            severity: parsedResponse?.status,
          },
        });
      } catch (error) {
        devLog('error', 'request offer edit', error);

        dispatch({
          type: type.REQUEST_OFFER_EDIT_FAILURE,
          payload: `${error}`,
        });

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `REQUEST_OFFER_DELETE_SUCCESS_${new Date().getTime()}`,
            message: 'Error, unable to request a call for editing this offer.',
            severity: 'error',
          },
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'REQUEST_OFFER_EDIT',
        });
      }
    })();
  };

export const requestOfferDeleteAction =
  (restId, userId, userType, dealIds, reason, reasonMessage, contact, phone) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'REQUEST_OFFER_DELETE',
    });
    dispatch({
      type: type.REQUEST_OFFER_DELETE_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: requestOfferDelete,
            variables: {
              dealIds,
              reason,
              reasonMessage,
              contact,
              phone,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        const parsedResponse = JSON.parse(response?.data?.requestOfferDelete);

        devLog('success', 'request offer delete', parsedResponse);

        dispatch({
          type: type.REQUEST_OFFER_DELETE_SUCCESS,
        });

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `REQUEST_OFFER_DELETE_SUCCESS_${new Date().getTime()}`,
            message: parsedResponse?.message,
            severity: parsedResponse?.status,
          },
        });
      } catch (error) {
        devLog('error', 'request offer delete', error);

        dispatch({
          type: type.REQUEST_OFFER_DELETE_FAILURE,
          payload: `${error}`,
        });

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `REQUEST_OFFER_DELETE_SUCCESS_${new Date().getTime()}`,
            message: 'Error, unable to request a call for deleting this offer.',
            severity: 'error',
          },
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'REQUEST_OFFER_DELETE',
        });
      }
    })();
  };

export const requestGoalDeleteAction =
  (restId, userId, userType, goalId, reason, reasonMessage, contact, phone) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'REQUEST_GOAL_DELETE',
    });
    dispatch({
      type: type.REQUEST_GOAL_DELETE_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: requestGoalDelete,
            variables: {
              goalId,
              reason,
              reasonMessage,
              contact,
              phone,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        const parsedResponse = JSON.parse(response?.data?.requestGoalDelete);

        devLog('success', 'request goal delete', parsedResponse);

        dispatch({
          type: type.REQUEST_GOAL_DELETE_SUCCESS,
        });

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `REQUEST_GOAL_DELETE_SUCCESS_${new Date().getTime()}`,
            message: parsedResponse?.message,
            severity: parsedResponse?.status,
          },
        });
      } catch (error) {
        devLog('error', 'request goal delete', error);

        dispatch({
          type: type.REQUEST_GOAL_DELETE_FAILURE,
          payload: `${error}`,
        });

        dispatch({
          type: type.ADD_MESSAGE,
          payload: {
            id: `REQUEST_OFFER_DELETE_SUCCESS_${new Date().getTime()}`,
            message: 'Error, unable to request a call for ending this goal.',
            severity: 'error',
          },
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'REQUEST_GOAL_DELETE',
        });
      }
    })();
  };
