import React from 'react';
import Lockdown from './Lockdown';
import timelineImage from '../../../assets/images/timeline_image.png';

// TODO : Add new image for guestbook lockdown
const GuestbookLockdown = () => (
  <Lockdown
    header='Guest Book'
    content='View and add guests to your guest book'
    image={timelineImage}
  />
);

export default GuestbookLockdown;
