import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from '@redux-devtools/extension';
import { reducers as defaultReducers } from './reducers/rootReducer';

let middlewares = [thunk];

if (import.meta.env.VITE_USE_REDUX_LOGGER === 'true') {
  const logger = createLogger({
    collapsed: true,
    diff: true,
  });

  middlewares = [...middlewares, logger];
}

export function createReducerManager(initialReducers) {
  // Create an object which maps keys to reducers
  const reducers = { ...initialReducers };

  // Create the initial combinedReducer
  let combinedReducer = combineReducers(reducers);

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove = [];

  return {
    getReducerMap: () => reducers,

    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state, action) => {
      // If any reducers have been removed, clean up their state first
      if (keysToRemove.length > 0) {
        // eslint-disable-next-line no-param-reassign
        state = { ...state };
        // eslint-disable-next-line no-restricted-syntax
        for (const key of keysToRemove) {
          // eslint-disable-next-line no-param-reassign
          delete state[key];
        }
        keysToRemove = [];
      }

      //  Clear Redux state on logout
      if (action.type === 'SET_LOGOUT') {
        // storage.removeItem("persist:root"); // Redux Persist: Reset state,
        localStorage.removeItem('mode');
        sessionStorage.removeItem('selectedRestaurant');
        sessionStorage.removeItem('date');

        // eslint-disable-next-line no-param-reassign
        state = undefined;
      }

      // Delegate to the combined reducer
      return combinedReducer(state, action);
    },

    // Adds a new reducer with the specified key
    add: (key, reducer) => {
      // if (!key || reducers[key]) {
      //   return;
      // }

      // Note: This overrides the old reducer with the new one
      if (!key) {
        return;
      }

      // Add the reducer to the reducer mapping
      reducers[key] = reducer;

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },

    // Removes a reducer with the specified key
    remove: (key) => {
      if (!key || !reducers[key]) {
        return;
      }

      // Remove it from the reducer mapping
      delete reducers[key];

      // Add the key to the list of keys to clean up
      keysToRemove.push(key);

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },
  };
}

const reducerManager = createReducerManager(defaultReducers);
const store = createStore(
  reducerManager.reduce,
  composeWithDevTools(applyMiddleware(...middlewares)),
);
store.reducerManager = reducerManager;

// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

window.getStore = () => store;
export default store;
