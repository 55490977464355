import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// TODO move to component library
const Breadcrumbs = ({ breadcrumbs, style, ...props }) => {
  if (!breadcrumbs?.data || breadcrumbs?.data?.length === 0) {
    return <></>;
  }

  return (
    <Box style={{ width: '100%' }}>
      <Box display='flex' columnGap='5px'>
        {breadcrumbs?.data?.map((crumb, index) => {
          const isLast = index === breadcrumbs?.data.length - 1;
          return (
            <Box
              key={crumb?.label}
              display='flex'
              columnGap='5px'
              style={{
                lineHeight: '40px',
                fontSize: '20px',
                fontWeight: 'bold',
                color: isLast ? '#E54439' : '#DEDEDE',
              }}
            >
              {index !== 0 && <Box>{' / '}</Box>}
              {typeof crumb === 'object' && !isLast ? (
                <Box style={{ cursor: 'pointer' }} onClick={crumb?.onClick}>
                  {crumb?.label}
                </Box>
              ) : (
                <Box>{crumb?.label || crumb}</Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

Breadcrumbs.defaultProps = {
  style: {},
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired, // A crumb can be either a string or an object with a label and onClick
  style: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({ breadcrumbs: state.breadcrumbs });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
