import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { fetchTransactionBookingsAction } from '../../actions/transactionsAction';
import { usePageViews } from '../../utils/analytics';
import BookingsTable from './BookingsTable';
import Error from '../error/Error';
import BookingsOrder from './BookingsOrder';
import { subEventsAction, unsubEventsAction } from '../../actions/subEventsAction';
import { devLog, sortTransactionsByDate, titleCaseFromCamel } from '../../utils';
import { setDateRangeAction, setDatesAction } from '../../actions/datePickerAction';

const Bookings = ({
  dates,
  fetchTransactionBookings,
  region,
  restId,
  subEvents,
  transactions,
  unsubEvents,
  userId,
  userType,
  setDates,
  setDateRange,
}) => {
  const { startDate, endDate } = dates;
  usePageViews();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.startDate && location.state?.endDate) {
      const momentStartDate = moment(location.state.startDate);
      const momentEndDate = moment(location.state.endDate);

      setDates(momentStartDate, momentEndDate);
      setDateRange(momentEndDate.diff(momentStartDate, 'days'));
    }
  }, []);

  // Subscription to new bookings
  useEffect(() => {
    const today = moment();

    // NOTE: subscribe only if today is visible during datepicker dates
    const shouldSubscribe = today.isBetween(startDate, endDate, 'day', '[]');

    if (!shouldSubscribe) {
      return;
    }

    subEvents(restId, userId);

    // eslint-disable-next-line consistent-return
    return () => {
      unsubEvents();
    };
  }, [restId, endDate, subEvents, unsubEvents]);

  useEffect(() => {
    // Inclusive of StartDate, and exclusive of EndDate. So + 1 day to Endate.
    const formatStartDate = moment(startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(endDate).add(1, 'day').format('YYYY-MM-DD');

    fetchTransactionBookings(restId, region, formatStartDate, formatEndDate, userId, userType);
  }, [restId, region, endDate, fetchTransactionBookings, startDate, userId, userType]);

  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    if (transactions.fetching || transactions.error) {
      return;
    }

    const getType = (
      inAppOrder,
      transactionId,
      status,
      takeaway,
      tableOrders,
      platform,
      tableNum,
    ) => {
      if (platform === 'eatclub') {
        // Eatclub Orders
        if (tableOrders) {
          return 'tableOrder';
        }

        if (inAppOrder && transactionId !== null) {
          return 'inAppOrder';
        }
        if (takeaway) {
          return 'takeAway';
        }
        return 'dineIn';
      }

      if (platform === 'whitelabel') {
        // WhiteLabel Orders
        if (tableNum) {
          return 'tableOrder';
        }
        return 'takeAway';
      }

      devLog('error', 'order has no platform.');
      return '';
    };

    const bookingObject = transactions.data.map((booking) => {
      const {
        arrivalTime,
        created,
        discount,
        inAppOrder,
        issueStatus,
        name,
        objectId,
        orderId,
        partySize,
        platform,
        promoTitle,
        refundAmount,
        refundType,
        status,
        tableNum,
        tableOrders,
        takeaway,
        total,
        transactionId,
      } = booking;

      const bookingReference = orderId ? orderId.slice(-6) : objectId.slice(-6);
      const date = moment(created).format(import.meta.env.VITE_DATE_FORMAT);
      const arrivalDateTime = `${date}, ${arrivalTime.toLowerCase()}`;
      const orderType = getType(
        inAppOrder,
        transactionId,
        status,
        takeaway,
        tableOrders,
        platform,
        tableNum,
      );

      const getStatus = () => {
        if (inAppOrder && !status) {
          return 'Completed';
        }

        if (!issueStatus && !status) {
          return null;
        }

        const text = [];

        if (issueStatus) {
          if (issueStatus === 'partySizeReported') {
            text.push('Party Size Changed');
          } else {
            text.push(titleCaseFromCamel(issueStatus));
          }
        }

        if (status) {
          text.push(titleCaseFromCamel(status));
        }

        return text.join(', ');
      };

      const getOrderDetail = () => {
        if (platform === 'eatclub' && orderType === 'inAppOrder') {
          return <BookingsOrder orderId={orderId} region={region} />;
        }

        if (platform === 'whitelabel') {
          return <BookingsOrder orderId={orderId} region={region} />;
        }

        return '';
      };

      const object = {
        created,
        arrivalTime,
        arrivalDateTime,
        bookingId: objectId,
        bookingReference,
        deal: discount,
        issueStatus,
        new: booking._new,
        objectId: orderId || objectId,
        order: getOrderDetail(),
        orderId,
        partySize,
        platform,
        promoDeal: promoTitle || '',
        refundAmount,
        refundType,
        reservationName: name,
        status: getStatus(),
        tableNum,
        tableOrders,
        total: total ? parseFloat(total) : null,
        type: orderType,
      };

      return object;
    });

    setBookings(bookingObject);
  }, [region, transactions.data, transactions.error, transactions.fetching]);

  return (
    <>
      {transactions.error && (
        <Error error={transactions.error} message={transactions.errorMessage} />
      )}
      <BookingsTable bookings={bookings} />
    </>
  );
};

Bookings.propTypes = {
  dates: PropTypes.shape({
    endDate: PropTypes.instanceOf(moment),
    startDate: PropTypes.instanceOf(moment),
  }).isRequired,
  fetchTransactionBookings: PropTypes.func.isRequired,
  subEvents: PropTypes.func.isRequired,
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
  }).isRequired,
  unsubEvents: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  setDates: PropTypes.func.isRequired,
  setDateRange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { data } = state.transactions;
  const sortedData = data.sort((a, b) => sortTransactionsByDate(a, b, 'desc'));

  return {
    transactions: { data: sortedData, ...state.transactions },
    dates: state.dates,
    restId: state.restaurantActive.restaurant.objectId,
    region: state.restaurantActive.restaurant.region,
    userId: state.user.userInfo.objectId,
    userType: state.user.userInfo.userType,
    activeRestaurant: state.restaurantActive.restaurant,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTransactionBookings: fetchTransactionBookingsAction,
      subEvents: subEventsAction,
      unsubEvents: unsubEventsAction,
      setDates: setDatesAction,
      setDateRange: setDateRangeAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
