/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Box, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const ComparePercent = ({ change, percent, dateRange }) => {
  const getDeltaColor = (change) => {
    switch (change) {
      case 'gain':
        return green[500];
      case 'loss':
        return red[500];
      default:
        return 'inherit';
    }
  };

  const getDeltaArrow = (change) => {
    switch (change) {
      case 'gain':
        return <ArrowUpward fontSize='small' color='inherit' />;
      case 'loss':
        return <ArrowDownward fontSize='small' color='inherit' />;
      default:
        return '';
    }
  };

  const formatPercent = (num) => {
    if (typeof num !== 'number' || num === 0) {
      return '-';
    }
    return `${num.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%`;
  };

  return (
    <Tooltip
      title={`Compared to the previous ${dateRange} day${dateRange > 1 ? 's' : ''}`}
      placement='bottom-start'
    >
      <Box
        display='flex'
        alignItems='center'
        style={{
          color: getDeltaColor(change),
        }}
      >
        {getDeltaArrow(change)}
        <Typography variant='subtitle1' component='span'>
          {formatPercent(percent)}
        </Typography>
      </Box>
    </Tooltip>
  );
};

ComparePercent.propTypes = {
  dateRange: PropTypes.number.isRequired,
  change: PropTypes.string.isRequired,
  percent: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  dateRange: state.dates.dateRange,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ComparePercent);
