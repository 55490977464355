import React from 'react';
import { makeStyles } from '@mui/styles';
import AppError from '../../errorApp/ErrorApp';
import LogMessages from '../../logMessages/LogMessages';

const useStyles = makeStyles((theme) => ({
  contentInner: {
    flexGrow: 1,
    // [theme.breakpoints.up('md')]: {
    //   padding: `0 72px 0 72px`,
    // },
    // [theme.breakpoints.down('sm')]: {
    //   overflow: 'hidden', // fix: grids at full width cause unwanted "x" scrolling
    //   paddingBottom: '3px', // fix: make room for shadows/elevation
    //   padding: `0 20px 0 20px`,
    // },
  },
  content: {
    position: 'relative',
  },

  overrides: {
    listItemText: {
      fontSize: '2px',
    },
    MuiTypography: {
      body1: {
        color: 'orange',
        fontSize: '2px',
      },
    },
  },
}));

const Body = (props) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <main className={classes.content}>
      <div className={classes.contentInner}>{children}</div>
      <AppError />
      <LogMessages />
    </main>
  );
};

export default Body;
