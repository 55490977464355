/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RestaurantPickerChild from './RestaurantPickerChild';

const RestaurantPicker = ({ user }) => {
  const [restaurantGroup, setRestaurantGroup] = useState(false);

  useEffect(() => {
    if (user?.userFetching || user?.userError) {
      return;
    }

    if (user?.userInfo?.restaurants?.length > 1) {
      // Set Active Restaurant from RestaurantGroup
      setRestaurantGroup(user?.userInfo?.restaurants);
    }
  }, [user?.userFetching, user?.userError, user?.userInfo?.restaurants]);

  if (!restaurantGroup) {
    return null;
  }

  return <RestaurantPickerChild restaurantGroup={restaurantGroup} />;
};

RestaurantPicker.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantPicker);
