/**
 * Sort Deals by Lightning, and by Start Timne
 *
 * @param deal1 first deal
 * @param deal2 next deal
 */
export const sortDealsByLightningByStartTime = (deal1, deal2) => {
  const deal1Start = deal1.startTime;
  const deal2Start = deal2.startTime;
  const deal1AllDay = !deal1.lightning;

  if (deal1AllDay) return -1;
  if (deal1Start < deal2Start) return -1;
  if (deal1Start > deal2Start) return 1;

  return 0;
};
