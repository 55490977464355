import * as type from '../actions/types';
import { RegexDiscountType } from '../constants/formats';

const initialState = {
  bookingsData: [],
  compareBookingsData: [],
  fetching: true,
  error: false,
  errorMessage: '',
  success: false,
  shouldFetch: true,
};

const removeStunts = (data) => {
  const withoutStunts = data.filter((reso) => {
    // NOTE: include whitelabel orders (they don't have discounts)
    if (reso.discount === null) {
      return true;
    }

    const hasStunt = RegexDiscountType.test(reso.discount);
    return hasStunt;
  });
  return withoutStunts;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_ANALYTIC_BOOKINGS_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    case type.FETCH_ANALYTIC_BOOKINGS_SUCCESS: {
      const { bookings, compareBookings } = action.payload;

      const updatedBookings = removeStunts(bookings);
      const updatedCompareBookings = removeStunts(compareBookings);

      return {
        ...state,
        bookingsData: updatedBookings,
        compareBookingsData: updatedCompareBookings,
        fetching: false,
        success: true,
        shouldFetch: false,
      };
    }
    case type.FETCH_ANALYTIC_BOOKINGS_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        fetching: false,
      };

    case type.SET_PLATFORM:
      return {
        ...state,
        bookingsData: [],
        compareBookingsData: [],
        fetching: true,
        error: false,
        errorMessage: '',
        success: false,
        shouldFetch: true,
      };

    // NOTE: update bookings if restaurant or date changes
    case type.SET_ACTIVE_RESTAURANT:
    case type.SET_SEARCH_DATES:
      return {
        ...state,
        shouldFetch: true,
        success: false,
      };

    default:
      return state;
  }
};
