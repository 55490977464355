/* eslint-disable */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  GetApp,
} from '@mui/icons-material/';
import { Button, Typography } from '@mui/material';
import { CSVLink } from 'react-csv';
import useStyles from './BookingsStyles';
import { trackEvent } from '../../utils/analytics';

const TablePagination = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, bookings } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const bookingsHeaders = [
    { label: 'Name', key: 'reservationName' },
    { label: 'Arrival', key: 'arrivalDateTime' },
    { label: 'Type', key: 'type' },
    { label: 'Party', key: 'partySize' },
    { label: 'Percent', key: 'deal' },
    { label: 'Promo', key: 'promoDeal' },
    { label: 'Reference', key: 'bookingReference' },
    { label: 'Transaction Total', key: 'total' },
    { label: 'Platform', key: 'platform' },
    { label: 'Status', key: 'status' },
  ];

  const betweenPaginStart = () => {
    if (page === 0) {
      if (count === 0) {
        return 0;
      }
      return 1;
    }
    return page * rowsPerPage + 1;
  };

  const betweenPaginEnd = () => {
    if (count < rowsPerPage) {
      return count;
    }
    if ((page + 1) * rowsPerPage > count) {
      return count;
    }
    return (page + 1) * rowsPerPage;
  };

  return (
    <div className={classes.paginationContainer}>
      {bookings && (
        <div className={classes.flexGrow}>
          <Button
            startIcon={<GetApp />}
            color='primary'
            disabled={!bookings.length}
            // component={CSVLink} // TODO re-add this. Removed because csv link ref breaks in mui
            onClick={() => trackEvent('button_click: export_bookings')}
          >
            <CSVLink
              headers={bookingsHeaders}
              data={bookings}
              filename='eatclub-transactions.csv' // todo: add start and end date to filename
              disabled={!bookings.length}
              style={{ color: '#E54439', textDecoration: 'none' }}
            >
              Export
            </CSVLink>
          </Button>
        </div>
      )}

      <div>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label='first page'
        >
          {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label='previous page'
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <Typography
          variant='caption'
          component='span'
          color='inherit'
        >{`${betweenPaginStart()}-${betweenPaginEnd()} of ${count}`}</Typography>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='next page'
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='last page'
        >
          {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
        </IconButton>
      </div>
    </div>
  );
};

export default TablePagination;
