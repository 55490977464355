import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { increaseDealPercentage, increaseDealQty } from '../graphql/mutations';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

export const increaseDealPercentAction =
  (dealId, newDiscount, restId, userId, userType) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'INCREASE_DEAL',
    });

    dispatch({
      type: type.INCREASE_DEAL_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: increaseDealPercentage,
            variables: {
              dealId,
              newDiscount,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          devLog('error', 'increase deal percent', error);
          dispatch({
            type: type.INCREASE_DEAL_FAILURE,
            payload: `Unable to increase deal percent: ${error}`,
          });
          throw new Error(error.errors[0].message);
        });

        // destructure
        const {
          data: { increaseDealPercentage: deal },
        } = response;

        devLog('success', 'increase deal percent', deal);

        dispatch({
          type: type.INCREASE_DEAL_SUCCESS,
          payload: { editedDeal: deal },
        });
      } catch (error) {
        devLog('error', 'increase deal percent', error);

        dispatch({
          type: type.INCREASE_DEAL_FAILURE,
          payload: `Unable to increase deal percent: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'INCREASE_DEAL',
        });
      }
    })();
  };

export const increaseDealQtyAction =
  (dealId, qty, todayOnly, restId, userId, userType) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'INCREASE_DEAL',
    });
    dispatch({
      type: type.INCREASE_DEAL_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: increaseDealQty,
            variables: {
              dealId,
              addQty: qty,
              todayOnly,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          devLog('error', 'increase deal qty', error);
          dispatch({
            type: type.INCREASE_DEAL_FAILURE,
            payload: `Unable to increase deal quantity: ${error}`,
          });
          throw new Error(error.errors[0].message);
        });

        // destructure
        const {
          data: { increaseDealQty: deal },
        } = response;

        devLog('success', 'increase deal qty', deal);

        dispatch({
          type: type.INCREASE_DEAL_SUCCESS,
          payload: { editedDeal: deal },
        });
      } catch (error) {
        devLog('error', 'increase deal qty', error);

        dispatch({
          type: type.INCREASE_DEAL_FAILURE,
          payload: `Unable to increase deal quantity: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'INCREASE_DEAL',
        });
      }
    })();
  };
