/* eslint-disable react/prop-types */
import React from 'react';
import { Popover as MaterialPopover, Typography } from '@mui/material';
import useStyles from './PopoverStyles';

const Popover = ({
  anchorEl,
  handlePopoverClose,
  open,
  text,
  anchorOriginVertical = 'bottom',
  anchorOriginHorizontal = 'center',
  transformOriginVertical = 'top',
  transformOriginHorizontal = 'center',
  PaperProps,
}) => {
  const classes = useStyles();

  return (
    <MaterialPopover
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: anchorOriginVertical,
        horizontal: anchorOriginHorizontal,
      }}
      transformOrigin={{
        vertical: transformOriginVertical,
        horizontal: transformOriginHorizontal,
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      PaperProps={PaperProps}
    >
      <Typography>{text}</Typography>
    </MaterialPopover>
  );
};

export default Popover;
