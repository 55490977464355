/* eslint-disable */
import React from 'react';
// import PropTypes from "prop-types";
import { TextField } from '@mui/material';
import useStyles from './CreateDealsStyles';

const DealQuantity = ({ newDeal, setNewDeal }) => {
  const { quantity } = newDeal;

  const classes = useStyles();

  const handleChange = (event) => {
    const { value } = event.target;
    const newQuantity = value === '' || value === '0' ? '' : Number(value);
    setNewDeal({
      ...newDeal,
      quantity: { ...quantity, value: newQuantity },
      startDate: { ...newDeal.startDate, enabled: true },
    });
  };

  return (
    <>
      <TextField
        className={classes.formControl}
        label='Number of Deals'
        type='number'
        value={quantity.value}
        onChange={handleChange}
        onBlur={handleChange}
        placeholder='10'
        // InputLabelProps={{ shrink: true }}
        inputProps={{
          min: 1,
          step: 1,
        }}
      />
    </>
  );
};

DealQuantity.propTypes = {};

export default DealQuantity;
