import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    // fix for variant outline select label being cut off
    '& .MuiInputLabel-shrink + div .MuiOutlinedInput-notchedOutline legend': {
      width: ['90px', '!important'],
    },
  },
}));

export default useStyles;
