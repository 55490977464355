import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  predictedResultsContainer: {
    ...theme.eatclub.typography.body.P,
    maxWidth: '300px',
    width: '100%',
  },
  predictedResultsHeader: {
    display: 'inline-flex',
    marginBottom: '10px',
    gap: '10px',
    alignItems: 'center',
  },
}));

export default useStyles;
