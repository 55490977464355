import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { ReactTableWrapper } from '../ReactTableWrapper/ReactTableWrapper';
import useStyles from './SettlementStyles';
import IconNewTab from '../../assets/icon_new_tab.svg';
import { formatCurrency, formatDate, sortByProperty, unique } from '../../utils/helpers';
import { fetchSettlementsByRestIdAction } from '../../actions/settlementsAction';
import { restaurantActivePropTypes } from '../../data/models/Restaurant';

const Settlements = ({ fetchSettlementsByRestId, restaurantActive, settlements }) => {
  const classes = useStyles();

  useEffect(() => {
    fetchSettlementsByRestId(restaurantActive.restaurant.objectId, 200, 0);
  }, [fetchSettlementsByRestId, restaurantActive.restaurant.objectId]);

  const hasMultiplePlatforms = useMemo(
    () => unique(settlements.data.map((settlement) => settlement?.platform)).length > 1,
    [settlements.data],
  );

  const revenueLabel = (amount) => {
    if (amount < 0) {
      return (
        <Box className={classes.negativeRevenueLabel}>
          {formatCurrency(amount, true, '$0', true)}
        </Box>
      );
    }
    return (
      <Box className={classes.positiveRevenueLabel}>{`+${formatCurrency(
        amount,
        true,
        '$0',
        true,
      )}`}</Box>
    );
  };

  const columns = [
    {
      id: 'period',
      label: 'Period',
      sortType: (rowA, rowB) => sortByProperty(rowA?.original, rowB?.original, '$period'),
    },
    { id: 'settlementId', label: 'Settlement #' },
    { id: 'transactions', label: 'Transactions' },
    { id: 'customers', label: 'Customers' },
    {
      id: 'aov',
      label: 'Average order value',
      format: ({ value }) => formatCurrency(value, true, '$0', true),
    },
    { id: 'revenue', label: 'Revenue', format: ({ value }) => revenueLabel(value) },
    {
      id: 'ecFees',
      label: 'Fees',
      format: ({ value }) => revenueLabel(value),
    },
    hasMultiplePlatforms ? { id: 'platform', label: 'Platform' } : null,
    {
      id: 'view',
      disableSortBy: true,
    },
  ].filter((item) => !!item);

  const getPeriod = (startDate, endDate) => {
    if (startDate === endDate) {
      return formatDate(startDate, 'ddd Do MMM');
    }

    return `${formatDate(startDate, 'ddd Do MMM')} - ${formatDate(endDate, 'ddd Do MMM')}`;
  };

  const data = (settlements.data || []).map((settlement) => ({
    period: (
      <Box
        className={classes.clickableContainer}
        onClick={() => window.open(settlement.pdfLink, '_blank', 'noopener')}
      >
        <Box>{getPeriod(settlement.startDate, settlement.endDate)}</Box>
        <IconNewTab />
      </Box>
    ),
    $period: moment(settlement.startDate).unix(), // NOTE: This is the sorting value for column `period`
    settlementId: settlement.settlementId,
    // To be used once Transactions has been revamped
    // transactions: (
    //   <Box
    //     className={classes.transactionsContainer}
    //     onClick={() =>
    //       history.push('/transactions', {
    //         startDate: settlement.startDate,
    //         endDate: settlement.endDate,
    //       })
    //     }
    //   >
    //     {`${settlement.numTransactions} - View`}
    //   </Box>
    // ),
    transactions: settlement.numTransactions,
    customers: settlement.totalCustomers,
    aov: settlement.averageOrderValue,
    revenue: settlement.totalRevenue,
    ecFees: settlement.ecFees * -1, // note: fees are taken from the venue so show it as a negative
    platform: settlement.platform,
    view: (
      <Box
        className={classes.clickableContainer}
        onClick={() => window.open(settlement.pdfLink, '_blank', 'noopener')}
      >
        <Box className={classes.viewText}>View</Box>
      </Box>
    ),
  }));

  return (
    <Box style={{ paddingBottom: '40px' }}>
      <ReactTableWrapper
        columns={columns}
        data={data}
        loading={settlements.pending}
        noRecordsMsg='No settlements to display'
        pagination
      />
    </Box>
  );
};

Settlements.propTypes = {
  fetchSettlementsByRestId: PropTypes.func.isRequired,
  restaurantActive: restaurantActivePropTypes.isRequired,
  settlements: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.string.isRequired,
    pending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  restaurantActive: state.restaurantActive,
  settlements: state.settlements,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSettlementsByRestId: fetchSettlementsByRestIdAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Settlements);
