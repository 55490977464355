import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    // fix for variant outline select label being cut off
    '& .MuiInputLabel-shrink + div .MuiOutlinedInput-notchedOutline legend': {
      width: ['105px', '!important'],
    },
  },
  removeAppearance: {
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
    },
  },
}));

export default useStyles;
