import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Popover } from '@eatclub-apps/ec-component-library';
import { bindActionCreators } from 'redux';
import { CleanTextField } from '@eatclub-apps/ec-bookings-library'; // TODO move this to component library
import {
  clearRestaurantsByNameAction,
  fetchRestaurantsByNameAction,
} from '../../../../../actions/restaurantAction';
import useStyles from './RestaurantFinderStyles';
import { restaurantPropTypes } from '../../../../../data/models/Restaurant';
import { isVoid } from '../../../../../utils/helpers';

const RestaurantFinder = ({
  fetchRestaurantsByName,
  restaurants,
  clearRestaurantsByName,
  isOpen,
  onClose,
  anchorEl,
  onSelect,
}) => {
  const classes = useStyles();
  const [inputTimeout, setInputTimeout] = useState(0);
  const [restName, setRestName] = useState(null);

  useEffect(() => {
    if (inputTimeout) {
      clearTimeout(inputTimeout);
    }

    setInputTimeout(
      setTimeout(() => {
        if (isVoid(restName)) {
          clearRestaurantsByName();
        } else {
          fetchRestaurantsByName(restName);
        }
      }, 300),
    );
  }, [restName]);

  const restaurantSelected = (account) => {
    setRestName(null);
    onSelect(account);
  };

  const getItems = () => {
    if (restaurants.success || restaurants.error) {
      if (restaurants.data?.length > 0) {
        return restaurants.data.slice(0, 5).map((restaurantToUse) => (
          <Box
            onClick={() => restaurantSelected(restaurantToUse)}
            className={classes.navigationLink}
          >
            {restaurantToUse.name} ({restaurantToUse.region})
          </Box>
        ));
      }

      return <Box style={{ padding: '8px 12px' }}>No venues found!</Box>;
    }

    if (restaurants.fetching) {
      return <Box style={{ padding: '8px 12px' }}>Searching ...</Box>;
    }

    return <></>;
  };

  return (
    <Popover
      onClose={onClose}
      isOpen={isOpen}
      anchorEl={anchorEl}
      innerProps={{
        disableAutoFocus: true,
        disableEnforceFocus: true,
        disableRestoreFocus: true,
        keepMounted: true,
      }}
      innerStyle={{
        borderRadius: '10px',
        fontSize: '18px',
        marginTop: '10px',
        padding: '8px',
        width: '380px',
      }}
    >
      <Box className={classes.navigationLinks}>
        <CleanTextField
          autofocus
          placeholder='e.g. Burger Palace'
          value={restName}
          onChange={setRestName}
          disableSpellCheck
          style={{
            input: { padding: '12px', fontSize: '18px', lineHeight: 'unset' },
          }}
        />
        {getItems()}
      </Box>
    </Popover>
  );
};

RestaurantFinder.defaultProps = {
  onClose: () => {},
  onSelect: () => {},
  anchorEl: null,
};

RestaurantFinder.propTypes = {
  fetchRestaurantsByName: PropTypes.func.isRequired,
  clearRestaurantsByName: PropTypes.func.isRequired,
  restaurants: PropTypes.shape({
    data: PropTypes.arrayOf(restaurantPropTypes),
    fetching: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  anchorEl: PropTypes.element,
};

const mapStateToProps = (state) => ({
  restaurantActive: state.restaurantActive,
  restaurants: state.restaurants,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRestaurantsByName: fetchRestaurantsByNameAction,
      clearRestaurantsByName: clearRestaurantsByNameAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantFinder);
