import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paperDatePicker: {
    padding: theme.spacing(2),
    overflowX: 'auto',
    width: '700px',
    maxWidth: '100%',
  },
  gridItemDatePicker: {
    minWidth: '315px',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  formControlDatePicker: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minWidth: 110,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  highlightDay: {
    width: '36px',
    height: '36px',
    margin: '0 2px',
    padding: 0,
    fontSize: '0.75rem',
    fontWeight: '500',
  },
  highlightDayEnds: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  highlightDayBetween: {
    backgroundColor: theme.palette.primary.mainShades[200],
    color: theme.palette.common.black,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

export default useStyles;
