export const OBSettings = `
	daysInAdvance
	systemTimeInterval
	bookingsDuration
	waitlist
	abHighchairs
	abVaccination
	abTakenBy
	abConfirmation
	ccCapture
	ccNoShowFee
	ccPaymentPolicy
	ccWaitlistShow
	ccWaitlistPolicy
	ccAddBookings
	terms
	logo
	heroImage
	favicon
	stepperColor
	iconBgColor
	datePickerColor
	linkColor
	availableTimesColor
	formBgColor
	venueMessage
	disabledAccess
	dressCode
	kidFriendly
	kidsPlayArea
	byo
	corkage
	cakeage
	showHighchairs
	groupSizeLimit
	groupSizeMessage
	areaPhotos
	slug
	openingHoursStyle
	cutoffMode
	cutoffValue
`;

export const Settlement = `
	settlementId
	startDate
	endDate
	totalRevenue
	totalCustomers
	averageOrderValue
	numTransactions
	ecNet
	ecFees
	pdfLink
	platform
`;

export const Closure = `
	restId
	startDate
	endDate
	startTime
	endTime
`;

export const Restaurant = `
	objectId
	enabled
	name
	region
	menuVersion
	bdmEmail
	bdmName
	bdmPhone
	revPerDiner
	revPerDinerTakeaway
	hours
	platform
	showOppos
	showPredictions
	offersEnabled
	address1
	address2
	city
	state
	postcode
	phone
	website
	abn
	tradingName
	instagramLink
	facebookLink
	twitterLink
	obeeEnabled
	obeeGoLiveDate
	premium
	ecPayEnabled
	closures {
		${Closure}
	}
	inAppOrdering
	averageOrderValue
	averageBookingValue
`;

export const RestaurantName = `
	objectId
	enabled
	name
`;

export const OBTableBlock = `
	id
	tableId
	date
	startTime
	endTime
`;

export const OBTable = `
	id
	areaId
	tableNumber
	notes
	min
	max
	priority
	isPublic
	blocks {
		${OBTableBlock}
	}
`;

export const OBArea = `
	id
	name
	duration
	capacity
	priority
	minGroup
	maxGroup
	cutoffMode
	cutoffValue
`;

export const OBSession = `
	id
	areaId
	label
	startTime
	endTime
	isPublic
	day
	date
	sittingTimes
`;

export const OBGlobalSession = `
	objectId
	label
	startTime
	endTime
	isPublic
	day
	date
	sittingTimes
`;

export const OBGuest = `
	id
	firstName
	lastName
	email
	mobile
	landline
	title
	description
	notes
	allergies
	birthday
	vip
	jobCompany
	interests
	secretary
	address
	postcode
	website
	blog
	facebook
	twitter
	instagram
	pinterest
	linkedIn
	blacklisted
	favouriteMeals
	favouriteDrinks
	preferredTableArea
`;

export const OBBooking = `
	createdAt
	id
	areaId
	date
	time
	size
	name
	code
	restaurantComments
	customerComments
	source
	status
	tableNumber
	duration
	labels
	kids
	highchairs
	vaccinated
	staff
	history
	paymentCustomerId
	paymentMethodId
	paymentMethodName
	guests {
		${OBGuest}
	}
	ccNoShowFee
	ccNoShowChargeType
	ccNoShowChargeStatus
	ccNoShowChargeTime
	ccNoShowPaymentIntentId
`;

export const OBAvailabilitySuggestion = `
  time
  table {
    ${OBTable}
  }
`;

export const OBStaff = `
	objectId
	fullName
	appearAs
	restId
`;

export const OBRule = `
  id
  type
  areaId
  day
  date
  timeOption
  timeMin
  timeMax
  sizeOption
  sizeMin
  sizeMax
  applyTo
  ruleOption
  ruleOptionValue
  ruleIntervals
  grouping
`;

export const OBLimit = `
  objectId
  areaId
  days
  startDate
  endDate
  rules {
    ${OBRule}
  }
`;

export const OBTimeslotLimit = `
	objectId
	restId
	conditionId
	startTime
	endTime
	limitType
	limitValue
	groupSizeOption
	groupSizeMin
	groupSizeMax
	timeInterval
	source
`;

export const OBTimeslotCondition = `
	objectId
	areaId
	days
	startDate
	endDate
	limits {
	 ${OBTimeslotLimit}
 }
`;

export const Integration = `
	id
	data
	active
`;
