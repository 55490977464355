import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tableStyles: {
    // FIX: border causing weird 1px spacing
    '& .MuiTableFooter-root > .MuiTableRow-footer > .MuiTableCell-footer': {
      borderBottom: 0,
    },
    // table scrollbar styles
    '& ::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '& ::-webkit-scrollbar:vertical': {
      width: '12px',
    },
    '& ::-webkit-scrollbar:horizontal': {
      height: '12px',
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, .25)',
      borderRadius: '10px',
      border: '2px solid #ffffff',
    },
    '& ::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: '#ffffff',
    },
    // FIX: prevent squishy column headers
    '& .MuiTableCell-root.MuiTableCell-head': {
      minWidth: '100px',
    },
  },
}));

export default useStyles;
