/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Box, Collapse } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchOrderAction } from '../../actions/ordersAction';
import OrderCategories from './OrderCategories';
import OrderTotal from './OrderTotal';

const TableOrderBooking = ({
  fetchOrder,
  handleClickRefundItem,
  mode,
  open,
  order,
  orderEntering,
  orderId,
  orderLoading,
  region,
  restId,
  setOrderEntering,
  setOrderLoading,
  userId,
  userType,
}) => {
  useEffect(() => {
    if (open && !orderLoading && (orderEntering || order.orderFetching)) {
      setOrderLoading(true);
    }
    if (open && orderLoading && !orderEntering && !order.orderFetching) {
      setOrderLoading(false);
    }
  }, [open, order.orderFetching, orderEntering, orderLoading, setOrderLoading]);

  return (
    <Collapse
      in={open}
      onEnter={() => {
        setOrderEntering(true);
      }}
      onEntered={() => {
        fetchOrder(orderId, region, restId, userId, userType);
        setOrderEntering(false);
      }}
    >
      {!orderEntering && !order.orderFetching && order.orderData.items && (
        <>
          <Box style={{ marginRight: 50 }}>
            <OrderCategories
              orderItems={order.orderData.items}
              mode={mode}
              handleClickRefundItem={handleClickRefundItem}
            />
            <OrderTotal order={order.orderData} />
          </Box>
        </>
      )}
    </Collapse>
  );
};

const mapStateToProps = (state) => ({
  order: state.order,
  region: state.restaurantActive.restaurant.region,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchOrder: fetchOrderAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TableOrderBooking);
