import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    // color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  chart: {
    width: ['100%', '!important'],
  },
  chartHeight: {
    height: '300px',
  },
  card: {
    height: '100%',
  },
}));

export default useStyles;
