import PropTypes from 'prop-types';
import React from 'react';
import { Box, Divider, Fade } from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, CenteredContent } from '@eatclub-apps/ec-component-library';
import Check from '../../../assets/check_in_circle.svg';
import TargetIllustration from '../../../assets/target_illustration.svg';
import { offerPropTypes } from '../../../reducers/generatedOffersReducer';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency, pluralise, roundToNearest } from '../../../utils/helpers';
import { COLORS } from '../../../EatClubTheme';
import useStyles from './CreateGoalStyles';

const Success = ({ startingOffers }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.successContainer}>
      <Box className={classes.successBanner}>
        <CenteredContent style={{ height: 'auto' }}>
          <Box className={classes.heading}>Success</Box>
        </CenteredContent>
        <Box position='relative'>
          <TargetIllustration
            style={{
              color: COLORS.GRAPEFRUIT,
              position: 'absolute',
              left: '-110px',
              top: '-20px',
            }}
          />
        </Box>
      </Box>

      <Box className={classes.successContent}>
        <Fade in timeout={700}>
          <Box display='inline-flex' gap='16px'>
            <Check style={{ marginTop: '3px' }} />
            <Box>
              You have set up a goal to earn between{' '}
              {formatCurrency(
                roundToNearest(
                  startingOffers.reduce(
                    (total, offer) => total + parseFloat(offer?.targetValMonthlyLower, 10),
                    0,
                  ),
                  10,
                ),
                false,
              )}
              -
              {formatCurrency(
                roundToNearest(
                  startingOffers.reduce(
                    (total, offer) => total + parseFloat(offer?.targetValMonthlyUpper, 10),
                    0,
                  ),
                  10,
                ),
                false,
              )}{' '}
              per month
            </Box>
          </Box>
        </Fade>
        <Divider />
        <Fade in timeout={700} style={{ transitionDelay: '0.5s' }}>
          <Box display='inline-flex' gap='16px'>
            <Check style={{ marginTop: '3px' }} />
            <Box>
              {startingOffers?.length} {pluralise('offer is', startingOffers?.length, 'offers are')}{' '}
              now live on the EatClub Marketplace
            </Box>
          </Box>
        </Fade>
        <Divider />
        <Fade in timeout={700} style={{ transitionDelay: '1s' }}>
          <Box display='inline-flex' gap='16px'>
            <Check style={{ marginTop: '3px' }} />
            <Box>You can track your progress on the offers page</Box>
          </Box>
        </Fade>
        <Divider />
        <Fade in timeout={700} style={{ transitionDelay: '1.5s' }}>
          <Box display='inline-flex' gap='16px'>
            <Check style={{ marginTop: '3px' }} />
            <Box>Edit your goal or make changes at any time</Box>
          </Box>
        </Fade>
        <Divider />
      </Box>

      <Button
        style={{ marginTop: '50px' }}
        onClick={() => {
          trackEvent('button_click: return_to_offers');
          history.push('/offers');
        }}
      >
        Return to offers
      </Button>
    </Box>
  );
};

Success.propTypes = {
  startingOffers: PropTypes.arrayOf(offerPropTypes).isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Success);
