/* eslint-disable */
import React, { useState } from 'react';
import { FormControlLabel, Checkbox, Chip } from '@mui/material';
import Popover from '../../popover/Popover';

const DealDay = ({ mode, day, days, dealDateDay, handleSelectedDay }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  const selected = days.values.findIndex((selectedDay) => selectedDay === day.value) !== -1;

  return (
    <>
      <Checkbox
        color='secondary'
        checked
        icon={<Chip label={day.name} />}
        checkedIcon={
          <Chip
            disabled={mode === 'create'}
            label={` ${dealDateDay === day.value ? '• ' : ''} ${day.name}`}
            color={selected ? 'primary' : 'default'}
            style={{
              opacity: day.closed ? 0.5 : 1,
              backgroundColor: selected && mode === 'create' ? 'rgba(0, 0, 0, 0.26)' : '',
            }}
          />
        }
        value={day.value}
        onChange={(e) => handleSelectedDay(e, dealDateDay === day.value || day.closed)}
        onMouseEnter={dealDateDay === day.value || day.closed ? handlePopoverOpen : undefined}
        onMouseLeave={dealDateDay === day.value || day.closed ? handlePopoverClose : undefined}
        disabled={mode === 'create'}
      />
      <Popover
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        open={openPopover}
        text={day.closed ? 'Closed on this day' : 'Must include Start Date'}
      />
    </>
  );
};

export default DealDay;
