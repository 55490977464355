import * as type from '../actions/types';

const initialState = {
  subscription: {},
  fetching: false,
  error: false,
  errorMessage: '',
  success: false,
  shouldFetch: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SUB_EVENTS_PENDING: {
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    }
    case type.SUB_EVENTS_SUCCESS: {
      return {
        ...state,
        subscription: action.payload,
        fetching: false,
        success: true,
        shouldFetch: false,
      };
    }
    case type.SUB_EVENTS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.UNSUB_EVENTS: {
      if (typeof state.subscription.unsubscribe === 'function') {
        state.subscription.unsubscribe();
      }

      return {
        ...state,
        subscription: {},
        fetching: false,
        error: false,
        errorMessage: '',
        success: false,
      };
    }

    default: {
      return state;
    }
  }
};
