import React, { useState } from 'react';
import { HelpOutline } from '@mui/icons-material';
import Popover from '../../popover/Popover';

const PredictedResultHelp = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  return (
    <>
      <HelpOutline onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} />
      <Popover
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        open={openPopover}
        text={
          <p>
            EatClub&apos;s world&apos;s first machine learning model takes in millions of rows of
            data from thousands of venues to accurately predict the number of redemptions you will
            receive for any given deal. <br />
            <br />
            This allows you to make better decisions, filling your restaurant more often and making
            higher profits because of it.
          </p>
        }
        anchorOriginVertical='center'
        anchorOriginHorizontal='left'
        transformOriginHorizontal='left'
        PaperProps={{
          style: { maxWidth: '600px' },
        }}
      />
    </>
  );
};

export default PredictedResultHelp;
