import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useStyles from './OpportunityCardStyles';

const EstimateLabel = ({ type, value, compact }) => {
  const classes = useStyles({ compact });

  return (
    <Box>
      <Box className={classes.label}>{type}</Box>
      <Box className={classes.labelValueContainer}>
        <Box className={classes.redLabel}>{value}</Box>
        <Box>expected</Box>
      </Box>
    </Box>
  );
};

EstimateLabel.defaultProps = {
  compact: false,
};

EstimateLabel.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  compact: PropTypes.bool,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EstimateLabel);
