import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrderRefundConfirmDialog from './OrderRefundConfirmDialog';
import { refundFullAction, clearRefundAction } from '../../actions/refundAction';
import { formatNumber } from '../../utils';

const OrderRefundFullDialog = ({
  clearRefund,
  handleCloseDialog,
  openDialog,
  order,
  refund,
  refundFull,
  restId,
  userId,
  userType,
}) => {
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(<></>);

  const { success, loading } = refund;
  const { total, reservationName, objectId } = order;

  const handleClickOpenDialogConfirm = (message) => {
    setOpenDialogConfirm(true);
    setConfirmMessage(message);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };

  const submit = () => {
    const message = (
      <>
        Are you sure you want to refund <strong>${formatNumber(total)}</strong> for{' '}
        <strong>{reservationName}</strong>?
      </>
    );

    handleClickOpenDialogConfirm(message);
  };

  const confirm = () => {
    refundFull(objectId, restId, userId, userType);
  };

  // NOTE: Close dialog when state returns successful
  useEffect(() => {
    if (!success || !openDialog) {
      return;
    }
    handleCloseDialog();
  }, [handleCloseDialog, openDialog, success]);

  // NOTE: Disable close when loading
  const handleOnClose = () => {
    if (loading) {
      return;
    }
    handleCloseDialog();
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleOnClose}
        keepMounted={false}
        TransitionProps={{
          onEnter: () => {
            setConfirmMessage(<></>);
          },
          onExit: clearRefund,
        }}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle>Refund Full Order</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Refund the full order of <strong>${formatNumber(total)}</strong> for{' '}
            <strong>{reservationName}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button onClick={submit} color='primary' autoFocus>
            {loading ? <CircularProgress color='primary' size={22} /> : 'Refund'}
          </Button>
        </DialogActions>
      </Dialog>

      <OrderRefundConfirmDialog
        title='Refund Full Order?'
        description={confirmMessage}
        handleCloseDialog={handleCloseDialogConfirm}
        openDialog={openDialogConfirm}
        confirmAction={confirm}
      />
    </>
  );
};

OrderRefundFullDialog.propTypes = {
  clearRefund: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    objectId: PropTypes.string,
    reservationName: PropTypes.string,
    total: PropTypes.number,
  }).isRequired,
  refund: PropTypes.shape({
    data: PropTypes.shape({}),
    loading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    success: PropTypes.bool,
  }).isRequired,
  refundFull: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  refund: state.refund,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ refundFull: refundFullAction, clearRefund: clearRefundAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderRefundFullDialog);
