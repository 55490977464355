import * as type from '../actions/types';

const initialState = {
  invoicesFetching: true,
  invoicesData: [],
  invoicesError: false,
  invoicesErrorMessage: '',
  invoicesSuccess: false,
  invoicesShouldFetch: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_INVOICES_PENDING:
      return {
        ...state,
        invoicesFetching: true,
        invoicesError: false,
        invoicesErrorMessage: '',
      };
    case type.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesData: action.payload,
        invoicesFetching: false,
        invoicesError: false,
        invoicesErrorMessage: '',
        invoicesSuccess: true,
        invoicesShouldFetch: false,
      };
    case type.FETCH_INVOICES_FAILURE:
      return {
        ...state,
        invoicesError: true,
        invoicesErrorMessage: action.payload,
        invoicesFetching: false,
      };

    // if restaurant id changes then show loading state
    case type.SET_ACTIVE_RESTAURANT_ID:
      return {
        ...state,
        invoicesFetching: true,
      };

    // if restaurant changes then reset invoices
    case type.SET_ACTIVE_RESTAURANT:
      return {
        ...state,
        invoicesSuccess: false,
        invoicesShouldFetch: true,
      };

    default:
      return state;
  }
};
