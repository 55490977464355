import PropTypes from 'prop-types';
import React from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CenteredContent } from '@eatclub-apps/ec-component-library';
import Bag from '../../../assets/bag.svg';
import Chair from '../../../assets/chair.svg';
import Target from '../../../assets/target.svg';
import theme, { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';
import { offerPropTypes } from '../../../reducers/generatedOffersReducer';
import { formatCurrency, pluralise } from '../../../utils/helpers';
import ActionMenu from '../ActionMenu/ActionMenu';
import SmallCheck from '../../../assets/small_check.svg';
import useStyles from './OfferCardStyles';

/**
 * A more basic version of the other offer card, used only for predictions
 */
const PredictedOfferCard = ({ offer, deleteOffer, editOffer, occurrences }) => {
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));
  const classes = useStyles();

  const getBookingLabel = () => {
    if (offer?.partySizeLower === offer?.partySizeUpper) {
      return Math.max(1, offer?.partySizeMonthlyLower);
    }
    return `${Math.max(1, offer?.partySizeMonthlyLower)} - ${Math.max(
      2,
      offer?.partySizeUpper * occurrences,
    )}`;
  };

  const actions = [
    {
      name: 'Edit offer',
      type: 'edit',
      onClick: () => editOffer(offer),
      visible: true,
    },
    {
      name: 'Remove offer',
      type: 'delete',
      onClick: () => deleteOffer(offer),
      visible: true,
    },
  ];

  const hasDineIn = () => !offer?.takeawayOnly;
  const hasTakeaway = () => !offer?.dineInOnly;

  const getCustomers = () => {
    return (
      <Box>
        <Box className={classes.predictionLabel}>Customers</Box>
        <Box>
          <span className={classes.predictionValue}>{getBookingLabel()}</span> Predicted
        </Box>
      </Box>
    );
  };

  const getRevenue = () => {
    return (
      <Box>
        <Box className={classes.predictionLabel}>Revenue</Box>
        <Box>
          <span className={classes.predictionValue}>
            {formatCurrency(Math.round(offer?.targetValMonthly), false)}
          </span>{' '}
          Predicted
        </Box>
      </Box>
    );
  };

  const getRepeatText = () => {
    // Don't show count on mobile due to screen width
    if (breakpointXsDown) {
      return 'Repeats';
    }

    return ` Repeats ${
      occurrences > 0 ? `${occurrences} ${pluralise('time', occurrences)}` : 'weekly'
    }`;
  };

  return (
    <Box>
      <Box className={classes.container}>
        <Box className={classes.leftBar} />
        <Grid container className={classes.innerContainer}>
          <Grid item xs={12} sm={6} display='flex' style={{ gap: '40px' }}>
            <Box>
              <Box className={classes.headingRow}>
                <Box className={classes.subHeading}>
                  {!offer?.lightning
                    ? 'All day'
                    : `${!breakpointXsDown ? 'From' : ''} ${offer?.startTimeReadable} - ${
                        offer?.endTimeReadable
                      }`}
                </Box>
                <Box className={classes.statusAndPeakContainer}>
                  {offer.lightning && (
                    <Box>
                      <Box className={classes.peak}>
                        {offer?.inPeak || offer?.onPeak ? 'Peak' : 'Off-peak'}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box className={classes.details}>
                <Box className={classes.detail}>
                  <SmallCheck style={{ marginTop: '5px', color: COLORS.BLACK }} />
                  <Box>{offer?.discount}% off</Box>
                </Box>

                <Box display='inline-flex' gap='5px'>
                  <SmallCheck style={{ marginTop: '5px', color: COLORS.BLACK }} />
                  <Box>{getRepeatText()}</Box>
                </Box>
                <Box display='flex' gap='6px'>
                  {hasDineIn() && (
                    <Box className={classes.iconBackground}>
                      <Box left='6px' top='3px' style={{ position: 'absolute' }}>
                        <Chair />
                      </Box>
                    </Box>
                  )}
                  {hasTakeaway() && (
                    <Box className={classes.iconBackground}>
                      <Box left='4px' top='3px' style={{ position: 'absolute' }}>
                        <Bag />
                      </Box>
                    </Box>
                  )}
                  <Box className={classes.iconBackground}>
                    <Box left='-1px' top='3px' style={{ position: 'absolute' }}>
                      <Target />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={5} className={classes.predictionsContainer}>
            <Box style={{ display: 'flex', gap: '12px' }}>
              <CenteredContent>{getCustomers()}</CenteredContent>
              <CenteredContent>{getRevenue()}</CenteredContent>
            </Box>
          </Grid>
        </Grid>

        {actions?.length > 0 && (
          <Box className={classes.actionMenu}>
            <ActionMenu options={actions} disabled={offer?.status === 'cancelled'} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

PredictedOfferCard.defaultProps = {
  occurrences: 0,
};

PredictedOfferCard.propTypes = {
  offer: offerPropTypes.isRequired,
  deleteOffer: PropTypes.func.isRequired,
  editOffer: PropTypes.func.isRequired,
  occurrences: PropTypes.number,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PredictedOfferCard);
