import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Modal, CenteredContent, Button } from '@eatclub-apps/ec-component-library';
import { dealPropTypes } from '../../../data/models/Deal';
import { trackEvent } from '../../../utils/analytics';
import { getDayFromInt, formatCurrency } from '../../../utils/helpers';
import useStyles, { modalInputStyle, modalStyle } from './ModalStyles';
import ModalSectionHeader from './ModalSectionHeader';

const RemoveOfferModal = ({ isOpen, offer, onClose, onSave, revenueMonthly }) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: remove_offer');
    }
  }, [isOpen]);

  return (
    <Modal
      inputStyle={modalInputStyle}
      fullWidth={false}
      style={modalStyle}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
      footer={[
        <Button
          onClick={onClose}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='tertiary'
        >
          Cancel
        </Button>,
        <Button
          onClick={onSave}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='primary'
        >
          Save changes
        </Button>,
      ]}
    >
      {isOpen && (
        <Box className={classes.modalBody}>
          <Box className={classes.modalContent}>
            <Box className={classes.modalHeader}>Remove offer</Box>
            <Box>
              <Box>You&apos;re about to remove the following:</Box>
              <Box className={classes.inlineText}>
                <Box className={classes.discountLabel}>{offer?.discount}% off</Box>
                <CenteredContent>
                  on {getDayFromInt(offer?.dayOfWeek)} from{' '}
                  {`${offer?.startTimeReadable} - ${offer?.endTimeReadable}`}
                </CenteredContent>
              </Box>
            </Box>
            <Box>
              <ModalSectionHeader header='Impact' />
              <Box className={classes.impactRow}>
                <Box>Change in revenue goal</Box>
                <Box className={classes.redLabel}>
                  -{formatCurrency(offer?.targetValMonthly, false)}
                </Box>
              </Box>
              <Box className={classes.impactRow}>
                <Box>Adjusted revenue goal</Box>
                <Box className={classes.impactCurrency}>
                  {formatCurrency(revenueMonthly - offer?.targetValMonthly, false)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

RemoveOfferModal.defaultProps = {
  offer: null,
  onSave: () => {},
};

RemoveOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  offer: dealPropTypes,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  revenueMonthly: PropTypes.number.isRequired,
};

export default RemoveOfferModal;
