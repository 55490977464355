import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    backgroundColor: props?.selected ? '#FBF1EE' : '#FFFFFF',
    cursor: 'pointer',
    padding: '8px',
    paddingLeft: '13px',
    paddingRight: '13px',
    borderRadius: '100px',
    fontSize: '18px',
    // minWidth: '100px',
    transition: 'background-color 0.1s',
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',

    '&:hover': {
      backgroundColor: '#F2F2F2 !important',
    },
  }),
  dayButton: (props) => ({
    color: props?.selected ? '#E54439' : '#6E6E6D',
    textTransform: 'capitalize',
    fontWeight: props?.selected ? 'bold' : 'normal',
  }),
  itemAmount: (props) => ({
    backgroundColor: props?.selected ? '#FFFFFF' : '#F0F0F0',
    borderRadius: '10px',
    width: '20px',
    height: '20px',
    paddingTop: '1px',
    // margin: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '14px',
  }),
}));

export default useStyles;
