/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */

export const getWhitelabelData = (bookings, type) => {
  const orders = bookings.reduce((accumByPlatform, booking) => {
    const key = booking.platform;

    if (key !== 'whitelabel') {
      return accumByPlatform;
    }

    if (!accumByPlatform[key]) {
      accumByPlatform[key] = { total: 0, origTotal: 0, count: 0 };
    }

    accumByPlatform[key].origTotal += Number(booking.origTotal);
    accumByPlatform[key].total += Number(booking.total);
    accumByPlatform[key].count += 1;

    return accumByPlatform;
  }, {});

  const averageOrder = orders.hasOwnProperty('whitelabel')
    ? orders.whitelabel.total / orders.whitelabel.count
    : 0;

  const ordersTotal = orders.hasOwnProperty('whitelabel') ? orders.whitelabel.total : 0;

  switch (type) {
    case 'averageOrder':
      return averageOrder;

    case 'ordersTotal':
      return ordersTotal;

    default:
      return undefined;
  }
};

export const getCompareWhitelabelData = (compareBookings, type) => {
  const compareOrders = compareBookings.reduce((accumByPlatform, booking) => {
    const key = booking.platform;

    if (key !== 'whitelabel') {
      return accumByPlatform;
    }

    if (!accumByPlatform[key]) {
      accumByPlatform[key] = { total: 0, origTotal: 0, count: 0 };
    }

    accumByPlatform[key].origTotal += Number(booking.origTotal);
    accumByPlatform[key].total += Number(booking.total);
    accumByPlatform[key].count += 1;

    return accumByPlatform;
  }, {});

  const compareAverageOrder = compareOrders.hasOwnProperty('whitelabel')
    ? compareOrders.whitelabel.total / compareOrders.whitelabel.count
    : 0;

  const compareOrdersTotal = compareOrders.hasOwnProperty('whitelabel')
    ? compareOrders.whitelabel.total
    : 0;

  switch (type) {
    case 'compareAverageOrder':
      return compareAverageOrder;

    case 'compareOrdersTotal':
      return compareOrdersTotal;
    default:
      return undefined;
  }
};
