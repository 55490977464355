import * as type from '../actions/types';
import { safeParse } from '../utils/helpers';

const initialState = {
  data: {},
  error: '',
  pending: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.OBEE_PRICING_PENDING:
      return {
        ...state,
        pending: true,
        error: '',
      };
    case type.OBEE_PRICING_SUCCESS:
      const parseData = safeParse(action.payload.data);
      return {
        ...state,
        data: { lite: parseData?.lite, premium: parseData?.premium },
        pending: false,
        success: true,
      };
    case type.OBEE_PRICING_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
