import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { Button, Card, CardContent } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { usePageViews } from '../../../utils/analytics';
import useStyles from '../createDeal/CreateDealsStyles';
import { fetchDealAction, clearDealAction } from '../../../actions/dealAction';
import EditDealForm from './EditDealForm';
import LoadingBox from '../../loading/LoadingBox';

const EditDeal = ({ clearDeal, deal, fetchDeal, region, userId, userType, restId }) => {
  const classes = useStyles();
  const { dealId } = useParams();
  usePageViews();

  // Fetch the Deal
  useEffect(() => {
    if (!dealId || deal.fetching || deal.success) {
      return;
    }
    fetchDeal([dealId], region, restId, userId, userType);
  }, [deal.fetching, deal.success, dealId, fetchDeal, region, restId, userId, userType]);

  // Clear deal on unsubscribe
  useEffect(
    () => () => {
      clearDeal();
    },
    [clearDeal],
  );

  return (
    <div className={classes.root}>
      <Button component={Link} color='primary' to='/deals' style={{ marginBottom: 6 }}>
        <ChevronLeft /> Back to Deals
      </Button>
      {!deal.fetching && deal.success ? (
        <EditDealForm />
      ) : (
        <Card>
          <CardContent>
            <LoadingBox />
          </CardContent>
        </Card>
      )}
    </div>
  );
};

EditDeal.propTypes = {
  clearDeal: PropTypes.func.isRequired,
  fetchDeal: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    fetching: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  region: PropTypes.string.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  region: state.restaurantActive.restaurant.region,
  deal: state.deal,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clearDeal: clearDealAction, fetchDeal: fetchDealAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditDeal);
