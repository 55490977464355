import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Chip } from '@mui/material';
import { Favorite } from '@mui/icons-material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchFavouritesAction } from '../../../actions/restaurantFavAction';
import { platformMap } from '../../../constants';

const Favourited = ({ fetchFavourites, restId, restaurantFav, platform, userId, userType }) => {
  const [favourite, setFavourite] = useState(0);
  const { numberOfFavs, error, shouldFetch } = restaurantFav;

  useEffect(() => {
    if (!shouldFetch) {
      return;
    }
    fetchFavourites(restId, userId, userType);
  }, [fetchFavourites, restId, shouldFetch, userId, userType]);

  useEffect(() => {
    if (error) {
      return;
    }

    const favouriteNumber = Number(numberOfFavs) ? numberOfFavs : 0;
    setFavourite(favouriteNumber);
  }, [numberOfFavs, error]);

  const format = (text) => text.toLocaleString('en');

  if (error) {
    return <Chip icon={<Favorite />} label='-' />;
  }

  if (platform === platformMap.whitelabel.value) {
    return null;
  }

  return (
    <Tooltip title={`Favourited by ${format(favourite)} user${favourite !== 1 ? 's' : ''}`}>
      <Chip icon={<Favorite />} label={format(favourite)} />
    </Tooltip>
  );
};

Favourited.propTypes = {
  fetchFavourites: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  restaurantFav: PropTypes.shape({
    numberOfFavs: PropTypes.number,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    shouldFetch: PropTypes.bool,
  }).isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  platform: state.platform.platform,
  restaurantFav: state.restaurantFav,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFavourites: fetchFavouritesAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Favourited);
