import moment from 'moment';
import * as type from '../actions/types';
import { calculateStatusForDeal } from '../data/models/Deal';
import { formatTimeFromInt } from '../utils/helpers';

const initialState = {
  data: [],
  // dataBookings: [],
  fetching: true,
  error: '',
  shouldFetch: true,
  goalId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_DEALS_FOR_GOAL_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    case type.FETCH_DEALS_FOR_GOAL_SUCCESS: {
      const { deals } = action.payload;

      const today = moment(); // TODO get in restaurant's timezone

      const updatedData = deals.map((deal) => {
        const startTime = formatTimeFromInt(deal.startTime);
        const endTime = formatTimeFromInt(deal.endTime);

        return {
          ...deal,
          startTimeReadable: startTime,
          endTimeReadable: endTime,
          status: calculateStatusForDeal(deal),
        };
      });

      // const sortedDeals = Array.from(deals).sort((deal1, deal2) =>
      //   sortDealsByLightningByStartTime(deal1, deal2),
      // );

      return {
        ...state,
        data: updatedData,
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }
    case type.FETCH_DEALS_FOR_GOAL_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        fetching: false,
      };

    case type.CREATE_DEAL_SUCCESS: {
      return state;
    }

    case type.DISABLE_DEAL_SUCCESS: {
      return state;
    }

    case type.DELETE_DEAL_SUCCESS: {
      return state;
    }

    case type.UPDATE_DEAL_SUCCESS: {
      return state;
    }

    case type.INCREASE_DEAL_SUCCESS: {
      return state;
    }

    case type.HELP_DEAL_SUCCESS: {
      return state;
    }

    default:
      return state;
  }
};
