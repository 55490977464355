import { createUseStyles } from 'react-jss';
import { COLORS } from '../../EatClubTheme';

const useStyles = createUseStyles(() => ({
  fixFirstColumn: {
    '& th:first-child:after, td:first-child:after': {
      boxShadow: 'inset 10px 0 8px -8px #00000026',
    },
  },
  container: ({ style }) => ({
    borderRadius: '8px 8px 0 0',
    // maxHeight: '602px',
    paddingBottom: '40px',
    overflow: 'auto',
    position: 'relative',
    zIndex: 99,
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(49, 49, 49, 0)',
      borderRadius: '8px',
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(49, 49, 49, 0.6)',
      },
    },
    ...style?.container,
  }),
  table: {
    borderCollapse: 'separate',
    borderRadius: '8px 8px 0 0',
    borderSpacing: 0,
    maxHeight: '600px',
    minWidth: '100%',
    overflowX: 'scroll',
    textAlign: 'left',
    width: 'max-content',
    zIndex: 99,

    '& tr td': {
      position: 'relative',
    },

    '& th:first-child, td:first-child': {
      left: 0,
      paddingLeft: '24px !important',
      position: 'sticky',
      zIndex: 2,
    },

    '& th:first-child': {
      backgroundColor: COLORS.LINEN,
    },

    '& td:first-child': {
      backgroundColor: COLORS.WHITE,
      transition: 'background-color 0.1s',
    },

    '& td:first-child:after, th:first-child:after': {
      bottom: '-1px',
      content: '""',
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translate(100%)',
      transition: 'box-shadow 0.25s',
      transitionDelay: '0s',
      transitionDuration: '0.25s',
      transitionProperty: 'box-shadow',
      transitionTimingFunction: 'ease',
      width: '30px',
    },

    '& th:last-child, td:last-child': {
      paddingRight: '24px !important',
    },
  },
  headerRow: {
    background: COLORS.LINEN,
    height: '54px',
    position: 'sticky',
    top: 0,
    zIndex: 99,
  },
  dataRow: {
    backgroundColor: COLORS.WHITE,
    height: '54px',
    transition: 'background-color 0.1s',
    '& td': {
      transition: 'background-color 0.1s',
    },
    '&:hover': {
      backgroundColor: `${COLORS.LIGHT_CLOUD} !important`,

      '& td': {
        backgroundColor: `${COLORS.LIGHT_CLOUD} !important`,
      },
    },
  },
  totalsRow: {
    backgroundColor: COLORS.CLOUD,
    bottom: 0,
    height: '54px',
    position: 'sticky',
    zIndex: 2,
    '& td:first-child': {
      backgroundColor: `${COLORS.CLOUD} !important`,
    },
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 99,
  },
  headerCell: {
    border: 'none',
    fontWeight: 500,
    padding: '0 15px',
    transition: 'color 0.1s',
    userSelect: 'none',

    '&:hover': {
      '& $iconArrow': {
        opacity: '0.4',
      },
    },
  },
  dataCell: {
    borderBottom: `1px solid ${COLORS.CLOUD}`,
    padding: '0 15px',
  },
  dataCellOverflow: {
    marginLeft: '-10px',
    overflow: 'hidden',
    padding: '5px 10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 'fit-content',
    '&:hover': {
      background: COLORS.WHITE,
      borderRadius: '8px',
      cursor: 'default',
      overflow: 'unset',
      position: 'absolute',
      textOverflow: 'unset',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 99,
    },
  },
  iconArrow: {
    marginTop: '2px',
    opacity: 0,
    transition: 'transform 0.1s, opacity 0.1s',
  },
  headerCellInner: {
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
    width: 'fit-content',
  },
  paginationBtn: {
    userSelect: 'none',
    background: '#F2F2F2',
    width: '48px',
    height: '24px',
    borderRadius: '4px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    transition: 'background 0.1s',
    '&:hover': {
      background: COLORS.LIGHT_CLOUD,
      cursor: 'pointer',
    },
  },
  pageSizeBtn: {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    padding: '5px 10px',
    transition: 'background 0.1s',
    borderRadius: '4px',
    '&:hover': {
      background: COLORS.LIGHT_CLOUD,
      cursor: 'pointer',
    },
  },
  noRecords: {
    alignItems: 'center',
    backgroundColor: COLORS.WHITE,
    color: COLORS.SMOKE,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '500px',
    textAlign: 'center',
  },
  paginationContainer: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '14px',
    justifyContent: 'flex-end',
    paddingTop: '12px',
  },
}));

export default useStyles;
