/* eslint-disable import/prefer-default-export */
import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { bookingsForAnalytics } from '../graphql/queries';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

const fetchBookings = (
  resolve,
  reject,
  restId,
  region,
  startDate,
  endDate,
  platform,
  bookingType,
  userId,
  userType,
) => {
  const paginatedBookings = [];

  const bookingsLoop = async (pageSize, offset) => {
    try {
      const response = await API.graphql(
        {
          query: bookingsForAnalytics,
          variables: { restId, region, pageSize, offset, startDate, endDate, platform },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      // destructure
      const {
        data: { bookingsForAnalytics: bookings },
      } = response;

      if (!bookings) {
        throw new Error('analytics null');
      }

      // congregate data
      if (bookings.length > 0) {
        paginatedBookings.push(...bookings);
      }

      // But wait, there's more!
      if (bookings.length >= pageSize) {
        bookingsLoop(pageSize, offset + pageSize);
        return;
      }

      devLog('success', bookingType, paginatedBookings);

      // Success resolve
      resolve({ [bookingType]: paginatedBookings });
    } catch (error) {
      devLog('error', bookingType, error);

      reject(error);
    }
  };
  bookingsLoop(500, 0);
};

export const fetchAnalyticBookingsAction =
  (
    restId,
    region,
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    platform,
    userId,
    userType,
  ) =>
  async (dispatch) => {
    dispatch({
      type: type.FETCH_ANALYTIC_BOOKINGS_PENDING,
    });

    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'ANALYTIC_BOOKINGS',
    });

    const promiseBookings = new Promise((resolve, reject) => {
      fetchBookings(
        resolve,
        reject,
        restId,
        region,
        startDate,
        endDate,
        platform,
        'bookings',
        userId,
        userType,
      );
    });

    const promiseCompareBookings = new Promise((resolve, reject) => {
      fetchBookings(
        resolve,
        reject,
        restId,
        region,
        compareStartDate,
        compareEndDate,
        platform,
        'compareBookings',
        userId,
        userType,
      );
    });

    try {
      const response = await Promise.all([promiseBookings, promiseCompareBookings]);

      devLog('success', 'analytic bookings', response);

      dispatch({
        type: type.FETCH_ANALYTIC_BOOKINGS_SUCCESS,
        payload: {
          bookings: response[0].bookings,
          compareBookings: response[1].compareBookings,
        },
      });
    } catch (error) {
      devLog('error', 'analytic bookings', error);
      dispatch({
        type: type.FETCH_ANALYTIC_BOOKINGS_FAILURE,
        payload: `Unable to retrieve analytics: ${error.message}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'ANALYTIC_BOOKINGS',
      });
    }
  };
