/* eslint-disable */
import React, { useState } from 'react';
import { Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Flag } from '@mui/icons-material';
import FlagDialog from './FlagDialog';
import { trackEvent } from '../../utils/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      position: 'relative',
    },
  },
  fab: {
    zIndex: 999,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const FlagButton = ({ menu }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    trackEvent('modal_view: report_issue');
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Fab color='primary' variant='extended' className={classes.fab} onClick={handleOpenDialog}>
        <Flag className={classes.extendedIcon} />
        Report Issue
      </Fab>
      <FlagDialog menu={menu} open={open} handleCloseDialog={handleCloseDialog} />
    </div>
  );
};

export default FlagButton;
