/* eslint-disable no-shadow */
import { Box, CircularProgress } from '@mui/material';
import {
  Button,
  Dropdown,
  SegmentedControl,
  TextSelect,
  TimePicker,
  ToggleGroup,
} from '@eatclub-apps/ec-component-library';
import moment from 'moment';
import PropTypes from 'prop-types';
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { clearDealPredictAction } from '../../../actions/dealPredictAction';
import { updateDealAction } from '../../../actions/dealUpdateAction';
import { editOfferAction } from '../../../actions/offersForGoalAction';
import { goalsPropTypes } from '../../../data/models/Goal';
import { COLORS } from '../../../EatClubTheme';
import {
  findObjectByProperty,
  formatCurrency,
  isSet,
  emptyObject,
  getType,
  eatClubDayOfWeekToMoment,
  getDayOfWeek,
  isEmpty,
} from '../../../utils/helpers';
import Error from '../../error/Error';
import EditOfferModal from '../../offers/Modals/EditOfferModal';
import OfferChangePrediction from '../../offers/OfferChangePrediction/OfferChangePrediction';
import useStyles from '../createDeal/CreateDealsStyles';

/**
 * This is the new version of the old "EditDealForm"
 */
const EditOfferForm = ({
  offerGroup,
  goals,
  dealUpdate,
  updateDeal,
  restId,
  userId,
  userType,
  editOffer,
  restaurantHours,
  clearDealPredict,
  offerEdit,
  predict,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { data: prediction, pending } = predict;

  // Set as changed initially to trigger refetch
  const [changed, setChanged] = useState(false); // Flag for if the form has been edited
  const [goal, setGoal] = useState(null); // TODO change to ref to avoid render
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (offerGroup?.goalId) {
      setGoal(findObjectByProperty(goals?.data, offerGroup?.goalId));
    }
  }, [offerGroup, goals]);

  const getInitialPeriod = () => {
    if (offerGroup?.lightning) {
      return 'timeLimited';
    }
    return 'allDay';
  };

  const getFrequency = () => (offerGroup?.recurring ? 'recurring' : 'oneTime');

  const dineInOffer = offerGroup?.deals?.find((deal) => getType(deal) !== 'Takeaway');
  const takeawayOffer = offerGroup?.deals?.find((deal) => getType(deal) !== 'Dine In');

  const getTypes = () => [
    ...(dineInOffer !== undefined ? ['dineIn'] : []),
    ...(takeawayOffer !== undefined ? ['takeaway'] : []),
  ];

  // TODO move somewhere else
  const hoursByDay = JSON.parse(restaurantHours);
  const startDay = moment(offerGroup?.startDate).format('ddd').toLowerCase();
  const openHoursByDay = useCallback(
    (dayName) => {
      const hours = Object.keys(hoursByDay)
        .map((day, index) => (day.substr(0, 3) === dayName ? index : null))
        .filter((e) => e !== null)
        .map((index) => Object.values(hoursByDay)[index]);

      if (hours[0] > hours[1]) {
        hours[1] = 1439; // cap tomorrow to midnight
      }

      return hours;
    },
    [hoursByDay],
  );
  const todayHours = openHoursByDay(startDay);

  // if store opens at 9:01 start bookings at 9:15
  const openingOption =
    todayHours[0] % 15 > 0 ? todayHours[0] + 15 - (todayHours[0] % 15) : todayHours[0];

  // if store closes at 17:01 end bookings at 17:00
  const closingOption =
    todayHours[1] % 15 > 0 ? todayHours[1] - (todayHours[1] % 15) : todayHours[1];

  const lastBookingDateIsToday = (offer) =>
    !isEmpty(offer?.lastBookingDate) &&
    moment.parseZone(offer.lastBookingDate).local().isSame(moment(), 'day');

  const wasRecurringOfferBookedToday = (offer) => {
    return offer?.recurring && lastBookingDateIsToday(offer);
  };

  /**
   * Get either the recurring quantity or the quantity left
   * @param offer
   * @returns {Requireable<number>|*}
   */
  const getQuantityForOffer = (offer) => {
    if (!offer?.recurring || lastBookingDateIsToday(offer)) {
      return offer?.qtyLeft;
    }

    return offer?.recurringQty;
  };

  const [frequency, setFrequency] = useState(getFrequency());
  const [dayOfWeek, setDayOfWeek] = useState(offerGroup?.dayOfWeek);
  const [period, setPeriod] = useState(getInitialPeriod());
  const [startTime, setStartTime] = useState(
    offerGroup?.lightning ? offerGroup?.startTime : openingOption,
  );
  const [endTime, setEndTime] = useState(
    offerGroup?.lightning ? offerGroup?.endTime : closingOption,
  );
  const [percentage, setPercentage] = useState(offerGroup?.discount);
  const [dineInQuantity, setDineInQuantity] = useState(getQuantityForOffer(dineInOffer));
  const [takeawayQuantity, setTakeawayQuantity] = useState(getQuantityForOffer(takeawayOffer));
  const [type, setType] = useState(getTypes());
  const [errors, setErrors] = useState({});
  const [allOccurrences, setAllOccurrences] = useState(offerGroup?.recurring);
  const [submitting, setSubmitting] = useState(false);

  const belongsToGoal = !!offerGroup?.goalId;

  const validate = () => {
    const newErrors = {};

    // Day of week must be set
    if (!isSet(dayOfWeek)) {
      newErrors.dayOfWeek = 'You must set a day of the week';
    }

    // Time period
    if (period !== 'allDay' && period !== 'timeLimited') {
      newErrors.period = 'You must choose a time period';
    }

    // Start and end times
    if (period === 'timeLimited') {
      if (!isSet(startTime)) {
        newErrors.startTime = 'You must set a start time';
      }

      if (!isSet(endTime)) {
        newErrors.endTime = 'You must set an end time';
      }

      if (isSet(startTime) && isSet(endTime) && startTime >= endTime) {
        newErrors.startTime = 'Start time must be before end time';
        newErrors.endTime = 'Start time must be before end time';
      }
    }

    // Percentage
    if (!isSet(percentage)) {
      newErrors.percentage = 'You must set an offer percentage';
    }

    // Quantity
    if (
      (!isSet(dineInQuantity) && !isSet(takeawayQuantity)) ||
      (type.includes('dineIn') && dineInQuantity <= 0) ||
      (type.includes('takeaway') && takeawayQuantity <= 0)
    ) {
      newErrors.quantity = 'You must set a quantity';
    }

    // Type
    if (type?.length === 0) {
      newErrors.type = 'You must choose one of takeaway or delivery';
    }

    setErrors(newErrors);
    return emptyObject(newErrors);
  };

  useEffect(() => {
    validate();

    setChanged(
      () =>
        frequency !== getFrequency() ||
        period !== getInitialPeriod() ||
        startTime !== (offerGroup?.lightning ? offerGroup?.startTime : openingOption) ||
        endTime !== (offerGroup?.lightning ? offerGroup?.endTime : closingOption) ||
        percentage !== offerGroup?.discount ||
        dineInQuantity !== dineInOffer?.qtyLeft ||
        takeawayQuantity !== takeawayOffer?.qtyLeft ||
        (dineInOffer !== undefined && !type.includes('dineIn')) ||
        (takeawayOffer !== undefined && !type.includes('takeaway')),
    );
  }, [
    frequency,
    dayOfWeek,
    period,
    startTime,
    endTime,
    percentage,
    dineInQuantity,
    takeawayQuantity,
    type,
  ]);

  const dineInQtyChange = () => {
    if (dineInQuantity !== dineInOffer?.qtyLeft) {
      if (type?.includes('dineIn')) {
        return dineInQuantity;
      }

      return 0;
    }

    return null;
  };

  const takeawayQtyChange = () => {
    if (takeawayQuantity !== takeawayOffer?.qtyLeft) {
      if (type?.includes('takeaway')) {
        return takeawayQuantity;
      }

      return 0;
    }

    return null;
  };

  const handleSubmit = (
    editReason,
    additionalReason,
    revenueChange,
    customersLower,
    customersUpper,
  ) => {
    const valid = validate();

    if (!valid) {
      return;
    }

    setSubmitting(true);

    editOffer(
      offerGroup?.deals?.map((deal) => deal?.objectId),
      period !== getInitialPeriod() ? period === 'timeLimited' : null,
      percentage !== offerGroup?.discount ? percentage : null,
      dineInQtyChange(), // set to 0 if we don't use that service
      takeawayQtyChange(),
      period === 'timeLimited' && startTime !== offerGroup?.startTime ? startTime : null,
      period === 'timeLimited' && endTime !== offerGroup?.endTime ? endTime : null,
      type?.includes('dineIn'),
      type?.includes('takeaway'),
      offerGroup?.goalId,
      offerGroup?.goalOfferId,
      editReason,
      additionalReason, // reason message
      allOccurrences,
      revenueChange !== 0 ? revenueChange : null,
      customersLower !== 0 ? customersLower : null,
      customersUpper !== 0 ? customersUpper : null,
      userId,
      userType,
      restId,
    );
  };

  const showConfirmationModal = () => {
    const valid = validate();
    if (valid) {
      setShowConfirmation(true);
    }
  };

  useEffect(() => {
    if (submitting && !offerEdit.pending && offerEdit.success) {
      setSubmitting(false);

      history.push('/offers');
    }

    if (submitting && !offerEdit.pending && offerEdit.error) {
      setSubmitting(false);

      // handle error
    }
  }, [offerEdit]);

  // Redirect back to deals page upon action success
  useEffect(() => {
    if (!dealUpdate.success) {
      return;
    }
    history.push('/deals');
  });

  // TODO move this to theme
  const labelStyles = {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 'bold',
    color: COLORS.CHARCOAL,
    marginBottom: '8px',
  };

  const styles = {
    dropdown: {
      label: labelStyles,
      padding: '0',
      width: '100%',
    },
    segmentedControl: {
      label: labelStyles,
      groupContainer: {
        // width: '350px',
        // display: 'flex',
      },
      option: {
        width: '170px',
      },
    },
    textField: {
      label: labelStyles,
    },
    toggleGroup: {
      container: {
        // flexGrow: 1,
      },
      toggleGroup: {
        // width: '100%',
      },
      groupContainer: {
        gap: '20px',
      },
      groupOptionContainer: {
        flex: '1',
      },
      label: labelStyles,
      option: {
        flexGrow: 1,
        textAlign: 'left',
        borderColor: COLORS.GRAY_BORDER,
        borderRadius: '4px',
        padding: '10px',
        fontSize: '18px',
        width: '135px',
      },
      selectedOption: {
        borderColor: COLORS.OLIVE,
      },
    },
    textSelect: {
      container: { flexGrow: 1 },
      label: labelStyles,
      textFieldContainer: { width: '100%' },
      textFieldRoot: { width: '100%' },
    },
  };

  const days = [
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
    { label: 'Sunday', value: 7 },
  ];

  const isValid = emptyObject(errors);

  const getOfferDate = () => {
    const days = (7 + eatClubDayOfWeekToMoment(offerGroup?.dayOfWeek) - getDayOfWeek(moment())) % 7;

    return moment().add(days, 'days');
  };

  const offerDate = getOfferDate();
  const offerEndDate = moment(offerGroup?.endDate);
  const diffInDays = offerEndDate.diff(offerDate, 'days');
  const occurrencesLeft = Math.max(Math.ceil(diffInDays / 7), 1);

  const calcOfferRevenue = () => {
    if (allOccurrences) {
      return formatCurrency(Math.max(0, offerGroup?.revenueExpected) * occurrencesLeft, false);
    }
    return formatCurrency(Math.max(0, offerGroup?.revenueExpected), false);
  };

  return (
    <Box style={{ fontSize: '18px', marginBottom: '200px' }}>
      {/* TODO make this its own component */}
      {belongsToGoal && (
        <Box mb='10px' display='flex' gap='10px'>
          <Box style={{ fontWeight: 'bold' }}>!</Box>
          <Box>
            This offer makes up {calcOfferRevenue()} of your{' '}
            {formatCurrency(goal?.targetRevenue, false)} revenue goal
          </Box>
        </Box>
      )}
      {offerGroup?.recurring && (
        <Box>
          {/* <RadioButtonGroup
            items={[
              { label: 'Edit just this offer', value: false },
              { label: 'Edit all future occurrences of this offer', value: true },
            ]}
            onSelect={setAllOccurrences}
            value={allOccurrences}
            style={{
              buttonCircleInnerSelected: {
                backgroundColor: COLORS.OLIVE,
              },
            }}
          /> */}
          <Box>You are editing a recurring offer, edits will apply to all future occurrences.</Box>
        </Box>
      )}
      <form id='createDealForm'>
        <Box
          display='flex'
          flexDirection='column'
          gap='24px'
          mt='40px'
          style={{ maxWidth: '350px' }}
        >
          {/* {!belongsToGoal && (
            <SegmentedControl
              label='Frequency'
              value={frequency}
              items={[
                { label: 'One time', value: 'oneTime' },
                { label: 'Recurring', value: 'recurring' },
              ]}
              onSelect={setFrequency}
              style={styles.segmentedControl}
              error={errors?.frequency}
              disabled
            />
          )} */}
          <Dropdown
            label='Day of the week'
            value={dayOfWeek}
            items={days}
            onSelect={setDayOfWeek}
            style={styles.dropdown}
            // disabled={belongsToGoal}
            disabled
            error={errors?.dayOfWeek}
          />
          <SegmentedControl
            label='Time period'
            value={period}
            items={[
              { label: 'All day', value: 'allDay' },
              { label: 'Time limited', value: 'timeLimited' },
            ]}
            onSelect={setPeriod}
            style={styles.segmentedControl}
            error={errors?.period}
          />
          {period === 'timeLimited' && (
            <Box>
              <TimePicker
                className={classes.formControl}
                label='Start time'
                value={startTime}
                onSelect={setStartTime}
                startTime={todayHours[0]}
                endTime={todayHours[1]}
                increment={15}
                style={styles.dropdown}
                error={errors?.startTime}
              />

              <TimePicker
                className={classes.formControl}
                label='End time'
                value={endTime}
                onSelect={setEndTime}
                startTime={todayHours[0]}
                endTime={todayHours[1]}
                increment={15}
                style={{ ...styles.dropdown, marginTop: '20px' }}
                error={errors?.endTime}
              />
            </Box>
          )}
          <Dropdown
            label='Offer percentage'
            value={percentage}
            items={[
              { label: '50%', value: '50% Off' },
              { label: '45%', value: '45% Off' },
              { label: '40%', value: '40% Off' },
              { label: '35%', value: '35% Off' },
              { label: '30%', value: '30% Off' },
              { label: '25%', value: '25% Off' },
              { label: '20%', value: '20% Off' },
            ]}
            placeholder={percentage}
            onSelect={setPercentage}
            style={styles.dropdown}
            error={errors?.dropdown}
          />
          <ToggleGroup
            label='Available for'
            value={type}
            onSelect={setType}
            items={[
              { label: 'Dine-in', value: 'dineIn' },
              { label: 'Takeaway', value: 'takeaway' },
            ]}
            style={styles.toggleGroup}
            error={errors?.type}
            singleChoice={false}
          />
          <Box display='flex' gap='20px'>
            {type.includes('dineIn') && (
              <TextSelect
                label='Dine In Quantity'
                value={dineInQuantity}
                onChange={setDineInQuantity}
                style={styles.textSelect}
                disabled={!type?.includes('dineIn') || wasRecurringOfferBookedToday(dineInOffer)}
                minNumber={0}
                increment
                error={errors?.quantity}
                fullWidth
              />
            )}

            {type.includes('takeaway') && (
              <TextSelect
                label='Takeaway Quantity'
                value={takeawayQuantity}
                onChange={setTakeawayQuantity}
                style={styles.textSelect}
                disabled={
                  !type?.includes('takeaway') || wasRecurringOfferBookedToday(takeawayOffer)
                }
                minNumber={0}
                increment
                error={errors?.quantity}
                fullWidth
              />
            )}
          </Box>
        </Box>
      </form>
      {dealUpdate.error && <Error error={dealUpdate.error} message={dealUpdate.errorMessage} />}

      <Box
        style={{
          alignSelf: 'flex-end',
          display: 'flex',
          flexGrow: '1',
          position: 'fixed',
        }}
      >
        <Box className={classes.predictionBar}>
          {!goals?.errorMessage && (
            <OfferChangePrediction
              endTime={endTime}
              startDate={offerGroup?.startDate}
              discount={percentage}
              startTime={startTime}
              dineIn={type?.includes('dineIn')}
              takeaway={type?.includes('takeaway')}
              lightning={period === 'timeLimited'}
              dineInQuantity={type?.includes('dineIn') ? dineInQuantity : 0}
              takeawayQuantity={type?.includes('takeaway') ? takeawayQuantity : 0}
              recurring={frequency === 'recurring'}
              changed={changed}
              partySizeLower={offerGroup?.partySizeLower}
              partySizeUpper={offerGroup?.partySizeUpper}
              revenueExpected={offerGroup?.revenueExpected}
              belongsToGoal={!!belongsToGoal}
            />
          )}

          <Button
            disabled={
              !isValid || dealUpdate.saving || !changed || pending || emptyObject(prediction)
            }
            onClick={showConfirmationModal}
          >
            {dealUpdate.saving ? <CircularProgress color='inherit' size={23} /> : 'Save changes'}
          </Button>
        </Box>
      </Box>
      <EditOfferModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        offer={offerGroup}
        mode='disable'
        onSave={handleSubmit}
        goal={goal}
        percentage={percentage}
        dayOfWeek={dayOfWeek}
        period={period}
        startTime={startTime}
        endTime={endTime}
        allOccurrences={allOccurrences}
        occurrencesLeft={occurrencesLeft}
        changed={changed}
        submitting={submitting}
      />
    </Box>
  );
};

EditOfferForm.propTypes = {
  goals: goalsPropTypes.isRequired,
  updateDeal: PropTypes.func.isRequired,
  editOffer: PropTypes.func.isRequired,
  clearDealPredict: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dealUpdate: state.dealUpdate,
  restId: state.restaurantActive.restaurant.objectId,
  restaurantHours: state.restaurantActive.restaurant.hours,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
  goals: state.goals,
  offerEdit: state.offerEdit,
  predict: state.dealPredict,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDeal: updateDealAction,
      editOffer: editOfferAction,
      clearDealPredict: clearDealPredictAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditOfferForm);
