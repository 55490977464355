import * as type from '../actions/types';

const initialState = {
  error: '',
  pending: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.EDIT_OFFER_PENDING:
      return {
        ...state,
        pending: true,
        error: '',
      };
    case type.EDIT_OFFER_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
      };
    case type.EDIT_OFFER_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
