import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { clearPostDailyOppoAction } from '../../../actions/dealDailyOpposPostAction';
import { opportunitiesPropTypes } from '../../../reducers/dealDailyOpposReducer';
import { trackEvent } from '../../../utils/analytics';
import useStyles, { modalInputStyle, modalStyle } from './ModalStyles';
import PostedOpportunity from '../Opportunity/PostedOpportunity';

const PostOppoModal = ({ dealDailyOppoPost, clearPostDailyOppo, dailyOppos }) => {
  const classes = useStyles();
  const history = useHistory();
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const oppo = dailyOppos?.data?.find((oppo) => oppo?.objectId === dealDailyOppoPost?.data?.oppoId);

  return (
    <Modal
      inputStyle={modalInputStyle}
      fullWidth={false}
      style={modalStyle}
      isOpen={dealDailyOppoPost?.success && oppo}
      onClose={clearPostDailyOppo}
      showCloseText={false}
      footer={[
        <Button
          onClick={() => {
            clearPostDailyOppo();
            history.push('/offers');
          }}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='tertiary'
        >
          Return to offers
        </Button>,
        <Button
          onClick={() => {
            clearPostDailyOppo();
            trackEvent(`button_click: oppo_success_view_active_offers`);
            history.push('/offers/active');
          }}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='primary'
        >
          View all active offers
        </Button>,
      ]}
    >
      <Box className={classes.oppoModal}>
        <Box style={{ maxWidth: '400px' }}>
          <Box className={classes.oppoSuccess}>Success, more customers on the way</Box>
          <Box className={classes.oppoDescription}>
            The opportunity has been successfully launched and can be located in your active offers.
          </Box>
        </Box>
        <Box style={{ width: '100%' }}>
          <PostedOpportunity opportunity={oppo} />
        </Box>
      </Box>
    </Modal>
  );
};

PostOppoModal.defaultProps = {};

PostOppoModal.propTypes = {
  clearPostDailyOppo: PropTypes.func.isRequired,
  dealDailyOppoPost: opportunitiesPropTypes.isRequired,
  dailyOppos: opportunitiesPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  dealDailyOppoPost: state.dealDailyOppoPost,
  dailyOppos: state.dealDailyOppos,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearPostDailyOppo: clearPostDailyOppoAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PostOppoModal);
