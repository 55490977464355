import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem, ListSubheader } from '@mui/material';
import { Apps } from '@mui/icons-material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPlatformAction } from '../../actions/platformAction';
import { platformMap } from '../../constants';
import { trackEvent } from '../../utils/analytics';

const PlatformPicker = ({ platform, setPlatform, restaurant }) => {
  const platformOptions = !restaurant.platform ? ['eatclub', 'whitelabel'] : [restaurant.platform];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleSelectMenu = (event) => {
    trackEvent('button_click: set_platform');

    const value = event.target.getAttribute('value');

    if (!value) {
      handleCloseDropdown();
      return;
    }

    setPlatform(value);
    handleCloseDropdown();
  };

  return (
    <>
      <Button onClick={handleOpenDropdown} color='primary' variant='contained' startIcon={<Apps />}>
        {platformMap[platform].name}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseDropdown}>
        <ListSubheader
          component='li'
          style={{
            outline: 0,
            lineHeight: 1.5,
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
          }}
        >
          Select Platform
        </ListSubheader>
        {platformOptions.map((option) => (
          <MenuItem
            key={option}
            onClick={handleSelectMenu}
            selected={platform === option}
            value={option}
          >
            {platformMap[option].name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

PlatformPicker.propTypes = {
  platform: PropTypes.string.isRequired,
  setPlatform: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    platform: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  platform: state.platform.platform,
  restaurant: state.restaurantActive.restaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setPlatform: setPlatformAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlatformPicker);
