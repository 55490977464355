import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Slider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dropdown, Tooltip, TextField } from '@eatclub-apps/ec-component-library';
import { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency, roundToNearest, safeDivision } from '../../../utils/helpers';
import Bag from '../../../assets/bag.svg';
import Chair from '../../../assets/chair.svg';
import Pencil from '../../../assets/pencil.svg';
import useStyles from './CreateGoalStyles';

const RevenueTarget = ({
  onNext,
  maxRevenue,
  maxRevenueLower,
  maxRevenueUpper,
  setRevenueTarget,
  revenueTarget,
  setDeliveryType,
  deliveryType,
  onBack,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [showRevenueInput, setShowRevenueInput] = useState(false);

  useEffect(() => {
    if (revenueTarget > maxRevenue) {
      setRevenueTarget(maxRevenue);
    }
  }, [maxRevenue]);

  const setType = (value) => {
    switch (value) {
      case 'dineIn':
        setDeliveryType(['dineIn']);
        return;
      case 'takeaway':
        setDeliveryType(['takeaway']);
        return;
      case 'dineInAndTakeaway':
      default:
        setDeliveryType(['dineIn', 'takeaway']);
    }
  };

  const getValueFromType = () => {
    // If one delivery type chosen, use it
    if (deliveryType.length === 1) {
      if (deliveryType.includes('dineIn')) {
        return 'dineIn';
      }

      if (deliveryType.includes('takeaway')) {
        return 'takeaway';
      }
    }

    // default to both
    return 'dineInAndTakeaway';
  };

  const handleChange = (event, newValue) => {
    setRevenueTarget(newValue);
  };

  const chairInCircle = () => (
    <Box
      style={{
        position: 'relative',
        borderRadius: '50px',
        backgroundColor: '#F0F0F0',
        width: '21px',
        height: '21px',
        alignItems: 'center',
      }}
    >
      <Chair
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '21px',
          // height: '21px',
          // position: 'absolute',
        }}
      />
    </Box>
  );

  const bagInCircle = () => (
    <Box
      style={{
        position: 'relative',
        borderRadius: '50px',
        backgroundColor: '#F0F0F0',
        width: '21px',
        height: '21px',
        alignItems: 'center',
      }}
    >
      <Bag
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '21px',
          // height: '21px',
          // position: 'absolute',
        }}
      />
    </Box>
  );

  const getTypeLabel = (type) => {
    switch (type) {
      case 'dineIn':
        return (
          <Box
            style={{
              display: 'flex',
              gap: '14px',
            }}
          >
            <Box>Dine-in only</Box>
            <Box style={{ display: 'flex', gap: '6px', paddingTop: '2px' }}>{chairInCircle()}</Box>
          </Box>
        );
      case 'takeaway':
        return (
          <Box
            style={{
              display: 'flex',
              gap: '14px',
            }}
          >
            <Box>Takeaway only</Box>
            <Box style={{ display: 'flex', gap: '6px', paddingTop: '2px' }}>{bagInCircle()}</Box>
          </Box>
        );
      case 'dineInAndTakeaway':
      default:
        return (
          <Box
            style={{
              display: 'flex',
              gap: '14px',
            }}
          >
            <Box>Takeaway and dine-in</Box>
            <Box style={{ display: 'flex', gap: '6px', paddingTop: '2px' }}>
              {chairInCircle()}
              {bagInCircle()}
            </Box>
          </Box>
        );
    }
  };

  return (
    <Box className={classes.revenueTargetContainer}>
      <Box>
        <Box className={classes.revenueTargetHeading}>Set your monthly revenue goal</Box>
        <Box mt='20px' className={classes.revenueTargetDescription}>
          Revenue in your pocket after the offer amount given to the customer
        </Box>
        <Box mt='20px'>
          <Dropdown
            items={[
              {
                label: getTypeLabel('dineInAndTakeaway'),
                value: 'dineInAndTakeaway',
              },
              { label: getTypeLabel('takeaway'), value: 'takeaway' },
              { label: getTypeLabel('dineIn'), value: 'dineIn' },
            ]}
            color={COLORS.OLIVE}
            value={getValueFromType()}
            onSelect={setType}
            style={{
              container: { maxWidth: '335px' },
              dropdownContentInner: { fontSize: '18px' },
              menu: { fontSize: '16px' },
            }}
          />
        </Box>
        <Box style={{ paddingTop: '50px' }}>
          <Box className={classes.sliderTitleContainer}>
            <Box className={classes.sliderTitle}>
              <span style={{ fontWeight: '500' }}>{formatCurrency(revenueTarget, false)}</span> per
              month
            </Box>
            <Pencil
              style={{
                cursor: 'pointer',
                marginTop: '5px',
              }}
              onClick={() => {
                trackEvent('button_click: toggle_revenue_input');
                setShowRevenueInput(!showRevenueInput);
              }}
            />
          </Box>
          {showRevenueInput && (
            <Box mb='20px' maxWidth='300px'>
              <TextField
                label='Revenue target'
                value={revenueTarget}
                onChange={setRevenueTarget}
                // type='number'
                maxNumber={maxRevenue}
                inputPrefix='$'
              />
            </Box>
          )}
          <Box className={classes.sliderBackground}>
            <Slider
              className={classes.sliderStyles}
              max={maxRevenue}
              onChange={handleChange}
              style={{
                alignItems: 'center',
                color: '#EEC1BB',
                display: 'flex',
                margin: 'auto 40px',
              }}
              value={revenueTarget}
            />
          </Box>
        </Box>
        <Box className={classes.potentialRevenueContainer}>
          <Box className={classes.potentialRevenueRow}>
            <Box>
              {breakpointSmDown ? 'Max potential revenue' : 'Maximum potential extra revenue'}
            </Box>
            <Box className={classes.tooltipContainer}>
              <Tooltip
                maxWidth='400px'
                message='Our AI has taken your historical data and compared it to the market to estimate the highest amount you could be earning from the EatClub Marketplace.'
              />
            </Box>
          </Box>
          <Box className={classes.potentialRevenueRow}>
            <Box style={{ fontWeight: 'bold' }}>{formatCurrency(maxRevenue, false)}</Box>
            <Box
              className={classes.selectButton}
              onClick={() => {
                trackEvent('button_click: set_max_revenue');
                setRevenueTarget(maxRevenue);
              }}
            >
              SELECT
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.goalsFooter} flexDirection='column'>
        <Box className={classes.footerEstimateContainer}>
          <Box style={{ width: '100%' }}>
            <Box className={classes.footerEstimate}>
              <Box className={classes.goalsFooterProjection}>
                <Box display='flex' style={{ gap: '10px' }}>
                  <Box>Expected monthly range: </Box>
                  <Box style={{ fontWeight: FONT_WEIGHTS.MEDIUM }}>
                    {formatCurrency(
                      roundToNearest(safeDivision(revenueTarget, maxRevenue) * maxRevenueLower, 10),
                      false,
                    )}
                    -
                    {formatCurrency(
                      roundToNearest(safeDivision(revenueTarget, maxRevenue) * maxRevenueUpper, 10),
                      false,
                    )}
                  </Box>
                </Box>

                <Box display='flex' style={{ gap: '10px' }}>
                  <Box>Expected yearly range: </Box>
                  <Box style={{ fontWeight: FONT_WEIGHTS.MEDIUM }}>
                    {formatCurrency(
                      roundToNearest(
                        safeDivision(revenueTarget, maxRevenue) * maxRevenueLower,
                        10,
                      ) * 12,
                      false,
                    )}
                    -
                    {formatCurrency(
                      roundToNearest(
                        safeDivision(revenueTarget, maxRevenue) * maxRevenueUpper,
                        10,
                      ) * 12,
                      false,
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box style={{ width: '100%' }}>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box className={classes.goalsFooterPadding}>
              <Button onClick={onBack} type='tertiary'>
                Back
              </Button>

              <Button disabled={revenueTarget <= 0} style={{ marginLeft: 'auto' }} onClick={onNext}>
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

RevenueTarget.propTypes = {
  onNext: PropTypes.func.isRequired,
  maxRevenue: PropTypes.number.isRequired,
  maxRevenueLower: PropTypes.number.isRequired,
  maxRevenueUpper: PropTypes.number.isRequired,
  setRevenueTarget: PropTypes.func.isRequired,
  revenueTarget: PropTypes.number.isRequired,
  deliveryType: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDeliveryType: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RevenueTarget);
