import { makeApiAction } from './actionHelper';
import { settlementsByRestId } from '../graphql/queries';

export const fetchSettlementsByRestIdAction =
  (restId, pageSize = 500, page = 0) =>
  (dispatch) => {
    makeApiAction(
      'FETCH_SETTLEMENTS',
      settlementsByRestId,
      { restId, pageSize, page },
      {},
      'settlementsByRestId',
      false,
      dispatch,
    );
  };
