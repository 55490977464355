import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: 'flex',
  // },
  '@global': {
    '.MuiPaper-root': {
      styleOverrides: { root: { backgroundImage: 'unset' } },
    },
    [theme.breakpoints.down('sm')]: {
      '.MuiPaper-root': {
        borderRadius: [0],
      },
    },
    '.MuiButton-root': {
      borderRadius: '20px',
    },
  },
}));

export default useStyles;
