import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1,
    padding: '40px',
    backgroundColor: COLORS.LINEN,
    borderRadius: '15px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',

    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px',
    },
  },
  heading: {
    ...theme.eatclub.typography.heading.H1,
    color: COLORS.GOAL_POST_BANNER_HEADER,
  },
  content: {
    ...theme.eatclub.typography.body.PL,
  },
  options: {
    display: 'inline-flex',
    lineHeight: '40px',
    flexWrap: 'wrap',
    gap: '10px',
  },
  option: {
    display: 'inline-flex',
    gap: '10px',
  },
  predictions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '800px',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  launchButton: {
    width: '100%',
    maxWidth: '900px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default useStyles;
