import * as type from '../actions/types';

const initialState = {
  messages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.ADD_MESSAGE: {
      const { id, message, severity } = action.payload;
      const checkId = state.messages.findIndex((foundMessage) => foundMessage.id === id);

      if (checkId === -1) {
        const addMessage = [...state.messages, { id, message, severity }];

        return {
          ...state,
          messages: addMessage,
        };
      }

      return state;
    }

    case type.REMOVE_MESSAGE: {
      const { id } = action.payload;
      const removeMessage = [...state.messages.filter((foundMessage) => foundMessage.id !== id)];

      return {
        ...state,
        messages: removeMessage,
      };
    }

    case type.CLEAR_MESSAGES:
      return {
        ...state,
        messages: [],
      };

    default:
      return state;
  }
};
