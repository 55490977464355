import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  indexLabel: {
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    lineHeight: '25px',
    border: '2px solid #b5b5b5',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#b5b5b5',
    marginRight: '1rem',
    boxSizing: 'content-box',
    '&.hasEdit:not(.disabled)': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  formControl: {
    width: '300px',
    // marginTop: theme.spacing(2),
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  leftColumn: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0),
      // marginRight: theme.spacing(9)
      marginRight: 200,
    },
  },
  textfieldTime: {
    '& ::-webkit-calendar-picker-indicator': {
      display: 'none',
    },
  },
  predictionBar: {
    alignItems: 'center',
    background: '#FFFFFF',
    bottom: '0',
    boxShadow: '0px -2px 7px rgba(212, 212, 212, 0.25)',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px',
    left: '295px',
    padding: '10px 24px',
    position: 'fixed',
    right: '0',
    [theme.breakpoints.down('sm')]: {
      left: '0',
    },
  },
  offerChangePrediction: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLORS.NOTIFICATION_BACKGROUND_YELLOW,
    borderRadius: '6px',
    gap: ' 10px 60px',
    height: '60px',
    padding: '0 40px',
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
    flex: 1,

    [theme.breakpoints.down('sm')]: {
      padding: '16px',
      height: 'auto',
    },
  },
  predictedResultsText: {
    fontWeight: 'bold',
  },
  predictedResultsContainer: {
    display: 'flex',
    gap: '16px 30px',
    // flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  predictedResult: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '20px',
  },
  predictedResultLabel: {
    color: COLORS.SMOKE,
  },
  buttonContainer: {
    width: '100%',
    gap: '15px',
    justifyContent: 'space-between',
    display: 'flex',
    marginTop: '50px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

export default useStyles;
