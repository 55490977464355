import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { fetchGoalsAction } from '../../../actions/goalsAction';
import { fetchOffersForGoalAction } from '../../../actions/offersForGoalAction';
import { dealsPropTypes } from '../../../data/models/Deal';
import { goalsPropTypes } from '../../../data/models/Goal';
import { usePageViews } from '../../../utils/analytics';
import DailyPerformanceGraph from '../DailyPerformanceGraph/DailyPerformanceGraph';
import GoalAnalytics from '../GoalAnalytics/GoalAnalytics';
import OffersByDay from '../OffersByDay/OffersByDay';
import useStyles from './ViewGoalStyles';

const ViewGoal = ({
  restId,
  fetchGoals,
  fetchOffersForGoal,
  goals,
  userId,
  userType,
  offersForGoal,
}) => {
  const { goalId } = useParams();
  const classes = useStyles();
  usePageViews();

  const [goal, setGoal] = useState(null);

  // Fetch goals if we haven't yet
  useEffect(() => {
    fetchGoals(restId, userId, userType);
  }, [fetchGoalsAction, restId, goalId, userId, userType]);

  // Fetch offers if we haven't yet
  useEffect(() => {
    fetchOffersForGoal(restId, goalId, userId, userType);
  }, [fetchOffersForGoal, restId, goalId, userId, userType]);

  // Find the goal we are trying to edit
  useEffect(() => {
    setGoal(goals?.data?.find((someGoal) => `${someGoal?.objectId}` === `${goalId}`));
  }, [goalId, goals]);

  if (goals.pending || goal === null || goal === undefined) {
    return <CircularProgress />;
  }

  return (
    <Box className={classes.container}>
      <GoalAnalytics goal={goal} showViewDetails={false} />
      <Box mt='48px'>
        <DailyPerformanceGraph goal={goal} />
      </Box>

      <Box
        className={classes.offersContainer}
        style={{
          minHeight: `${Math.min(900, offersForGoal.length * 150)}px`,
        }}
      >
        <Box className={classes.heading}>Revenue goal offers</Box>
        <OffersByDay offers={offersForGoal} goal={goal} />
      </Box>
    </Box>
  );
};

ViewGoal.propTypes = {
  goals: goalsPropTypes.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  fetchGoals: PropTypes.func.isRequired,
  fetchOffersForGoal: PropTypes.func.isRequired,
  offersForGoal: dealsPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  goals: state.goals,
  offersForGoal: state.offersForGoal?.data,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchGoals: fetchGoalsAction,
      fetchOffersForGoal: fetchOffersForGoalAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewGoal);
