import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@eatclub-apps/ec-component-library';
import { fetchActiveOffersForRestaurantAction } from '../../../actions/activeOffersForRestaurantAction';
import { fetchGoalsAction } from '../../../actions/goalsAction';
import { dealsPropTypes } from '../../../data/models/Deal';
import { goalsPropTypes } from '../../../data/models/Goal';
import { COLORS } from '../../../EatClubTheme';
import { canEditAll, getDateRange } from '../../../utils/helpers';

import { usePageViews } from '../../../utils/analytics';
import OffersByDay from '../OffersByDay/OffersByDay';
import Bag from '../../../assets/bag.svg';
import Chair from '../../../assets/chair.svg';
import Target from '../../../assets/target.svg';
import DeleteAllOffersModal from '../Modals/DeleteAllOffersModal';
import useStyles from './AllOffersStyles';

const AllOffers = ({
  fetchActiveOffersForRestaurant,
  activeOffersForRestaurant,
  restId,
  userId,
  userType,
  fetchGoals,
  goals,
  user,
}) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));
  usePageViews();

  // Fetch active offers
  useEffect(() => {
    fetchActiveOffersForRestaurant(restId, userId, userType);
  }, [fetchActiveOffersForRestaurant, restId, userId, userType]);

  // Fetch goals for offers
  useEffect(() => {
    fetchGoals(restId);
  }, [fetchGoals, restId]);

  const history = useHistory();
  const [showDeleteAllOffersModal, setShowDeleteAllOffersModal] = useState(false);

  const today = moment().startOf('day');
  const nextWeek = moment().startOf('day').add(1, 'week');
  const activeGoal = goals.data.find((goal) => goal.status === 'active');

  return (
    <Box className={classes.container}>
      <Box className={classes.topSection}>
        <Box className={classes.headingContainer}>
          <Box className={classes.heading}>All offers</Box>
          {!breakpointXsDown && (
            <Box className={classes.date}>Next 7 days: {getDateRange(today, nextWeek)}</Box>
          )}
        </Box>

        <Box className={classes.iconLegend}>
          <Box className={classes.icon}>
            <Chair />
            <Box>Dine-in</Box>
          </Box>
          <Box className={classes.icon}>
            <Bag />
            <Box>Takeaway</Box>
          </Box>
          <Box className={classes.icon}>
            <Target />
            <Box>Goals</Box>
          </Box>
        </Box>
      </Box>

      {breakpointXsDown && (
        <Box className={classes.date}>Next 7 days: {getDateRange(today, nextWeek)}</Box>
      )}

      <Box mt='24px'>
        <OffersByDay offers={activeOffersForRestaurant.data} goal={activeGoal} />
      </Box>

      {canEditAll(user) && (
        <Box mt='24px'>
          <Button
            type='text'
            style={{
              color: COLORS.BRAND_PRIMARY,
              fontSize: '16px',
              fontWeight: 'bold',
              textAlign: 'right',
            }}
            onClick={() => setShowDeleteAllOffersModal(true)}
          >
            Delete all offers
          </Button>
        </Box>
      )}

      <DeleteAllOffersModal
        isOpen={showDeleteAllOffersModal}
        onClose={() => setShowDeleteAllOffersModal(false)}
        onSave={() => history.push('/offers')}
      />
    </Box>
  );
};

AllOffers.propTypes = {
  activeOffersForRestaurant: dealsPropTypes.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  restId: PropTypes.string.isRequired,
  fetchActiveOffersForRestaurant: PropTypes.func.isRequired,
  fetchGoals: PropTypes.func.isRequired,
  goals: goalsPropTypes.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
  activeOffersForRestaurant: state.activeOffersForRestaurant,
  goals: state.goals,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchActiveOffersForRestaurant: fetchActiveOffersForRestaurantAction,
      fetchGoals: fetchGoalsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AllOffers);
