export const getTodayHours = (restaurantHours, startDay) => {
  const hoursByDay = restaurantHours;

  const todayHours = Object.keys(hoursByDay)
    .map((day, index) => (day.substr(0, 3) === startDay ? index : null))
    .filter((e) => e !== null)
    .map((index) => Object.values(hoursByDay)[index]);

  return { start: todayHours[0], end: todayHours[1] };
};

export const getClosingTime = (restaurant, currentDay, endTime = 1439) => {
  try {
    const closingTime = restaurant?.hoursParsed?.[`${currentDay}Close`];

    if (closingTime) {
      return closingTime;
    }
  } catch {}

  return endTime; // close before midnight as default
};
