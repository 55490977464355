/* eslint-disable */
import React, { useState } from 'react';
import { FormGroup, FormLabel, FormControl } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popover from '../../popover/Popover';
import DealDay from './DealDay';

const DealDays = ({ newDeal, setNewDeal, mode, restaurantHours }) => {
  const { days } = newDeal;

  const hoursByDay = JSON.parse(restaurantHours);

  const openHoursByDay = (dayName) =>
    Object.keys(hoursByDay)
      .map((day, index) => (day.substr(0, 3) === dayName ? index : null))
      .filter((e) => e !== null)
      .map((index) => Object.values(hoursByDay)[index]);

  const isClosed = (dayName) => {
    const hours = openHoursByDay(dayName);
    return hours.some((el) => el === -1);
  };

  const daysArray = [
    { name: 'Mon', value: 'mon', closed: isClosed('mon') },
    { name: 'Tue', value: 'tue', closed: isClosed('tue') },
    { name: 'Wed', value: 'wed', closed: isClosed('wed') },
    { name: 'Thu', value: 'thu', closed: isClosed('thu') },
    { name: 'Fri', value: 'fri', closed: isClosed('fri') },
    { name: 'Sat', value: 'sat', closed: isClosed('sat') },
    { name: 'Sun', value: 'sun', closed: isClosed('sun') },
  ];

  const handleSelectedDay = (event, disable) => {
    if (disable) {
      return;
    }

    setNewDeal((prev) => {
      const currentDay = event.target.value;
      const dayIndex = prev.days.values.findIndex((day) => day === currentDay);

      // remove day
      if (dayIndex > -1) {
        const newArray = prev.days.values.filter((day, index) => index !== dayIndex);
        return { ...newDeal, days: { ...days, values: newArray } };
      }

      // add day
      return {
        ...newDeal,
        days: { ...days, values: [...prev.days.values, currentDay] },
      };
    });
  };

  const dealDateDay = newDeal.startDate.value
    ? newDeal.startDate.value.format('ddd').toLowerCase()
    : '';

  const [modeAnchorEl, setModeAnchorEl] = useState(null);
  const handleModePopoverOpen = (event) => {
    setModeAnchorEl(event.currentTarget);
  };
  const handleModePopoverClose = () => {
    setModeAnchorEl(null);
  };
  const openModePopover = Boolean(modeAnchorEl);

  return (
    <>
      <FormControl
        variant='standard'
        disabled={mode === 'create'}
        onMouseEnter={mode === 'create' ? handleModePopoverOpen : undefined}
        onMouseLeave={mode === 'create' ? handleModePopoverClose : undefined}
      >
        <FormLabel component='legend' style={{ marginBottom: '.5rem' }}>
          Recurring every:
        </FormLabel>
        <FormGroup row>
          {daysArray.map((day, index) => (
            <DealDay
              key={index}
              day={day}
              days={days}
              dealDateDay={dealDateDay}
              handleSelectedDay={handleSelectedDay}
              mode={mode}
            />
          ))}
        </FormGroup>
      </FormControl>

      <Popover
        anchorEl={modeAnchorEl}
        handlePopoverClose={handleModePopoverClose}
        open={openModePopover}
        text='Unable to edit in an existing deal'
        anchorOriginVertical='center'
        anchorOriginHorizontal='left'
        transformOriginHorizontal='left'
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  restaurantHours: state.restaurantActive.restaurant.hours,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DealDays);
