import { makeStyles } from '@mui/styles';
import { COLORS, FONT_WEIGHTS, SPACING } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #DCDCDB',
    borderRadius: '8px',
    padding: '10px',
  },
  graphHeader: {
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  graphContainer: {
    height: '150px',
    margin: '20px -10px',
  },
  performanceDayContainer: {
    display: 'flex',
    gap: '24px',
    padding: '20px 30px',
    flexWrap: 'wrap',
  },
  performanceDay: {
    fontSize: '14px',
    textAlign: 'center',
    padding: '24px 16px',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f1f1f1 !important',
    },
  },
  legendContainer: {
    padding: '20px',
  },
  legend: {
    display: 'flex',
    gap: '12px',
    marginLeft: 'auto',
  },
  legendItem: {
    display: 'flex',
    gap: '8px',
    ...theme.eatclub.typography.body.small,
  },
  legendBar: {
    width: '15px',
    height: '2px',
    borderRadius: '6px',
  },
  predictedLegendBar: {
    width: '15px',
    borderTop: `2px dashed ${COLORS.NIMBUS}`,
  },
  legendBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    justifyContent: 'center',
  },
  dayName: {
    marginBottom: '8px',
  },
  dayRevenue: {
    marginBottom: '12px',
    fontSize: '18px',
    fontWeight: 500,
  },
  dayDate: {
    fontSize: '18px',
  },

  // Below graph
  goalDataContainer: {
    marginTop: '20px',
    backgroundColor: '#F2EFEE',
    padding: '28px 20px',
    borderRadius: '0 0 8px 8px',
    overflowX: 'hidden',
  },
  goalData: {
    gap: '40px',
    width: '100%',
    display: 'flex',
    maxWidth: '100%',
    overflowX: 'auto',
  },
  detailItem: {
    minWidth: '70px',
  },
  detailHeading: {
    ...theme.eatclub.typography.heading.H3,
  },
  detailSubheading: {
    marginBottom: '10px',
    ...theme.eatclub.typography.body.small,
  },
  detailDate: {
    marginTop: '4px',
    ...theme.eatclub.typography.body.small,
  },
  detailValue: {
    ...theme.eatclub.typography.body.textMedium,
  },
  closedMessageContainer: {
    display: 'flex',
    marginTop: '20px',
    gap: SPACING.S,
  },
  closedMessageHeader: {
    ...theme.eatclub.typography.body.smallBold,
  },
  closedMessageContent: {
    ...theme.eatclub.typography.body.small,
  },
  emptyStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '57px auto 100px auto',
    gap: '15px',
    fontSize: '16px',
    alignItems: 'center',
  },
  emptyStateHeading: {
    fontWeight: FONT_WEIGHTS.BOLD,
  },
}));

export default useStyles;
