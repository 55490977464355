/**
 * Formats the `value` as string, with commas, and to the desired number of decimal places.
 *
 * @param decimals Number of decimal places to round
 */
export const formatNumber = (value, decimals = 2) => {
  if (value === null || value === undefined) {
    return '';
  }

  return value.toLocaleString('en', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};
