import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tab: {
    '& > span': {
      flexDirection: 'column-reverse',
    },
  },
}));

export default useStyles;
