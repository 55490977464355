/**
 * Formats `moment object` into minutes `number`.
 *
 * @param time Number of decimal places to round
 */
export const momentAsMinutes = (time) => {
  if (!time) {
    return 0;
  }
  if (!time._isAMomentObject) {
    return time;
  }

  return time.hours() * 60 + time.minute();
};
