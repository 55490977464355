/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import { Launch } from '@mui/icons-material';

const TableOrdersButton = ({ setOpenDialog, setActiveBooking, booking }) => {
  const handleClickOpen = () => {
    setActiveBooking(booking);
    setOpenDialog(true);
  };

  return (
    <>
      <Tooltip title='View Table Orders'>
        <IconButton size='large' variant='contained' color='primary' onClick={handleClickOpen}>
          <Launch />
        </IconButton>
      </Tooltip>
    </>
  );
};

TableOrdersButton.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default TableOrdersButton;
