import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchDailyOpposAction,
  clearDailyOpposAction,
} from '../../../actions/dealDailyOpposAction';
import Error from '../../error/Error';
import OppoRow from './OppoRow';
import { clearPostDailyOppoAction } from '../../../actions/dealDailyOpposPostAction';
import { clearUpdateDailyOppoAction } from '../../../actions/dealDailyOppoUpdateAction';

const DailyOpposDialog = ({
  clearDailyOppos,
  dailyOppos,
  fetchDailyOppos,
  handleCloseDialog,
  open,
  oppoPost,
  restId,
  userId,
  userType,
  clearPostDailyOppo,
  clearUpdateDailyOppo,
}) => {
  const { data, pending, error } = dailyOppos;

  const renderDialogContent = () => {
    if (pending) {
      return <DialogContentText>Loading Daily Opportunities</DialogContentText>;
    }

    if (data.length === 0) {
      return (
        <DialogContentText>
          There were no opportunities found for your venue today. Please check again tomorrow.
        </DialogContentText>
      );
    }

    return (
      <>
        <DialogContentText style={{ marginBottom: '2rem' }}>
          Our AI model has identified the following opportunities for your venue today.
        </DialogContentText>
        {oppoPost.error && <Error error message={oppoPost.error} />}
        <Box style={{ marginBottom: '2rem' }}>
          {data.map((oppo) => (
            <OppoRow key={oppo.objectId} oppo={oppo} />
          ))}
        </Box>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth='md'
      keepMounted={false}
      TransitionProps={{
        onEnter: () => {
          fetchDailyOppos(restId, userId, userType);
        },
        onExit: () => {},
        onExited: () => {
          clearDailyOppos();
          clearPostDailyOppo();
          clearUpdateDailyOppo();
        },
      }}
    >
      <DialogTitle>Daily Opportunities</DialogTitle>
      <DialogContent dividers>
        {Boolean(error) && <Error error message={error} />}
        {renderDialogContent()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

DailyOpposDialog.defaultProps = {
  userId: null,
};

DailyOpposDialog.propTypes = {
  clearDailyOppos: PropTypes.func.isRequired,
  clearPostDailyOppo: PropTypes.func.isRequired,
  clearUpdateDailyOppo: PropTypes.func.isRequired,
  dailyOppos: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.string,
    pending: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  oppoPost: PropTypes.shape({
    pending: PropTypes.bool,
    error: PropTypes.string,
    success: PropTypes.bool,
  }).isRequired,
  fetchDailyOppos: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  dailyOppos: state.dealDailyOppos,
  oppoPost: state.dealDailyOppoPost,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.objectId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearDailyOppos: clearDailyOpposAction,
      clearPostDailyOppo: clearPostDailyOppoAction,
      clearUpdateDailyOppo: clearUpdateDailyOppoAction,
      fetchDailyOppos: fetchDailyOpposAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DailyOpposDialog);
