import { makeStyles } from '@mui/styles';

const drawerWidth = 295;

const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundColor: '#ffffff',
    // minHeight: '100vh',
    // [theme.breakpoints.up('md')]: {
    //   width: '100%',
    //   marginLeft: 0,
    //   justifyContent: 'center',
    // },
  },
  containerInner: {
    // [theme.breakpoints.up('md')]: {
    //   // maxWidth: '1424px',
    //   justifyContent: 'center',
    //   marginLeft: 'auto',
    //   marginRight: 'auto',
    // },
    // paddingBottom: '100px',
  },
  pageContent: ({ navigationOpen }) => ({
    // paddingTop: '85px',
    marginLeft: navigationOpen ? `${drawerWidth}px` : 0,
    transition: navigationOpen ? 'margin-left 0.25s' : 'margin-left: 0.3s',

    [theme.breakpoints.down('sm')]: {
      // marginLeft: '20px',
      // paddingTop: '175px',
    },
  }),
}));

export default useStyles;
