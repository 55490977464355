import * as type from '../actions/types';

const initialState = {
  disabling: false,
  error: false,
  errorMessage: '',
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.DISABLE_DEAL_PENDING:
      return {
        ...state,
        disabling: true,
        error: false,
        errorMessage: '',
      };
    case type.DISABLE_DEAL_SUCCESS:
      return {
        ...state,
        disabling: false,
        success: true,
      };
    case type.DISABLE_DEAL_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        disabling: false,
      };

    case type.CLEAR_DISABLE_DEAL:
      return {
        ...state,
        disabling: false,
        error: false,
        errorMessage: '',
        success: false,
      };

    default:
      return state;
  }
};
