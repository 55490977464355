import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '1rem',
    height: '6.5rem',
    borderRadius: '20px',
    width: '100%',
  },
  oppoBox: {
    padding: '0',
    maxWidth: '100px',
    position: 'relative',
  },
  oppoBoxContent: {
    padding: '1rem',
    overflow: 'hidden',
  },
  optimalBanner: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    width: '100%',
    position: 'absolute',
    bottom: '0',
  },
  optimalBannerText: {
    color: '#fff',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    textAlign: 'center',
  },
}));

export default useStyles;
