import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@eatclub-apps/ec-component-library';
import { potentialRevenuesPropTypes } from '../../../reducers/potentialRevenuesReducer';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency } from '../../../utils/helpers';
import ActionArrow from '../../../assets/action_arrow.svg';
import Blob from '../../../assets/blob.svg';
import CornerBlob from '../../../assets/corner_blob.svg';
import TargetArrows from '../../../assets/target_arrows.svg';
import useStyles from './RevenueBannerStyles';
import { ConditionalWrapper } from '../../ConditionalWrapper';

const DesktopRevenueBanner = ({ potentialRevenues }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.container}>
      {/* Left */}
      <Box className={classes.leftBanner}>
        <Box className={classes.heading}>Unlock extra revenue with goals</Box>

        <Button
          className={classes.previousGoalsButton}
          onClick={(event) => {
            event.stopPropagation();
            trackEvent('button_click: view_previous_goals');
            history.push('/goals/previous');
          }}
          type='text'
        >
          View previous goals
        </Button>
      </Box>

      {/* Right */}
      <Box className={classes.rightBanner}>
        <Box className={classes.rightBannerInner}>
          <Box className={classes.bannerPitch}>
            You could be earning more from EatClub. Set a revenue goal and our AI will help you get
            there.
          </Box>
          <Box display='flex' flexDirection='column' gap='15px'>
            <ConditionalWrapper
              condition={potentialRevenues.fetching}
              // eslint-disable-next-line react/no-unstable-nested-components
              wrapper={(children) => (
                <Skeleton variant='rounded' width='100%'>
                  {children}
                </Skeleton>
              )}
            >
              {potentialRevenues.data != null && (
                <Box className={classes.ctaContainer}>
                  <Box>
                    <Box className={classes.extraRevenue}>Extra revenue you could be making</Box>
                    <Box className={classes.moneyPerMonthContainer}>
                      <Box className={classes.blobAmountContainer}>
                        <Blob className={classes.blob} />
                        <Box className={classes.revenueAmountText}>
                          {formatCurrency(potentialRevenues.data?.maxRevenue?.amount, false)}
                        </Box>
                      </Box>
                      <Box>per month</Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      className={classes.getStartedButton}
                      onClick={() => {
                        trackEvent('button_click: create_new_goal_from_banner');
                        history.push('/goals/create');
                      }}
                    >
                      <Box className={classes.buttonText}>Get started</Box>
                      <ActionArrow />
                    </Box>
                  </Box>
                </Box>
              )}
            </ConditionalWrapper>
          </Box>
        </Box>
      </Box>

      <Box>
        <CornerBlob className={classes.cornerBlob} />
        <TargetArrows className={classes.targetArrows} />
      </Box>
    </Box>
  );
};

DesktopRevenueBanner.propTypes = {
  potentialRevenues: potentialRevenuesPropTypes.isRequired,
};

export default DesktopRevenueBanner;
