/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  FormHelperText,
  CircularProgress,
  Box,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OrderRefundConfirmDialog from './OrderRefundConfirmDialog';
import { clearOrderAction, fetchOrderAction } from '../../actions/ordersAction';
import { refundItemsAction, clearRefundAction } from '../../actions/refundAction';
import OrderTotal from './OrderTotal';
import OrderCategories from './OrderCategories';
import { formatNumber } from '../../utils';

const OrderRefundItemsDialog = ({
  clearOrderAction,
  clearRefundAction,
  detailedOrder,
  fetchOrderAction,
  handleCloseDialog,
  openDialog,
  order: { orderId, objectId },
  refund,
  refundItemsAction,
  region,
  restId,
  userId,
  userType,
}) => {
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

  const [refundItems, setRefundItems] = useState([]);
  const [error, setError] = useState('');
  const [confirmMessage, setConfirmMessage] = useState(<></>);

  const handleClickOpenDialogConfirm = (message) => {
    setOpenDialogConfirm(true);
    setConfirmMessage(message);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };

  const handleClickRefundItem = (item) => {
    let newRefundItems = Array.from(refundItems);

    const itemIndex = newRefundItems.findIndex(
      (refundItem) => refundItem.objectId === item.objectId,
    );

    if (itemIndex > -1) {
      newRefundItems.splice(itemIndex, 1);
    } else {
      newRefundItems = [...newRefundItems, item];
    }

    setRefundItems(newRefundItems);
  };

  const submit = (event) => {
    event.preventDefault();

    if (refundItems.length === 0) {
      // setError("Please select items to refund.");
      return;
    }
    setError('');

    const refundTotal = refundItems.reduce((total, refundItem) => {
      const optionsTotal = !refundItem.itemOptions
        ? 0
        : refundItem.itemOptions.reduce((optTotal, refundOption) => {
            if (!refundOption.choices) {
              return optTotal;
            }

            const choices = JSON.parse(refundOption.choices);

            const choicesTotal = choices.reduce((choiceTotal, refundChoice) => {
              if (!refundChoice.price) {
                return choiceTotal;
              }

              choiceTotal += Number(refundChoice.price);
              return choiceTotal;
            }, 0);

            optTotal += choicesTotal;

            return optTotal;
          }, 0);

      total = (Number(refundItem.price) + optionsTotal) * refundItem.qty + total;

      return total;
    }, 0);

    const message = (
      <>
        Are you sure you want to refund <strong>${formatNumber(refundTotal)}</strong> (
        {refundItems.length} item{refundItems.length > 1 ? 's' : ''})?
      </>
    );

    handleClickOpenDialogConfirm(message);
  };

  const confirm = () => {
    const refundItemIds = refundItems.map((item) => item.objectId);
    const refundItemIdsString = JSON.stringify(refundItemIds);

    refundItemsAction(objectId, refundItemIdsString, restId, userId, userType);
  };

  // NOTE: Close dialog when state returns successful
  useEffect(() => {
    if (!refund.success || !openDialog) {
      return;
    }
    handleCloseDialog();
  }, [handleCloseDialog, openDialog, refund.success]);

  // NOTE: Disable close when loading
  const handleOnClose = () => {
    if (refund.loading) {
      return;
    }
    handleCloseDialog();
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleOnClose}
        keepMounted={false}
        TransitionProps={{
          onEnter: () => {
            fetchOrderAction(orderId, region, restId, userId, userType);
            setRefundItems([]);
            setError('');
            setConfirmMessage(<></>);
          },
          onExit: () => {
            clearRefundAction();
            clearOrderAction();
          },
        }}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>Refund Specific Items</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Select the items to refund</DialogContentText>
          {Boolean(error) && <FormHelperText error>{error}</FormHelperText>}

          {detailedOrder.orderFetching ? (
            <CircularProgress color='primary' size={22} />
          ) : (
            <>
              {Object.keys(detailedOrder.orderData).length > 0 ? (
                <>
                  <Box style={{ marginLeft: -16, marginRight: -16 }}>
                    <OrderCategories
                      orderItems={detailedOrder.orderData.items}
                      mode='refund'
                      handleClickRefundItem={handleClickRefundItem}
                    />
                    <Box style={{ marginRight: 50 }}>
                      <OrderTotal order={detailedOrder.orderData} />
                    </Box>
                  </Box>
                </>
              ) : (
                <Typography paragraph>There were no orders found.</Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button onClick={submit} color='primary'>
            {refund.loading ? <CircularProgress color='primary' size={22} /> : 'Refund'}
          </Button>
        </DialogActions>
      </Dialog>

      <OrderRefundConfirmDialog
        title='Refund Items?'
        description={confirmMessage}
        handleCloseDialog={handleCloseDialogConfirm}
        openDialog={openDialogConfirm}
        confirmAction={confirm}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  detailedOrder: state.order,
  refund: state.refund,
  region: state.restaurantActive.restaurant.region,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearOrderAction,
      clearRefundAction,
      fetchOrderAction,
      refundItemsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderRefundItemsDialog);
