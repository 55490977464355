import * as type from '../actions/types';

const initialState = {
  hasError: false,
  allErrors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SET_APP_ERROR:
      return {
        ...state,
        hasError: true,
        allErrors: [...state.allErrors, action.payload],
      };
    case type.REMOVE_APP_ERROR: {
      const removedError = [...state.allErrors.filter((error) => error.name !== action.payload)];
      return {
        ...state,
        allErrors: removedError,
      };
    }
    case type.CLEAR_APP_ERROR:
      return {
        ...state,
        hasError: false,
        allErrors: [],
      };
    default:
      return state;
  }
};
