import PropTypes from 'prop-types';

export const obeePricingPropTypes = PropTypes.shape({
  data: PropTypes.shape({
    lite: PropTypes.string,
    premium: PropTypes.string,
  }),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

export const obeeRequestPropTypes = PropTypes.shape({
  data: PropTypes.string,
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

export const obeeOnboardPropTypes = PropTypes.shape({
  data: PropTypes.shape({
    operationsEmail: PropTypes.string,
    obeeGoLiveDate: PropTypes.string,
  }),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

export const obeeUpgradePropTypes = PropTypes.shape({
  data: PropTypes.string,
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});
