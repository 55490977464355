import { makeStyles } from '@mui/styles';
import { COLORS, FONT_SIZES } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '40px',
    marginBottom: '200px',
  },
  topSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  heading: {
    ...theme.eatclub.typography.heading.H1,
    // fontSize: '36px',
    // lineHeight: '36px',
  },
  date: {
    // fontSize: FONT_SIZES.XXL,
    ...theme.eatclub.typography.body.PXL,
    // lineHeight: '40px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  headingContainer: {
    display: 'flex',
    gap: '20px',
    alignItems: 'baseline',
    whiteSpace: 'nowrap',
  },
  iconLegend: {
    display: 'flex',
    gap: '20px',
    fontSize: FONT_SIZES.M,
    color: COLORS.SMOKE,
    height: '100%',
  },
  icon: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
}));

export default useStyles;
