import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    ...theme.eatclub.typography.heading.H2,
  },
  description: {
    ...theme.eatclub.typography.body.PL,
    marginTop: '20px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  optionsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '24px',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  modalFooter: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '15px',
    width: '100%',
  },
}));

export default useStyles;
