/* eslint-disable */
import * as type from '../actions/types';
import { updateItemInArray } from './reducerHelper';

const initialState = {
  data: [],
  fetching: true,
  error: false,
  errorMessage: '',
};

const parseChoicesWithIndexes = (stringifiedChoices, doubleParse = false) => {
  let choices = JSON.parse(stringifiedChoices);
  if (doubleParse) {
    choices = JSON.parse(choices);
  }

  // NOTE: choice index is a new field, some options may have it, some may not.
  // create new indexes if they don't.
  const needsInitialIndex = choices.some((el) => !el.hasOwnProperty('index'));

  if (needsInitialIndex) {
    choices = choices.map((choice, index) => ({
      ...choice,
      index: index + 1,
    }));
  }

  // sort by index
  const sortedChoices = choices.slice().sort((a, b) => a.index - b.index);

  return sortedChoices;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_MENU_ITEMS_PENDING: {
      return {
        ...state,
        data: [],
        fetching: true,
        error: false,
        errorMessage: '',
      };
    }
    case type.FETCH_MENU_ITEMS_SUCCESS: {
      const { items } = action.payload;

      const itemsWithOptions = items.map((item) => {
        if (!item.options) {
          return {
            ...item,
            options: [],
          };
        }

        const optionsWithChoices = item.options.map((option) => {
          const parsedOption = JSON.parse(option);

          return {
            ...parsedOption,
            choices: parseChoicesWithIndexes(parsedOption.choices),
          };
        });

        return {
          ...item,
          options: optionsWithChoices,
        };
      });

      return {
        ...state,
        data: itemsWithOptions,
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }
    case type.FETCH_MENU_ITEMS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.SET_ACTIVE_RESTAURANT_ID: {
      return {
        data: [],
        fetching: true,
        error: false,
        errorMessage: '',
      };
    }

    case type.TOGGLE_MENU_ITEM_STOCK_SUCCESS: {
      const { updatedMenuItem } = action.payload;

      const newItems = updateItemInArray(
        state.data,
        'objectId',
        updatedMenuItem.objectId,
        (item) => ({
          ...item,
          disableDate: updatedMenuItem.disableDate,
        }),
      );

      return {
        ...state,
        data: newItems,
      };
    }

    case type.TOGGLE_MENU_CHOICE_STOCK_SUCCESS: {
      const { updatedOption } = action.payload;

      const newItems = updateItemInArray(
        state.data,
        'objectId',
        updatedOption.menuItemId,
        (item) => {
          const { options } = item;

          const choices = parseChoicesWithIndexes(updatedOption.choices, true);
          const newOptions = updateItemInArray(
            options,
            'objectId',
            updatedOption.objectId,
            (option) => ({
              ...option,
              choices,
            }),
          );

          return {
            ...item,
            options: newOptions,
          };
        },
      );

      return {
        ...state,
        data: newItems,
      };
    }

    default: {
      return state;
    }
  }
};
