import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { predictionsForDeal } from '../graphql/queries';
import { devLog } from '../utils';

export const fetchDealPredictAction =
  ({
    dineIn,
    discount,
    endTime,
    lightning,
    quantity,
    recurring,
    recurringDays,
    restId,
    startDate,
    startTime,
    takeaway,
    userId,
    userType,
    controllerSignal,
  }) =>
  (dispatch) => {
    // dispatch({
    //   type: type.SET_ID_APP_LOADING,
    //   payload: 'PREDICTION',
    // });

    dispatch({
      type: type.FETCH_DEAL_PREDICT_PENDING,
    });

    const getData = async () => {
      try {
        const response = await API.graphql(
          {
            query: predictionsForDeal,
            variables: {
              dineIn,
              discount,
              endTime,
              lightning,
              quantity,
              recurring,
              recurringDays,
              restId,
              startDate,
              startTime,
              takeaway,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        // TODO: cancel network request if aborted
        if (controllerSignal.aborted) {
          // superseded by another fetch
          return;
        }

        const prediction = JSON.parse(response.data.predictionsForDeal);

        if (prediction.result !== 'success') {
          dispatch({
            type: type.FETCH_DEAL_PREDICT_FAILURE,
            payload: `Unable to predict deal: result unsuccessful`,
          });
          return;
        }

        devLog('success', 'predictions for deal', prediction);

        dispatch({
          type: type.FETCH_DEAL_PREDICT_SUCCESS,
          payload: prediction,
        });
      } catch (error) {
        devLog('error', 'predictions for deal', error);

        dispatch({
          type: type.FETCH_DEAL_PREDICT_FAILURE,
          payload: `Unable to predict deal: ${error}`,
        });
      } finally {
        // dispatch({
        //   type: type.REMOVE_ID_APP_LOADING,
        //   payload: 'PREDICTION',
        // });
      }
    };
    getData();
  };

export const clearDealPredictAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_DEAL_PREDICT,
  });
};
