/* eslint-disable */
import React, { useState } from 'react';
// import PropTypes from "prop-types";
import { MenuItem, Button, Menu, Typography } from '@mui/material';
import { CalendarViewDay } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { setSelectedDayAction } from '../../../actions/dealsByDayAction';

const SelectDay = ({ setSelectedDayAction, selectedDay }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const today = moment().weekday();

  const dealDays = [
    { name: 'Sunday', isToday: false, value: 7 },
    { name: 'Monday', isToday: false, value: 1 },
    { name: 'Tuesday', isToday: false, value: 2 },
    { name: 'Wednesday', isToday: false, value: 3 },
    { name: 'Thursday', isToday: false, value: 4 },
    { name: 'Friday', isToday: false, value: 5 },
    { name: 'Saturday', isToday: false, value: 6 },
  ];

  const todayIndex = dealDays.findIndex((day) => day.value === today);
  dealDays[todayIndex].isToday = true;

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const index = dealDays.findIndex((day) => day.value === selectedDay);
  const [selectedOption, setSelectedOption] = useState(dealDays[index]);

  const handleSelectDay = (event) => {
    setAnchorEl(null);

    const value = Number(event.currentTarget.getAttribute('value'));
    const name = event.currentTarget.getAttribute('name');

    if (!name) {
      return;
    }

    setSelectedDayAction(value);

    const index = dealDays.findIndex((day) => day.value === value);
    setSelectedOption(dealDays[index]);
  };

  return (
    <>
      <Button
        aria-controls='deals-day-menu'
        aria-haspopup='true'
        onClick={handleOpenDropdown}
        variant='contained'
        color='primary'
        // size="small"
        startIcon={<CalendarViewDay />}
      >
        {selectedOption.name
          ? `${selectedOption.name}${selectedOption.isToday ? ' (today)' : ''}`
          : 'Select Day'}
      </Button>
      <Menu
        id='deals-day-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
      >
        {dealDays.map((day, index) => (
          <MenuItem
            key={index}
            onClick={handleSelectDay}
            value={day.value}
            name={day.name}
            selected={selectedOption.value === day.value}
          >
            {day.name}
            {day.isToday ? (
              <Typography color='textSecondary' style={{ marginLeft: '0.25rem' }}>
                (Today)
              </Typography>
            ) : (
              ''
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedDay: state.dealsByDay.selectedDay,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setSelectedDayAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SelectDay);
