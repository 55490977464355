import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  selectDayButton: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginBottom: theme.spacing(2),
  },
  hr: {
    marginBottom: theme.spacing(2),
    opacity: 0.3,
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
