import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { usePageViews } from '../../../utils/analytics';
import EditGoalForm from './EditGoalForm';

const EditGoal = () => {
  usePageViews();

  return <EditGoalForm />;
};

EditGoal.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditGoal);
