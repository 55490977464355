/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useEffect, useCallback } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  CircularProgress,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  clearDisableDealAction,
  disableDealAction,
  enableDealAction,
} from '../../actions/dealDisableAction';

const DealDisableDialog = ({
  clearDisableDealAction,
  deal,
  dealDisable,
  disableDealAction,
  enableDealAction,
  isDisabled,
  openDialog,
  restId,
  setOpenDialog,
  userId,
  userType,
}) => {
  const mode = isDisabled ? 'enable' : 'disable';

  const submit = (event) => {
    event.preventDefault();

    const date = moment().format('YYYY-MM-DD');

    if (mode === 'disable') {
      disableDealAction(deal.dealId, date, restId, userId, userType);
    } else {
      enableDealAction(deal.dealId, date, restId, userId, userType);
    }
  };

  const handleClose = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  useEffect(() => {
    if (!dealDisable.success) {
      return;
    }
    handleClose();
  }, [dealDisable.success, handleClose]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      TransitionProps={{
        onEnter: () => {},
        onExit: () => {
          clearDisableDealAction();
        },
      }}
    >
      <DialogTitle>
        {mode === 'disable' ? 'Disable' : 'Enable'} {deal.discount} Deal
      </DialogTitle>

      {mode === 'disable' ? (
        <DialogContent>
          Are you sure you want to disable this deal? This deal wil be disabled for the rest of the
          day.
        </DialogContent>
      ) : (
        <DialogContent>Are you sure you want to enable this deal?</DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={submit} type='submit' color='primary' autoFocus>
          {dealDisable.disabling ? (
            <CircularProgress color='primary' size={22} />
          ) : mode === 'disable' ? (
            'Disable'
          ) : (
            'Enable'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  dealDisable: state.dealDisable,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clearDisableDealAction, disableDealAction, enableDealAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DealDisableDialog);
