import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../EatClubTheme';

const useStyles = makeStyles(() => ({
  navigationLinks: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    // fontSize: '15px',
  },
  navigationLink: {
    padding: '8px 12px',
    borderRadius: '6px',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    gap: '16px',
    '&:hover': {
      backgroundColor: COLORS.LINEN,
    },
  },
}));

export default useStyles;
