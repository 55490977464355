import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { dealsByDay, dealsByDayBookings } from '../graphql/queries';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

export const fetchDealsByDayAction =
  (restaurantId, day, restId, userId, userType) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: `DEALS_BY_DAY_${day}`,
    });
    dispatch({
      type: type.FETCH_DEALS_BY_DAY_PENDING,
    });

    (async () => {
      try {
        const dealsResponse = await API.graphql(
          {
            query: dealsByDay,
            variables: {
              restId: restaurantId,
              day,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        // destructure
        const {
          data: { dealsByDay: deals },
        } = dealsResponse;

        devLog('success', 'deals by day', deals);

        const bookingsResponse = await API.graphql(
          {
            query: dealsByDayBookings,
            variables: {
              restId: restaurantId,
              day,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        // destructure
        const {
          data: { dealsByDayBookings: bookings },
        } = bookingsResponse;

        devLog('success', 'deals by day bookings', bookings);

        dispatch({
          type: type.FETCH_DEALS_BY_DAY_SUCCESS,
          payload: { deals, bookings },
        });
      } catch (error) {
        devLog('error', 'deals by day', error);

        dispatch({
          type: type.FETCH_DEALS_BY_DAY_FAILURE,
          payload: `Unable to retrieve deals by days: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: `DEALS_BY_DAY_${day}`,
        });
      }
    })();
  };

export const setSelectedDayAction = (day) => (dispatch) => {
  dispatch({
    type: type.SET_SELECTED_DAY,
    payload: day,
  });
};
