/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, List } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrderCategory from './OrderCategory';
import Error from '../error/Error';

const OrderCategories = ({ order, orderItems, mode, handleClickRefundItem }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (order.orderFetching || order.orderError) {
      return;
    }

    // Reduce orders by "categories"
    const ordersByCategories = orderItems.reduce((accumByCategory, item) => {
      // check if item is already reduced
      const itemIndex = accumByCategory.findIndex(
        (accumItem) => accumItem.category === item.category,
      );

      if (itemIndex === -1) {
        accumByCategory.push({
          category: item.category,
          items: [item],
        });
      } else {
        accumByCategory[itemIndex].items.push(item);
      }
      return accumByCategory;
    }, []);

    setCategories(ordersByCategories);
  }, [order.orderError, order.orderFetching, orderItems]);

  if (order.orderError) {
    return <Error error={order.orderError} message={order.orderErrorMessage} />;
  }

  return (
    <>
      {categories.length > 0 ? (
        <List dense>
          {categories.map((category, index) => (
            <OrderCategory
              key={index}
              catIndex={index}
              category={category}
              mode={mode}
              handleClickRefundItem={handleClickRefundItem}
            />
          ))}
        </List>
      ) : (
        <Typography paragraph>There are no items.</Typography>
      )}
    </>
  );
};

OrderCategories.propTypes = {
  order: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ order: state.order });
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrderCategories);
