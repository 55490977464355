import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Image } from '@eatclub-apps/ec-component-library';
import IconSmallArrowRight from '../../../assets/icon_small_arrow_right.svg';
import useStyles from './LockdownStyles';

const Lockdown = ({ header, content, image }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box>
      <Box className={classes.bannerContainer}>
        <Box style={{ display: 'flex', gap: '20px', alignItems: 'baseline' }}>
          <Box className={classes.bannerHeader}>Access the Floorplan & Timeline view today</Box>
          <Box className={classes.bannerPrice}>Only an extra $50 per month</Box>
        </Box>
        <Box
          className={classes.bannerButton}
          onClick={() => {
            history.replace('/livebookings');
          }}
        >
          View plan
          <IconSmallArrowRight style={{ marginLeft: '8px' }} />
        </Box>
      </Box>
      <Box className={classes.lockdownContainer}>
        <Box className={classes.lockdownHeader}>{header}</Box>
        <Box className={classes.lockdownContent}>{content}</Box>
        <Box className={classes.lockdownImage}>
          <Image src={image} alt='premium' height={1885} width={2853} />
        </Box>
      </Box>
    </Box>
  );
};

Lockdown.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Lockdown;
