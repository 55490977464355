import * as type from '../actions/types';

const initialState = {
  data: [],
  fetching: false,
  error: false,
  errorMessage: '',
  success: false,
  shouldFetch: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_DAILY_ORDER_SUMMARIES_PENDING: {
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    }
    case type.FETCH_DAILY_ORDER_SUMMARIES_SUCCESS: {
      const { dailyOrderSummaries } = action.payload;

      const orderSummariesByDate = Array.from(dailyOrderSummaries).sort(
        (a, b) => new Date(b.salesPeriod) - new Date(a.salesPeriod),
      );

      return {
        ...state,
        data: orderSummariesByDate,
        fetching: false,
        success: true,
        shouldFetch: false,
      };
    }
    case type.FETCH_DAILY_ORDER_SUMMARIES_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }
    case type.CLEAR_DAILY_ORDER_SUMMARIES: {
      return {
        ...state,
        fetching: false,
        error: false,
        errorMessage: '',
        success: false,
      };
    }

    case type.SET_PLATFORM:
      return {
        ...state,
        data: [],
        fetching: true,
        error: false,
        errorMessage: '',
        success: false,
        shouldFetch: true,
      };

    // if began changing to new restaurant
    case type.SET_ACTIVE_RESTAURANT_ID:
      return {
        ...state,
        fetching: true,
      };

    // if new restaurant is now active
    case type.SET_ACTIVE_RESTAURANT:
      return {
        ...state,
        success: false,
        shouldFetch: true,
      };

    default: {
      return state;
    }
  }
};
