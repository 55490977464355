/* eslint-disable */
import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Flag } from '@mui/icons-material';
import OrderFlagDialog from './OrderFlagDialog';

const OrderFlagButton = ({ booking }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    if (!openDialog) {
      setOpenDialog(true);
    } else {
      setOpenDialog(false);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (booking.platform === 'whitelabel') {
    return null;
  }

  return (
    <>
      <Tooltip title='Report Issue'>
        <IconButton
          onClick={handleOpenDialog}
          color={booking.issueStatus ? 'primary' : 'default'}
          size='large'
        >
          <Flag />
        </IconButton>
      </Tooltip>

      <OrderFlagDialog
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialog}
        booking={booking}
      />
    </>
  );
};

export default OrderFlagButton;
