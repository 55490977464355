import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Modal,
  TextSelect,
  SegmentedControl,
  ToggleGroup,
} from '@eatclub-apps/ec-component-library';
import { clearPostDailyOppoAction } from '../../../../actions/dealDailyOpposPostAction';
import { COLORS } from '../../../../EatClubTheme';
import { potentialRevenuesPropTypes } from '../../../../reducers/potentialRevenuesReducer';
import { trackEvent } from '../../../../utils/analytics';
import { roundToNearest } from '../../../../utils/helpers';
import useStyles from './EditParametersModalStyles';

const EditParametersModal = ({
  isOpen,
  onClose,
  onSave,
  revenueTarget,
  strategy,
  types,
  potentialRevenues,
  dineInMax,
}) => {
  const classes = useStyles();
  const [newRevenueTarget, setNewRevenueTarget] = useState(revenueTarget);
  const [newStrategy, setNewStrategy] = useState(strategy);
  const [newTypes, setNewTypes] = useState(types);
  const [selectionValues, setSelectionValues] = useState([]);
  const [newDineInMax, setNewDineInMax] = useState(dineInMax);

  // Refresh everything on open
  useEffect(() => {
    if (isOpen) {
      setNewRevenueTarget(revenueTarget);
      setNewStrategy(strategy);
      setNewTypes(types);
      setNewDineInMax(dineInMax);
    }
  }, [isOpen]);

  const getMaxRevenueForStrategy = () => {
    const revenueStrategy =
      newStrategy === 'lowerOffers' ? 'lowerOffersOptimized' : 'offPeakOptimized';
    return potentialRevenues.data[revenueStrategy];
  };

  // Set the selection values for the text select
  useEffect(() => {
    const createValueArray = (maxValue) => {
      const values = [
        // eslint-disable-next-line prefer-spread
        ...Array.apply(null, { length: 10 }).map(
          (_, num) => roundToNearest((maxValue / 10) * num, 50), // Rounded to nearest 10
        ),
        maxValue,
      ];

      const valueObject = values.map((value) => ({ label: `${value}`, value }));
      return valueObject;
    };

    setSelectionValues(createValueArray(getMaxRevenueForStrategy()));
  }, [newStrategy]);

  const save = () => {
    trackEvent('button_click: save_edit_parameters');
    onSave(newRevenueTarget, newStrategy, newTypes, newDineInMax);
    onClose();
  };

  // TODO move this to theme
  const labelStyles = {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 'bold',
  };

  const toggleGroupStyles = {
    container: {
      // flexGrow: 1,
    },
    toggleGroup: {
      // width: '100%',
    },
    label: labelStyles,
    option: {
      flexGrow: 1,
      textAlign: 'left',
      borderColor: COLORS.GRAY_BORDER,
      borderRadius: '4px',
      padding: '10px',
      fontSize: '18px',
      width: '150px',
    },
    selectedOption: {
      borderColor: COLORS.OLIVE,
    },
  };

  return (
    <Modal
      style={{
        container: { padding: '20px' },
        header: { padding: '20px' },
        content: { padding: '15px 35px 30px 35px' },
      }}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
    >
      <Box className={classes.container}>
        <Box style={{ maxWidth: '700px' }}>
          <Box className={classes.heading}>Edit parameters</Box>
          <Box className={classes.description}>
            Changes will re-calculate your starting schedule of offers.
          </Box>
        </Box>
        <Box mt='30px'>
          <Box className={classes.optionsContainer}>
            <TextSelect
              onChange={(value) => setNewRevenueTarget(parseInt(value, 10))}
              validation='numbers_only'
              prefix='$'
              minNumber={0}
              maxNumber={getMaxRevenueForStrategy()}
              items={selectionValues}
              label='Monthly revenue target'
              value={newRevenueTarget}
              style={{
                label: labelStyles,
                textField: { width: '150px' },
                container: { flexGrow: 1 },
                textFieldContainer: { width: '100%' },
                textFieldRoot: { width: '100%' },
              }}
              fullWidth
            />
            <SegmentedControl
              label='Optimisation strategy'
              items={[
                { label: 'Offpeak', value: 'offPeak' },
                { label: 'Lower offers', value: 'lowerOffers' },
              ]}
              onSelect={setNewStrategy}
              value={newStrategy}
              style={{
                label: labelStyles,
                option: {
                  fontSize: '18px',
                },
                groupContainer: {
                  width: '100%',
                },
              }}
            />
            <Box style={{ width: 'max-content' }}>
              <ToggleGroup
                label='Available for'
                onSelect={setNewTypes}
                items={[
                  { label: 'Dine-in', value: 'dineIn' },
                  { label: 'Takeaway', value: 'takeaway' },
                ]}
                style={toggleGroupStyles}
                value={newTypes}
                singleChoice={false}
              />
            </Box>
            {/* <Box style={{ flexGrow: 1 }}> */}
            {/*  <TextSelect */}
            {/*    onChange={setNewDineInMax} */}
            {/*    validation='numbers_only' */}
            {/*    minNumber={0} */}
            {/*    increment */}
            {/*    maxNumber={10} */}
            {/*    label='Set a dine-in maximum' */}
            {/*    value={newDineInMax} */}
            {/*    style={{ */}
            {/*      label: labelStyles, */}
            {/*      textField: { width: '150px' }, */}
            {/*    }} */}
            {/*  /> */}
            {/* </Box> */}
          </Box>
        </Box>
        <Box className={classes.modalFooter}>
          <Button type='tertiary' onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={newTypes.length <= 0}
            type='primary'
            onClick={save}
            style={{ fontSize: '18px' }}
          >
            Save changes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

EditParametersModal.defaultProps = {};

EditParametersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  revenueTarget: PropTypes.number.isRequired,
  strategy: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  potentialRevenues: potentialRevenuesPropTypes.isRequired,
  dineInMax: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  dealDailyOppoPost: state.dealDailyOppoPost,
  dailyOppos: state.dealDailyOppos,
  potentialRevenues: state.potentialRevenues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearPostDailyOppo: clearPostDailyOppoAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditParametersModal);
