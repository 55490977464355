import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';

const drawerWidth = 295;

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: COLORS.WHITE,
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      padding: `0 72px 1px 72px`, // That 1px prevents the scrollbar from breaking
      justifyContent: 'center',
    },
    [theme.breakpoints.down('md')]: {
      padding: `0 20px`,
    },
  },
  containerInner: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '1424px',
      justifyContent: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  appBar: {
    color: '#2E2E2E',
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    // paddingTop: '80px',
    marginBottom: '24px',
    maxWidth: '1484px',
    // [theme.breakpoints.up('md')]: {
    //   paddingLeft: '72px',
    //   paddingRight: '72px',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   padding: '0 20px',
    // },
  },
  menuLogo: {
    left: '0',
    margin: '0 auto',
    position: 'absolute',
    right: '0',
    width: '150px',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#F5F2ED',
    height: '100%',
  },
  list: {
    marginTop: '16px',
    fontSize: '22px',
  },
  header: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: '0',
    },
  },
  mobileHeader: {
    alignItems: 'center',
    background: COLORS.LINEN,
    display: 'flex',
    padding: '20px',
    position: 'sticky',
    margin: '0 -20px',
    zIndex: 99,
    top: 0,
    height: '66px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  breadcrumbContainer: {
    paddingBottom: '60px',
    justifyContent: 'center',
    width: '100%',
  },
  headerToolbar: {
    ...theme?.mixins?.toolbar,
    paddingLeft: '0',
    paddingRight: '0',
    marginBottom: '20px',
    // justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      // paddingLeft: '40px',
    },
  },
  toolbar: {
    ...theme?.mixins?.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: theme.spacing(0, 0),
    // padding: '0 40px',
    backgroundColor: '#F5F2ED',
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
  },
  title: {
    flexGrow: 1,
    marginLeft: '0',
    fontSize: '40px',
    fontWeight: '900',
    // paddingBottom: '10px',
    maxWidth: '500px',
    lineHeight: '1.36',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  headerBackNavigation: {
    display: 'flex',
    gap: '10px',
  },
  backNavigationLabel: {
    color: '#E54439',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
  },
  backArrowContainer: {
    width: '19px',
    height: '19px',
    border: '1px solid #D9D9D9',
    borderRadius: '20px',
    position: 'relative',
    backgroundColor: '#ffffff',
  },
  hide: {
    display: 'none',
  },
  logo: {
    height: '35px',
    width: '300px',
    display: 'block',
  },
  navText: {
    '& > span': {
      fontSize: '22px',
    },
  },
  headerDivider: {
    margin: '10px 40px 0px 40px',
    backgroundColor: '#E4E4E4',
    [theme.breakpoints.down('md')]: {
      margin: '10px 20px 0px 20px',
    },
  },
  headerButton: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      height: '100%',
    },
  },
  flex: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  newLabel: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.OLIVE_2,
    padding: '4px 16px',
    ...theme.eatclub.typography.heading.SUBHEAD,
    // fontWeight: FONT_WEIGHTS.MEDIUM,
    borderRadius: '6px',
    letterSpacing: 1.5,
  },
}));

export default useStyles;
