import { API } from 'aws-amplify';
import moment from 'moment-timezone';
import { getAppVersion, only } from '../utils/helpers';
import * as type from './types';
import { affiliatedRestaurants, restaurantById, restaurantSearchByName } from '../graphql/queries';
import { devLog } from '../utils';
import { makeApiAction } from './actions';
import {
  saveAverageBookingValue,
  saveAverageOrderValue,
  saveRestaurant,
} from '../graphql/mutations';
import { RestaurantInputKeys } from '../data/models/Restaurant';

const ausTimezones = moment.tz.zonesForCountry('AU');

/**
 * Sets the timezone for all future moment instances
 * @param {string} region
 */
const setMomentTimezone = (region) => {
  const timezone = ausTimezones.find((zone) => zone.toLowerCase().match(region.toLowerCase()));

  // set the default timezone to restaurant's region
  if (timezone) {
    moment.tz.setDefault(timezone);
  } else {
    // default to the local browser timezone
    moment.tz.setDefault();
  }
};

export const fetchRestaurantAction = (restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'RESTAURANT',
  });

  const getData = async () => {
    try {
      const response = await API.graphql(
        {
          query: restaurantById,
          variables: {
            restId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error);
      });

      devLog('success', 'restaurant', response.data.restaurantById);

      const { region } = response.data.restaurantById;
      setMomentTimezone(region);
      const todayByTimezone = moment().weekday();

      dispatch({
        type: type.FETCH_RESTAURANT_SUCCESS,
        payload: {
          restaurant: response.data.restaurantById,
          todayByTimezone,
        },
      });
    } catch (error) {
      devLog('error', 'restaurant', error);

      dispatch({
        type: type.FETCH_RESTAURANT_FAILURE,
        payload: `Unable to retrieve Restaurant: ${error.message}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'RESTAURANT',
      });
    }
  };
  getData();
};

export const fetchRestaurantsByNameAction = (name, userId, userType) => (dispatch) => {
  dispatch({
    type: type.FETCH_RESTAURANTS_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: restaurantSearchByName,
          variables: {
            name,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'app-version': getAppVersion(),
        },
      );

      devLog('success', 'restaurants', response.data.restaurantSearchByName);

      dispatch({
        type: type.FETCH_RESTAURANTS_SUCCESS,
        payload: response.data.restaurantSearchByName,
      });
    } catch (error) {
      devLog('error', 'restaurants', error);

      dispatch({
        type: type.FETCH_RESTAURANTS_FAILURE,
        payload: `Unable to retrieve Restaurants: ${error.errors[0].message}`,
      });
    }
  })();
};

export const clearRestaurantsByNameAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_RESTAURANTS,
  });
};

export const saveRestaurantAction = (updatedRestaurant) => (dispatch) => {
  const action = 'SAVE_RESTAURANT';

  const updatedRestaurantData = only(updatedRestaurant, RestaurantInputKeys());

  const variables = {
    restaurant: updatedRestaurantData,
  };

  makeApiAction(
    action,
    saveRestaurant,
    variables,
    null,
    'saveRestaurant',
    true,
    dispatch,
    'Your profile has been saved',
    null,
    false,
    'Unable to save profile',
  );
};

export const fetchAffiliatedRestaurantsAction = (restId) => (dispatch) => {
  const action = 'FETCH_AFFILIATED_RESTAURANTS';

  const variables = { restId };

  makeApiAction(
    action,
    affiliatedRestaurants,
    variables,
    null,
    'affiliatedRestaurants',
    false,
    dispatch,
    null,
    null,
    false,
    'Unable to retrieve affiliated restaurants',
  );
};

/**
 * @param {number} abv "Average booking value"
 */
export const saveAverageBookingValueAction = (abv) => (dispatch) => {
  // note: using Average order value instead of Average booking value terminology in the api messaging to avoid clashing with ECBookings terminology

  makeApiAction(
    'UPDATE_AVERAGE_BOOKING_VALUE',
    saveAverageBookingValue,
    { abv },
    {},
    'saveAverageBookingValue',
    true,
    dispatch,
    'Average order value updated.',
    null,
    false,
    'Unable to update average order value.',
  );
};

/**
 * @param {number} aov "Average order value"
 */
export const saveAverageOrderValueAction = (aov) => (dispatch) => {
  makeApiAction(
    'UPDATE_AVERAGE_ORDER_VALUE',
    saveAverageOrderValue,
    { aov },
    {},
    'saveAverageOrderValue',
    true,
    dispatch,
    'Average order value updated.',
    null,
    false,
    'Unable to update the average order value.',
  );
};
