import { PickersDay } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import MomentUtils from '@date-io/moment';
import {
  Paper,
  Button,
  Popover,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import moment from 'moment';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EventNote } from '@mui/icons-material';
import { setDatesAction, setDateRangeAction } from '../../actions/datePickerAction';
import * as consts from '../../constants';
import useStyles from './DatePickerStyles';
import { trackEvent } from '../../utils/analytics';

export const CalendarPopover = ({
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  handlePopoverClose,
  dateRange,
  setDateRange,
}) => {
  const classes = useStyles();

  const dateRangeOptions = [
    { name: 'Today', value: 1 },
    { name: 'Last 7 Days', value: 7 },
    { name: 'Last 14 Days', value: 14 },
    { name: 'Last 28 Days', value: 28 },
    { name: 'Last 90 Days', value: 90 },
    { name: 'Custom', value: 0 },
  ];

  const checkWithinRange = (range) => !!dateRangeOptions.find((option) => option.value === range);

  const handleDatePickerRange = (event) => {
    const range = event.target.value;
    setDateRange(range);

    // Only set dates if not a custom selection
    if (range === 1) {
      // Today
      setSelectedStartDate(moment().startOf('day'));
      setSelectedEndDate(moment().startOf('day'));
    } else if (range !== 0) {
      // Last x days
      setSelectedStartDate(moment().subtract(range, 'd').startOf('day'));
      setSelectedEndDate(moment().subtract(1, 'd').startOf('day'));
    }
  };

  const renderDay = (day, selectedDate, pickersDayProps, renderType) => {
    let firstDate = selectedStartDate;
    let lastDate = selectedEndDate;

    if (renderType === 'before') {
      firstDate = selectedStartDate;
      lastDate = selectedDate?.[0];
    }

    const isDayFirst = day.isSame(firstDate, 'day');
    const isDayBetween = day.isBetween(firstDate, lastDate, 'day');
    const isDayLast = day.isSame(lastDate, 'day');

    const wrapperClassName = clsx(classes.highlightDay, {
      [classes.highlightDayEnds]: isDayFirst || isDayLast,
      [classes.highlightDayBetween]: isDayBetween,
    });

    return <PickersDay className={wrapperClassName} {...pickersDayProps} />;
  };

  return (
    <Paper className={classes.paperDatePicker}>
      <LocalizationProvider utils={MomentUtils} dateAdapter={AdapterMoment}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControlDatePicker}
              variant='standard'
              // variant="filled"
              color='primary'
              // fullWidth
            >
              <InputLabel id='select-date-label'>Select Date</InputLabel>
              <Select
                size='small'
                labelId='select-date-label'
                id='select-date'
                value={checkWithinRange(dateRange) ? dateRange : 0}
                onChange={(event) => handleDatePickerRange(event)}
                fullWidth
              >
                {dateRangeOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12} className={classes.gridItemDatePicker}>
            <InputLabel>Select Start Date:</InputLabel>
            <StaticDatePicker
              displayStaticWrapperAs='desktop'
              autoOk
              openTo='day'
              // disableToolbar
              // variant='static'
              format='DD/MM/YYYY'
              value={selectedStartDate}
              renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) =>
                renderDay(day, selectedDate, dayInCurrentMonth, 'after')
              }
              onChange={(date) => {
                if (dateRange !== 0) {
                  setDateRange(0);
                }
                setSelectedStartDate(date);

                if (moment(date).isAfter(selectedEndDate)) {
                  setSelectedEndDate(date);
                }
              }}
              renderInput={(props) => <TextField {...props} />}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputLabel>Select End Date:</InputLabel>
            <StaticDatePicker
              displayStaticWrapperAs='desktop'
              autoOk
              openTo='day'
              // disableToolbar
              // variant='static'
              format='DD/MM/YYYY'
              value={selectedEndDate}
              renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) =>
                renderDay(day, selectedDate, dayInCurrentMonth, 'before')
              }
              onChange={(date) => {
                if (dateRange !== 0) {
                  setDateRange(0);
                }
                setSelectedEndDate(date);

                if (moment(date).isBefore(selectedStartDate)) {
                  setSelectedStartDate(date);
                }
              }}
              renderInput={(props) => <TextField {...props} />}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant='contained'
              color='primary'
              onClick={handlePopoverClose}
              className={classes.mr2}
            >
              Search
            </Button>
            <Button
              color='secondary'
              onClick={() => {
                handlePopoverClose('cancel');
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Paper>
  );
};

export const Calendar = ({ dates, setDatesAction, setDateRangeAction }) => {
  // these 'selected' states will be passed into CalendarPopover to close itself
  const [selectedStartDate, setSelectedStartDate] = useState(dates.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(dates.endDate);

  const [dateRange, setDateRange] = useState(dates.dateRange);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const popoverOpen = Boolean(popoverAnchorEl);

  const formatDate = (date) => moment(date).format(consts.DatePickerFormat);

  const handlePopoverClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (cancel) => {
    if (cancel !== 'cancel') {
      // Set dates to the new values
      trackEvent('button_click: set_date_range');

      setDatesAction(selectedStartDate, selectedEndDate);

      const customRangeOfDays =
        Math.floor(moment(selectedEndDate).diff(selectedStartDate, 'days', '[]')) + 1;
      setDateRangeAction(dateRange === 0 ? customRangeOfDays : dateRange);
    } else {
      // reset back to last selection
      if (selectedStartDate !== dates.startDate) {
        setSelectedStartDate(dates.startDate);
      }
      if (selectedEndDate !== dates.endDate) {
        setSelectedEndDate(dates.endDate);
      }
      setDateRange(dates.dateRange);
    }

    // Close popup
    setPopoverAnchorEl(null);
  };

  return (
    <>
      <Button
        variant='contained'
        // size="small"
        color='primary'
        onClick={handlePopoverClick}
        startIcon={<EventNote />}
      >
        {formatDate(dates.startDate)}

        {formatDate(dates.startDate) !== formatDate(dates.endDate) && (
          <>&nbsp;-&nbsp; {formatDate(dates.endDate)}</>
        )}
      </Button>
      <Popover
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={() => {
          handlePopoverClose('cancel');
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CalendarPopover
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          handlePopoverClose={handlePopoverClose}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Popover>
    </>
  );
};

Calendar.propTypes = {
  dates: PropTypes.shape({}).isRequired,
  setDatesAction: PropTypes.func.isRequired,
  setDateRangeAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dates: state.dates,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDatesAction,
      setDateRangeAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
