import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OppoDiscount from './OppoDiscount';
import { postDailyOppoAction } from '../../../actions/dealDailyOpposPostAction';
import { minutesToTime } from '../../../utils';
import useStyles from './OppoRowStyles';
import { updateDailyOppoAction } from '../../../actions/dealDailyOppoUpdateAction';

const OppoRow = ({ oppo, oppoPost, postDailyOppo, restId, userId, userType, oppoUpdate }) => {
  const classes = useStyles();

  const { alternates, objectId, discount, startTime, endTime, _posted, prediction, lightning } =
    oppo;

  const { pending } = oppoPost;
  const [laidDiscount, setLaidDiscount] = useState(discount);
  const [oppoPrediction, setOppoPrediction] = useState(prediction);

  // Only allow discounts that are present in the "alternates" returned via api
  const alternatesList = JSON.parse(alternates);
  const hasAlternates = alternatesList.length > 1;
  const discountOptions = alternatesList.map((option) => option.discount);

  const isLastDiscount =
    discountOptions[discountOptions.length - 1].substr(0, 2) === laidDiscount.substr(0, 2);
  const isFirstDiscount = discountOptions[0].substr(0, 2) === laidDiscount.substr(0, 2);
  const isUpdating = oppoUpdate.pendingIds.findIndex((id) => id === objectId) > -1;

  const handleSubmit = (id) => {
    postDailyOppo(id, laidDiscount, 'partnerPortal', restId, userId, userType);
  };

  const updatePrediction = (newDiscountPercentage) => {
    const newPrediction = alternatesList.filter(
      (alternatesObject) => alternatesObject.discount === newDiscountPercentage,
    );

    if (newPrediction) {
      setOppoPrediction(newPrediction[0]?.prediction);
    }
  };

  const increaseDiscount = () => {
    const discountIndex = discountOptions.findIndex(
      (option) => option.substr(0, 2) === laidDiscount.substr(0, 2),
    );
    const nextDiscount = discountOptions[discountIndex + 1];
    const nextDiscountPercentage = nextDiscount.substr(0, 3);
    setLaidDiscount(nextDiscountPercentage);
    updatePrediction(nextDiscountPercentage);
  };

  const decreaseDiscount = () => {
    const discountIndex = discountOptions.findIndex(
      (option) => option.substr(0, 2) === laidDiscount.substr(0, 2),
    );
    const nextDiscount = discountOptions[discountIndex - 1];
    const nextDiscountPercentage = nextDiscount.substr(0, 3);
    setLaidDiscount(nextDiscountPercentage);
    updatePrediction(nextDiscountPercentage);
  };

  return (
    <Grid
      key={objectId}
      container
      spacing={3}
      alignItems='center'
      justifyContent='space-evenly'
      className={classes.mb_sm_2}
      style={{ opacity: _posted ? 0.5 : 1 }}
    >
      <Grid item xs={12} md>
        <Box display='flex'>
          <OppoDiscount discountAmount={laidDiscount} isOptimal={discount === laidDiscount} />
          {hasAlternates && (
            <Box display='flex' flexDirection='column' justifyContent='center'>
              <Tooltip title='Increase Deal' placement='bottom-start'>
                <IconButton
                  size='small'
                  onClick={increaseDiscount}
                  disabled={Boolean(_posted) || isLastDiscount}
                >
                  <KeyboardArrowUp fontSize='large' />
                </IconButton>
              </Tooltip>
              <Tooltip title='Decrease Deal' placement='bottom-start'>
                <IconButton
                  size='small'
                  onClick={decreaseDiscount}
                  disabled={Boolean(_posted) || isFirstDiscount}
                >
                  <KeyboardArrowDown fontSize='large' />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper className={classes.paper}>
          <Typography>Time</Typography>
          <Typography color='textPrimary' variant='h6' component='div' style={{ fontWeight: 600 }}>
            {lightning ? `${minutesToTime(startTime)} - ${minutesToTime(endTime)}` : 'All Day'}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper className={classes.paper}>
          <Typography>Expected Customers</Typography>
          {isUpdating ? (
            <CircularProgress color='primary' style={{ marginTop: '.5rem' }} size={24} />
          ) : (
            <Typography
              color='textPrimary'
              variant='h6'
              component='div'
              style={{ fontWeight: 600 }}
            >
              {oppoPrediction}
            </Typography>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md>
        <ButtonBase
          className={classes.buttonBase}
          onClick={() => (_posted ? undefined : handleSubmit(objectId))}
          disabled={Boolean(_posted) || pending}
        >
          <Paper
            className={`${classes.paperButton} ${pending ? classes.disabled : ''} ${
              _posted ? classes.success : ''
            }`}
          >
            <Typography variant='h6' style={{ lineHeight: 1.2, fontWeight: 600 }}>
              {_posted ? 'Posted!' : 'Post Now'}
            </Typography>
          </Paper>
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

OppoRow.propTypes = {
  oppo: PropTypes.shape({
    _posted: PropTypes.bool,
    created: PropTypes.string,
    alternates: PropTypes.string,
    dayOfWeek: PropTypes.number,
    dineInOnly: PropTypes.bool,
    discount: PropTypes.string,
    endTime: PropTypes.number,
    iaoOnly: PropTypes.bool,
    lightning: PropTypes.bool,
    objectId: PropTypes.string,
    prediction: PropTypes.string,
    qty: PropTypes.number,
    recurring: PropTypes.bool,
    startTime: PropTypes.number,
    takeawayOnly: PropTypes.bool,
  }).isRequired,
  oppoPost: PropTypes.shape({
    error: PropTypes.string,
    pending: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  oppoUpdate: PropTypes.shape({
    error: PropTypes.string,
    pending: PropTypes.bool,
    pendingIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  postDailyOppo: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  oppoPost: state.dealDailyOppoPost,
  oppoUpdate: state.dealDailyOppoUpdate,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { postDailyOppo: postDailyOppoAction, updateDailyOppo: updateDailyOppoAction },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OppoRow);
