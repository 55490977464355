import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@eatclub-apps/ec-component-library';
import { useDispatch } from 'react-redux';
import { deleteAllOffersAction } from '../../../actions/offersForGoalAction';
import { trackEvent } from '../../../utils/analytics';
import useStyles, { modalInputStyle, modalStyle } from './ModalStyles';

const DeleteAllOffersModal = ({ isOpen, onClose, onSave }) => {
  const muiTheme = useTheme();

  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const classes = useStyles();
  const dispatch = useDispatch();

  const [deleting, setDeleting] = useState(false);

  const save = () => {
    trackEvent('button_click: confirm_delete_all_offers');
    setDeleting(true);
    dispatch(deleteAllOffersAction())
      .then(onSave)
      .finally(() => setDeleting(false));
  };

  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: delete_all_offers');
    }
  }, [isOpen]);

  return (
    <Modal
      inputStyle={modalInputStyle}
      fullWidth={false}
      style={modalStyle}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
      footer={[
        <Button
          onClick={onClose}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='tertiary'
        >
          Cancel
        </Button>,
        <Button
          disabled={deleting}
          loading={deleting}
          onClick={save}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='primary'
        >
          Delete All Offers
        </Button>,
      ]}
    >
      <Box className={classes.modalBody}>
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeader}>Delete all offers</Box>
          <Box>Are you sure you want to delete all offers?</Box>
        </Box>
      </Box>
    </Modal>
  );
};

DeleteAllOffersModal.defaultProps = {
  onSave: () => {},
};

DeleteAllOffersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

export default DeleteAllOffersModal;
