import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  CircularProgress,
  Alert,
  AlertTitle,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { HelpOutline } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { clearDeleteDealAction, deleteDealAction } from '../../actions/dealDeleteAction';

const DealDeleteDialog = ({
  clearDeleteDeal,
  deal,
  dealDelete,
  deleteDeal,
  openDialog,
  restId,
  setOpenDialog,
  userId,
  userType,
}) => {
  const { dealId, discount, origin } = deal;
  const { deleting, success } = dealDelete;

  const submit = (event) => {
    event.preventDefault();
    deleteDeal(dealId, restId, userId, userType);
  };

  const handleClose = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  useEffect(() => {
    if (!success) {
      return;
    }
    handleClose();
  }, [success, handleClose]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      TransitionProps={{
        onExited: () => {
          clearDeleteDeal();
        },
      }}
    >
      <DialogTitle>Delete {discount} Deal</DialogTitle>

      {origin !== 'eatclub' || dealDelete.success ? (
        <>
          <DialogContent>Are you sure you want to delete this deal?</DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              form='delete-deal-form'
              onClick={submit}
              type='submit'
              color='primary'
              autoFocus
            >
              {deleting ? <CircularProgress color='primary' size={22} /> : 'Delete'}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <Alert icon={<HelpOutline />} variant='filled' severity='error'>
              <AlertTitle>Unable to delete deal</AlertTitle>
              This deal was created by the EatClub platform, please contact your account manager to
              make any changes.
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button component={Link} color='primary' to='/support?discussion=3'>
              Contact Account Manager
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

DealDeleteDialog.propTypes = {
  clearDeleteDeal: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    dealId: PropTypes.string,
    discount: PropTypes.string,
    origin: PropTypes.string,
  }).isRequired,
  dealDelete: PropTypes.shape({
    deleting: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  deleteDeal: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  dealDelete: state.dealDelete,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { clearDeleteDeal: clearDeleteDealAction, deleteDeal: deleteDealAction },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DealDeleteDialog);
