import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { toggleMenuChoiceStock } from '../graphql/mutations';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

// Used for v2 menus only
export const toggleChoiceStockAction =
  (restId, choiceId, inStock, userId, userType) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: `TOGGLE_CHOICE_STOCK_${choiceId}`,
    });

    dispatch({
      type: type.TOGGLE_CHOICE_STOCK_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: toggleMenuChoiceStock,
            variables: { restId, choiceId, inStock },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        devLog('success', 'choices toggle stock', response.data.toggleMenuChoiceStock);

        dispatch({
          type: type.TOGGLE_CHOICE_STOCK_SUCCESS,
          payload: { updatedChoice: response.data.toggleMenuChoiceStock },
        });
      } catch (error) {
        devLog('error', 'choices toggle stock', error);

        dispatch({
          type: type.TOGGLE_CHOICE_STOCK_FAILURE,
          payload: `Unable to toggle choice stock: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: `TOGGLE_CHOICE_STOCK_${choiceId}`,
        });
      }
    })();
  };

export const clearToggleChoiceStockAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_TOGGLE_CHOICE_STOCK,
  });
};
