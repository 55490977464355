import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  TextArea,
  InlineTextField,
  Dropdown,
} from '@eatclub-apps/ec-component-library';
import { dealsPropTypes } from '../../../data/models/Deal';
import { goalPropTypes } from '../../../data/models/Goal';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency, pluralise } from '../../../utils/helpers';
import useStyles, { modalInputStyle, modalStyle } from './ModalStyles';
import ModalSectionHeader from './ModalSectionHeader';

const RequestEndGoalModal = ({ isOpen, onClose, onSave, targetRevenue, goal, deals }) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const today = moment().startOf('day');
  const end = moment(goal?.endDate);
  const daysLeft = Math.max(0, end.diff(today, 'days')); // Max is to make sure we don't go negative

  const [endGoalReason, setEndGoalReason] = useState(null);
  const [endGoalAdditionalReason, setEndGoalAdditionalReason] = useState(null);
  const [contactName, setContactName] = useState(null);
  const [contactPhone, setContactPhone] = useState(null);

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: end_goal_request');
    }
  }, [isOpen]);

  return (
    <Modal
      inputStyle={modalInputStyle}
      fullWidth={false}
      style={modalStyle}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
      footer={[
        <Button
          onClick={onClose}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='tertiary'
        >
          Cancel
        </Button>,
        <Button
          onClick={() => onSave('', endGoalReason, contactName, contactPhone)}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='primary'
        >
          Request a call
        </Button>,
      ]}
    >
      <Box className={classes.modalBody}>
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeader}>End your current goal</Box>

          <Box style={{ maxWidth: '580px' }}>
            Your goal is currently in progress. Request a call from your account manager and they’ll
            be in touch.
          </Box>

          <Box className={classes.modalContentSmall}>
            <Box className={classes.impactRowSmall}>
              <Box>Current revenue goal</Box>
              <Box className={classes.impactValue}>{formatCurrency(targetRevenue, false)}</Box>
            </Box>
            <Box className={classes.impactRowSmall}>
              <Box>Total Offers</Box>
              <Box className={classes.impactValue}>{deals?.data?.length}</Box>
            </Box>
            <Box className={classes.impactRowSmall}>
              <Box>Current total revenue</Box>
              <Box className={classes.impactValue}>{formatCurrency(goal?.revenue, false)}</Box>
            </Box>
            <Box className={classes.impactRowSmall}>
              <Box>Days left</Box>
              <Box className={classes.impactValue}>
                {daysLeft} {pluralise('day', daysLeft)}
              </Box>
            </Box>
          </Box>

          <Box>
            <ModalSectionHeader header='Let us know why' />

            <Box mt='20px' display='flex' flexDirection='column' style={{ gap: '15px' }}>
              <Dropdown
                allowDeselect
                items={[
                  { label: 'Restaurant became busy', value: 'busy' },
                  { label: 'Staffing issue', value: 'staffIssue' },
                  { label: "Time isn't suitable", value: 'badTime' },
                  { label: 'Offer percentage too high', value: 'offerTooHigh' },
                  { label: 'Other', value: 'other' },
                ]}
                onSelect={setEndGoalReason}
                placeholder='Select a reason for ending the goal'
                value={endGoalReason}
              />

              <TextArea
                placeholder='Additional Reason?'
                resizable={false}
                value={endGoalAdditionalReason}
                onChange={setEndGoalAdditionalReason}
              />
            </Box>
          </Box>

          <Box>
            <ModalSectionHeader header='Contact details' />

            <Box className={classes.impactRow} style={{ maxWidth: '300px' }}>
              <Box>Name</Box>
              <InlineTextField
                value={contactName}
                onChange={setContactName}
                style={{
                  textFieldInner: { padding: '5px 10px' },
                  input: { width: '100%' },
                }}
                placeholder='Empty (optional)'
                maxCharacters={24}
              />
            </Box>

            <Box className={classes.impactRow} style={{ maxWidth: '300px' }}>
              <Box>Phone</Box>
              <InlineTextField
                value={contactPhone}
                onChange={setContactPhone}
                style={{
                  textFieldInner: { padding: '5px 10px' },
                  input: { width: '100%' },
                }}
                placeholder='Empty (optional)'
                maxCharacters={24}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

RequestEndGoalModal.defaultProps = {
  onSave: () => {},
};

RequestEndGoalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  targetRevenue: PropTypes.number.isRequired,
  goal: goalPropTypes.isRequired,
  deals: dealsPropTypes.isRequired,
};

export default RequestEndGoalModal;
