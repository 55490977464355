/* eslint-disable */
import React from 'react';
import { Collapse, List } from '@mui/material';
import MenuChoices from './MenuChoices';

const MenuOptions = ({ openItem, options }) => (
  <Collapse in={openItem} timeout='auto' unmountOnExit>
    <List component='div' disablePadding>
      <>
        {options.map((option, index) => (
          <MenuChoices key={index} option={option} />
        ))}
      </>
    </List>
  </Collapse>
);

export default MenuOptions;
