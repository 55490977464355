import * as type from '../actions/types';

const initialState = {
  dealsFetching: false,
  deals: [],
  dealsError: false,
  dealsErrorMessage: '',
  dealsSuccess: false,
  dealsShouldFetch: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_DEALS_BY_IDS_PENDING:
      return {
        ...state,
        dealsFetching: true,
        dealsError: false,
        dealsErrorMessage: '',
        dealsShouldFetch: false,
      };
    case type.FETCH_DEALS_BY_IDS_SUCCESS:
      return {
        ...state,
        dealsFetching: false,
        deals: action.payload,
        dealsError: false,
        dealsErrorMessage: '',
        dealsSuccess: true,
        dealsShouldFetch: false,
      };
    case type.FETCH_DEALS_BY_IDS_FAILURE:
      return {
        ...state,
        dealsFetching: false,
        dealsError: true,
        dealsErrorMessage: action.payload,
      };

    case type.CLEAR_DEALS_BY_IDS:
      return {
        ...state,
        deals: [],
        dealsError: false,
        dealsErrorMessage: '',
        dealsFetching: false,
        dealsShouldFetch: false,
        dealsSuccess: true,
      };

    // if restaurant id changes then show loading state
    case type.SET_ACTIVE_RESTAURANT_ID:
      return {
        ...state,
        dealsFetching: true,
      };

    // if dates change, or restaurants change then reset deals
    case type.SET_ACTIVE_RESTAURANT:
    case type.SET_SEARCH_DATES:
      return {
        ...state,
        dealsShouldFetch: true,
        dealsSuccess: false,
      };
    default:
      return state;
  }
};
