import * as type from '../actions/types';

const initialState = {
  loadingIds: [],
  hasCompletedInitialLoad: false, // Whether it has loaded at all yet
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SET_ID_APP_LOADING: {
      const checkId = state.loadingIds.findIndex((id) => id === action.payload);
      if (checkId === -1) {
        const addLoadingIds = [...state.loadingIds, action.payload];
        return {
          ...state,
          loadingIds: addLoadingIds,
        };
      }
      return state;
    }
    case type.REMOVE_ID_APP_LOADING: {
      const removeLoadingIds = [...state.loadingIds.filter((id) => id !== action.payload)];
      return {
        ...state,
        loadingIds: removeLoadingIds,
        hasCompletedInitialLoad:
          state.hasCompletedInitialLoad || removeLoadingIds?.length === state.loadingIds.length,
      };
    }
    case type.CLEAR_APP_LOADING:
      return {
        ...state,
        loadingIds: [],
      };
    default:
      return state;
  }
};
