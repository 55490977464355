/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem, CircularProgress, ListSubheader } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setActiveRestaurantIdAction } from '../../actions/restaurantActiveAction';

const RestaurantPickerChild = ({ restaurantActive, restaurantGroup, setActiveRestaurantId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedOption = restaurantActive.restaurant.name || restaurantGroup[0].name;
  const selectedId = restaurantActive.restaurant.objectId || restaurantGroup[0].objectId;

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectRestaurant = (event) => {
    setAnchorEl(null);

    const objectId = event.currentTarget.getAttribute('id');

    // bail if already selected
    if (selectedId === objectId) {
      return;
    }

    if (objectId) {
      setActiveRestaurantId(objectId);
    }
  };

  const truncateString = (text) => {
    if (text.length > 19) {
      return <span title={text}>{text.substr(0, 19)}&hellip;</span>;
    }
    return text;
  };

  return (
    <>
      <Button
        // ref={anchorRef}
        disabled={restaurantActive.fetching}
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleOpenDropdown}
        color='primary'
        variant='contained'
        // size="small"
        startIcon={<LocationOn />}
      >
        {restaurantActive.fetching ? (
          <CircularProgress color='inherit' size={22} style={{ margin: '0 1.5rem 0 1rem' }} />
        ) : (
          <>{selectedOption ? truncateString(selectedOption) : 'Select Restaurant'}</>
        )}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleSelectRestaurant}
      >
        <ListSubheader
          component='li'
          style={{
            outline: 0,
            lineHeight: 1.5,
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
          }}
        >
          Select Restaurant
        </ListSubheader>

        {restaurantGroup.map((restaurant, index) => {
          const { name, objectId } = restaurant;
          return (
            <MenuItem
              key={index}
              onClick={handleSelectRestaurant}
              id={objectId}
              selected={selectedId === objectId}
            >
              {name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

RestaurantPickerChild.propTypes = {
  restaurantGroup: PropTypes.array.isRequired,
  setActiveRestaurantId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  restaurantActive: state.restaurantActive,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setActiveRestaurantId: setActiveRestaurantIdAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantPickerChild);
