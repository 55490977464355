import moment from 'moment';
import * as type from '../actions/types';

const initialState = {
  restaurant: {},
  restaurantFetching: true,
  restaurantError: false,
  restaurantErrorMessage: '',
  todayByTimezone: -1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_RESTAURANT_PENDING:
      return {
        ...state,
        restaurant: {},
        restaurantFetching: true,
        restaurantError: false,
        restaurantErrorMessage: '',
      };
    case type.FETCH_RESTAURANT_SUCCESS: {
      // Parse the hours here, to avoid doing it multiple times in other places
      let hoursParsed = {};
      try {
        hoursParsed = JSON.parse(action.payload?.restaurant?.hours);
      } catch {
        //
      }

      const today = moment().startOf('day');
      const obeeIsLive = today >= moment(action.payload?.restaurant?.obeeGoLiveDate);
      const closedEarlyToday =
        (action.payload?.restaurant?.closures || []).filter((closure) =>
          today.isBetween(moment(closure.startDate), moment(closure.endDate), 'day', '[]'),
        )?.length > 0;

      return {
        ...state,
        restaurant: {
          ...action.payload.restaurant,
          hoursParsed,
          obeeIsLive,
          closedEarlyToday,
        },
        todayByTimezone: action.payload.todayByTimezone,
        restaurantFetching: false,
        restaurantError: false,
        restaurantErrorMessage: '',
      };
    }

    case type.FETCH_RESTAURANT_FAILURE:
      return {
        ...state,
        restaurantError: true,
        restaurantErrorMessage: action.payload,
        restaurantFetching: false,
      };

    case type.SET_SELECTED_DAY:
      return {
        ...state,
        selectedDay: action.payload,
      };

    default:
      return state;
  }
};
