import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { toggleMenuItemStock } from '../graphql/mutations';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

export const toggleMenuItemStockAction =
  (restId, menuItemId, inStock, userId, userType) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: `TOGGLE_MENU_ITEM_STOCK_${menuItemId}`,
    });

    dispatch({
      type: type.TOGGLE_MENU_ITEM_STOCK_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: toggleMenuItemStock,
            variables: {
              restId,
              menuItemId,
              inStock,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        devLog('success', 'menu item toggle stock', response.data.toggleMenuItemStock);

        dispatch({
          type: type.TOGGLE_MENU_ITEM_STOCK_SUCCESS,
          payload: { updatedMenuItem: response.data.toggleMenuItemStock },
        });
      } catch (error) {
        devLog('error', 'menu item toggle stock', error);

        dispatch({
          type: type.TOGGLE_MENU_ITEM_STOCK_FAILURE,
          payload: `Unable to toggle menu item stock: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: `TOGGLE_MENU_ITEM_STOCK_${menuItemId}`,
        });
      }
    })();
  };

export const clearMenuItemStockAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_TOGGLE_MENU_ITEM_STOCK,
  });
};
