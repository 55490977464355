/* eslint-disable */
import React from 'react';
// import PropTypes from "prop-types";
import { List, ListSubheader, Box, Divider } from '@mui/material';
import Product from './MenuItem';

const MenuCategory = ({ categoriesLength, category, handleToggleItem, index, openItemId }) => (
  <>
    <List
      dense={false}
      style={{ paddingTop: '1rem' }}
      subheader={
        <ListSubheader component='div' color='primary'>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <span>{category.categoryTitle}</span>
          </Box>
        </ListSubheader>
      }
    >
      {category.products
        .sort((a, b) => a?.itemIndex - b?.itemIndex)
        .map((product) => (
          <Product
            handleToggleItem={() => handleToggleItem(category?.objectId + product.objectId)}
            key={category?.objectId + product.objectId}
            product={product}
            openItem={openItemId === category?.objectId + product.objectId}
          />
        ))}
    </List>
    {index < categoriesLength - 1 && <Divider />}
  </>
);

// MenuCategory.propTypes = {};

export default MenuCategory;
