import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './ModalStyles';

const ModalSectionHeader = ({ header }) => {
  const classes = useStyles();

  return (
    <Box className={classes.modalSectionHeaderContainer}>
      <Box className={classes.subheading}>{header}</Box>
      <Box className={classes.modalSectionHeaderDivider} />
    </Box>
  );
};

ModalSectionHeader.propTypes = {
  header: PropTypes.string.isRequired,
};

export default ModalSectionHeader;
