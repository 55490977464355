import * as type from '../actions/types';

const initialState = {
  fetching: false,
  data: [],
  error: false,
  errorMessage: '',
  success: false,
  shouldFetch: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_DEALS_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
        shouldFetch: false,
      };
    case type.FETCH_DEALS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.payload,
        error: false,
        errorMessage: '',
        success: true,
        shouldFetch: false,
      };
    case type.FETCH_DEALS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };

    case type.CLEAR_DEALS:
      return {
        ...state,
        data: [],
        error: false,
        errorMessage: '',
        fetching: false,
        shouldFetch: false,
        success: true,
      };

    // if restaurant id changes then show loading state
    case type.SET_ACTIVE_RESTAURANT_ID:
      return {
        ...state,
        fetching: true,
      };

    // if dates change, or restaurants change then reset deals
    case type.SET_ACTIVE_RESTAURANT:
    case type.SET_SEARCH_DATES:
      return {
        ...state,
        shouldFetch: true,
        success: false,
      };
    default:
      return state;
  }
};
