import './init';
import React from 'react';
import { createRoot } from 'react-dom/client';
import moment from 'moment';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider as RollbarProvider, ErrorBoundary, historyContext } from '@rollbar/react';
import { createBrowserHistory } from 'history';
import CallRequest from './components/bookingConfirmation/CallRequest';
import ConfirmBooking from './components/bookingConfirmation/ConfirmBooking';
import AllOpportunities from './components/opportunity/AllOpportunities';
import configureRollbar from './rollbar';
import store from './store';
import theme from './AppTheme';
import AppWithAuth from './components/auth/AppWithAuth';
import Opportunity from './components/opportunity/Opportunity';
import AppContainer from './AppContainer';

// NOTE: enable moment in window to test in browser
if (!import.meta.env.MODE || import.meta.env.MODE === 'development') {
  window.moment = moment;
}

const { rollbar } = configureRollbar();

const history = createBrowserHistory();
history.listen(historyContext(rollbar, {}));

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <RollbarProvider instance={rollbar}>
    <ErrorBoundary>
      <Provider store={store}>
        <CssBaseline>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <AppContainer>
                <Router>
                  <Switch>
                    <Route exact path='/oppo/:oppoId'>
                      <Opportunity />
                    </Route>
                    <Route exact path='/alloppos/:restId'>
                      <AllOpportunities />
                    </Route>
                    <Route exact path='/booking-confirm/:bookingId'>
                      <ConfirmBooking />
                    </Route>
                    <Route exact path='/booking-call/:bookingId'>
                      <CallRequest />
                    </Route>
                    <Route path='*'>
                      <AppWithAuth />
                    </Route>
                  </Switch>
                </Router>
              </AppContainer>
            </StyledEngineProvider>
          </ThemeProvider>
        </CssBaseline>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>,
);
