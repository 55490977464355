/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Typography,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTodayHours, minutesToMoment } from '../../../utils';
import useStyles from './CreateDealsStyles';
import Popover from '../../popover/Popover';
import { isStaffUser } from '../../../utils/helpers';

const DealType = ({ newDeal, setNewDeal, mode, restaurantHours, user }) => {
  const classes = useStyles();
  const { type } = newDeal;

  useEffect(() => {
    // Enable the next Create Deal step

    if (
      !newDeal.type.value ||
      newDeal.type.enabled === false ||
      newDeal.discount.enabled === true
    ) {
      return;
    }

    setNewDeal({
      ...newDeal,
      discount: { ...newDeal.discount, enabled: true, value: '' },
      quantity: { ...newDeal.quantity, enabled: false, value: '' },
      startDate: { ...newDeal.startDate, enabled: false },
      time: {
        ...newDeal.time,
        enabled: false,
      },
      validFor: {
        ...newDeal.validFor,
        enabled: false,
        checks: { dineIn: true, takeAway: true },
      },
      days: { ...newDeal.days, enabled: false },
    });
  }, [newDeal, setNewDeal]);

  const handleChange = (event) => {
    setNewDeal({
      ...newDeal,
      type: { ...newDeal.type, value: event.target.value },
      discount: { ...newDeal.discount, enabled: true, value: '' },
      quantity: { ...newDeal.quantity, enabled: false, value: '' },
      startDate: {
        ...newDeal.startDate,
        enabled: false,
        errors: { startDate: '' },
      },
      time: {
        ...newDeal.time,
        times: {
          start:
            event.target.value === 'allDay'
              ? newDeal.time.defaultTimes.start
              : newDeal.time.times.start,
          end:
            event.target.value === 'allDay'
              ? newDeal.time.defaultTimes.end
              : newDeal.time.times.end,
        },
        enabled: false,
        errors: { start: '', end: '' },
      },
      validFor: {
        ...newDeal.validFor,
        enabled: false,
        checks: { dineIn: true, takeAway: true },
      },
      days: { ...newDeal.days, enabled: false, values: [] },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  return (
    <>
      <FormControl
        variant='standard'
        component='fieldset'
        disabled={mode === 'create'}
        onMouseEnter={mode === 'create' ? handlePopoverOpen : undefined}
        onMouseLeave={mode === 'create' ? handlePopoverClose : undefined}
      >
        <FormLabel component='legend' className={classes.mb1}>
          Select Deal Type:
        </FormLabel>
        <RadioGroup aria-label='time' name='time1' value={type.value} onChange={handleChange} row>
          <FormControlLabel
            value='allDay'
            control={<Radio color={type.value === 'allDay' ? 'primary' : 'secondary'} />}
            label={
              <>
                <Typography display='inline'>All Day Deal</Typography>&nbsp;
                <Typography display='inline' color='textSecondary' variant='subtitle2'>
                  * 10% - 50% Off
                </Typography>
              </>
            }
          />
          <FormControlLabel
            value='timeLimited'
            control={<Radio color={type.value === 'timeLimited' ? 'primary' : 'secondary'} />}
            label={
              <>
                <Typography display='inline'>Time Limited Deal</Typography>
                &nbsp;
                <Typography display='inline' color='textSecondary' variant='subtitle2'>
                  * {isStaffUser(user) ? '15%' : '20%'} - 50% Off
                </Typography>
              </>
            }
          />
        </RadioGroup>
      </FormControl>
      <Popover
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        open={openPopover}
        text='Unable to edit in an existing deal'
        anchorOriginVertical='center'
        anchorOriginHorizontal='left'
        transformOriginHorizontal='left'
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DealType);
