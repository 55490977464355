import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchActiveOffersForRestaurantAction } from '../../../actions/activeOffersForRestaurantAction';
import { fetchGoalsAction } from '../../../actions/goalsAction';
import { goalsPropTypes } from '../../../data/models/Goal';
import { objectKeyExists } from '../../../utils/helpers';
import { usePageViews } from '../../../utils/analytics';
import useStyles from '../createDeal/CreateDealsStyles';
import { clearDealAction } from '../../../actions/dealAction';
import EditOfferForm from './EditOfferForm';
import LoadingBox from '../../loading/LoadingBox';

const EditOffer = ({
  clearDeal,
  fetchActiveOffersForRestaurant,
  activeOffersForRestaurant,
  userId,
  userType,
  restId,
  goals,
  fetchGoals,
}) => {
  const classes = useStyles();
  const { dealId } = useParams();
  usePageViews();

  const [offer, setOffer] = useState(false);

  // Since the deals are bundled now, we'd have to fetch all deals for the restaurant
  // and find our deal in that. Horribly inefficient.
  useEffect(() => {
    fetchActiveOffersForRestaurant(restId, userId, userType);
  }, [fetchActiveOffersForRestaurant, dealId, restId, userId, userType]);

  useEffect(() => {
    if (!activeOffersForRestaurant.data) {
      return;
    }

    const foundOffer = activeOffersForRestaurant?.data?.find(
      (offerToFind) => offerToFind?.id === dealId,
    );

    setOffer(foundOffer);
  }, [activeOffersForRestaurant.data, dealId]);

  // If the offer has a goal, try to get it
  useEffect(() => {
    if (offer?.goalId && !objectKeyExists(goals?.data, offer.goalId)) {
      fetchGoals(restId, userId, userType);
    }
  }, [offer, userId, userType]);

  // Clear deal on unsubscribe
  useEffect(
    () => () => {
      clearDeal();
    },
    [clearDeal],
  );

  if (activeOffersForRestaurant.fetching) {
    return <LoadingBox />;
  }

  if (!offer) {
    return <Box>Either the offer does not exist, or it is no longer active</Box>;
  }

  return (
    <Box>
      <EditOfferForm offerGroup={offer} />
    </Box>
  );
};

EditOffer.propTypes = {
  clearDeal: PropTypes.func.isRequired,
  fetchActiveOffersForRestaurant: PropTypes.func.isRequired,
  activeOffersForRestaurant: PropTypes.shape({
    fetching: PropTypes.bool,
    success: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  fetchGoals: PropTypes.func.isRequired,
  goals: goalsPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  region: state.restaurantActive.restaurant.region,
  activeOffersForRestaurant: state.activeOffersForRestaurant,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
  goals: state.goals,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearDeal: clearDealAction,
      fetchActiveOffersForRestaurant: fetchActiveOffersForRestaurantAction,
      fetchGoals: fetchGoalsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditOffer);
