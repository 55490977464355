/* eslint-disable */
import * as type from '../actions/types';
import { updateItemInArray } from './reducerHelper';

const initialState = {
  data: [],
  fetching: true,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_CATEGORIES_PENDING: {
      return {
        ...state,
        data: [],
        fetching: true,
        error: false,
        errorMessage: '',
      };
    }
    case type.FETCH_CATEGORIES_SUCCESS: {
      const { categories } = action.payload;

      return {
        ...state,
        data: categories,
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }
    case type.FETCH_CATEGORIES_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.SET_ACTIVE_RESTAURANT_ID: {
      return {
        data: [],
        fetching: true,
        error: false,
        errorMessage: '',
      };
    }

    case type.TOGGLE_PRODUCT_STOCK_SUCCESS: {
      const { updatedProduct } = action.payload;

      const newCategories = state?.data?.map((category) => {
        const newProducts = category?.products?.map((product) => {
          // Compare IDs since the same product can appear in multiple categories
          if (product?.objectId === updatedProduct?.objectId) {
            return {
              ...product,
              disableDate: updatedProduct?.disableDate,
            };
          }
          return product;
        });

        return {
          ...category,
          products: newProducts,
        };
      });

      return {
        ...state,
        data: newCategories,
      };
    }

    case type.TOGGLE_CHOICE_STOCK_SUCCESS: {
      const { updatedChoice } = action.payload;

      const newCategories = state?.data?.map((category) => {
        const newProducts = category?.products?.map((product) => {
          const newOptions = product?.options?.map((option) => {
            const newChoices = option?.choices?.map((choice) => {
              if (choice?.objectId !== updatedChoice?.objectId) {
                return choice;
              }

              return {
                ...choice,
                disableDate: updatedChoice?.disableDate,
              };
            });

            return {
              ...option,
              choices: newChoices,
            };
          });

          return {
            ...product,
            options: newOptions,
          };
        });

        return {
          ...category,
          products: newProducts,
        };
      });

      return {
        ...state,
        data: newCategories,
      };
    }

    default: {
      return state;
    }
  }
};
