import { Box } from '@mui/material';
import Proptypes from 'prop-types';
import React from 'react';
import useStyles from './OpportunityCardStyles';

export const LargeNumber = ({ number, style }) => {
  const classes = useStyles();

  return (
    <Box style={{ position: 'relative', ...style }}>
      <Box className={classes.largeNumber}>{number}</Box>
      <Box className={classes.largeNumberBackground} />
    </Box>
  );
};

LargeNumber.defaultProps = {
  style: {},
};

LargeNumber.propTypes = {
  number: Proptypes.number.isRequired,
  style: Proptypes.shape({}),
};
