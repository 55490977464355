import * as type from '../actions/types';

const initialState = {
  userInfo: {},
  userFetching: true,
  userError: false,
  userErrorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_USER_PENDING:
      return {
        ...state,
        ...initialState,
        userFetching: true,
      };

    case type.FETCH_USER_SUCCESS: {
      const parsedRestaurants = action.payload?.restaurants?.map((restaurant) =>
        JSON.parse(restaurant),
      );

      return {
        ...state,
        userFetching: false,
        userInfo: {
          ...action.payload,
          restaurants: parsedRestaurants,
        },
      };
    }

    case type.FETCH_USER_FAILURE:
      return {
        ...state,
        userFetching: false,
        userError: true,
        userErrorMessage: action.payload,
      };
    case type.CLEAR_LOGIN_ERROR:
      return {
        ...state,
        userError: false,
        userErrorMessage: '',
      };
    default:
      return state;
  }
};
