import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { COLORS } from '../../../EatClubTheme';

const GraphDot = ({ cx, cy, clickHandler }) => (
  <>
    <circle
      onClick={clickHandler}
      cx={cx}
      cy={cy}
      r={11}
      fill={COLORS.OLIVE}
      style={{ cursor: 'pointer' }}
    />
    <circle
      onClick={clickHandler}
      cx={cx}
      cy={cy}
      r={10}
      fill='white'
      style={{ cursor: 'pointer' }}
    />
    <circle
      onClick={clickHandler}
      cx={cx}
      cy={cy}
      r={3}
      fill='#425C58'
      style={{ cursor: 'pointer' }}
    />
  </>
);

GraphDot.defaultProps = {
  cx: 0,
  cy: 0,
};

GraphDot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  clickHandler: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GraphDot);
