import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  cardTitle: {
    marginBottom: theme.spacing(1),
  },
  hr: {
    marginBottom: theme.spacing(2),
    opacity: 0.3,
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mbMd1: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
  mr3: {
    marginRight: theme.spacing(2),
  },
  justifyLgEnd: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
  },
  marginRightLgAuto: {
    [theme.breakpoints.up('lg')]: {
      marginRight: 'auto',
    },
  },
  fixOutline: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: 'white',
      paddingLeft: 5,
      paddingRight: 5,
      marginLeft: -5,
    },
  },
}));

export default useStyles;
