import * as type from './types';

export const addMessageAction = (id, message, severity) => (dispatch) => {
  dispatch({
    type: type.ADD_MESSAGE,
    payload: { id, message, severity },
  });
};

export const removeMessageAction = (id) => (dispatch) => {
  dispatch({
    type: type.REMOVE_MESSAGE,
    payload: { id },
  });
};

export const clearMessagesAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_MESSAGES,
  });
};
