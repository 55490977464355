import { makeStyles } from '@mui/styles';
import { COLORS, FONT_SIZES, FONT_WEIGHTS } from '../../EatClubTheme';

const useStyles = makeStyles(() => ({
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxWidth: '250px',
  },
  popoverHeader: {
    fontSize: FONT_SIZES.H4,
    fontWeight: FONT_WEIGHTS.BOLD,
  },
  popoverContent: {
    color: COLORS.SMOKE,
    fontSize: '16px',
  },
  icon: {
    color: COLORS.CHARCOAL,
    opacity: 0.8,
    transition: 'opacity 0.1s',
    '&:hover': {
      opacity: 1,
    },
  },
  transactionsContainer: {
    color: COLORS.SMOKE,
    transition: 'color 0.25s',
    '&:hover': {
      color: COLORS.CHARCOAL,
      cursor: 'pointer',
    },
  },
  clickableContainer: {
    alignItems: 'baseline',
    color: COLORS.CHARCOAL,
    display: 'flex',
    gap: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  negativeRevenueLabel: {
    padding: '5px',
    borderRadius: '4px',
    width: 'fit-content',
    background: '#FAE3E2',
    color: '#E7554B',
  },
  positiveRevenueLabel: {
    padding: '5px',
    borderRadius: '4px',
    width: 'fit-content',
    background: '#E3EEE2',
    color: '#487447',
  },
  viewText: {
    textDecoration: 'underline',
  },
}));

export default useStyles;
