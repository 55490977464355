import { makeStyles } from '@mui/styles';
import { COLORS } from '../EatClubTheme';

const drawerWidth = 295;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      overflowX: 'hidden',
      flexShrink: 0,
    },
    '@media print': {
      visibility: 'hidden', // hide side nav for runsheet print
    },
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
    background: COLORS.LINEN,
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  newLabel: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.BRAND_PRIMARY,
    padding: '6px 8px',
    ...theme.eatclub.typography.body.PS,
    borderRadius: '4px',
    letterSpacing: 1.5,
  },
  premiumLabel: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.OLIVE_4,
    padding: '6px 8px',
    ...theme.eatclub.typography.body.PS,
    borderRadius: '4px',
    letterSpacing: 1.5,
  },
}));

export default useStyles;
