/* eslint-disable react/prop-types */
import React from 'react';
import PropType from 'prop-types';
import { Typography, Paper, Grid, Box, Skeleton } from '@mui/material';

import { PhoneAndroid, Person } from '@mui/icons-material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { usePageViews } from '../../utils/analytics';
import useStyles from './SupportStyles';
import Error from '../error/Error';
import SupportFormAccountManager from './SupportFormAccountManager';
import SupportFormBilling from './SupportFormBilling';

const AccountManagerInfo = ({ bdmName, bdmPhone }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item lg='auto' xs={12} className={`${classes.mr2} ${classes.mbMd1}`}>
        <Box display='flex' className={classes.justifyLgEnd}>
          <Person fontSize='small' color='primary' style={{ marginRight: 8 }} />
          <Typography color='textSecondary' variant='body2'>
            {bdmName}
          </Typography>
        </Box>
      </Grid>
      <Grid item lg='auto' xs={12}>
        <Box display='flex' className={classes.justifyLgEnd}>
          <PhoneAndroid fontSize='small' color='primary' style={{ marginRight: 8 }} />
          <Typography color='textSecondary' variant='body2'>
            {bdmPhone}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

const Support = ({ activeRestaurant, restaurant }) => {
  usePageViews();
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item sm={8} xs={12}>
        {restaurant.restaurantError && (
          <Error error={restaurant.restaurantError} message={restaurant.restaurantErrorMessage} />
        )}

        <Paper className={classes.paper}>
          <Grid container alignItems='flex-end' style={{ marginBottom: 8 }}>
            <Grid
              item
              md='auto'
              xs={12}
              className={`${classes.marginRightLgAuto} ${classes.mbMd1}`}
            >
              <Typography variant='subtitle1'>Contact Your Account Manager</Typography>
            </Grid>

            {activeRestaurant.bdmName && activeRestaurant.bdmPhone && (
              <AccountManagerInfo
                bdmName={activeRestaurant.bdmName}
                bdmPhone={activeRestaurant.bdmPhone}
              />
            )}
          </Grid>

          {restaurant.restaurantFetching ? (
            <Box pt={0.5}>
              <Skeleton variant='rectangular' height={214} style={{ marginBottom: 6 }} />
              <Skeleton />
              <Skeleton width='50%' />
            </Box>
          ) : (
            <Box mt={3}>
              <SupportFormAccountManager />
            </Box>
          )}
        </Paper>
      </Grid>

      <Grid item sm={8} xs={12}>
        <Paper className={classes.paper}>
          <Typography variant='subtitle1' className={classes.cardTitle}>
            Contact Billing Enquiries
          </Typography>
          <Box mt={3}>
            <SupportFormBilling />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

Support.propTypes = {
  activeRestaurant: PropType.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
  activeRestaurant: state.restaurantActive.restaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Support);
