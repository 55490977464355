import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { COLORS } from '../../EatClubTheme';

export const TableHeading = ({ label, handleViewAll }) => (
  <Box
    display='flex'
    justifyContent='space-between'
    style={{
      borderRadius: '8px 8px 0 0',
      backgroundColor: '#F2EFEE',
      height: '56px',
      fontSize: '12px',
      textTransform: 'uppercase',
      letterSpacing: '0.13em',
      fontWeight: '500',
      padding: '20px 30px',
    }}
  >
    <Box style={{ color: '#313131' }}>{label}</Box>
    {handleViewAll && (
      <Box
        className='hover-underline'
        style={{ color: COLORS.SMOKE, cursor: 'pointer', fontWeight: '500' }}
        onClick={handleViewAll}
      >
        View all
      </Box>
    )}
  </Box>
);

TableHeading.defaultProps = {
  handleViewAll: null,
};

TableHeading.propTypes = {
  label: PropTypes.string.isRequired,
  handleViewAll: PropTypes.func,
};
