import { API } from 'aws-amplify';
import { startGoal, editGoal, endGoal, deleteGoal } from '../graphql/mutations';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { goalsForRestaurant } from '../graphql/queries';
import { devLog } from '../utils';
import { createAsyncAction } from '../lib/react-redux';

export const fetchGoalsAction = (restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'GOALS',
  });
  dispatch({
    type: type.FETCH_GOALS_PENDING,
  });

  const getData = async () => {
    try {
      const response = await API.graphql(
        {
          query: goalsForRestaurant,
          variables: {
            restId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      const {
        data: { goalsForRestaurant: goals },
      } = response;

      devLog('success', 'goals', goals);

      dispatch({
        type: type.FETCH_GOALS_SUCCESS,
        payload: goals,
      });
    } catch (error) {
      devLog('error', 'goals', error);

      dispatch({
        type: type.FETCH_GOALS_FAILURE,
        payload: `Unable to retrieve goals: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'GOALS',
      });
    }
  };
  getData();
};

export const startGoalAction = (restId, userId, userType, goal, offers) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'START_GOAL',
  });

  dispatch({
    type: type.START_GOAL_PENDING,
  });

  (async () => {
    try {
      const postStartingOffers = offers.map((offer) => ({
        goalId: offer.goalId,
        restId: offer.restId,
        dealId: offer.dealId,
        discount: offer.discount,
        offerDate: offer.offerDate,
        lightning: offer.lightning,
        startTime: offer.startTime,
        endTime: offer.endTime,
        dayOfWeek: offer.dayOfWeek,
        inPeak: offer.inPeak,
        takeawayOnly: offer.takeawayOnly,
        dineInOnly: offer.dineInOnly,
        iaoOnly: offer.iaoOnly,
        qty: offer.qty,
        targetVal: offer.targetVal,
        targetValLower: Math.round(offer.targetValLower),
        targetValUpper: Math.round(offer.targetValUpper),
        targetValMonthly: offer.targetValMonthly,
        targetValMonthlyLower: offer.targetValMonthlyLower,
        targetValMonthlyUpper: offer.targetValMonthlyUpper,
        optimal: offer.optimal,
        optimality: offer.optimality,
        bookingsPrediction: offer.bookingsPrediction,
        bookingsExpected: offer.bookingsExpected,
        bookingsLower: offer.bookingsLower,
        bookingsUpper: offer.bookingsUpper,
        partySizePrediction: offer.partySizePrediction,
        partySizeExpected: offer.partySizeExpected,
        partySizeLower: offer.partySizeLower,
        partySizeUpper: offer.partySizeUpper,
        partySizeMonthlyExpected: offer.partySizeMonthlyExpected,
        partySizeMonthlyLower: offer.partySizeMonthlyLower,
        partySizeMonthlyUpper: offer.partySizeMonthlyUpper,
        revenueExpected: offer.revenueExpected,
        revenueLower: offer.revenueLower,
        revenueUpper: offer.revenueUpper,
        contributionExpected: offer.contributionExpected,
        contributionLower: offer.contributionLower,
        contributionUpper: offer.contributionUpper,
        contributionADExpected: offer.contributionADExpected,
        contributionADLower: offer.contributionADLower,
        contributionADUpper: offer.contributionADUpper,
        discountExpected: offer.discountExpected,
        discountLower: offer.discountLower,
        discountUpper: offer.discountUpper,
        foodCostExpected: offer.foodCostExpected,
        foodCostLower: offer.foodCostLower,
        foodCostUpper: offer.foodCostUpper,
        commissionExpected: offer.commissionExpected,
        commissionLower: offer.commissionLower,
        commissionUpper: offer.commissionUpper,
      }));

      const response = await API.graphql(
        {
          query: startGoal,
          variables: {
            goal,
            offers: postStartingOffers,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'start goal', response.data.goal);

      dispatch({
        type: type.START_GOAL_SUCCESS,
        // payload: goals,
      });
    } catch (error) {
      devLog('error', 'start goal', error);

      dispatch({
        type: type.START_GOAL_FAILURE,
        payload: `${error}`,
      });

      dispatch({
        type: type.SET_APP_ERROR,
        payload: `${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'START_GOAL',
      });
    }
  })();
};

export const clearGoalsAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_GOALS,
  });
};

export const editGoalAction =
  (restId, userId, userType, goalId, duration, autoAdjust) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'EDIT_GOAL',
    });

    dispatch({
      type: type.EDIT_GOAL_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: editGoal,
            variables: {
              goalId,
              duration,
              autoAdjust,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        devLog('success', 'edit goal', response.data.goal);

        dispatch({
          type: type.EDIT_GOAL_SUCCESS,
          // payload: goals,
        });
      } catch (error) {
        devLog('error', 'edit goal', error);

        dispatch({
          type: type.EDIT_GOAL_FAILURE,
          payload: `${error}`,
        });

        dispatch({
          type: type.SET_APP_ERROR,
          payload: `${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'EDIT_GOAL',
        });
      }
    })();
  };

export const endGoalAction = (goalId, reason) =>
  createAsyncAction(
    'END_GOAL',
    endGoal,
    { goalId, reason },
    'endGoal',
    'Success, current goal has ended',
    true,
    true,
  );

export const deleteGoalAction = (goalId) =>
  createAsyncAction(
    'DELETE_GOAL',
    deleteGoal,
    { goalId },
    'deleteGoal',
    'Success, goal has been deleted',
    true,
    true,
  );
