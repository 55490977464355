import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchGoalsAction } from '../../actions/goalsAction';
import { goalsPropTypes } from '../../data/models/Goal';
import { usePageViews } from '../../utils/analytics';
import GoalCard from './GoalAnalytics/GoalCard';
import { TableHeading } from '../tableHeading/TableHeading';
import DeleteGoalModal from './Modals/DeleteGoalModal';

const PreviousGoalsScreen = ({ goals, fetchGoals, restId }) => {
  const [pastGoals, setPastGoals] = useState([]);
  const [goalToRemove, setGoalToRemove] = useState(null);
  usePageViews();

  useEffect(() => {
    fetchGoals(restId);
  }, [fetchGoals, restId]);

  useEffect(() => {
    setPastGoals(goals.data.filter((goal) => goal.status === 'complete'));
  }, [goals]);

  return (
    <Box>
      <TableHeading label='Previous Goals' />
      <Box mt='10px' display='flex' flexDirection='column' gap='10px'>
        {pastGoals.map((goal) => (
          <GoalCard key={goal?.objectId} goal={goal} deleteGoal={setGoalToRemove} />
        ))}
      </Box>

      <DeleteGoalModal
        isOpen={goalToRemove !== null}
        goalId={goalToRemove?.objectId}
        onClose={() => setGoalToRemove(null)}
        onSave={() => {
          setGoalToRemove(null);
          fetchGoals(restId);
        }}
      />
    </Box>
  );
};

PreviousGoalsScreen.propTypes = {
  restId: PropTypes.string.isRequired,
  goals: goalsPropTypes.isRequired,
  fetchGoals: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  goals: state.goals,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchGoals: fetchGoalsAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreviousGoalsScreen);
