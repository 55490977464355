import { createDeal } from '../graphql/mutations';
import { createAsyncAction } from '../lib/react-redux';

export const createDealAction = (deal) =>
  createAsyncAction(
    'CREATE_DEAL',
    createDeal,
    deal,
    'createDeal',
    'Success, the deal has been created',
    false,
    true,
  );
