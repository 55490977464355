import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveAverageBookingValueAction } from '../../../actions/restaurantAction';
import DataPoint from '../DataPoint/DataPoint';
import { trackEvent } from '../../../utils/analytics';
import { getChange, getPercent, renderValue } from '../../../data/models/Analytics';

const DineInRevenue = ({ data, averageBookingValue, saveAverageBookingValue }) => {
  const {
    isDineInOffline,
    dineInOfflineAOV,
    dineInOfflineRevenueBeforeOffer,
    dineInOfflineRevenueBeforeOfferCompare,
    dineInOfflineRevenueAfterOffer,
    dineInOfflineRevenueAfterOfferCompare,
    dineInAOV,
    dineInAOVCompare,
    dineInRevenueAfterOffer,
    dineInRevenueAfterOfferCompare,
    dineInRevenueBeforeOffer,
    dineInRevenueBeforeOfferCompare,
  } = data;
  const revenueBeforePercent = getPercent(
    dineInRevenueBeforeOffer,
    dineInRevenueBeforeOfferCompare,
  );
  const revenueBeforeChange = getChange(dineInRevenueBeforeOffer, dineInRevenueBeforeOfferCompare);

  const revenueAfterPercent = getPercent(dineInRevenueAfterOffer, dineInRevenueAfterOfferCompare);
  const revenueAfterChange = getChange(dineInRevenueAfterOffer, dineInRevenueAfterOfferCompare);

  const offlineRevenueBeforePercent = getPercent(
    dineInOfflineRevenueBeforeOffer,
    dineInOfflineRevenueBeforeOfferCompare,
  );
  const offlineRevenueBeforeChange = getChange(
    dineInOfflineRevenueBeforeOffer,
    dineInOfflineRevenueBeforeOfferCompare,
  );

  const offlineRevenueAfterPercent = getPercent(
    dineInOfflineRevenueAfterOffer,
    dineInOfflineRevenueAfterOfferCompare,
  );
  const offlineRevenueAfterChange = getChange(
    dineInOfflineRevenueAfterOffer,
    dineInOfflineRevenueAfterOfferCompare,
  );

  const aovPercent = getPercent(dineInAOV, dineInAOVCompare);
  const aovChange = getChange(dineInAOV, dineInAOVCompare);

  const handleSubmit = (value) => {
    trackEvent('button_click: set_avg_booking_value');

    const abv = parseInt(value, 10);

    if (value === '' || Number.isNaN(value) || abv < 1) {
      return;
    }

    saveAverageBookingValue(abv);
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} sm={12} xs={12}>
        <Typography style={{ fontWeight: 500 }}>Dine-in</Typography>
      </Grid>
      {isDineInOffline ? (
        <>
          <DataPoint
            change={offlineRevenueBeforeChange}
            percent={offlineRevenueBeforePercent}
            title='Revenue before offer'
            value={renderValue(dineInOfflineRevenueBeforeOffer)}
          />

          <Grid item lg={12} sm={12} xs={12} style={{ margin: '-12px 0' }}>
            <Divider />
          </Grid>

          <DataPoint
            change={offlineRevenueAfterChange}
            percent={offlineRevenueAfterPercent}
            title='Revenue after offer'
            value={renderValue(dineInOfflineRevenueAfterOffer)}
          />

          <Grid item lg={12} sm={12} xs={12} style={{ margin: '-12px 0' }}>
            <Divider />
          </Grid>

          <DataPoint
            editable
            handleSubmit={handleSubmit}
            saving={averageBookingValue.pending}
            title='Average order value'
            value={dineInOfflineAOV}
          />
        </>
      ) : (
        <>
          <DataPoint
            change={revenueBeforeChange}
            percent={revenueBeforePercent}
            title='Revenue before offer'
            value={renderValue(dineInRevenueBeforeOffer)}
          />
          <Grid item lg={12} sm={12} xs={12} style={{ margin: '-12px 0' }}>
            <Divider />
          </Grid>
          <DataPoint
            change={revenueAfterChange}
            percent={revenueAfterPercent}
            title='Revenue after offer'
            value={renderValue(dineInRevenueAfterOffer)}
          />
          <Box style={{ paddingLeft: '16px', fontSize: '10px', width: '180px', color: '#6A6A6A' }}>
            This value does not include monthly fees paid to EatClub
          </Box>
          <Grid item lg={12} sm={12} xs={12} style={{ margin: '-12px 0' }}>
            <Divider />
          </Grid>
          <DataPoint
            change={aovChange}
            percent={aovPercent}
            title='Average order value'
            value={renderValue(dineInAOV)}
          />
        </>
      )}
    </Grid>
  );
};

DineInRevenue.propTypes = {
  data: PropTypes.shape({}).isRequired,
  averageBookingValue: PropTypes.shape({
    pending: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool,
  }).isRequired,
  saveAverageBookingValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  averageBookingValue: state.averageBookingValue,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveAverageBookingValue: saveAverageBookingValueAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DineInRevenue);
