/* eslint-disable no-prototype-builtins */
import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { subscribeToPubSubEvents } from '../graphql/subscriptions';
import { devLog } from '../utils';
import { transactionById } from '../graphql/queries';
import packageInfo from '../../package.json';

export const subEventsAction = (restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'SUB_EVENTS',
  });
  dispatch({
    type: type.SUB_EVENTS_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: subscribeToPubSubEvents,
          variables: {
            restId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).subscribe({
        next: async (event) => {
          if (event.value.hasOwnProperty('errors')) {
            dispatch({
              type: type.SUB_EVENTS_FAILURE,
              payload: `Unable to subscribe to events: ${event.value.errors[0].message}`,
            });
          }

          devLog('success', 'sub new booking', event.value.data.subscribeToPubSubEvents);

          const {
            value: {
              data: { subscribeToPubSubEvents: newTransaction },
            },
          } = event;

          // NOTE: pubsub will fire twice if whitelabel
          // skip the first transactions that is 'sent'
          if (newTransaction.status === 'sent') {
            return;
          }

          // NOTE: fetch the transaction since 'subscribeToPubSubEvents' is missing
          // the 'platform' field
          const transactionResponse = await API.graphql(
            {
              query: transactionById,
              variables: {
                bookingId: newTransaction.objectId,
              },
              authMode: 'AMAZON_COGNITO_USER_POOLS',
            },
            {
              'user-id': userId,
              'user-type': userType,
              'rest-id': restId,
              'app-version': getAppVersion(),
            },
          ).catch((error) => {
            throw new Error(error.errors[0].message);
          });

          const {
            data: { transactionById: transaction },
          } = transactionResponse;

          devLog('success', 'sub new booking fetch txn', transaction);

          dispatch({
            type: type.SUB_NEW_BOOKING_SUCCESS,
            payload: {
              newBooking: transaction,
            },
          });
        },
        error: (error) => {
          throw new Error(error);
        },
        close: () => {
          // offically unsubscribed
        },
      });

      devLog('success', 'sub events', response);

      dispatch({
        type: type.SUB_EVENTS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      devLog('error', 'sub new booking', error);

      dispatch({
        type: type.SUB_EVENTS_FAILURE,
        payload: `Unable to subscribe to events: ${error[0].message}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'SUB_EVENTS',
      });
    }
  })();
};

export const unsubEventsAction = () => (dispatch) => {
  dispatch({
    type: type.UNSUB_EVENTS,
  });
};
