import { devLog } from '../utils';
import {
  filterByProperty,
  findObjectByProperty,
  replaceObject,
  updateObjectByProperty,
} from '../utils/helpers';

export const updateItemInArray = (array, arrayKey, itemKey, updateItemCallback) => {
  const updatedItems = array.map((item) => {
    if (item[arrayKey] !== itemKey) {
      // preserve other items
      return item;
    }

    // create an updated item
    const updatedItem = updateItemCallback(item);
    return updatedItem;
  });

  return updatedItems;
};

export const deleteItemInArray = (array, arrayKey, itemKey) => {
  const updatedItems = array.filter((item) => {
    if (item[arrayKey] !== itemKey) {
      // preserve other items
      return item;
    }
    // item to delete returns null
    return null;
  });

  return updatedItems;
};

export const setPending = (state) => {
  return {
    ...state,
    pending: true,
    success: false,
    error: '',
  };
};

export const setFailed = (state, error = '') => {
  return {
    ...state,
    pending: false,
    success: false,
    error,
  };
};

/**
 * Successfully update the API with the local data.
 * Used for a successful response for the API, but no data in the response
 */
export const setSuccess = (state) => {
  return {
    ...state,
    apiData: state.data,
    pending: false,
    success: true,
    hasChanges: false,
    error: '',
  };
};

export const clear = (state, initialData = {}) => {
  return {
    ...state,
    data: initialData,
    apiData: initialData,
    pending: false,
    error: '',
    success: false,
  };
};

/** Temporarily update while updating on the backend
 * Used when overwriting data with new data
 */
export const setDataPending = (updatedData, state) => {
  return {
    ...state,
    data: updatedData,
    pending: true,
    success: false,
    error: '',
  };
};

/** Update the data from a successful response
 * Used in successful fetch and successful update
 */
export const setDataSuccess = (updatedData, state) => {
  return {
    ...state,
    data: updatedData,
    apiData: updatedData,
    hasChanges: false,
    pending: false,
    success: true,
    error: '',
  };
};

export const setFetchPending = (state) => {
  return {
    ...setPending(state),
    hasFetched: true, // Set true on pending to prevent other components from fetching
  };
};

/** Update the data from a successful fetch response
 */
export const setFetchSuccess = (apiData, state) => {
  return {
    ...setDataSuccess(apiData, state),
    hasFetched: true,
  };
};

// Temporarily update while updating on the backend
export const updateItemPending = (updatedItem, state, idFieldName = 'id') => {
  const newItems = updateObjectByProperty(state.data, updatedItem, idFieldName);
  return setDataPending(newItems, state);
};

/**
 * Update an existing data item with the new data
 */
export const updateItemSuccess = (updatedItem, state, idFieldName = 'id') => {
  const newItems = updateObjectByProperty(state.data, updatedItem, idFieldName);
  return setDataSuccess(newItems, state);
};

/**
 * Given a list of items, update with the new given items
 */
export const bulkUpdate = (newItems, data, key = 'id') => {
  return data.map((item) => {
    const updatedItem = findObjectByProperty(newItems, item[key], key);
    return updatedItem || item || null;
  });
};

export const bulkUpdatePending = (items, state, key) => {
  return setDataPending(bulkUpdate(items, state.data, key), state);
};

/**
 * TODO check this one, it might not actually match the response it's looking for
 */
export const bulkUpdateSuccess = (items, state, key) => {
  devLog('info', 'BULK UPDATE DEBUG', { items, state, key });
  return setDataSuccess(bulkUpdate(items, state.data, key), state);
};

/**
 * When failing to update an item through the API
 * Note: might want to have the reversion as optional?
 */
export const updateItemFailure = (state, error = '') => {
  return {
    ...state,
    data: state.apiData,
    pending: false,
    success: false,
    error,
  };
};

/**
 * Reset an item back to its API data
 */
export const resetToApiData = (state) => {
  return {
    ...state,
    data: state.apiData,
    hasChanges: false,
  };
};

export const createItemPending = (createdItem, state, updateWhilePending = false) => {
  const getNewItems = () => {
    if (updateWhilePending) {
      return [...state.data, createdItem];
    }

    return [...state.data];
  };

  return setDataPending(getNewItems(), state);
};

/**
 * Add the newly created item to the data
 * Note: items are usually created locally before being saved to
 * the API, so you probably want to use updateItemSuccess instead most times
 */
export const createItemSuccess = (createdItem, state) => {
  const newItems = [...state.data, createdItem];
  return setDataSuccess(newItems, state);
};

/**
 * Intended to replace the createItemSuccess function.
 * This will either add the new object to the array, or update the
 * existing one if an ID matches
 * TODO rename to createItemSuccess
 */
export const createItemSuccessOrUpdate = (newItem, state, idField = 'id') => {
  const id = newItem?.[idField];
  const newItems = replaceObject(state.data, newItem, id, idField, true);
  return setDataSuccess(newItems, state);
};

export const createItemFailure = (state, error = '') => {
  return {
    ...state,
    pending: false,
    error,
  };
};

export const deleteItemPending = (itemId, state, property = 'id') => {
  const newItems = filterByProperty(state.data, itemId, property, true);
  return setDataPending(newItems, state);
};

export const deleteItemSuccess = (itemId, state, property = 'id') => {
  const newItems = filterByProperty(state.data, itemId, property, true);
  return setDataSuccess(newItems, state);
};

export const deleteItemFailure = (state, error = '') => {
  // TODO revert failed update back to apiData's version
  return {
    ...state,
    pending: false,
    error,
  };
};
