import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select } from '@mui/material';
import useStyles from './BookingsTableFilterStyles';
import './BookingsTableFilterStyles.css';

const Filter = ({ column, index, onFilterChanged }) => {
  const {
    lookup,
    tableData: { filterValue = '' },
  } = column;

  const [filter, setFilter] = useState(filterValue);

  const handleChange = (event, columnId) => {
    const { value } = event.target;
    setFilter(value);
    onFilterChanged(columnId, value);
  };

  return (
    <FormControl variant='standard' fullWidth>
      <Select displayEmpty onChange={(e) => handleChange(e, index)} value={filter}>
        <MenuItem value=''>{filter ? 'None' : 'Filter'} </MenuItem>
        {Object.keys(lookup).map((option) => (
          <MenuItem key={option} value={option} name={column.lookup[option]}>
            {column.lookup[option]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

Filter.propTypes = {
  column: PropTypes.shape({
    lookup: PropTypes.shape({}),
    tableData: PropTypes.shape({
      filterValue: PropTypes.string,
    }),
  }).isRequired,
  index: PropTypes.number.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
};

const BookingsTableFilters = (props) => {
  const classes = useStyles();

  const { columns, onFilterChanged } = props;

  return (
    <tr>
      {columns.map((column, index) => (
        <td key={column.field} className={`${classes.td} safari_only_sticky_cells`}>
          {column.filtering && (
            <Filter column={column} index={index} onFilterChanged={onFilterChanged} />
          )}
        </td>
      ))}
    </tr>
  );
};

BookingsTableFilters.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      filtering: PropTypes.bool,
    }),
  ).isRequired,
  onFilterChanged: PropTypes.func.isRequired,
};

export default BookingsTableFilters;
