import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { predictionsForDeal } from '../graphql/queries';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

export const updateDailyOppoAction =
  ({
    dineIn,
    discount,
    endTime,
    lightning,
    oppoId,
    quantity,
    recurring,
    recurringDays,
    restId,
    startDate,
    startTime,
    takeaway,
    userId,
    userType,
  }) =>
  (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'UPDATE_OPPO',
    });

    dispatch({
      type: type.UPDATE_DAILY_OPPO_PENDING,
      payload: { oppoId },
    });

    const getData = async () => {
      try {
        const response = await API.graphql(
          {
            query: predictionsForDeal,
            variables: {
              dineIn,
              discount,
              endTime,
              lightning,
              quantity,
              recurring,
              recurringDays,
              restId,
              startDate,
              startTime,
              takeaway,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        const {
          data: { predictionsForDeal: stringifiedPrediction },
        } = response;

        const prediction = JSON.parse(stringifiedPrediction);

        if (prediction.result !== 'success') {
          dispatch({
            type: type.UPDATE_DAILY_OPPO_FAILURE,
            payload: { error: `Unable to update opportunity: result unsuccessful`, oppoId },
          });
          return;
        }

        devLog('success', 'update opportunity', prediction);

        dispatch({
          type: type.UPDATE_DAILY_OPPO_SUCCESS,
          payload: { prediction: prediction.prediction, oppoId },
        });
      } catch (error) {
        devLog('error', 'update oppo', error);

        dispatch({
          type: type.UPDATE_DAILY_OPPO_FAILURE,
          payload: { eror: `Unable to update opportunity: ${error}`, oppoId },
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'UPDATE_OPPO',
        });
      }
    };
    getData();
  };

export const clearUpdateDailyOppoAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_UPDATE_DAILY_OPPO,
  });
};
