import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  chart: {
    width: ['100%', '!important'],
  },
  chartHeight: {
    height: '200px',
  },
}));

export default useStyles;
