import * as type from '../actions/types';

const initialState = {
  data: [],
  fetching: false,
  error: false,
  errorMessage: '',
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_RESTAURANTS_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: false,
        success: false,
      };
    case type.FETCH_RESTAURANTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: false,
        errorMessage: '',
        success: true,
      };
    case type.FETCH_RESTAURANTS_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        fetching: false,
      };

    case type.CLEAR_RESTAURANTS:
      return initialState;

    default:
      return state;
  }
};
