/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React from 'react';
import { Button, useMediaQuery, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RemoveShoppingCart, ShoppingCart } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { toggleMenuChoiceStockAction } from '../../actions/menuChoiceStockAction';
import useStyles from './MenusStyles';
import { trackEvent } from '../../utils/analytics';

const MenuChoiceStock = ({
  toggleMenuChoiceStockAction,
  choice,
  optionId,
  restId,
  userId,
  userType,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const breakpointMdDown = useMediaQuery(theme.breakpoints.down('sm'));

  const isDisabledToday = (disabledDate) =>
    moment(disabledDate, 'YYYY-MM-DD').isSame(moment(), 'day');

  const isOutOfStock = isDisabledToday(choice.disableDate);

  const toggleIsDisabled = () => {
    trackEvent('button_click: toggle_menu_choices_stock');
    if (isOutOfStock) {
      toggleMenuChoiceStockAction(restId, optionId, choice.title, true, userId, userType);
      return;
    }

    toggleMenuChoiceStockAction(restId, optionId, choice.title, false, userId, userType);
  };

  // MOBILE BUTTON
  if (breakpointMdDown) {
    return (
      <IconButton
        size='small'
        color={isOutOfStock ? 'primary' : 'default'}
        className={isOutOfStock ? 'listButtonDisabled' : ''}
        onClick={toggleIsDisabled}
        title='Toggle stock level'
      >
        {isOutOfStock ? <RemoveShoppingCart fontSize='small' /> : <ShoppingCart fontSize='small' />}
      </IconButton>
    );
  }

  // DESKTOP BUTTON
  return (
    <Button
      variant='text'
      size='small'
      startIcon={isOutOfStock ? <RemoveShoppingCart /> : <ShoppingCart />}
      className={`${classes.listButton} ${isOutOfStock ? 'listButtonDisabled' : ''}`}
      onClick={toggleIsDisabled}
    >
      {isOutOfStock ? 'Out of Stock' : 'In Stock'}
    </Button>
  );
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ toggleMenuChoiceStockAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuChoiceStock);
