import { makeStyles } from '@mui/styles';
import { COLORS, FONT_SIZES, FONT_WEIGHTS } from '../../../EatClubTheme';

const useStyles = makeStyles(() => ({
  bannerContainer: {
    background: COLORS.OLIVE_5,
    margin: '24px 0',
    padding: '10px 20px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '55px',
    flexWrap: 'wrap',
    rowGap: '12px',
  },
  bannerHeader: {
    color: COLORS.OLIVE,
    fontSize: '18px',
    fontWeight: FONT_WEIGHTS.BLACK,
  },
  bannerPrice: {
    fontSize: '16px',
  },
  bannerButton: {
    cursor: 'pointer',
    fontWeight: FONT_WEIGHTS.MEDIUM,
    fontSize: '16px',
  },
  lockdownContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '26px',
  },
  lockdownHeader: {
    fontSize: '44px',
    fontWeight: FONT_WEIGHTS.BLACK,
    marginTop: '60px',
  },
  lockdownContent: {
    fontSize: FONT_SIZES.H4,
    maxWidth: '411px',
  },
  lockdownImage: {
    width: '100%',
    maxWidth: '800px',
  },
}));

export default useStyles;
