import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { useState, useEffect, useCallback } from 'react';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { TextField, Typography } from '@mui/material';
import Popover from '../../popover/Popover';
import useStyles from './CreateDealsStyles';

const DealDate = ({ newDeal, setNewDeal, mode, restaurantHours }) => {
  const { startDate } = newDeal;
  const classes = useStyles();

  const hoursByDay = JSON.parse(restaurantHours);

  const minutesOfDay = (day) => day.minutes() + day.hours() * 60;

  const openHoursByDay = useCallback(
    (dayName, capToMidnight = true) => {
      const hours = Object.keys(hoursByDay)
        .map((day, index) => (day.substr(0, 3) === dayName ? index : null))
        .filter((e) => e !== null)
        .map((index) => Object.values(hoursByDay)[index]);

      if (capToMidnight && hours[0] > hours[1]) {
        hours[1] = 1439;
      }

      return hours;
    },
    [hoursByDay],
  );

  const validateIsOpen = useCallback(
    (startDate, frequency, type) => {
      const selectedDay = startDate.format('ddd').toLowerCase();
      const selectedDayHours = openHoursByDay(selectedDay);

      const closeTimeMinutes = selectedDayHours[1];

      if (closeTimeMinutes === -1) {
        return `Your venue is closed on ${startDate.format('dddd')}. Please select another day.`;
      }

      if (frequency === 'oneTime' && type === 'timeLimited') {
        const closeTime = moment(startDate).startOf('day').add(selectedDayHours[1], 'minutes');

        const today = moment();
        const isToday = today.isSame(startDate, 'day');
        const todayMinutes = minutesOfDay(today);

        if (isToday && todayMinutes > closeTimeMinutes) {
          return `Your venue closed today at ${closeTime.format(
            'h:mma',
          )}. Please select another day.`;
        }

        return '';
      }

      return '';
    },
    [openHoursByDay],
  );

  const getTodaysOpeningHours = (startDate) => {
    const currentDay = startDate.format('ddd').toLowerCase();
    const todayHours = openHoursByDay(currentDay, false);

    if (todayHours.some((el) => el === -1)) {
      return 'Closed';
    }

    const openTime = moment(startDate).startOf('day').add(todayHours[0], 'minutes');
    const closeTime = moment(startDate).startOf('day').add(todayHours[1], 'minutes');

    return `${openTime.format('h:mma')} - ${closeTime.format('h:mma')}`;
  };

  // Add the startDate day to the deal days
  useEffect(() => {
    if (
      !newDeal.startDate.value ||
      !newDeal.startDate.enabled ||
      // !newDeal.days.enabled ||
      newDeal.frequency.value !== 'recurring'
    ) {
      return;
    }

    const currentDay = newDeal.startDate.value.format('ddd').toLowerCase();
    const currentDayHours = openHoursByDay(currentDay);
    const isClosed = currentDayHours.some((el) => el === -1);

    // Skip if venue is closed
    if (isClosed) {
      return;
    }

    const dayIndex = newDeal.days.values.findIndex((day) => day === currentDay);

    if (dayIndex > -1) {
      return;
    }

    setNewDeal({
      ...newDeal,
      days: {
        ...newDeal.days,
        values: [...newDeal.days.values, currentDay],
      },
    });
  });

  useEffect(() => {
    // Enable the next Create Deal step
    if (!newDeal.startDate.value || newDeal.startDate.enabled === false) {
      return;
    }

    const hasErrors = validateIsOpen(
      newDeal.startDate.value,
      newDeal.frequency.value,
      newDeal.type.value,
    );

    // First, see if there's any errors before checking next step
    if (hasErrors) {
      if (newDeal.startDate.errors.startDate) {
        return;
      }

      setNewDeal({
        ...newDeal,
        startDate: {
          ...newDeal.startDate,
          errors: { startDate: hasErrors },
        },
        time: {
          ...newDeal.time,
          enabled: false, // disable next steps
        },
        validFor: {
          ...newDeal.validFor,
          enabled: false, // disable next steps
        },
        days: {
          ...newDeal.days,
          enabled: false, // disable next steps
        },
      });
      return;
    }

    // Check if should enable next step
    if (newDeal.type.value === 'timeLimited') {
      if (newDeal.time.enabled === true) {
        return;
      }

      setNewDeal({
        ...newDeal,
        time: {
          ...newDeal.time,
          enabled: true,
        },
      });
    } else {
      if (newDeal.validFor.enabled === true) {
        return;
      }

      setNewDeal({
        ...newDeal,
        time: {
          ...newDeal.time,
          enabled: false,
        },
        validFor: {
          ...newDeal.validFor,
          enabled: true,
        },
      });
    }
  }, [validateIsOpen, newDeal, setNewDeal]);

  const handleDateChange = (date) => {
    // preselect day for DealDays step
    const currentDay = date.format('ddd').toLowerCase();
    const currentDayHours = openHoursByDay(currentDay);
    const isClosed = currentDayHours.some((el) => el === -1);

    /// NOTE: Add preselect day to existing days, only if day is not closed
    const dayIndex = newDeal.days.values.findIndex((day) => day === currentDay);
    const updatedValues =
      dayIndex === -1 && !isClosed ? [...newDeal.days.values, currentDay] : newDeal.days.values;

    const hasErrors = validateIsOpen(date, newDeal.frequency.value, newDeal.type.value);

    setNewDeal({
      ...newDeal,
      startDate: {
        ...startDate,
        value: date,
        errors: { startDate: hasErrors },
      },
      time: {
        ...newDeal.time,
        enabled: newDeal.type.value === 'timeLimited' && Boolean(!hasErrors),
      },
      days: {
        ...newDeal.days,
        values: updatedValues,
      },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  return (
    <>
      <LocalizationProvider utils={MomentUtils} dateAdapter={AdapterMoment}>
        <DatePicker
          disabled={mode === 'create'}
          className={classes.formControl}
          label='Deal Start Date'
          emptyLabel=''
          format={import.meta.env.VITE_DATE_FORMAT}
          value={startDate.value}
          onChange={handleDateChange}
          inputVariant='outlined'
          disablePast={mode !== 'create'}
          onMouseEnter={mode === 'create' ? handlePopoverOpen : undefined}
          onMouseLeave={mode === 'create' ? handlePopoverClose : undefined}
          showTodayButton
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.formControl}
              label='Deal Start Date'
              error={Boolean(startDate.errors.startDate)}
              helperText={startDate.errors.startDate}
            />
          )}
        />
      </LocalizationProvider>
      <Popover
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        open={openPopover}
        text='Unable to edit in an existing deal'
        anchorOriginVertical='center'
        anchorOriginHorizontal='left'
        transformOriginHorizontal='left'
      />
      {newDeal.type.value === 'timeLimited' && (
        <Typography
          variant='caption'
          component='div'
          color='textSecondary'
          style={{ marginTop: '.5rem', marginLeft: '1rem' }}
        >
          <strong>{startDate.value.format('dddd')}&apos;s Hours: </strong>
          {getTodaysOpeningHours(startDate.value)}
        </Typography>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  restaurantHours: state.restaurantActive.restaurant.hours,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DealDate);
