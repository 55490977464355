import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { devLog } from '.';

export const usePageViews = () => {
  const location = useLocation();
  // Track page visit whenever location changes
  useEffect(() => {
    ReactGA.send('pageview');
  }, [location.pathname]);
};

export const trackEvent = (eventName) => {
  devLog('tracking', 'tracked', eventName);
  ReactGA.event(eventName);
};
