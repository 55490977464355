import moment from 'moment';
import * as type from '../actions/types';
import { calculateStatusForDeal } from '../data/models/Deal';
import { formatTimeFromInt, formatIntFromTime, getDayOfWeek } from '../utils/helpers';

const initialState = {
  data: [],
  fetching: true,
  error: '',
  shouldFetch: true,
  goalId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_ACTIVE_DEALS_FOR_RESTAURANT_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    case type.FETCH_ACTIVE_DEALS_FOR_RESTAURANT_SUCCESS: {
      const { deals } = action.payload;

      const today = moment(); // TODO get in restaurant's timezone

      // Set a status
      const getStatus = (deal) => calculateStatusForDeal(deal);

      const updatedData = deals.map((deal) => {
        const startTime = formatTimeFromInt(deal.startTime);
        const endTime = formatTimeFromInt(deal.endTime);

        return {
          ...deal,
          startTimeReadable: startTime,
          endTimeReadable: endTime,
          status: getStatus(deal),
        };
      });

      return {
        ...state,
        data: updatedData,
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }
    case type.FETCH_ACTIVE_DEALS_FOR_RESTAURANT_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        fetching: false,
      };
    case type.DISABLE_DEAL_SUCCESS:
      const { disabledDeal } = action.payload;

      // Update the changed deal locally
      const updatedDeals = state.data.map((deal) => {
        if (deal?.objectId && deal?.objectId === disabledDeal?.objectId) {
          return {
            ...deal,
            disabledForDate: disabledDeal?.disabledForDate,
            enabled: disabledDeal?.enabled,
            status: 'disabled',
          };
        }

        return deal;
      });

      return {
        ...state,
        fetching: false,
        data: updatedDeals,
      };
    case type.ENABLE_DEAL_SUCCESS:
      const { enabledDeal } = action.payload;

      // Update the changed deal locally
      const updatedEnabledDeals = state.data.map((deal) => {
        if (deal?.objectId && deal?.objectId === enabledDeal?.objectId) {
          const updatedDeal = {
            ...deal,
            disabledForDate: enabledDeal?.disabledForDate,
            enabled: enabledDeal?.enabled,
          };

          return {
            ...updatedDeal,
            status: calculateStatusForDeal(updatedDeal),
          };
        }

        return deal;
      });

      return {
        ...state,
        fetching: false,
        data: updatedEnabledDeals,
      };
    case type.DELETE_DEAL_SUCCESS: {
      const { deletedDeal } = action.payload;

      // Update the changed deal locally
      const updatedDeletedDeals = state.data.filter(
        (deal) => deal?.objectId !== deletedDeal?.object,
      );

      return {
        ...state,
        fetching: false,
        data: updatedDeletedDeals,
      };
    }

    default:
      return state;
  }
};
