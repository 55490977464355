import { makeStyles } from '@mui/styles';
import { COLORS, FONT_SIZES, FONT_WEIGHTS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: '12px',
    position: 'relative',
    backgroundColor: COLORS.LINEN,
    borderRadius: '12px 12px 0 12px',
    cursor: 'pointer',
    flexWrap: 'wrap',
    padding: '30px 20px 20px 20px',
  },
  heading: {
    ...theme.eatclub.typography.heading.H3,
  },
  description: {
    ...theme.eatclub.typography.body.P,
    lineHeight: '24px',
  },
  monthlyRevenueLabel: {
    fontSize: '14px',
  },
  target: {
    marginTop: '12px',
    position: 'relative',
    zIndex: 1,
    fontSize: FONT_SIZES.XXL,
  },
  blob: {
    left: '-10px',
    width: 'calc(100% - 20px)',
    height: '100%',
    position: 'absolute',
    color: COLORS.WHITE,
    zIndex: -1,
  },
  targetText: {
    fontWeight: FONT_WEIGHTS.BOLD,
    color: COLORS.OLIVE_TEXT,
  },
  createGoalButton: {
    marginTop: '16px',
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.13em',
    width: 'fit-content',
    fontWeight: FONT_WEIGHTS.MEDIUM,
  },
  createGoalIcon: {
    marginBottom: '2px',
    marginLeft: '4px',
  },
  ctaContainer: {
    minHeight: '95px',
    marginTop: '28px',
  },
  previousGoalsButton: {
    marginTop: '20px',
  },
  cornerBlob: {
    bottom: '0',
    position: 'absolute',
    right: '0',
  },
  targetArrows: {
    bottom: '45px',
    height: '62px',
    position: 'absolute',
    right: '25px',
    transform: 'scale(2)',
    width: '75px',
  },
}));

export default useStyles;
