import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@eatclub-apps/ec-component-library';
import { potentialRevenuesPropTypes } from '../../../reducers/potentialRevenuesReducer';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency } from '../../../utils/helpers';
import Blob from '../../../assets/blob.svg';
import CornerBlob from '../../../assets/corner_blob.svg';
import TargetArrows from '../../../assets/target_arrows.svg';
import useStyles from './MobileRevenueBannerStyles';

const MobileRevenueBanner = ({ potentialRevenues }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box>
      <Box
        className={classes.container}
        onClick={() => {
          trackEvent('button_click: create_new_goal_from_banner');
          history.push('/goals/create');
        }}
      >
        <Box className={classes.heading}>Unlock extra revenue with goals</Box>
        <Box className={classes.description}>
          Set a revenue goal and our AI will help you get there every month.
        </Box>

        <Box className={classes.ctaContainer}>
          {potentialRevenues.fetching && <CircularProgress />}
          {!potentialRevenues.fetching && potentialRevenues.data != null && (
            <Box>
              <Box className={classes.monthlyRevenueLabel}>Your monthly revenue</Box>
              <Box className={classes.target}>
                <Blob className={classes.blob} />
                <Box className={classes.targetText}>
                  {formatCurrency(potentialRevenues.data?.maxRevenue?.amount, false)}
                </Box>
              </Box>

              <Button
                className={classes.createGoalButton}
                onClick={(event) => {
                  event.stopPropagation();
                  trackEvent('button_click: create_new_goal_banner');
                  history.push('/goals/create');
                }}
                type='text'
              >
                Create a goal
              </Button>
            </Box>
          )}
        </Box>

        <Box>
          <CornerBlob className={classes.cornerBlob} />
          <TargetArrows className={classes.targetArrows} />
        </Box>
      </Box>
      <Box>
        <Button
          className={classes.previousGoalsButton}
          onClick={(event) => {
            event.stopPropagation();
            history.push('/goals/previous');
          }}
          type='text'
        >
          View previous goals
        </Button>
      </Box>
    </Box>
  );
};

MobileRevenueBanner.propTypes = {
  potentialRevenues: potentialRevenuesPropTypes.isRequired,
};

export default MobileRevenueBanner;
