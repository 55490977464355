import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, Button, DialogActions, DialogContent } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchOrderAction, clearOrderAction } from '../../actions/ordersAction';
import Error from '../error/Error';
import OrderTotal from './OrderTotal';
import OrderCategories from './OrderCategories';
import OrderDetails from './OrderDetails';

const BookingsOrderDialog = ({
  clearOrder,
  fetchOrder,
  handleShowLoading,
  onClose,
  open,
  order,
  orderId,
  region,
  restId,
  userId,
  userType,
}) => {
  const handleClose = () => {
    onClose(clearOrder);
  };

  useEffect(() => {
    if (open) {
      fetchOrder(orderId, region, restId, userId, userType);
    }
  }, [fetchOrder, open, orderId, region, restId, userId, userType]);

  useEffect(() => {
    handleShowLoading(order.orderFetching);
  });

  if (order.orderFetching || !order.orderData.items) {
    return null;
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth='sm'>
      {order.orderError ? (
        <>
          <DialogTitle>Order</DialogTitle>
          <DialogContent dividers>
            <Error error={order.orderError} message={order.orderErrorMessage} />
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle>Order #{order.orderData.objectId.slice(-6)}</DialogTitle>
          <DialogContent dividers>
            <Box style={{ marginLeft: -16, marginRight: -16 }}>
              <OrderDetails order={order.orderData} />
              <OrderCategories orderItems={order.orderData.items} />
              <OrderTotal order={order.orderData} />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

BookingsOrderDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    orderFetching: PropTypes.bool.isRequired,
    orderError: PropTypes.bool.isRequired,
    orderErrorMessage: PropTypes.string.isRequired,
    orderData: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
      objectId: PropTypes.string,
    }),
  }).isRequired,
  orderId: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  handleShowLoading: PropTypes.func.isRequired,
  clearOrder: PropTypes.func.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  order: state.order,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearOrder: clearOrderAction,
      fetchOrder: fetchOrderAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BookingsOrderDialog);
