/* eslint-disable */
import React, { useEffect, useCallback } from 'react';
// import PropTypes from "prop-types";
import { Grid, Typography, Box, FormLabel } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import useStyles from './CreateDealsStyles';
import TextFieldTimePicker from './TextFieldTimePicker';

const DealTime = ({ newDeal, setNewDeal, restaurantHours }) => {
  const { time } = newDeal;
  const classes = useStyles();

  const hoursByDay = JSON.parse(restaurantHours);
  const startDay = newDeal.startDate.value.format('ddd').toLowerCase();

  const openHoursByDay = useCallback(
    (dayName) => {
      const hours = Object.keys(hoursByDay)
        .map((day, index) => (day.substr(0, 3) === dayName ? index : null))
        .filter((e) => e !== null)
        .map((index) => Object.values(hoursByDay)[index]);

      if (hours[0] > hours[1]) {
        hours[1] = 1439; // cap tomorrow to midnight
      }

      return hours;
    },
    [hoursByDay],
  );

  const todayHours = openHoursByDay(startDay);

  const minutesOfDay = (day) => day.minutes() + day.hours() * 60;

  const minutesToTime = (minutes, format = 'h:mma') => {
    if (minutes._isAMomentObject) {
      return time.format(format);
    }
    return moment().startOf('day').add(minutes, 'minutes').format(format);
  };

  const dayNames = {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
  };

  const openHoursByDaysMax = useCallback(
    (days) =>
      days.reduce((accumByTime, day) => {
        const hoursOfDay = openHoursByDay(day);

        // Skip closed days
        if (hoursOfDay.some((el) => el === -1)) {
          return accumByTime;
        }

        const openKey = 'open';
        const closeKey = 'close';

        const dayOpen = { minutes: hoursOfDay[0], day, when: openKey };
        const dayClose = { minutes: hoursOfDay[1], day, when: closeKey };

        if (!accumByTime[openKey]) {
          accumByTime[openKey] = dayOpen;
        } else {
          // check which is later
          if (dayOpen.minutes > accumByTime[openKey].minutes) {
            accumByTime[openKey] = {
              ...accumByTime[openKey],
              minutes: dayOpen.minutes,
              day: dayOpen.day,
            };
          }
        }

        if (!accumByTime[closeKey]) {
          accumByTime[closeKey] = dayClose;
        } else {
          // check which is earlier
          if (dayClose.minutes < accumByTime[closeKey].minutes) {
            accumByTime[closeKey] = {
              ...accumByTime[closeKey],
              minutes: dayClose.minutes,
              day: dayClose.day,
            };
          }
        }

        return accumByTime;
      }, {}),
    [openHoursByDay],
  );

  const validateHours = useCallback(
    (selectedTime, type) => {
      if (!selectedTime || !type) {
        return '';
      }

      if (!selectedTime.isValid()) {
        return 'Please select a valid time.';
      }

      if (type === 'open') {
        if (time.times.end) {
          if (selectedTime >= time.times.end) {
            return 'Please select a start time before the end time.';
          }
        }
      }

      if (type === 'close') {
        if (time.times.start) {
          if (selectedTime.unix() <= time.times.start.unix()) {
            return 'Please select an end time after the start time.';
          }
        }
      }

      const selectedTimeMinutes = minutesOfDay(selectedTime);

      if (newDeal.frequency.value === 'oneTime') {
        const startTimeMinutes = todayHours[0];
        const startTime = moment(newDeal.startDate.value)
          .startOf('day')
          .utc()
          .add(todayHours[0], 'minutes');

        if (type === 'open') {
          // NOTE: get moment local() time (without venue's timezone)
          const nowTimeMinutes = minutesOfDay(moment().local());

          const today = moment().startOf('day').utc();
          const isToday = today.isSame(newDeal.startDate.value, 'day');

          if (isToday && selectedTimeMinutes < nowTimeMinutes) {
            return `Please select a start time in the present.`;
          }

          if (selectedTimeMinutes < startTimeMinutes) {
            return `Your venue opens at ${startTime.format('h:mm a')} on ${startTime.format(
              'dddd',
            )}s. Please select start time after opening.`;
          }

          return '';
        }

        const closeTimeMinutes = todayHours[1];
        const closeTime = moment(newDeal.startDate.value)
          .startOf('day')
          .add(todayHours[1], 'minutes');

        if (selectedTimeMinutes > closeTimeMinutes) {
          return `Your venue closes at ${closeTime.format('h:mm a')} on ${closeTime.format(
            'dddd',
          )}s. Please select an end time before closing.`;
        }

        return '';
      }

      if (newDeal.frequency.value === 'recurring') {
        // check if the selected time is before/after
        // any of the selected days' opening hours

        const maxHours = openHoursByDaysMax(newDeal.days.values);

        if (Object.keys(maxHours).length === 0) {
          return '';
        }

        if (type === 'open') {
          if (selectedTimeMinutes < maxHours.open.minutes) {
            return `Your venue opens at ${minutesToTime(maxHours.open.minutes)} on ${
              dayNames[maxHours.open.day]
            }s. Please select a start time after opening.`;
          }

          return '';
        }

        if (type === 'close')
          if (selectedTimeMinutes > maxHours.close.minutes) {
            return `Your venue closes at ${minutesToTime(maxHours.close.minutes)} on ${
              dayNames[maxHours.close.day]
            }s. Please select an end time before closing.`;
          }

        return '';
      }

      return '';
    },
    [
      dayNames,
      newDeal.days.values,
      newDeal.frequency.value,
      newDeal.startDate.value,
      openHoursByDaysMax,
      time.times.end,
      time.times.start,
      todayHours,
    ],
  );

  // Validate the times when date changes!
  useEffect(() => {
    if (!newDeal.time.times.start || !newDeal.time.times.end || newDeal.time.enabled === false) {
      return;
    }

    const errorsStartTime = validateHours(newDeal.time.times.start, 'open');
    const errorsEndTime = validateHours(newDeal.time.times.end, 'close');

    // Has errors
    if (
      errorsStartTime === newDeal.time.errors.start &&
      errorsEndTime === newDeal.time.errors.end
    ) {
      return;
    }

    // errors: { ...time.errors, end: errors[0] },

    setNewDeal({
      ...newDeal,
      time: {
        ...newDeal.time,
        errors: {
          start: errorsStartTime,
          end: errorsEndTime,
        },
      },
    });
  }, [newDeal, setNewDeal, validateHours]);

  useEffect(() => {
    // Enable the next Create Deal step

    if (
      !newDeal.time.times.start ||
      !newDeal.time.times.end ||
      Object.values(newDeal.time.errors).some((el) => el) ||
      newDeal.validFor.enabled === true ||
      newDeal.time.enabled === false
    ) {
      return;
    }

    setNewDeal({
      ...newDeal,
      validFor: { ...newDeal.validFor, enabled: true },
    });
  }, [newDeal, setNewDeal]);

  const handleStartTimeChange = (selectedTime) => {
    const errorMessage = validateHours(selectedTime, 'open');

    if (errorMessage) {
      setNewDeal({
        ...newDeal,
        time: {
          ...time,
          times: { ...time.times, start: selectedTime },
          errors: { ...time.errors, start: errorMessage },
        },
      });
      return;
    }

    setNewDeal({
      ...newDeal,
      time: {
        ...time,
        times: { ...time.times, start: selectedTime },
        errors: { ...time.errors, start: '' },
      },
    });
  };

  const handleEndTimeChange = (selectedTime) => {
    const errorMessage = validateHours(selectedTime, 'close');

    if (errorMessage) {
      setNewDeal({
        ...newDeal,
        time: {
          ...time,
          times: { ...time.times, end: selectedTime },
          errors: { ...time.errors, end: errorMessage },
        },
      });
      return;
    }

    setNewDeal({
      ...newDeal,
      time: {
        ...time,
        times: { ...time.times, end: selectedTime },
        errors: { ...time.errors, end: '' },
      },
    });
  };

  const openTime = moment(newDeal.startDate.value).startOf('day').add(todayHours[0], 'minutes');
  const closeTime = moment(newDeal.startDate.value).startOf('day').add(todayHours[1], 'minutes');

  return (
    <Grid container alignItems='center'>
      <Grid item xs={12}>
        <FormLabel component='legend' style={{ marginBottom: '1rem' }}>
          Must be in venue between:
        </FormLabel>

        <Grid container justifyContent='flex-start' spacing={1}>
          <Grid item md='auto' xs={12}>
            <Box>
              <TextFieldTimePicker
                className={classes.formControl}
                label='Start time'
                value={time.times.start ? time.times.start.format('hh:mm a') : ''}
                onChange={handleStartTimeChange}
                error={Boolean(time.errors.start)}
                helperText={time.errors.start}
                earliest={openTime}
                latest={closeTime}
              />
            </Box>
          </Grid>
          <Grid item md='auto' xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant='subtitle1'>and</Typography>
          </Grid>
          <Grid item md='auto' xs={12}>
            <Box>
              <TextFieldTimePicker
                className={classes.formControl}
                label='End time'
                value={time.times.end ? time.times.end.format('hh:mm a') : ''}
                onChange={handleEndTimeChange}
                error={Boolean(time.errors.end)}
                helperText={time.errors.end}
                earliest={openTime}
                latest={closeTime}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  restaurantHours: state.restaurantActive.restaurant.hours,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DealTime);
