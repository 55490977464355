import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useStyles from './DayTabStyles';

const DayTab = ({ day, itemAmount, onClick, selected, disabled }) => {
  const classes = useStyles({ selected });

  // TODO add keyboard accessibility
  // TODO add hover state

  return (
    <Box className={classes.container} onClick={onClick}>
      <Box className={classes.dayButton}>{day}</Box>
      <Box className={classes.itemAmount}>{itemAmount}</Box>
    </Box>
  );
};

DayTab.defaultProps = {
  selected: false,
  disabled: false,
};

DayTab.propTypes = {
  day: PropTypes.string.isRequired,
  itemAmount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DayTab);
