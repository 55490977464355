import React from 'react';
import Lockdown from './Lockdown';
import timelineImage from '../../../assets/images/timeline_image.png';

const TimelineLockdown = () => (
  <Lockdown
    header='Timeline'
    content='Manage bookings with drag and drop timeline view of your day'
    image={timelineImage}
  />
);

export default TimelineLockdown;
