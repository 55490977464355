import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import { Chip, Typography, Box } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import DealIncreasePercentButton from './DealIncreasePercentButton';
import DealIncreaseQtyButton from './DealIncreaseQtyButton';
import DealOptionsButton from './dealOptions/DealOptionsButton';
import useStyles from './DealsTableStyles';
import { sortDealsByLightningByStartTime, titleCaseFromCamel } from '../../utils';
import { discountsAllDay } from '../../constants';

const DealsTable = ({ dealSaving, dealsByDay, todayByTimezone, selectedDay }) => {
  const classes = useStyles();
  const [tableDeals, setTableDeals] = useState([]);

  const formatPercent = (number) => {
    if (!number) {
      return '0%';
    }

    let format = Math.round(number * 100) / 100;
    if (format < 1) {
      format = '<1';
    } else if (number > 100) {
      format = 100;
    }
    return `${format}%`;
  };

  const getSelectedDay = () => {
    // Account for the fact that if sunday is the selected day, it means LAST sunday, not this sunday
    if (selectedDay === 7) {
      return moment().startOf('week');
    }

    return moment().startOf('week').add(selectedDay, 'days');
  };

  const selectedDateIsToday = getSelectedDay().weekday() === moment().weekday(); // TODO get moment() in restaurant's timezone

  const isDisabledToday = (disabledDate) =>
    moment(disabledDate, 'YYYY-MM-DD').isSame(getSelectedDay(), 'day');
  const isFuture = (deal) => moment(deal.startDate).isAfter(getSelectedDay(), 'day');

  const renderDeal = (rowData) => {
    if (!rowData.discount) {
      return null;
    }

    const isDisabled = isDisabledToday(rowData.disabledForDate);
    const settingText = rowData.setting;
    const recurringText = rowData.recurring ? 'Recurring' : 'One Time';

    return (
      <div>
        <Chip
          style={{ marginBottom: '.5rem' }}
          label={
            <Box display='flex' alignItems='center'>
              {rowData.discount}
              {settingText && <> - {settingText}</>}
            </Box>
          }
          variant='outlined'
          color={isDisabled ? 'secondary' : 'primary'}
        />
        <Typography style={{ marginLeft: '13px' }} display='block' variant='caption'>
          {recurringText}
        </Typography>
      </div>
    );
  };

  const renderPerformance = (rowData) => {
    if (!rowData.performance) {
      return null;
    }

    return (
      <span title='Benchmarking your deal against other similar restaurants.'>
        {rowData.performance}
      </span>
    );
  };

  const renderStatus = (rowData) => {
    const statuses = [];
    const isDisabled = isDisabledToday(rowData.disabledForDate);

    if (isDisabled) {
      statuses.push('Disabled for today');
    }

    if (rowData.status) {
      statuses.push(titleCaseFromCamel(rowData.status));
    }

    // Show a placeholder status if empty
    if (statuses.length === 0) {
      statuses.push('Active');
    }

    return statuses.join(', ');
  };

  const renderActions = (rowData) => {
    if (!rowData.actions) {
      return null;
    }

    if (rowData.actions === 'percent') {
      return <DealIncreasePercentButton deal={rowData} />;
    }
    if (rowData.actions === 'quantity') {
      return <DealIncreaseQtyButton deal={rowData} />;
    }
    if (rowData.actions === 'help') {
      // NOTE: disabling this Help Button for now
      // return <DealHelpButton deal={rowData} />;
      return null;
    }

    return null;
  };

  const renderOptions = (rowData) => (
    <DealOptionsButton isDisabled={isDisabledToday(rowData.disabledForDate)} deal={rowData} />
  );

  const tableData = () => ({
    columns: [
      {
        title: 'Deal',
        field: 'discount',
        render: (rowData) => renderDeal(rowData),
      },
      {
        title: 'Details',
        field: 'discountPeriod',
        customSort: (deal1, deal2) => sortDealsByLightningByStartTime(deal1, deal2),
      },
      {
        title: 'Redeemed Last Week',
        field: 'redeemedLastWeek',
        // hidden: true,
        editable: 'never',
      },
      {
        title: selectedDateIsToday ? '# of Deals Posted' : '# of Deals Scheduled',
        field: 'numberOfDeals',
        editable: 'never',
      },
      {
        title: 'Take Rate Last Week',
        field: 'takeRatePercent',
        // hidden: true,
        editable: 'never',
      },
      {
        title: 'Performance',
        render: (rowData) => renderPerformance(rowData),
        field: 'performance',
        // hidden: true,
        editable: 'never',
      },
      {
        title: 'Recommended Actions',
        render: (rowData) => renderActions(rowData),
        // hidden: true,
        editable: 'never',
        filtering: false,
        searchable: false,
      },
      {
        title: 'Status',
        render: (rowData) => renderStatus(rowData),
        field: 'status',
        editable: 'never',
      },
      {
        title: '',
        render: (rowData) => renderOptions(rowData),
        editable: 'never',
        filtering: false,
        searchable: false,
      },
    ],
  });

  const [table, setTable] = useState(tableData());

  useEffect(() => {
    if (dealsByDay.fetching || dealsByDay.error) {
      return;
    }

    // Filter out any deals that start after the chosen date
    const dealsArray = dealsByDay.data
      .filter((date) => !isFuture(date))
      .map((deal) => {
        const startTime = moment().startOf('day').add(deal.startTime, 'minutes').format('h:mm a');
        const endTime = moment().startOf('day').add(deal.endTime, 'minutes').format('h:mm a');

        const getActionType = () => {
          const discountIndex = discountsAllDay.findIndex((discount) => discount === deal.discount);

          if (deal.takeRate >= 70) {
            return 'quantity';
          }
          if (deal.takeRate <= 30) {
            if (discountIndex === discountsAllDay.length - 1) {
              // Condition if discount is maxed out
              return 'help';
            }
            return 'percent';
          }

          return '';
        };

        const getPerformance = () => {
          if (deal.takeRate >= 75) {
            return 'Above Average';
          }
          if (deal.takeRate <= 30) {
            return 'Below Average';
          }
          return 'Good';
        };

        const getDiscountPeriod = () => {
          if (!deal.lightning) {
            return 'All Day';
          }

          // if (startTime === "12:00 am" && endTime === "11:59 pm") {
          //   return "All Day";
          // }

          return `${startTime} - ${endTime}`;
        };

        const getSetting = () => {
          if (deal.takeawayOnly) {
            return 'Takeaway';
          }
          if (deal.dineInOnly) {
            return 'Dine In';
          }
          return '';
        };

        const getRedeemedLastWeek = () => {
          if (deal.recurring) {
            if (deal.redeemedLastWeek) {
              return deal.redeemedLastWeek;
            }
            return 0;
          }

          return '-';
        };

        const getNumberOfDeals = () => {
          const lastBookingDateWasSelectedDay = () =>
            moment.parseZone(deal.lastBookingDate).local().isSame(getSelectedDay(), 'day'); // TODO replace local with restaurant's timezone

          if (!deal.recurring || (selectedDateIsToday && lastBookingDateWasSelectedDay())) {
            return deal.qtyLeft;
          }

          return deal.recurringQty;
        };

        // The only way to update a column title is to change the entire table object when the data is updated
        setTable(tableData);

        const object = {
          actions: getActionType(),
          dealId: deal.objectId,
          discount: deal.discount,
          discountPeriod: getDiscountPeriod(),
          disabledForDate: deal.disabledForDate,
          performance: getPerformance(),
          lastBookingDate: deal.lastBookingDate,
          numberOfDeals: getNumberOfDeals(),
          recurring: deal.recurring,
          redeemedLastWeek: getRedeemedLastWeek(),
          status: deal.status,
          startTime: deal.startTime,
          endTime: deal.endTime,
          takeRatePercent: deal.recurring ? formatPercent(deal.takeRate) : '-',
          origin: deal.origin,
          lightning: deal.lightning,
          setting: getSetting(),
        };

        return object;
      });

    setTableDeals(dealsArray);
  }, [dealsByDay]);

  const getMinBodyHeight = useCallback(() => {
    if (tableDeals.length === 0) {
      return '145px';
    }

    if (tableDeals.length < 3) {
      return `calc(${tableDeals.length * 92}px + 93px)`;
    }

    return '300px';
  }, [tableDeals.length]);

  return (
    <div className={classes.tableStyles}>
      <MaterialTable
        title={<Typography variant='subtitle1'>Take Rate</Typography>}
        columns={table.columns}
        isLoading={dealSaving || dealsByDay.fetching}
        data={tableDeals}
        options={{
          emptyRowsWhenPaging: false,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          // padding: "dense",
          search: false,
          actionsColumnIndex: 7,
          // header: false,
          // toolbar: false
          draggable: false,
          sorting: false,
          minBodyHeight: getMinBodyHeight(),
          maxBodyHeight: 'calc( 100vh - 220px)',
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No deals to display',
          },
        }}
      />
    </div>
  );
};

DealsTable.propTypes = {
  dates: PropTypes.shape({}).isRequired,
  dealsByDay: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    dataBookings: PropTypes.arrayOf(PropTypes.shape({})),
    fetching: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    shouldFetch: PropTypes.bool,
    selectedDay: PropTypes.number,
  }).isRequired,
  dealSaving: PropTypes.bool.isRequired,
  todayByTimezone: PropTypes.number.isRequired,
  selectedDay: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  dates: state.dates,
  dealsByDay: state.dealsByDay,
  dealSaving: state.dealIncrease.saving,
  todayByTimezone: state.restaurant.todayByTimezone,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DealsTable);
