import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
  },
  cardTitle: {
    // color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  hr: {
    marginBottom: theme.spacing(2),
    // borderColor: grey[100],
    opacity: 0.3,
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
