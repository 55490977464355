/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Box,
  Collapse,
  CircularProgress,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import Error from '../../error/Error';
import { getTodayHours, minutesToMoment } from '../../../utils';
import useStyles from '../createDeal/CreateDealsStyles';
import DealDiscount from '../createDeal/DealDiscount';
import DealQuantity from '../createDeal/DealQuantity';
import DealTime from '../createDeal/DealTime';
import DealValidFor from '../createDeal/DealValidFor';
import DealDays from '../createDeal/DealDays';
import DealFrequency from '../createDeal/DealFrequency';
import DealType from '../createDeal/DealType';
import DealDate from '../createDeal/DealDate';
import { updateDealAction } from '../../../actions/dealUpdateAction';
import { discountsAllDay, discountsTimeLimited } from '../../../constants';

/**
 * @deprecated
 * Use EditOfferForm instead
 */
const EditDealForm = ({
  deal,
  dealUpdate,
  updateDealAction,
  restId,
  userId,
  userType,
  restaurantHours,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const getInitialFrequency = (recurring) => (recurring ? 'recurring' : 'oneTime');
  const initialFrequency = getInitialFrequency(deal.data.recurring);

  const getInitialType = (lightning) => {
    if (lightning) {
      return 'timeLimited';
    }
    return 'allDay';
  };
  const initialType = getInitialType(deal.data.lightning);

  const getInitialDiscount = (discount) => {
    // NOTE: some deals may return old discounts, new minimum is either 10% or 30%;

    if (initialType !== 'timeLimited') {
      if (discountsAllDay.indexOf(discount) === -1) {
        return discountsAllDay[0];
      }
    } else if (discountsTimeLimited.indexOf(discount) === -1) {
      return discountsTimeLimited[0];
    }
    return discount;
  };

  const getInitialDate = (date) => moment.utc(date, 'YYYY-MM-DD');

  const getRestaurantTime = (isEnd = false) => {
    const startDay = getInitialDate('startDate').format('ddd').toLowerCase();
    return minutesToMoment(
      getTodayHours(JSON.parse(restaurantHours), startDay)[isEnd ? 'end' : 'start'],
    );
  };

  const getInitialTime = (minutes, isEnd) => {
    if (initialType === 'allDay') {
      return getRestaurantTime(isEnd);
    }

    const time = moment().startOf('day').add(minutes, 'minutes');
    return time;
  };

  const getInitialDay = (dayInt) => [moment.weekdaysShort(dayInt).toLowerCase()];

  const [editDeal, setEditDeal] = useState({
    frequency: {
      index: 1,
      name: 'frequency',
      enabled: true,
      component: DealFrequency,
      value: initialFrequency,
      mode: 'create',
      // errors: { frequency: "" },
    },
    type: {
      index: 2,
      name: 'type',
      component: DealType,
      enabled: true,
      value: initialType,
      mode: 'create',
      // errors: { type: "" },
    },
    discount: {
      index: 3,
      name: 'discount',
      enabled: true,
      component: DealDiscount,
      value: getInitialDiscount(deal.data.discount),
      mode: 'edit',
      // errors: { discount: "" },
    },
    quantity: {
      index: 4,
      name: 'quantity',
      component: DealQuantity,
      enabled: true,
      value: deal.data.recurring ? deal.data.recurringQty : deal.data.qtyLeft,
      mode: 'edit',
      // errors: { quantity: "" },
    },
    startDate: {
      index: 5,
      name: 'startDate',
      component: DealDate,
      enabled: true,
      value: getInitialDate(deal.data.startDate),
      mode: 'create',
      errors: { start: '', end: '' },
    },
    time: {
      index: 6,
      name: 'time',
      component: DealTime,
      enabled: initialType === 'timeLimited',
      times: {
        start: getInitialTime(deal.data.startTime),
        end: getInitialTime(deal.data.endTime),
      },
      defaultTimes: {
        start: getRestaurantTime(),
        end: getRestaurantTime(true),
      },
      mode: 'edit',
      errors: { start: '', end: '' },
    },
    validFor: {
      index: 7,
      name: 'validFor',
      component: DealValidFor,
      enabled: true,
      checks: {
        dineIn: deal.data.dineInOnly ? true : !deal.data.takeawayOnly && !deal.data.dineInOnly,
        takeAway: deal.data.takeawayOnly ? true : !deal.data.takeawayOnly && !deal.data.dineInOnly,
      },
      mode: 'create',
      // errors: { validFor: "" },
    },
    days: {
      index: 8,
      name: 'days',
      component: DealDays,
      enabled: initialFrequency === 'recurring',
      values: getInitialDay(deal.data.dayOfWeek),
      mode: 'create',
      errors: { days: '' },
    },
  });

  const isStepValid = (step) => {
    const errors = [];

    if (!step.enabled) {
      return false;
    }

    // check errors
    if (step.errors) {
      errors.push(!Object.values(step.errors).some((el) => el !== ''));
    }

    // Test object
    if (step.checks) {
      errors.push(Object.values(step.checks).some((el) => el !== false));
    }

    // Test times
    if (step.times) {
      errors.push(!Object.values(step.times).some((el) => el === null));
    }

    // Test array
    if (step.values) {
      errors.push(step.values.length > 0);
    }

    // Test string
    errors.push(!(step.value === '' || step.value === null));

    return !errors.some((el) => el === false);
  };

  const areStepsInvalid = () => {
    const check = Object.values(editDeal).filter((step) => step.enabled && !isStepValid(step));

    return check.length > 0;
  };

  const getStepIndex = (stepName) => {
    const enabledSteps = Object.values(editDeal).filter((step) => step.enabled);
    return enabledSteps.findIndex((enabledStep) => enabledStep.name === stepName) + 1;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      time: {
        times: { start: startTime, end: endTime },
      },
    } = editDeal;

    const getTimeInMinutes = (type, startTime, endTime) => {
      if (!startTime || !endTime) {
        return 0;
      }

      const startTimeAtMidnight = startTime.clone().startOf('day');
      const startTimeInMinutes = startTime.diff(startTimeAtMidnight, 'minutes');

      if (type === 'startTime') {
        return startTimeInMinutes;
      }

      const endTimeInMinutes = endTime.diff(startTimeAtMidnight, 'minutes');
      return endTimeInMinutes;
    };

    const object = {
      dealId: deal.data.objectId,
      discount: editDeal.discount.value,
      qty: editDeal.quantity.value,
      startTime: getTimeInMinutes('startTime', startTime, endTime),
      endTime: getTimeInMinutes('endTime', startTime, endTime),
    };

    updateDealAction(object, restId, userId, userType);
  };

  // Redirect back to deals page upon action success
  useEffect(() => {
    if (!dealUpdate.success) {
      return;
    }
    history.push('/deals');
  });

  return (
    <Card style={{ paddingTop: '1rem' }}>
      <CardContent>
        <form onSubmit={handleSubmit} id='createDealForm'>
          {Object.values(editDeal)
            .sort((a, b) => a.index - b.index) // NOTE: explicitly sort to prevent unexpected safari iOS13.3 sort order
            .map((step, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Collapse key={index} in={Boolean(step.enabled)} mountOnEnter unmountOnExit>
                {index !== 0 && <hr style={{ margin: '30px 0', opacity: 0.2 }} />}
                <Grid container>
                  <Grid item>
                    <Box
                      className={`${classes.indexLabel} ${step.mode === 'create' ? 'disabled' : ''}
                    ${isStepValid(step) && 'hasEdit'}`}
                    >
                      {getStepIndex(step.name)}
                    </Box>
                  </Grid>
                  <Grid item md='auto'>
                    <step.component newDeal={editDeal} setNewDeal={setEditDeal} mode={step.mode} />
                  </Grid>
                </Grid>
              </Collapse>
            ))}
        </form>
      </CardContent>
      {dealUpdate.error && <Error error={dealUpdate.error} message={dealUpdate.errorMessage} />}
      <CardActions>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          form='createDealForm'
          style={{ width: '126px' }}
          disabled={areStepsInvalid()}
        >
          {dealUpdate.saving ? <CircularProgress color='inherit' size={23} /> : 'Save Deal'}
        </Button>

        <Button variant='text' component={Link} to='/deals'>
          Cancel
        </Button>
      </CardActions>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  deal: state.deal,
  dealUpdate: state.dealUpdate,
  restId: state.restaurantActive.restaurant.objectId,
  restaurantHours: state.restaurantActive.restaurant.hours,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateDealAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditDealForm);
