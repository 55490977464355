import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDailyOpposAction } from '../../actions/dealDailyOpposAction';
import DailyOpposDialog from '../deals/dailyOppos/DailyOpposDialog';

const DailyOppoPopup = ({
  activeRestaurant,
  dailyOppos,
  fetchDailyOppos,
  restId,
  userId,
  userType,
  goalsEnabled,
}) => {
  const { data } = dailyOppos;
  const lastShown = localStorage.getItem('last_shown_daily_oppos');
  const shownToday = moment(lastShown).isSame(moment(), 'day'); // Assumes user only travels forward in time

  const [open, setOpen] = useState(
    !shownToday && activeRestaurant.activeRestaurant.showOppos && !goalsEnabled,
  );

  // Fetch oppos if we want to show the popup
  useEffect(() => {
    if (open) {
      fetchDailyOppos(restId, userId, userType);
    }
  }, []);

  // Don't show if no oppos
  if (data.length === 0) {
    return null;
  }

  const handleCloseDialog = () => {
    localStorage.setItem('last_shown_daily_oppos', Date());
    setOpen(false);
  };

  return <DailyOpposDialog open={open} handleCloseDialog={handleCloseDialog} />;
};

DailyOppoPopup.propTypes = {
  activeRestaurant: PropTypes.shape({
    activeRestaurant: PropTypes.shape({
      showOppos: PropTypes.bool,
    }),
  }).isRequired,
  dailyOppos: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.string,
    pending: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  fetchDailyOppos: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  goalsEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  dailyOppos: state.dealDailyOppos,
  restId: state.restaurantActive.restaurant.objectId,
  goalsEnabled: state.restaurantActive.restaurant.offersEnabled,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDailyOppos: fetchDailyOpposAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DailyOppoPopup);
