import React from 'react';
import { Redirect } from 'react-router-dom';

// Routes
import {
  RunSheet,
  Settings,
  Floorplan,
  Timeline,
  EditFloorplan,
  Integrations,
  Guestbook,
  MarketPlaceIntegrations,
  Calendar,
} from '@eatclub-apps/ec-bookings-library';
import Analytics from '../components/analytics/Analytics';
import Logout from '../components/auth/Logout';
import Billing from '../components/billing/Billing';
import Bookings from '../components/bookings/Bookings';
import CreateDeal from '../components/deals/createDeal/CreateDeal';
import Deals from '../components/deals/Deals';
import EditDeal from '../components/deals/editDeal/EditDeal';
import EditOffer from '../components/deals/editOffer/EditOffer';
import BookingsLanding from '../components/liveBookings/BookingsLanding/BookingsLanding';
import BookingsLandingPageTitle from '../components/liveBookings/BookingsLanding/BookingsLandingPageTitle';
import FloorplanLockdown from '../components/liveBookings/Lockdown/FloorplanLockdown';
import GuestbookLockdown from '../components/liveBookings/Lockdown/GuestbookLockdown';
import TimelineLockdown from '../components/liveBookings/Lockdown/TimelineLockdown';
import Menus from '../components/menus/Menus';
import NotFound from '../components/notFound/NotFound';
import AllOffers from '../components/offers/AllOffers/AllOffers';
import CreateGoal from '../components/offers/CreateGoal/CreateGoal';
import EditGoal from '../components/offers/EditGoal/EditGoal';
import ViewGoal from '../components/offers/ViewGoal/ViewGoal';
import Offers from '../components/offers/OffersDashboard/Offers';
import PreviousGoalsScreen from '../components/offers/PreviousGoalsScreen';
import Support from '../components/support/Support';
import Profile from '../components/profile/Profile';
import Settlements from '../components/settlements/Settlements';

const routes = [
  {
    path: ['/', '/index.html'],
    exact: true,
    pageTitle: '',
    blockWhiteLabel: false,
    main: () => <Redirect to='/analytics' />,
  },
  {
    path: '/analytics',
    pageTitle: 'Analytics',
    exact: true,
    blockWhiteLabel: false,
    main: () => <Analytics />,
  },
  {
    path: '/deals',
    pageTitle: 'Deals',
    exact: true,
    blockWhiteLabel: true,
    main: () => <Deals />,
  },
  {
    path: '/deals/create',
    pageTitle: 'Create Deal',
    exact: true,
    blockWhiteLabel: true,
    backNavigation: {
      label: 'Back',
      route: '/offers',
    },
    main: () => <CreateDeal />,
  },
  {
    path: '/deals/edit/:dealId',
    pageTitle: 'Edit Deal',
    exact: false,
    blockWhiteLabel: false,
    main: () => <EditDeal />,
  },
  {
    path: '/offers',
    pageTitle: 'Create demand, ambience & fill tables',
    exact: true,
    blockWhiteLabel: true,
    action: {
      label: 'Create new offer',
      route: '/deals/create',
    },
    main: () => <Offers />,
  },
  {
    path: '/offers/active',
    // pageTitle: 'All offers',
    exact: true,
    blockWhiteLabel: true,
    backNavigation: {
      label: 'Back',
      route: '/offers',
    },
    main: () => <AllOffers />,
  },
  {
    path: '/offers/edit/:dealId',
    pageTitle: 'Edit Offer',
    exact: false,
    blockWhiteLabel: false,
    backNavigation: {
      label: 'Back',
      route: '/offers',
    },
    main: () => <EditOffer />,
  },
  {
    path: '/goals/previous',
    pageTitle: 'Previous Goals',
    exact: true,
    blockWhiteLabel: false,
    backNavigation: {
      label: 'Back to offers',
      route: '/offers',
    },
    main: () => <PreviousGoalsScreen />,
  },
  {
    path: '/goals/create',
    pageTitle: '',
    exact: true,
    blockWhiteLabel: false,
    main: () => <CreateGoal />,
  },
  {
    path: '/goals/edit/:goalId',
    pageTitle: 'Edit Goal',
    exact: false,
    blockWhiteLabel: false,
    backNavigation: {
      label: 'Back',
      route: '/offers',
    },
    main: () => <EditGoal />,
  },
  {
    path: '/goals/:goalId',
    pageTitle: '',
    exact: false,
    blockWhiteLabel: false,
    backNavigation: {
      label: 'Back',
      route: '/offers',
    },
    main: () => <ViewGoal />,
  },
  {
    path: '/transactions',
    pageTitle: 'Transactions',
    exact: false,
    blockWhiteLabel: false,
    main: () => <Bookings />,
  },
  {
    path: '/settlements',
    pageTitle: 'Settlements',
    exact: false,
    blockWhiteLabel: false,
    ecPayEnabledRequired: true,
    action: {
      label: 'Historical statements',
      route: '/billing',
    },
    main: () => <Settlements />,
  },
  {
    path: '/billing',
    pageTitle: 'Billing & Invoices',
    exact: false,
    blockWhiteLabel: false,
    main: () => <Billing />,
  },
  {
    path: '/support',
    pageTitle: 'Support',
    exact: false,
    blockWhiteLabel: false,
    main: () => <Support />,
  },
  {
    path: '/menus',
    pageTitle: 'Menus',
    exact: true,
    blockWhiteLabel: false,
    main: () => <Menus />,
  },
  {
    path: '/livebookings',
    pageTitle: 'EatClub Bookings',
    exact: true,
    blockWhiteLabel: false,
    customPageTitle: () => <BookingsLandingPageTitle />,
    main: () => <BookingsLanding />,
  },
  {
    path: '/livebookings/runsheet',
    pageTitle: 'Run sheet',
    id: 'runsheet',
    exact: true,
    blockWhiteLabel: false,
    header: 'obee',
    obeeEnabledRequired: true,
    main: () => <RunSheet />,
  },
  {
    path: '/livebookings/monthly',
    pageTitle: 'Monthly',
    id: 'monthly',
    exact: true,
    blockWhiteLabel: false,
    header: 'obee',
    obeeEnabledRequired: true,
    main: () => <Calendar />,
  },
  {
    path: '/livebookings/floorplan',
    pageTitle: 'Floor Plan',
    id: 'floorplan',
    exact: true,
    blockWhiteLabel: false,
    header: 'obee',
    obeeEnabledRequired: true,
    premiumRequired: () => <FloorplanLockdown />, // show this if not on premium
    main: () => <Floorplan />,
  },
  {
    path: '/livebookings/floorplan/edit',
    pageTitle: 'Edit Floor Plan',
    id: 'editFloorplan',
    exact: true,
    blockWhiteLabel: false,
    header: 'custom',
    hideNavigation: true,
    forceHideNavigation: true, // No option to show on this screen
    obeeEnabledRequired: true,
    main: () => <EditFloorplan />,
  },
  {
    path: '/livebookings/timeline',
    pageTitle: 'Timeline',
    id: 'timeline',
    exact: true,
    blockWhiteLabel: false,
    header: 'obee',
    obeeEnabledRequired: true,
    premiumRequired: () => <TimelineLockdown />, // show this if not on premium
    main: () => <Timeline />,
  },
  {
    path: '/livebookings/guestbook',
    pageTitle: 'Guest book',
    id: 'guestbook',
    exact: true,
    blockWhiteLabel: false,
    header: 'obee',
    obeeEnabledRequired: true,
    premiumRequired: () => <GuestbookLockdown />, // show this if not on premium
    main: () => <Guestbook />,
  },
  // {
  //   path: '/livebookings/calendar',
  //   pageTitle: 'Calendar',
  //   exact: true,
  //   blockWhiteLabel: false,
  //   header: 'obee',
  //   main: () => <Calendar />,
  // },
  {
    path: '/livebookings/settings',
    pageTitle: 'Settings',
    exact: true,
    blockWhiteLabel: false,
    header: 'custom',
    hideNavigation: true,
    obeeEnabledRequired: true,
    forceHideNavigation: true,
    main: () => <Settings />,
  },
  {
    path: '/livebookings/integrations/fbmarketplace',
    pageTitle: 'Bookings integrations',
    exact: true,
    blockWhiteLabel: false,
    header: 'custom',
    hideNavigation: true,
    obeeEnabledRequired: true,
    forceHideNavigation: true,
    main: () => <MarketPlaceIntegrations />,
  },
  {
    path: '/livebookings/integrations',
    pageTitle: 'Bookings integrations',
    exact: true,
    blockWhiteLabel: false,
    header: 'custom',
    hideNavigation: true,
    obeeEnabledRequired: true,
    forceHideNavigation: true,
    main: () => <Integrations />,
  },
  {
    path: '/logout',
    pageTitle: 'Logout',
    blockWhiteLabel: false,
    header: 'custom',
    main: () => <Logout />,
  },
  {
    path: '/profile',
    pageTitle: 'Profile',
    blockWhiteLabel: true,
    main: () => <Profile />,
  },
  {
    path: '*',
    pageTitle: 'Not Found',
    exact: false,
    blockWhiteLabel: false,
    main: () => <NotFound />,
  },
];

export default routes;
