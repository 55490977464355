import * as type from '../actions/types';

const initialState = {
  data: [],
  fetching: false,
  error: false,
  errorMessage: '',
  success: false,
  shouldFetch: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_PAYOUTS_WHITELABEL_PENDING: {
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    }
    case type.FETCH_PAYOUTS_WHITELABEL_SUCCESS: {
      const { payouts } = action.payload;

      const payoutsByDate = Array.from(payouts).sort(
        (a, b) => new Date(b.payoutDate) - new Date(a.payoutDate),
      );

      return {
        ...state,
        data: payoutsByDate,
        fetching: false,
        success: true,
        shouldFetch: false,
      };
    }
    case type.FETCH_PAYOUTS_WHITELABEL_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }
    case type.CLEAR_PAYOUTS_WHITELABEL: {
      return {
        ...state,
        fetching: false,
        error: false,
        errorMessage: '',
        success: false,
      };
    }

    // if began changing to new restaurant
    case type.SET_ACTIVE_RESTAURANT_ID:
      return {
        ...state,
        fetching: true,
      };

    // if new restaurant is now active
    case type.SET_ACTIVE_RESTAURANT:
      return {
        ...state,
        success: false,
        shouldFetch: true,
      };

    default: {
      return state;
    }
  }
};
