import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

const OrderDetails = ({ order }) => {
  const {
    name,
    platform,
    email,
    phone,
    address,
    pickupTime,
    pickupDate,
    refundType,
    refundAmount,
    refundReason,
  } = order;

  return (
    <List disablePadding style={{ marginBottom: '.5rem' }}>
      <ListItem dense>
        <Typography variant='subtitle2' color='textPrimary'>
          Name
        </Typography>
        <ListItemText style={{ textAlign: 'right' }}>{name}</ListItemText>
      </ListItem>

      {platform === 'whitelabel' && (
        <>
          {email && (
            <ListItem dense>
              <Typography variant='subtitle2' color='textPrimary'>
                Email
              </Typography>
              <ListItemText style={{ textAlign: 'right' }}>{email} </ListItemText>
            </ListItem>
          )}

          {phone && (
            <ListItem dense>
              <Typography variant='subtitle2' color='textPrimary'>
                Phone
              </Typography>
              <ListItemText style={{ textAlign: 'right' }}>{phone} </ListItemText>
            </ListItem>
          )}

          {address && (
            <ListItem dense>
              <Typography variant='subtitle2' color='textPrimary'>
                Address
              </Typography>
              <ListItemText style={{ textAlign: 'right' }}>{address}</ListItemText>
            </ListItem>
          )}
        </>
      )}

      {refundType && (
        <>
          <ListItem dense>
            <Typography variant='subtitle2' color='textPrimary'>
              {refundType === 'partial' ? 'Partial Refund' : 'Refund'}
            </Typography>

            <ListItemText style={{ textAlign: 'right' }}>${refundAmount}</ListItemText>
          </ListItem>

          <ListItem dense>
            <Typography variant='subtitle2' color='textPrimary'>
              Refund Reason
            </Typography>

            <ListItemText style={{ textAlign: 'right' }}>{refundReason}</ListItemText>
          </ListItem>
        </>
      )}
    </List>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.shape({
    pickupDate: PropTypes.string,
    pickupTime: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    platform: PropTypes.string,
    objectId: PropTypes.string,
    name: PropTypes.string,
    refundAmount: PropTypes.string,
    refundType: PropTypes.string,
    refundReason: PropTypes.string,
  }).isRequired,
};

export default OrderDetails;
