import { makeStyles } from '@mui/styles';
import { COLORS, FONT_SIZES, FONT_WEIGHTS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    gap: '70px',
    backgroundColor: COLORS.LINEN,
    borderRadius: '12px 12px 0 12px',
    // cursor: 'pointer',
    flexWrap: 'wrap',
    padding: '40px 30px 30px 30px',
  },
  ctaContainer: {
    display: 'flex',
    gap: '40px',
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 2,

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  previousGoalsButton: {
    fontSize: '16px',
    marginTop: 'auto',

    [theme.breakpoints.down('sm')]: {
      marginTop: '8px',
    },
  },
  leftBanner: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '250px',
  },
  heading: {
    ...theme.eatclub.typography.heading.H3,
  },
  rightBanner: {
    display: 'inline-flex',
    gap: '20px',
    ...theme.eatclub.typography.body.bodyText,
    lineHeight: '20px',
  },
  rightBannerInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '44px',
  },
  bannerPitch: {
    fontSize: '18px',
    lineHeight: '28px',
    maxWidth: '430px',
  },
  extraRevenue: {
    fontSize: '14px',
  },
  moneyPerMonthContainer: {
    display: 'flex',
    gap: '12px',
    marginTop: '8px',
    fontSize: FONT_SIZES.XXL,
  },
  blobAmountContainer: {
    position: 'relative',
    zIndex: 1,
  },
  blob: {
    left: '-10px',
    width: 'calc(100% + 20px)',
    height: '100%',
    position: 'absolute',
    color: COLORS.WHITE,
    zIndex: -1,
  },
  revenueAmountText: {
    fontWeight: FONT_WEIGHTS.BOLD,
    color: COLORS.OLIVE_TEXT,
  },
  getStartedButton: {
    cursor: 'pointer',
    display: 'flex',
    border: '1px solid #313131',
    borderRadius: '100px',
    gap: '12px',
    padding: '10px 20px',
    transition: '0.2s all',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  buttonText: {
    letterSpacing: '0.13em',
    fontSize: '12px',
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  cornerBlob: {
    bottom: '0',
    position: 'absolute',
    right: '0',
  },
  targetArrows: {
    bottom: '45px',
    height: '82px',
    position: 'absolute',
    right: '35px',
    transform: 'scale(2)',
    width: '100px',
  },
}));

export default useStyles;
