/* eslint-disable */
import React, { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Tooltip, Menu, MenuItem } from '@mui/material';
import OrderRefundFullDialog from './OrderRefundFullDialog';
import OrderRefundAmountDialog from './OrderRefundAmountDialog';
import OrderRefundItemsDialog from './OrderRefundItemsDialog';

const OrderOptions = ({ order }) => {
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const handleClickOpenMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = (dialog) => {
    setOpenDialog(dialog);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // NOTE: options also hold the dialog component to easily render
  const options = [
    {
      name: 'Refund Full Order',
      value: 'refundOrder',
      component: OrderRefundFullDialog,
    },
    {
      name: 'Refund Specific Items',
      value: 'refundItems',
      component: OrderRefundItemsDialog,
    },
    {
      name: 'Refund Amount',
      value: 'refundAmount',
      component: OrderRefundAmountDialog,
    },
  ];

  return (
    <>
      <Tooltip title='Options'>
        <IconButton size='large' onClick={handleClickOpenMenu}>
          <MoreVert />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorElMenu}
        keepMounted={false}
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClickOpenDialog(option.value);
              handleCloseMenu();
            }}
            value={option.value}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>

      {options.map((option, index) => (
        <option.component
          key={index}
          handleCloseDialog={handleCloseDialog}
          order={order}
          openDialog={openDialog === option.value}
        />
      ))}
    </>
  );
};

export default OrderOptions;
