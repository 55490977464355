import * as type from '../actions/types';

const initialState = {
  saving: false,
  data: [],
  error: false,
  errorMessage: '',
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FLAG_ORDER_PENDING:
      return {
        ...state,
        saving: true,
        error: false,
        errorMessage: '',
      };
    case type.FLAG_ORDER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        saving: false,
        success: true,
      };
    case type.FLAG_ORDER_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        saving: false,
      };

    case type.CLEAR_FLAG_ORDER:
      return {
        ...state,
        saving: false,
        data: [],
        error: false,
        errorMessage: '',
        success: false,
      };
    default:
      return state;
  }
};
