export const platformMap = {
  eatclub: {
    initial: 'EC',
    name: 'EatClub',
    value: 'eatclub',
  },
  whitelabel: {
    initial: 'WL',
    name: 'White Label',
    value: 'whitelabel',
  },
};
