export const discountsAllDay = [
  '10% Off',
  '15% Off',
  '20% Off',
  '25% Off',
  '30% Off',
  '35% Off',
  '40% Off',
  '45% Off',
  '50% Off',
];

export const discountsTimeLimited = [
  '20% Off',
  '25% Off',
  '30% Off',
  '35% Off',
  '40% Off',
  '45% Off',
  '50% Off',
];
