/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { blue, lightGreen } from '@mui/material/colors';
import Chart from 'chart.js';
import moment from 'moment';
import { alpha } from '@mui/material/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useStyles from './DealsTimesChartStyles';
import CreateDealTimeButton from './CreateDealTimeButton';

const DealsTimesChart = ({ dealsByDay, restaurantHours, selectedDay }) => {
  const [dealButtons, setDealButtons] = useState([]);
  const classes = useStyles();
  const chartRef = useRef(null);
  const [chartObj, setChartObj] = useState(null);

  const hoursByDay = JSON.parse(restaurantHours);
  const selectedDayName = moment.weekdaysShort(selectedDay).toLowerCase();
  const selectedDayHours = Object.keys(hoursByDay)
    .map((day, index) => (day.substr(0, 3) === selectedDayName ? index : null))
    .filter((e) => e !== null)
    .map((index) => Object.values(hoursByDay)[index]);

  const timePeriods = useMemo(
    () => [
      {
        name: '5am - 7am',
        start: moment('05:00 am', 'hh:mm a'),
        end: moment('07:00 am', 'hh:mm a'),
      },
      {
        name: '7am - 9am',
        start: moment('07:00 am', 'hh:mm a'),
        end: moment('09:00 am', 'hh:mm a'),
      },
      {
        name: '9am - 11am',
        start: moment('09:00 am', 'hh:mm a'),
        end: moment('11:00 am', 'hh:mm a'),
      },
      {
        name: '11am - 1pm',
        start: moment('11:00 am', 'hh:mm a'),
        end: moment('01:00 pm', 'hh:mm a'),
      },
      {
        name: '1pm - 3pm',
        start: moment('01:00 pm', 'hh:mm a'),
        end: moment('03:00 pm', 'hh:mm a'),
      },
      {
        name: '3pm - 5pm',
        start: moment('03:00 pm', 'hh:mm a'),
        end: moment('05:00 pm', 'hh:mm a'),
      },
      {
        name: '5pm - 7pm',
        start: moment('05:00 pm', 'hh:mm a'),
        end: moment('07:00 pm', 'hh:mm a'),
      },
      {
        name: '7pm - 9pm',
        start: moment('07:00 pm', 'hh:mm a'),
        end: moment('09:00 pm', 'hh:mm a'),
      },
      {
        name: '9pm - 11pm',
        start: moment('09:00 pm', 'hh:mm a'),
        end: moment('11:00 pm', 'hh:mm a'),
      },
      {
        name: '11pm - 12am',
        start: moment('11:00 pm', 'hh:mm a'),
        end: moment('12:00 am', 'hh:mm a'),
      },
    ],
    [],
  );

  const timeLabels = timePeriods.map((timePeriod) => timePeriod.name);

  let dealsByTime = dealsByDay.data.reduce((accumByTime, deal) => {
    const startTime = moment().startOf('day').utc().add(deal.startTime, 'minutes');
    const endTime = moment().startOf('day').utc().add(deal.endTime, 'minutes');

    const startHours =
      selectedDayHours[0] !== -1
        ? moment().startOf('day').utc().add(selectedDayHours[0], 'minutes')
        : null;
    const endHours =
      selectedDayHours[1] !== -1
        ? moment().startOf('day').utc().add(selectedDayHours[1], 'minutes')
        : null;

    timePeriods.forEach((period) => {
      const dealIndex = accumByTime.findIndex((accumDeal) => accumDeal.name === period.name);

      const isDuringOpeningHours =
        startHours && endHours
          ? period.start.isBetween(startHours, endHours, 'minutes', '[)') ||
            startHours.isBetween(period.start, period.end, 'minutes', '[)')
          : null;

      const isDuringDealHours =
        period.start.isBetween(startTime, endTime, 'minutes', '[)') ||
        startTime.isBetween(period.start, period.end, 'minutes', '[)');

      const dealQty = deal.recurring ? deal.recurringQty : deal.qtyLeft;

      if (dealIndex === -1) {
        let object = {};

        if (isDuringOpeningHours && isDuringDealHours) {
          // NEW PERIOD - start with 1
          object = {
            ...object,
            total: 1,
            redeemed: deal.redeemedLastWeek,
            quantity: dealQty,
            name: period.name,
          };
        } else {
          // NO PERIOD - count as 0
          object = {
            total: 0,
            redeemed: 0,
            quantity: 0,
            name: period.name,
          };
        }

        if (isDuringOpeningHours) {
          object = { ...object, duringHours: true };
        } else {
          object = { ...object, duringHours: false };
        }

        accumByTime.push(object);
      } else if (isDuringOpeningHours && isDuringDealHours) {
        accumByTime[dealIndex].total += 1;
        accumByTime[dealIndex].quantity += dealQty;
        accumByTime[dealIndex].redeemed += deal.redeemedLastWeek;
      }
    });

    return accumByTime;
  }, []);

  const reduceBookingsLastWeek = (accumByTime, booking) => {
    const bookingTime = moment(booking.arrivalTime, 'h:mm A');

    timePeriods.forEach((period) => {
      const bookingIndex = accumByTime.findIndex(
        (accumBooking) => accumBooking.name === period.name,
      );

      const isDuringDealHours = bookingTime.isBetween(period.start, period.end, 'minutes', '[)');

      if (bookingIndex === -1) {
        accumByTime.push({
          name: period.name,
          total: isDuringDealHours ? 1 : 0,
        });
      } else if (isDuringDealHours) {
        accumByTime[bookingIndex].total += 1;
      }
    });

    return accumByTime;
  };

  if (dealsByDay.data.length === 0) {
    dealsByTime = timePeriods.map((period) => {
      const startHours =
        selectedDayHours[0] !== -1
          ? moment().startOf('day').utc().add(selectedDayHours[0], 'minutes')
          : null;
      const endHours =
        selectedDayHours[1] !== -1
          ? moment().startOf('day').utc().add(selectedDayHours[1], 'minutes')
          : null;

      const isDuringOpeningHours =
        startHours && endHours
          ? period.start.isBetween(startHours, endHours, 'minutes', '[)') &&
            period.end.isBetween(startHours, endHours, 'minutes', '(]')
          : null;

      return {
        total: 0,
        redeemed: 0,
        quantity: 0,
        name: period.name,
        duringHours: isDuringOpeningHours,
      };
    });
  }

  const bookingsLastWeek = dealsByDay.dataBookings
    .reduce(reduceBookingsLastWeek, [])
    .map((booking) => booking.total);

  const quantityData = dealsByTime.map((deal) => deal.quantity);

  // initialize Chart
  useEffect(() => {
    const options = {
      hover: {
        intersect: false,
      },
      legend: {
        // display: false
      },
      scales: {
        yAxes: [
          {
            stacked: false,
            // gridLines: false,
            // display: false,
            ticks: {
              precision: 0,
              beginAtZero: true,
              padding: 15,
            },
          },
        ],
        xAxes: [
          {
            stacked: false,
            gridLines: false,
            ticks: {
              padding: 15,
            },
          },
        ],
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 8,
        },
      },
      tooltips: {
        caretSize: 0,
        xPadding: 10,
        yPadding: 10,
      },
    };

    const chart = new Chart(chartRef.current, {
      type: 'bar',
      data: {
        labels: timeLabels,
        datasets: [
          {
            label: 'Orders Last Week',
            data: [],
            backgroundColor: alpha(blue[400], 0.5),
            borderWidth: 1,
            fill: false,
          },
          {
            label: 'Quantity',
            data: [],
            backgroundColor: alpha(lightGreen[400], 0.5),
            borderWidth: 1,
            fill: false,
          },
        ],
      },
      options,
    });
    setChartObj(chart);

    return () => {
      chart.destroy();
    };
  }, []);

  // Update chart when data changes
  useEffect(() => {
    if (!chartObj) {
      return;
    }

    // only update if chartjs has been initialized
    if (!Object.prototype.hasOwnProperty.call(chartObj.data.datasets[0].data, '_chartjs')) {
      return;
    }

    // For each empty deal, get the x-axis's pixel coordinate, and label name.
    const dealsAtPixels = dealsByTime
      .map((deal, index) => {
        if (deal.total === 0 && deal.duringHours) {
          return {
            xAxis: chartObj.chart.scales['x-axis-0'].getPixelForValue(index),
            time: timeLabels[index],
          };
        }
        return false;
      })
      .filter((deal) => deal);

    if (JSON.stringify(dealButtons) !== JSON.stringify(dealsAtPixels)) {
      setDealButtons(dealsAtPixels);
    }

    if (
      JSON.stringify(bookingsLastWeek) === JSON.stringify(chartObj.data.datasets[0].data) &&
      JSON.stringify(quantityData) === JSON.stringify(chartObj.data.datasets[1].data)
    ) {
      return;
    }

    chartObj.data.datasets[0].data = bookingsLastWeek;
    chartObj.data.datasets[1].data = quantityData;

    chartObj.update();
  }, [bookingsLastWeek, chartObj, dealButtons, dealsByTime, quantityData, timeLabels]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div className={classes.chartHeight} style={{ position: 'relative' }}>
          <canvas
            id='dealsTimeChart'
            ref={chartRef}
            className={`${classes.chart} ${classes.chartHeight}`}
          />
        </div>
        {dealButtons.map((deal, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CreateDealTimeButton key={index} deal={deal} timePeriods={timePeriods} />
        ))}
      </div>
    </>
  );
};

DealsTimesChart.propTypes = {
  dealsByDay: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    dataBookings: PropTypes.arrayOf(PropTypes.shape({})),
    fetching: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    shouldFetch: PropTypes.bool,
    selectedDay: PropTypes.number,
  }).isRequired,
  restaurantHours: PropTypes.string.isRequired,
  selectedDay: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  dealsByDay: state.dealsByDay,
  restaurantHours: state.restaurantActive.restaurant.hours,
  selectedDay: state.dealsByDay.selectedDay,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DealsTimesChart);
