import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
// import { useAnalytics } from 'use-analytics';
import { fetchActiveOffersForRestaurantAction } from '../../../actions/activeOffersForRestaurantAction';
import { fetchDailyOpposAction } from '../../../actions/dealDailyOpposAction';
import { fetchGoalsAction } from '../../../actions/goalsAction';
import ActionArrow from '../../../assets/action_arrow.svg';
import { dealsPropTypes } from '../../../data/models/Deal';
import { goalsPropTypes } from '../../../data/models/Goal';
import { opportunitiesPropTypes } from '../../../reducers/dealDailyOpposReducer';
import { offersPropTypes } from '../../../reducers/generatedOffersReducer';
import { trackEvent, usePageViews } from '../../../utils/analytics';
import { eatClubDayOfWeekToMoment, getDayOccurrences, getDayOfWeek } from '../../../utils/helpers';
import GoalAnalytics from '../GoalAnalytics/GoalAnalytics';
import OfferCard from '../OfferCard/OfferCard';
import PostOppoModal from '../Modals/PostOppoModal';
import TopOpportunities from './TopOpportunities';
import RevenueBanner from '../RevenueBanner/RevenueBanner';
import useStyles from './OffersStyles';
import { ConditionalWrapper } from '../../ConditionalWrapper';

const Offers = ({
  fetchGoals,
  restId,
  goals,
  fetchDailyOppos,
  fetchActiveOffersForRestaurant,
  userId,
  userType,
  dailyOppos,
  deals,
  activeOffersForRestaurant,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [topOppos, setTopOppos] = useState([]);
  const [repeatDays, setRepeatDays] = useState({}); // How many times each day repeats
  usePageViews();
  // const { track } = useAnalytics();

  // Fetch goals
  useEffect(() => {
    fetchGoals(restId, userId, userType);
  }, [fetchGoals, restId, userId, userType]);

  // Fetch oppos
  useEffect(() => {
    fetchDailyOppos(restId, userId, userType);
  }, [fetchDailyOppos, restId, userId, userType]);

  // Fetch active offers
  useEffect(() => {
    fetchActiveOffersForRestaurant(restId, userId, userType);
  }, [fetchActiveOffersForRestaurant, restId, userId, userType]);

  // Store daily oppos in state, so they can be marked as complete
  useEffect(() => {
    setTopOppos(dailyOppos?.data);
  }, [dailyOppos]);

  // Filter fetched goals to active
  const activeGoal = goals.data.find((goal) => goal.status === 'active');

  const currentDayOfWeek = getDayOfWeek(moment());

  const currentlyActiveOffers = activeOffersForRestaurant.data
    .filter((offer) => offer?.status === 'active')
    .sort((a, b) => (a?.startTime >= b?.startTime ? 1 : -1));

  const offersForDay = activeOffersForRestaurant.data
    .filter(
      (offer) =>
        offer?.status !== 'active' &&
        eatClubDayOfWeekToMoment(offer?.dayOfWeek) === currentDayOfWeek,
    )
    .sort((a, b) => (a?.startTime >= b?.startTime ? 1 : -1));

  const activeOffers = [...currentlyActiveOffers, ...offersForDay];

  // Recalculate the amount of times a deal appears
  useEffect(() => {
    setRepeatDays(getDayOccurrences(moment(), activeGoal?.endDate));
  }, [activeGoal]);

  const isLoading = goals.pending || activeOffersForRestaurant.fetching; // note: to make sure all elements 'load' at the same time

  return (
    <Box className={classes.container}>
      <Box className={classes.loadingContainer}>
        <ConditionalWrapper
          condition={isLoading}
          // eslint-disable-next-line react/no-unstable-nested-components
          wrapper={(children) => (
            <Skeleton variant='rounded' width='100%'>
              {children}
            </Skeleton>
          )}
        >
          {activeGoal ? <GoalAnalytics goal={activeGoal} /> : <RevenueBanner />}
        </ConditionalWrapper>
      </Box>

      <Box>
        <Box style={{ paddingBottom: '32px' }}>
          <Box className={classes.heading}>Today&apos;s offers</Box>
        </Box>

        {isLoading ? (
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Skeleton variant='rounded' height={85} />
            <Skeleton variant='rounded' height={85} />
            <Skeleton variant='rounded' height={85} />
          </Box>
        ) : (
          <Box className={classes.offersList}>
            {activeOffers?.length === 0 ? (
              <Box ml='30px'>There are no active offers right now</Box>
            ) : (
              activeOffers.map((deal) => (
                <OfferCard
                  key={deal?.objectId}
                  offer={deal}
                  occurrences={repeatDays?.[deal?.dayOfWeek]}
                />
              ))
            )}

            {activeOffersForRestaurant?.data.length > 0 && (
              <Box
                className={classes.viewAllOffers}
                onClick={() => {
                  trackEvent(`button_click: view_all_offers`);
                  history.push('/offers/active');
                }}
              >
                View all offers
                <Box className={classes.offerAmount}>{activeOffersForRestaurant.data.length}</Box>
                <ActionArrow style={{ marginLeft: 'auto', transform: 'scale(1.25)' }} />
              </Box>
            )}
          </Box>
        )}
      </Box>

      {topOppos?.length > 0 && (
        <Box>
          <TopOpportunities opportunities={topOppos} />
        </Box>
      )}

      <PostOppoModal />
    </Box>
  );
};

Offers.propTypes = {
  fetchGoals: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  goals: goalsPropTypes.isRequired,
  fetchDailyOppos: PropTypes.func.isRequired,
  fetchActiveOffersForRestaurant: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  dailyOppos: opportunitiesPropTypes.isRequired,
  deals: dealsPropTypes.isRequired,
  activeOffersForRestaurant: offersPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  goals: state.goals,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
  dailyOppos: state.dealDailyOppos,
  postDailyOppo: state.postDailyOppo,
  deals: state.deals,
  activeOffersForRestaurant: state.activeOffersForRestaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchGoals: fetchGoalsAction,
      fetchDailyOppos: fetchDailyOpposAction,
      fetchActiveOffersForRestaurant: fetchActiveOffersForRestaurantAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
