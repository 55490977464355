import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useStyles from './CustomHeaderStyles';

/**
 * A wrapper for pages that use a custom header (e.g. header is not reused on other screens)
 * This allows for the page to reposition based on the side menu even if the page is custom.
 */
const CustomHeader = ({ children, navigationOpen }) => {
  const classes = useStyles({ navigationOpen });

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.containerInner}>
          <Box className={classes.pageContent}>{children}</Box>
        </Box>
      </Box>
    </>
  );
};

CustomHeader.defaultProps = {
  navigationOpen: false,
};

CustomHeader.propTypes = {
  children: PropTypes.node.isRequired,
  navigationOpen: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomHeader);
