import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { toggleMenuItemOptionStock } from '../graphql/mutations';
import { devLog } from '../utils';

export const toggleMenuChoiceStockAction =
  (restId, optionId, choiceTitle, inStock, userId, userType) => (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: `TOGGLE_MENU_CHOICE_STOCK_${optionId}`,
    });

    dispatch({
      type: type.TOGGLE_MENU_CHOICE_STOCK_PENDING,
    });

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: toggleMenuItemOptionStock,
            variables: {
              restId,
              menuItemOptionId: optionId,
              choiceTitle,
              inStock,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        devLog('success', 'menu choices toggle stock', response.data.toggleMenuItemOptionStock);

        dispatch({
          type: type.TOGGLE_MENU_CHOICE_STOCK_SUCCESS,
          payload: { updatedOption: response.data.toggleMenuItemOptionStock },
        });
      } catch (error) {
        devLog('error', 'menu choices toggle stock', error);

        dispatch({
          type: type.TOGGLE_MENU_CHOICE_STOCK_FAILURE,
          payload: `Unable to toggle menu item stock: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: `TOGGLE_MENU_CHOICE_STOCK_${optionId}`,
        });
      }
    })();
  };

export const clearToggleMenuItemStockAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_TOGGLE_MENU_CHOICE_STOCK,
  });
};
