import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  actionsLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    '& > .MuiButton-root:not(:last-child)': {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(1),
        marginBottom: 0,
      },
    },
  },
  actionsRight: {
    '&:empty': {
      display: 'none',
    },
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  hr: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
      opacity: 0.3,
      display: 'block',
    },
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
