import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Skeleton, Divider, List, ListItem, ListItemText, Box, Collapse } from '@mui/material';
import { getVersion as getBookingsVersion } from '@eatclub-apps/ec-bookings-library';
import { restaurantActivePropTypes } from '../../../data/models/Restaurant';
import { findObjectByProperty, getVersionText } from '../../../utils/helpers';
import useStyles from './NavigationDrawerStyles';
import DownArrowSVG from '../../../assets/down_caret.svg';
import HideNavigationSVG from '../../../assets/hide_navigation.svg';
import ExclamationCircleSVG from '../../../assets/exclamation_circle.svg';
import RestaurantSwitcher from './RestaurantSwitcher/RestaurantSwitcher';
import { COLORS } from '../../../EatClubTheme';
import { userPropTypes } from '../../../data/models/User';

const NavigationDrawer = ({
  hideDrawer,
  navigationItems,
  allowHideNavigation,
  user,
  restaurantFetching,
  restaurantActive,
}) => {
  const classes = useStyles();
  const location = useLocation();

  const [collapsibles, setCollapsibles] = useState([]);

  // Check whether a nav item is selected (we're on that page)
  const isSelected = (slug) => {
    return location?.pathname === slug;
  };

  // Set up the navigation collapsibles
  useEffect(() => {
    const navsWithSubItems = navigationItems.filter((nav) => nav?.subItems?.length > 0);
    const navigationCollapsibles = navsWithSubItems?.map((nav) => {
      // Also, if we're on the selected screen, open the collapsible
      const hasSelectedNav =
        nav?.subItems?.filter((subItem) => {
          return isSelected(`/${subItem?.slug?.toLowerCase().replace(/ /g, '-')}`);
        }).length > 0;

      return {
        id: nav?.name,
        value: hasSelectedNav, // Whether it is open
      };
    });

    setCollapsibles(navigationCollapsibles);
  }, [navigationItems]);

  /**
   * Toggle a collapsible nav item on or off
   */
  const toggleCollapsible = (name) => {
    const newCollapsibles = collapsibles?.map((collapsible) => {
      if (collapsible?.id === name) {
        return { ...collapsible, value: !collapsible?.value };
      }

      return collapsible;
    });

    setCollapsibles(newCollapsibles);
  };

  const getNavLink = (slug) => `/${slug.toLowerCase().replace(/ /g, '-')}`;

  const loadingItem = (key) => (
    <Skeleton height={48} width={214} style={{ margin: 'auto' }} key={key} />
  );

  const isStaff = user.userInfo?.userType === 'staff';

  return (
    <Box className={classes.sidebar}>
      <Box className={classes.sidebarNavigation}>
        <Box>
          <Box style={{ marginTop: 'auto' }}>
            <Box style={{ marginLeft: '24px' }}>
              <RestaurantSwitcher />
            </Box>
          </Box>
          {isStaff && (
            <>
              <Box style={{ margin: '0 24px', paddingBottom: '10px' }}>
                <Box className={classes.staffAlert}>
                  <ExclamationCircleSVG style={{ flexShrink: 0 }} height={15} />
                  <Box style={{ fontSize: '15px' }}>{`Logged in as ${user.userInfo.name}`}</Box>
                </Box>
              </Box>

              {restaurantActive?.restaurant?.objectId && !restaurantActive?.restaurant?.enabled && (
                <Box style={{ margin: '0 24px', paddingBottom: '10px' }}>
                  <Box className={classes.staffAlert}>
                    <ExclamationCircleSVG style={{ flexShrink: 0 }} height={15} />
                    <Box style={{ fontSize: '15px' }}>This restaurant is currently disabled</Box>
                  </Box>
                </Box>
              )}

              <Divider style={{ margin: '10px 40px', backgroundColor: COLORS.SHARK }} />
            </>
          )}
          <List className={classes.list}>
            {navigationItems.map((nav) => {
              if (nav.section !== 1) {
                return <React.Fragment key={nav?.name} />;
              }

              if (nav?.subItems?.length > 0) {
                if (restaurantFetching && !isStaff) {
                  return loadingItem(nav?.name);
                }

                const open = findObjectByProperty(collapsibles, nav?.name, 'id')?.value === true;

                return (
                  <Box key={nav?.name}>
                    <ListItem
                      button
                      key={nav.name}
                      component={NavLink}
                      to='#'
                      onClick={() => toggleCollapsible(nav?.name)}
                      style={{ padding: '2px 40px', display: 'inline-flex' }}
                    >
                      <ListItemText
                        className={classes.navText}
                        primary={
                          <Box className={classes.subItem}>
                            {nav.name}
                            <DownArrowSVG
                              className={classes.expandArrow}
                              style={{
                                transform: open ? 'rotate(180deg)' : '',
                              }}
                            />
                          </Box>
                        }
                        style={{ color: COLORS.SMOKE }}
                      />
                    </ListItem>
                    <Collapse
                      in={open}
                      timeout='auto'
                      style={{ transitionDuration: '0.5s' }}
                      unmountOnExit
                    >
                      <List component='div' disablePadding>
                        {nav?.subItems?.map((subItem) => {
                          const selected = isSelected(getNavLink(subItem?.slug));
                          return (
                            <ListItem
                              button
                              key={subItem?.slug}
                              component={NavLink}
                              to={getNavLink(subItem?.slug)}
                              onClick={hideDrawer}
                              selected={selected}
                              style={{ padding: '2px 12px 2px 60px' }}
                            >
                              <ListItemText
                                className={classes.navText}
                                primary={subItem.name}
                                style={{ color: selected ? COLORS.BRAND_PRIMARY : COLORS.SMOKE }}
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </Box>
                );
              }

              if (restaurantFetching && !isStaff) {
                return loadingItem(nav?.name);
              }

              const selected = isSelected(getNavLink(nav?.slug));

              return (
                <ListItem
                  button
                  key={nav.name}
                  component={NavLink}
                  to={getNavLink(nav?.slug)}
                  onClick={hideDrawer}
                  selected={selected}
                  style={{ padding: '2px 40px' }}
                >
                  <ListItemText
                    className={classes.navText}
                    primary={nav.name}
                    style={{ color: selected ? COLORS.BRAND_PRIMARY : COLORS.SMOKE }}
                  />
                </ListItem>
              );
            })}
          </List>
          <Divider style={{ margin: '10px 40px', backgroundColor: COLORS.SHARK }} />
          <List>
            {navigationItems.map((nav) => {
              if (nav.section !== 2) {
                return <React.Fragment key={nav?.name} />;
              }

              if (restaurantFetching && !isStaff) {
                return loadingItem(nav?.name);
              }

              const selected = isSelected(getNavLink(nav?.slug));

              return (
                <ListItem
                  button
                  key={nav.name}
                  component={Link}
                  to={getNavLink(nav?.slug)}
                  onClick={hideDrawer}
                  selected={isSelected(getNavLink(nav?.slug))}
                  style={{ padding: '2px 40px' }}
                >
                  <ListItemText
                    className={classes.navText}
                    primary={nav.name}
                    style={{ color: selected ? COLORS.BRAND_PRIMARY : COLORS.SMOKE }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box>
          <Box className={classes.versionNumber}>
            {getVersionText()}, {getBookingsVersion()}
          </Box>
          {allowHideNavigation && (
            <>
              <Divider color={COLORS.LINEN} className={classes.divider} />
              <Box className={classes.hideNavigationContainer}>
                <Box className={classes.hideNavigation} onClick={hideDrawer}>
                  <HideNavigationSVG style={{ color: COLORS.BLACK }} />
                  <Box>Hide navigation</Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

NavigationDrawer.propTypes = {
  hideDrawer: PropTypes.func.isRequired,
  allowHideNavigation: PropTypes.bool.isRequired,
  restaurantFetching: PropTypes.bool.isRequired,
  navigationItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: userPropTypes.isRequired,
  restaurantActive: restaurantActivePropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  restaurantFetching: state.restaurants.fetching,
  restaurantActive: state.restaurantActive,
});

export default connect(mapStateToProps, null)(NavigationDrawer);
