import PropTypes from 'prop-types';
import * as type from '../actions/types';

const initialState = {
  data: [],
  error: false,
  errorMessage: '',
};

// Export prop types for easy import in components that use this data
export const breadcrumbsPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case type.CLEAR_BREADCRUMBS: {
      return {
        ...state,
        data: [],
        error: false,
        errorMessage: '',
      };
    }
    case type.SET_BREADCRUMBS: {
      return {
        ...state,
        data: action.payload,
        error: false,
        errorMessage: '',
      };
    }
    case type.ADD_BREADCRUMB: {
      return {
        ...state,
        data: [...state.data, action.payload],
        error: false,
        errorMessage: '',
      };
    }
    case type.POP_BREADCRUMB: {
      return {
        ...state,
        data: state.data.slice(0, -1),
        error: false,
        errorMessage: '',
      };
    }

    default: {
      return state;
    }
  }
};
