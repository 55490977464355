import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DatePicker, Divider, TextDropdown, Button } from '@eatclub-apps/ec-component-library';
import { dealsPropTypes } from '../../../../data/models/Deal';
import { trackEvent } from '../../../../utils/analytics';
import {
  formatCurrency,
  getLabelForValue,
  pluralise,
  roundToNearest,
} from '../../../../utils/helpers';
import OfferDataLabel from './OfferDataLabel';
import useStyles from './LaunchGoalStyles';

const LaunchGoalBox = ({
  offers,
  onNext,
  offerTimePeriod,
  setOfferTimePeriod,
  offerStartDay,
  setOfferStartDay,
  customersMonthlyLower,
  customersMonthlyUpper,
  revenueMonthlyLower,
  revenueMonthlyUpper,
  postingOffers,
}) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [offerPeriods] = useState([
    { label: '1 Month', value: 'monthly' },
    { label: 'Ongoing', value: 'ongoing' },
  ]);

  const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');

  const getCustomers = (monthlyLower, monthlyUpper) => {
    if (monthlyLower === monthlyUpper) {
      return `${monthlyUpper} people`;
    }

    return `${monthlyLower}-${monthlyUpper} people`;
  };

  const getRevenue = (monthlyLower, monthlyUpper) => {
    if (monthlyLower === monthlyUpper) {
      return `${formatCurrency(monthlyUpper, false)} monthly`;
    }

    return `${formatCurrency(monthlyLower, false)}-${formatCurrency(monthlyUpper, false)} monthly`;
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.heading}>Take your business to the next level</Box>
      <Box className={classes.content}>
        <Box className={classes.options}>
          <Box className={classes.option}>
            <Box>Launch for </Box>
            <TextDropdown
              items={offerPeriods}
              value={offerTimePeriod}
              shownValue={getLabelForValue(offerTimePeriod, offerPeriods).toLowerCase()}
              onSelect={(value) => {
                setOfferTimePeriod(value);
              }}
              direction='inverse'
              style={{
                fontWeight: 'bold',
                dropdown: {
                  backgroundColor: 'transparent',
                },
              }}
            />
          </Box>
          <Box className={classes.option}>
            <Box>starting</Box>

            {showDatePicker ? (
              <DatePicker
                // style={{marginLeft: '20px'}}
                value={offerStartDay}
                onSelect={(value) => setOfferStartDay(value)}
                disablePast
                closeOnScroll
              />
            ) : (
              <Box
                onClick={() => {
                  trackEvent('button_click: create_goal_change_date');
                  setShowDatePicker(true);
                }}
              >
                <TextDropdown
                  items={[{ label: 'Tomorrow', value: tomorrow }]}
                  value={tomorrow}
                  onSelect={(value) => {
                    setOfferStartDay(value);
                  }}
                  type='inverse'
                  style={{
                    fontWeight: 'bold',
                    dropdown: {
                      backgroundColor: 'transparent',
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box style={{ maxWidth: '900px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
        <Divider color='#939393' />
      </Box>
      <Box className={classes.predictions}>
        <OfferDataLabel
          style={{
            container: { display: 'flex', alignItems: 'center', gap: '10px' },
            label: { marginTop: '10px', minWidth: 'initial' },
            value: { fontSize: breakpointXsDown ? '18px' : '24px' },
          }}
          label={pluralise('customer', Math.round(customersMonthlyUpper))}
          value={getCustomers(customersMonthlyLower, customersMonthlyUpper)}
          feature
        />
        <OfferDataLabel
          style={{
            container: { display: 'flex', alignItems: 'center', gap: '10px' },
            label: { marginTop: '10px', minWidth: 'initial' },
            value: { fontSize: breakpointXsDown ? '18px' : '24px' },
          }}
          label='Revenue'
          value={getRevenue(
            roundToNearest(revenueMonthlyLower, 10),
            roundToNearest(revenueMonthlyUpper, 10),
          )}
          feature
        />
      </Box>
      <Box className={classes.launchButton}>
        <Button
          type='custom'
          className='launch-goal-button'
          disabled={offers?.length === 0 || postingOffers}
          onClick={onNext}
          style={{
            container: {
              width: '100%',
            },
            button: {
              width: '100%',
              border: '1px solid #e8594F',
              borderRadius: '100px',
              fontSize: breakpointXsDown ? '18px' : '24px',
              color: '#E8594F',
              fontWeight: 'bold',
              lineHeight: '24px',
              padding: '30px',
            },
            text: {
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              gap: '14px',
            },
          }}
        >
          {postingOffers && <CircularProgress size={24} color='inherit' />} Launch revenue goal
        </Button>
      </Box>
      <Box
        style={{
          zIndex: 1,
          position: 'absolute',
          bottom: '-300px',
          left: '-50px',
          width: 'calc(100% + 100px)',
          height: '300px',
          backgroundColor: 'white',
        }}
      />
    </Box>
  );
};

LaunchGoalBox.propTypes = {
  offers: dealsPropTypes.isRequired,
  onNext: PropTypes.func.isRequired,
  offerTimePeriod: PropTypes.string.isRequired,
  setOfferTimePeriod: PropTypes.func.isRequired,
  offerStartDay: PropTypes.string.isRequired,
  setOfferStartDay: PropTypes.func.isRequired,
  customersMonthlyLower: PropTypes.number.isRequired,
  customersMonthlyUpper: PropTypes.number.isRequired,
  revenueMonthlyLower: PropTypes.number.isRequired,
  revenueMonthlyUpper: PropTypes.number.isRequired,
  postingOffers: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LaunchGoalBox);
