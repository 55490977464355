/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Backdrop, Box } from '@mui/material';
import AppLayout from './layouts/AppLayout';
import Body from './components/layout/body/Body';
import Routes from './routes/SwitchRoutes';
import PageHeader from './components/layout/pageHeader/PageHeader';
import useStyles from './AppStyles';
import partnerConfig from './partner-aws-exports';
import staffConfig from './staff-aws-exports';
import { fetchUserAction } from './actions/loginAction';
import { setActiveRestaurantAction } from './actions/restaurantActiveAction';
import { fetchRestaurantAction } from './actions/restaurantAction';
import Error from './components/error/Error';
import './App.css';
import './DatePickerStyles.css';
import { devLog } from './utils';

const staffSession = localStorage.getItem('mode') === 'staff';
Amplify.configure(staffSession ? staffConfig : partnerConfig);

// NOTE: enable for debugging
// Amplify.Logger.LOG_LEVEL = "DEBUG";
// const currentConfig = Auth.configure();
// console.log("currentConfig: ", currentConfig);

const App = ({
  fetchRestaurant,
  fetchUser,
  restaurant,
  restaurants,
  restaurantActive,
  setActiveRestaurant,
  user,
}) => {
  const params = new URLSearchParams(window.location.search);

  const classes = useStyles();
  const history = useHistory();

  const { objectId: userId } = user.userInfo;
  const { id: restId } = restaurantActive;

  useEffect(() => {
    if (user?.userInfo?.objectId) {
      /* Initialize analytics & load plugins */
      ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID, {
        gaOptions: {
          userId: user?.userinfo?.objectId,
        },
      });
    }
  }, [user?.userInfo?.objectId]);

  // Fetch User Object
  useEffect(() => {
    // Extract objectId from the User in the User Pool
    Auth.currentAuthenticatedUser()
      .then((response) => {
        if (!response) {
          return null;
        }

        const authUserId = response.attributes['custom:objectId'];
        const { userPoolId } = response.pool;
        const userType =
          (userPoolId === import.meta.env.VITE_AWS_PARTNER_USER_POOLS_ID && 'partner') ||
          (userPoolId === import.meta.env.VITE_AWS_STAFF_USER_POOLS_ID && 'staff');

        if (!userType) {
          throw new Error('undefined user type');
        }
        localStorage.setItem('mode', userType);

        if (authUserId) {
          // Fetch the User from the User's table using the objectId
          fetchUser(authUserId, userType);

          if (userType === 'staff' && history.location.pathname === '/staff') {
            window.location.href = `${window.location.origin}?${params.toString()}`;
          }
          return null;
        }

        devLog('error', 'User objectId is not set!');
        return null;

        // // # Inject objectId into User Pool's User using below code:
        // Auth.updateUserAttributes(response, { "custom:objectId": "9254404B-C090-4269-FF80-BF6C52934600" })
        //   .then(response =>
        //     console.log("Added objectId to User from pool!")
        //   )
        //   .catch(error =>
        //     console.log("Error adding objectId to User", error)
        //   );
      })
      .catch((error) => {
        devLog('error', 'User is not signed in', error);
      });
  }, [fetchUser]);

  // Fetch Restaurant
  useEffect(() => {
    if (user.userFetching || user.userError) {
      return;
    }

    // when fetching affiliated restaurants, send rest ID from user id thing
    const sessionSelectedRestaurant = sessionStorage.getItem('selectedRestaurant');

    if (user.userInfo.userType === 'partner') {
      const id = restId === '' ? user.userInfo.restaurants[0].objectId : restId;
      if (sessionSelectedRestaurant) {
        fetchRestaurant(sessionSelectedRestaurant, userId, user.userInfo.userType);
      } else {
        fetchRestaurant(id, userId, user.userInfo.userType);
      }
    }

    const isStaff = user.userInfo.userType === 'staff';
    if (isStaff) {
      const restIdFromParams = params.get('restId');
      const lastSelectedRestaurant = localStorage.getItem(
        `lastUsedRestaurant:${user?.userInfo?.objectId}`,
      ); // Get restaurant used in previous session
      if (restIdFromParams) {
        sessionStorage.setItem('selectedRestaurant', restIdFromParams);
        localStorage.setItem(`lastUsedRestaurant:${user?.userInfo?.objectId}`, restIdFromParams);
        fetchRestaurant(restIdFromParams, userId, user.userInfo.userType);
      } else if (sessionSelectedRestaurant) {
        localStorage.setItem(
          `lastUsedRestaurant:${user?.userInfo?.objectId}`,
          sessionSelectedRestaurant,
        );
        fetchRestaurant(sessionSelectedRestaurant, userId, user.userInfo.userType);
      } else if (lastSelectedRestaurant) {
        sessionStorage.setItem('selectedRestaurant', lastSelectedRestaurant);
        fetchRestaurant(lastSelectedRestaurant, userId, user.userInfo.userType);
      } else {
        sessionStorage.setItem('selectedRestaurant', import.meta.env.VITE_DEFAULT_STAFF_RESTAURANT);
        fetchRestaurant(
          import.meta.env.VITE_DEFAULT_STAFF_RESTAURANT,
          userId,
          user.userInfo.userType,
        );
      }
    }
  }, [
    fetchRestaurant,
    restaurantActive.id,
    user.userError,
    user.userFetching,
    user.userInfo.restaurants,
    user.userInfo.userType,
  ]);

  // Set Active Restaurant
  useEffect(() => {
    if (restaurant.restaurantFetching || restaurants.fetching || restaurant.restaurantError) {
      return;
    }
    setActiveRestaurant(restaurant.restaurant);
  }, [
    restaurant.restaurant,
    restaurant.restaurantError,
    restaurant.restaurantFetching,
    restaurants.fetching,
    setActiveRestaurant,
  ]);

  return (
    <Router>
      <Box className={classes.root}>
        <AppLayout>
          <Body style={{ position: 'relative' }}>
            <Box>
              {user.userError && <Error error message={user.userErrorMessage} />}

              {!restaurantActive.restaurantFetching && restaurantActive.restaurant && (
                <>
                  <Route
                    exact
                    path={[
                      '/analytics',
                      '/deals',
                      '/transactions',
                      '/billing',
                      '/menus',
                      '/support',
                    ]}
                  >
                    <PageHeader activeRestaurant={restaurantActive.restaurant} />
                  </Route>
                  <Routes activeRestaurant={restaurantActive.restaurant} />
                </>
              )}

              {restaurantActive.restaurantFetching === 0 && !user.userError && (
                <Backdrop open style={{ zIndex: 9999 }} />
              )}
            </Box>
          </Body>
        </AppLayout>
      </Box>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
  restaurants: state.restaurants,
  restaurantActive: state.restaurantActive,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRestaurant: fetchRestaurantAction,
      fetchUser: fetchUserAction,
      setActiveRestaurant: setActiveRestaurantAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
