import { Auth, API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { ssoCreds, staffById, userById } from '../graphql/queries';
import { devLog } from '../utils';

export const fetchUserAction = (userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'FETCH_USER',
  });
  dispatch({
    type: type.FETCH_USER_PENDING,
  });

  const getData = async () => {
    try {
      if (userType === 'partner') {
        const response = await API.graphql(
          {
            query: userById,
            variables: {
              userId,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'app-version': getAppVersion(),
          },
        );

        if (Object.keys(response.data.userById).length > 0) {
          devLog('success', 'user', response.data.userById);

          dispatch({
            type: type.FETCH_USER_SUCCESS,
            payload: { ...response.data.userById, userType },
          });
        } else {
          dispatch({
            type: type.FETCH_USER_FAILURE,
            payload: `Unable to find user "${userId}". Please contact your administrator.`,
          });
        }
      }

      if (userType === 'staff') {
        const response = await API.graphql(
          {
            query: staffById,
            variables: {
              staffId: userId,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'app-version': getAppVersion(),
          },
        );

        if (Object.keys(response.data.staffById).length > 0) {
          devLog('success', 'user', response.data.staffById);

          dispatch({
            type: type.FETCH_USER_SUCCESS,
            payload: { ...response.data.staffById, userType },
          });
        } else {
          dispatch({
            type: type.FETCH_USER_FAILURE,
            payload: `Unable to find user "${userId}". Please contact your administrator.`,
          });
        }
      }
    } catch (error) {
      devLog('error', 'fetch user', error);
      dispatch({
        type: type.FETCH_USER_FAILURE,
        payload: error.errors[0].message,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'FETCH_USER',
      });
    }
  };
  getData();
};

export const setLogoutAction = () => (dispatch) => {
  devLog('info', 'Logging out');

  Auth.signOut()
    .then((data) => {
      devLog('success', 'signed out', data);

      // Removes query params/redirectTo when logging out
      window.history.replaceState({}, document.title, '/');

      dispatch({
        type: type.SET_LOGOUT,
      });
    })
    .catch((error) => {
      devLog('error', 'signed out', error);
    });
};

export const clearLoginErrorAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_LOGIN_ERROR,
  });
};

export const fetchSsoCredsAction = (ssoToken) => (dispatch) => {
  dispatch({
    type: type.FETCH_SSO_CREDS_PENDING,
  });

  const getData = async () => {
    try {
      const response = await API.graphql(
        {
          query: ssoCreds,
          variables: { ssoToken },
        },
        {
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });
      devLog('success', 'sso token', response?.data?.ssoCreds);

      const parsedResponse = JSON.parse(response?.data?.ssoCreds);

      dispatch({
        type: type.FETCH_SSO_CREDS_SUCCESS,
        payload: parsedResponse?.ssoCreds,
      });
    } catch (error) {
      devLog('error', 'sso creds', error);

      dispatch({
        type: type.ADD_MESSAGE,
        payload: {
          id: `FETCH_SSO_CREDS_${new Date().getTime()}`,
          message: `Error, unable to fetch sso creds.`,
          severity: 'error',
        },
      });

      dispatch({
        type: type.FETCH_SSO_CREDS_FAILURE,
        payload: `Unable to fetch sso creds: ${error}`,
      });
    }
  };
  getData();
};
