import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataPoint from '../DataPoint/DataPoint';
import { saveAverageOrderValueAction } from '../../../actions/restaurantAction';
import { trackEvent } from '../../../utils/analytics';
import { getChange, getPercent, renderValue } from '../../../data/models/Analytics';

const TakeawayRevenue = ({ data, averageOrderValue, saveAverageOrderValue }) => {
  const {
    isTakeawayOffline,
    takeawayOfflineAOV,
    takeawayOfflineRevenueBeforeOffer,
    takeawayOfflineRevenueBeforeOfferCompare,
    takeawayOfflineRevenueAfterOffer,
    takeawayOfflineRevenueAfterOfferCompare,
    takeawayAOV,
    takeawayAOVCompare,
    takeawayRevenueAfterOffer,
    takeawayRevenueAfterOfferCompare,
    takeawayRevenueBeforeOffer,
    takeawayRevenueBeforeOfferCompare,
  } = data;
  const revenueBeforePercent = getPercent(
    takeawayRevenueBeforeOffer,
    takeawayRevenueBeforeOfferCompare,
  );
  const revenueBeforeChange = getChange(
    takeawayRevenueBeforeOffer,
    takeawayRevenueBeforeOfferCompare,
  );

  const revenueAfterPercent = getPercent(
    takeawayRevenueAfterOffer,
    takeawayRevenueAfterOfferCompare,
  );
  const revenueAfterChange = getChange(takeawayRevenueAfterOffer, takeawayRevenueAfterOfferCompare);

  const offlineRevenueBeforePercent = getPercent(
    takeawayOfflineRevenueBeforeOffer,
    takeawayOfflineRevenueBeforeOfferCompare,
  );
  const offlineRevenueBeforeChange = getChange(
    takeawayOfflineRevenueBeforeOffer,
    takeawayOfflineRevenueBeforeOfferCompare,
  );

  const offlineRevenueAfterPercent = getPercent(
    takeawayOfflineRevenueAfterOffer,
    takeawayOfflineRevenueAfterOfferCompare,
  );
  const offlineRevenueAfterChange = getChange(
    takeawayOfflineRevenueAfterOffer,
    takeawayOfflineRevenueAfterOfferCompare,
  );

  const aovPercent = getPercent(takeawayAOV, takeawayAOVCompare);
  const aovChange = getChange(takeawayAOV, takeawayAOVCompare);

  const handleSubmit = (value) => {
    trackEvent('button_click: set_avg_order_value');

    const aov = parseInt(value, 10);

    if (value === '' || Number.isNaN(value) || aov < 1) {
      return;
    }

    saveAverageOrderValue(aov);
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} sm={12} xs={12}>
        <Typography style={{ fontWeight: 500 }}>Takeaway</Typography>
      </Grid>

      {isTakeawayOffline ? (
        <>
          <DataPoint
            change={offlineRevenueBeforeChange}
            percent={offlineRevenueBeforePercent}
            title='Revenue before offer'
            value={renderValue(takeawayOfflineRevenueBeforeOffer)}
          />

          <Grid item lg={12} sm={12} xs={12} style={{ margin: '-12px 0' }}>
            <Divider />
          </Grid>

          <DataPoint
            change={offlineRevenueAfterChange}
            percent={offlineRevenueAfterPercent}
            title='Revenue after offer'
            value={renderValue(takeawayOfflineRevenueAfterOffer)}
          />

          <Grid item lg={12} sm={12} xs={12} style={{ margin: '-12px 0' }}>
            <Divider />
          </Grid>

          <DataPoint
            editable
            handleSubmit={handleSubmit}
            saving={averageOrderValue.pending}
            title='Average order value'
            value={takeawayOfflineAOV}
          />
        </>
      ) : (
        <>
          <DataPoint
            change={revenueBeforeChange}
            percent={revenueBeforePercent}
            title='Revenue before offer'
            value={renderValue(takeawayRevenueBeforeOffer)}
          />

          <Grid item lg={12} sm={12} xs={12} style={{ margin: '-12px 0' }}>
            <Divider />
          </Grid>

          <DataPoint
            change={revenueAfterChange}
            percent={revenueAfterPercent}
            title='Revenue after offer'
            value={renderValue(takeawayRevenueAfterOffer)}
          />

          <Grid item lg={12} sm={12} xs={12} style={{ margin: '-12px 0' }}>
            <Divider />
          </Grid>

          <DataPoint
            change={aovChange}
            percent={aovPercent}
            title='Average order value'
            value={renderValue(takeawayAOV)}
          />
        </>
      )}
    </Grid>
  );
};

TakeawayRevenue.propTypes = {
  data: PropTypes.shape({}).isRequired,
  averageOrderValue: PropTypes.shape({
    pending: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  saveAverageOrderValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  averageOrderValue: state.averageOrderValue,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveAverageOrderValue: saveAverageOrderValueAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TakeawayRevenue);
