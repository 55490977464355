import * as type from '../actions/types';

const initialState = {
  data: [],
  error: '',
  pending: false,
  pendingIds: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.UPDATE_DAILY_OPPO_PENDING: {
      const { oppoId } = action.payload;
      const checkId = state.pendingIds.findIndex((id) => id === oppoId);

      if (checkId === -1) {
        return {
          ...state,
          pending: true,
          pendingIds: [...state.pendingIds, oppoId],
          error: '',
        };
      }

      return {
        ...state,
        pending: true,
        error: '',
      };
    }

    case type.UPDATE_DAILY_OPPO_SUCCESS: {
      const { prediction, oppoId } = action.payload;
      const removedPendingIds = [...state.pendingIds.filter((id) => id !== oppoId)];

      return {
        ...state,
        data: prediction,
        pending: false,
        pendingIds: removedPendingIds,
      };
    }

    case type.UPDATE_DAILY_OPPO_FAILURE: {
      const { error, oppoId } = action.payload;
      const removedPendingIds = [...state.pendingIds.filter((id) => id !== oppoId)];

      return {
        ...state,
        pending: false,
        pendingIds: removedPendingIds,
        error,
      };
    }

    case type.CLEAR_UPDATE_DAILY_OPPO:
      return {
        ...state,
        data: [],
        pending: false,
        pendingIds: [],
        error: '',
      };

    default:
      return state;
  }
};
