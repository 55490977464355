/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import useStyles from './ErrorStyles';

const Error = ({ error, message, style }) => {
  const classes = useStyles();

  return (
    <>
      {error && (
        <Alert style={style} elevation={3} severity='error' className={classes.mb2}>
          {message}
        </Alert>
      )}
    </>
  );
};

Error.propTypes = {
  error: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default Error;
