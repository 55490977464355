import { Box, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, CenteredContent } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { goalPropTypes } from '../../../data/models/Goal';
import { COLORS, FONT_SIZES, FONT_WEIGHTS } from '../../../EatClubTheme';
import { trackEvent } from '../../../utils/analytics';
import { clamp, formatCurrency, roundToNearest } from '../../../utils/helpers';
import GoalStatus from './GoalStatus';
import TotalCustomers from '../../../assets/total_customers.svg';
import TotalRevenue from '../../../assets/total_revenue.svg';
import useStyles from './GoalAnalyticsStyles';

const GoalAnalytics = ({ goal, showViewDetails }) => {
  const classes = useStyles();
  const history = useHistory();
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));

  const goalDuration = Math.abs(moment(goal?.startDate).diff(moment(goal?.endDate), 'days')) + 1; // include the start date
  const completionDays = Math.abs(moment(goal?.startDate).diff(moment(), 'days'));
  const progress = clamp(completionDays / Math.max(goalDuration, 1), 0, 1); // Clamp so that past goals don't go over target
  const revenueLowerExpected = Math.round(progress * goal?.targetRevenueLower);
  const revenueUpperExpected = Math.round(progress * goal?.targetRevenueUpper);
  const customersLowerExpected = Math.round(progress * goal?.targetCustomersLower);
  const customersUpperExpected = Math.round(progress * goal?.targetCustomersUpper);

  const getAmountRelativeToGoal = (revenue, revenueLowerBound, revenueUpperBound) => {
    if (revenueLowerBound === 0 && revenueUpperBound === 0) {
      return '';
    }

    return (
      <Box className={revenue < revenueLowerBound ? classes.redText : classes.greenText}>
        Target: {formatCurrency(revenueLowerBound, false)}-
        {formatCurrency(revenueUpperBound, false)}
      </Box>
    );
  };

  const getCustomersRelativeToGoal = (customers, customersLowerBound, customersUpperBound) => {
    if (customersLowerBound === 0 && customersUpperBound === 0) {
      return '';
    }

    return (
      <Box className={customers < customersLowerBound ? classes.redText : classes.greenText}>
        Target: {customersLowerBound}-{customersUpperBound}
      </Box>
    );
  };

  const getActiveStatus = () => {
    if (goal?.revenue >= revenueLowerExpected) {
      return 'ontrack';
    }

    return 'offtrack';
  };

  return (
    <Box
      className={classes.container}
      style={{
        background: showViewDetails ? COLORS.LINEN : '',
      }}
    >
      <Box className={classes.bannerTop}>
        <Box>
          <Box className={classes.heading}>Revenue goal</Box>

          <Box display='inline-flex' gap='16px'>
            <Box className={classes.target}>
              {formatCurrency(roundToNearest(goal?.targetRevenueLower, 10), false)}-
              {breakpointXsDown ? <br /> : ''}
              {formatCurrency(roundToNearest(goal?.targetRevenueUpper, 10), false)} by{' '}
              {moment(goal?.endDate).format('Do MMM')}
            </Box>

            <CenteredContent>
              <GoalStatus status={goal?.status === 'active' ? getActiveStatus() : goal?.status} />
            </CenteredContent>
          </Box>

          {!showViewDetails && (
            <Button
              type='text'
              style={{
                fontSize: FONT_SIZES.M,
                fontWeight: FONT_WEIGHTS.MEDIUM,
              }}
              onClick={() => {
                trackEvent('button_click: edit_goal');
                history.push(`/goals/edit/${goal?.objectId}`);
              }}
            >
              Edit goal
            </Button>
          )}
        </Box>
        <Box className={classes.analyticsTotals}>
          <Box display='flex' style={{ gap: '24px' }}>
            <TotalRevenue className={classes.totalImage} />
            <Box display='flex' flexDirection='column' style={{ gap: '8px' }}>
              <Box className={classes.label}>TOTAL REVENUE</Box>
              <Box className={classes.totalValue}>{formatCurrency(goal?.revenue, false)}</Box>
              {getAmountRelativeToGoal(
                goal?.revenue,
                roundToNearest(revenueLowerExpected, 10),
                roundToNearest(revenueUpperExpected, 10),
              )}
            </Box>
          </Box>
          <Box display='flex' style={{ gap: '24px' }}>
            <TotalCustomers className={classes.totalImage} />
            <Box display='flex' flexDirection='column' style={{ gap: '8px' }}>
              <Box className={classes.label}>CUSTOMERS</Box>
              <Box className={classes.totalValue}>{goal?.customers}</Box>
              {getCustomersRelativeToGoal(
                goal?.customers,
                customersLowerExpected,
                customersUpperExpected,
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {showViewDetails && (
        <>
          <Divider />
          <Box display='flex' style={{ gap: '0 30px', flexWrap: 'wrap' }}>
            <Button
              type='text'
              style={{
                fontSize: FONT_SIZES.M,
                paddingTop: '22px',
              }}
              onClick={() => {
                trackEvent(`button_click: view_current_goal`);
                history.push(`/goals/${goal?.objectId}`);
              }}
            >
              View current goal
            </Button>
            <Button
              onClick={() => {
                trackEvent(`button_click: view_previous_goals`);
                history.push('/goals/previous');
              }}
              type='text'
              style={{
                fontSize: FONT_SIZES.M,
                paddingTop: '22px',
              }}
            >
              View previous goals
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

GoalAnalytics.defaultProps = {
  showViewDetails: true,
};

GoalAnalytics.propTypes = {
  goal: goalPropTypes.isRequired,
  showViewDetails: PropTypes.bool,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GoalAnalytics);
