/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ComparePercent from '../comparePercent/ComparePercent';
import { getWhitelabelData, getCompareWhitelabelData } from '../../../reducers/analyticsHelpers';
import { formatNumber } from '../../../utils';
import { getChange, getPercent } from '../../../data/models/Analytics';

const AverageOrderValue = ({ averageOrder, compareAverageOrder }) => {
  const revenue = averageOrder;
  const revenueCompare = compareAverageOrder;
  const revenuePercent = getPercent(revenue, revenueCompare);
  const revenueChange = getChange(revenue, revenueCompare);

  const renderCurrency = (num) => {
    if (!Number.isFinite(revenue)) {
      return '-';
    }

    return `$${formatNumber(num, 0)}`;
  };

  return (
    <>
      <Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='subtitle1' style={{ marginBottom: '.5rem' }}>
            Average Order Value
          </Typography>
        </Box>

        <Typography variant='h4'>{renderCurrency(revenue)}</Typography>
        <ComparePercent percent={revenuePercent} change={revenueChange} />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  averageOrder: getWhitelabelData(state.analytics.bookingsData, 'averageOrder'),
  compareAverageOrder: getCompareWhitelabelData(
    state.analytics.compareBookingsData,
    'compareAverageOrder',
  ),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AverageOrderValue);
