/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import theme from '../../../../AppTheme';
import { FONT_WEIGHTS } from '../../../../EatClubTheme';

const OfferDataLabel = ({ label, value, style, feature }) => {
  // TODO I haven't yet got style overrides working with classNames so keeping this for now
  const styles = {
    container: { ...style, ...style?.container },
    label: {
      fontSize: '12px',
      color: '#6E6E6D',
      textTransform: 'uppercase',
      letterSpacing: '0.13em',
      minWidth: '140px',
      ...style?.label,
    },
    value: {
      ...style?.value,
    },
    featureValue: {
      padding: '4px 8px',
      borderRadius: '4px',
      backgroundColor: '#F8E4E0',
      marginTop: '5px',
      color: '#E7554B',
      fontWeight: FONT_WEIGHTS.MEDIUM,
      whiteSpace: 'nowrap',
      lineHeight: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        whiteSpace: 'auto',
      },
      ...style?.featureValue,
    },
  };

  return (
    <Box style={styles?.container}>
      <Box style={styles.label}>{label}</Box>
      <Box style={{ ...styles?.value, ...(feature && styles.featureValue) }}>{value}</Box>
    </Box>
  );
};

OfferDataLabel.defaultProps = {
  style: {},
  feature: false,
};

OfferDataLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  feature: PropTypes.bool,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OfferDataLabel);
