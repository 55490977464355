import * as type from '../actions/types';
import { calculateStatusForOffer, calculateStatusForDeal } from '../data/models/Deal';
import { formatTimeFromInt, findObjectByProperty } from '../utils/helpers';

const initialState = {
  data: [],
  fetching: true,
  success: false,
  error: '',
  shouldFetch: true,
  goalId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_OFFERS_FOR_GOAL_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    case type.FETCH_OFFERS_FOR_GOAL_SUCCESS: {
      const { offers } = action.payload;

      // TODO this was all copied from activeOffersForRestaurantReducer
      // Set a status
      const getStatus = (offer) => calculateStatusForOffer(offer);

      const updatedData = offers.map((offer) => {
        const startTime = formatTimeFromInt(offer.startTime);
        const endTime = formatTimeFromInt(offer.endTime);

        return {
          ...offer,
          id: offer?.deals?.map((deal) => deal?.objectId).join('_'),
          startTimeReadable: startTime,
          endTimeReadable: endTime,
          status: getStatus(offer),
        };
      });

      return {
        ...state,
        data: updatedData,
        fetching: false,
        error: false,
        errorMessage: '',
        success: true,
      };
    }
    case type.FETCH_OFFERS_FOR_GOAL_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        fetching: false,
      };
    case type.DISABLE_OFFER_FOR_DATE_SUCCESS:
      const { offerId, dealIds, changedOffer } = action.payload;

      // Loop through all the deals in the (right) offer, and set them as disabled
      const updatedData = state.data.map((offer) => {
        if (offer?.id && offer?.id === offerId) {
          const updatedDeals = offer?.deals.map((deal) => {
            if (dealIds.includes(deal?.objectId)) {
              const dealApiData = findObjectByProperty(changedOffer?.deals, deal?.objectId);
              return {
                ...deal,
                disabledForDate: dealApiData?.disabledForDate,
                enabled: dealApiData?.enabled,
                status: 'disabled',
              };
            }

            return deal;
          });

          const newOffer = {
            ...offer,
            deals: updatedDeals,
          };

          return {
            ...newOffer,
            status: calculateStatusForOffer(newOffer),
          };
        }

        return offer;
      });

      return {
        ...state,
        data: updatedData,
        fetching: false,
        success: true,
      };
    case type.RE_ENABLE_OFFER_FOR_DATE_SUCCESS:
      const reEnabledOfferId = action.payload.offerId;

      const updatedReEnabledData = state.data.map((offer) => {
        if (offer?.id !== reEnabledOfferId) {
          return offer;
        }

        const updatedDeals = offer?.deals.map((deal) => {
          const newDeal = {
            ...deal,
            disabledForDate: null,
          };

          return {
            ...newDeal,
            status: calculateStatusForDeal(newDeal),
          };
        });

        const newOffer = {
          ...offer,
          deals: updatedDeals,
          disabledForDate: null,
        };

        return {
          ...newOffer,
          status: calculateStatusForOffer(newOffer),
        };
      });

      return {
        ...state,
        data: updatedReEnabledData,
        fetching: false,
        success: true,
      };
    case type.DELETE_OFFER_SUCCESS:
      const deletedOfferId = action.payload.offerId;

      const deleteUpdatedData = state.data.filter((offer) => offer?.id !== deletedOfferId);

      return {
        ...state,
        data: deleteUpdatedData,
        fetching: false,
        success: true,
      };

    case type.DELETE_ALL_OFFERS_SUCCESS:
      return {
        ...state,
        data: [],
        fetching: false,
        success: true,
      };

    default:
      return state;
  }
};
