import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { useTheme } from '@mui/material/styles';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, Button, InlineTextField, Modal, Popover } from '@eatclub-apps/ec-component-library';
import React, { useEffect, useState } from 'react';
import { CleanTextField } from '@eatclub-apps/ec-bookings-library';
import { obeeSettingsPropTypes } from '../../../data/models/ObeeSettings';
import { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';
import useStyles from './BookingsLandingStyles';
import { clearObeeOnboardAction } from '../../../actions/obeeSignupAction';
import { capitaliseFirstLetter, generateSlug, isEmpty } from '../../../utils/helpers';
import { restaurantPropTypes } from '../../../data/models/Restaurant';
import ExclamationSVG from '../../../assets/exclamation.svg';
import IconInformation from '../../../assets/icon_information.svg';

const SignUpModal = ({
  isOpen,
  onClose,
  plan,
  onConfirm,
  loading,
  clearObeeOnboard,
  restaurantActive,
  slugError,
  setSlugError,
  isUpgrade,
  onUpgrade,
  obeeSettings,
}) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));

  const { restaurant } = restaurantActive;
  const defaultSlug = obeeSettings.data.slug || generateSlug(restaurant.name);

  // TODO pre-fill operations email if already onboarded

  const [planToUse, setPlanToUse] = useState(plan);
  const [slug, setSlug] = useState(defaultSlug);
  const [operationsEmail, setOperationsEmail] = useState(null);
  const [operationsEmailError, setOperationsEmailError] = useState(null);
  const [infoAnchorEl, setInfoAnchorEl] = useState(null);

  const requiredErrorMsg = 'This field is required.';
  const validEmailErrorMsg = 'A valid email is required.';

  useEffect(() => {
    if (isOpen) {
      clearObeeOnboard(); // clear reducer state
      unstable_batchedUpdates(() => {
        setPlanToUse(plan);
        setSlug(defaultSlug);
        setOperationsEmail(null);
        setOperationsEmailError(null);
        setSlugError(null);
      });
    }
  }, [isOpen]);

  const textFieldStyles = {
    label: {
      fontSize: '15px',
      width: '150px',
    },
    errorText: {
      marginLeft: breakpointXsDown ? 0 : '160px',
    },
    input: { fontSize: '15px', padding: '8px 12px' },
  };

  const confirm = () => {
    if (isEmpty(operationsEmail)) {
      setOperationsEmailError(requiredErrorMsg);
      return;
    }

    if (!validator.isEmail(operationsEmail)) {
      setOperationsEmailError(validEmailErrorMsg);
      return;
    }

    onConfirm(isEmpty(slug) ? defaultSlug : slug, operationsEmail, planToUse === 'premium');
  };

  return (
    <Modal
      fullWidth={false}
      style={{
        container: { padding: '20px' },
        modal: { minWidth: breakpointXsDown ? '100px' : '530px', maxWidth: '530px' },
        content: { padding: '40px' },
        header: {
          background: COLORS.LINEN,
          height: '40px',
          position: 'sticky',
          top: 0,
          zIndex: 99,
        },
        footer: { padding: 0 },
        footerInner: { padding: '12px 20px' },
      }}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
      showCloseButton={false}
      footer={
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type='tertiary' onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={isUpgrade ? onUpgrade : confirm} loading={loading}>
            {isUpgrade ? 'Upgrade' : 'Confirm'}
          </Button>
        </Box>
      }
    >
      <Box className={classes.modalHeaderContainer}>
        <Box className={classes.modalHeader}>
          {isUpgrade
            ? 'Upgrade the following venue to EatClub Bookings Premium'
            : 'Sign the following venue up to EatClub Bookings'}
        </Box>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <CleanTextField
          disabled
          value={restaurant.name}
          placeholder={breakpointXsDown ? 'Venue' : 'Empty'}
          label={!breakpointXsDown && 'Venue'}
          style={textFieldStyles}
        />

        <CleanTextField
          disabled
          value={`${restaurant.address1}, ${restaurant.region} ${restaurant.postcode}`}
          placeholder={breakpointXsDown ? 'Address' : 'Empty'}
          label={!breakpointXsDown && 'Address'}
          style={textFieldStyles}
        />

        <CleanTextField
          disabled
          value={capitaliseFirstLetter(`${planToUse} plan`)}
          placeholder={breakpointXsDown ? 'Plan' : 'Empty'}
          label={!breakpointXsDown && 'Plan'}
          style={textFieldStyles}
        />

        <CleanTextField
          disabled={isUpgrade}
          value={operationsEmail}
          onChange={setOperationsEmail}
          placeholder={breakpointXsDown ? 'Operational email' : 'Empty'}
          label={!breakpointXsDown && 'Operational email'}
          style={textFieldStyles}
          error={operationsEmailError}
          clearError={() => setOperationsEmailError(null)}
        />
        <Box className={classes.slugContainer}>
          <Box className={classes.urlHeading}>
            <Box>URL for the booking widget</Box>
            <IconInformation
              className={classes.infoIcon}
              onClick={(e) => {
                e.stopPropagation();
                setInfoAnchorEl(e.currentTarget);
              }}
            />
            <Popover
              isOpen={!!infoAnchorEl}
              anchorEl={infoAnchorEl}
              onClose={() => setInfoAnchorEl(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              innerStyle={{ borderRadius: '8px', marginTop: '-10px', padding: '12px' }}
            >
              <Box className={classes.popoverContainer}>
                {`${import.meta.env.VITE_BOOKING_WIDGET_BASE_URL}/${slug || defaultSlug}`}
              </Box>
            </Popover>
          </Box>

          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <InlineTextField
              disabled={isUpgrade}
              value={slug}
              onChange={(value) => {
                setSlugError(null);
                setSlug(value);
              }}
              placeholder={defaultSlug}
              style={{
                width: '100%',
                textFieldInner: {
                  margin: 0,
                  padding: '8px 12px',
                  width: '100%',
                  alignItems: 'baseline',
                },
                input: {
                  fontSize: '15px',
                  border: 'none',
                  width: '100%',
                  color: 'inherit',
                },
                label: {
                  fontSize: '15px',
                  color: COLORS.NIMBUS,
                },
                disabled: {
                  color: COLORS.NIMBUS,
                },
              }}
              maxCharacters={30}
              disableLabelFocus
              label='/'
            />
          </Box>

          {slug.length > 30 && <Box>Warning: The URL will be truncated to 30 characters</Box>}

          {slugError && (
            <Box
              style={{
                color: COLORS.BRAND_PRIMARY,
                fontWeight: FONT_WEIGHTS.MEDIUM,
                fontSize: '15px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <ExclamationSVG />
              {slugError}
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

SignUpModal.defaultProps = {
  plan: 'lite',
  slugError: null,
};

SignUpModal.propTypes = {
  plan: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  clearObeeOnboard: PropTypes.func.isRequired,
  restaurantActive: restaurantPropTypes.isRequired,
  slugError: PropTypes.string,
  setSlugError: PropTypes.func.isRequired,
  isUpgrade: PropTypes.bool.isRequired,
  onUpgrade: PropTypes.func.isRequired,
  obeeSettings: obeeSettingsPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  requestBookings: state.requestBookings,
  restaurantActive: state.restaurantActive,
  obeeSettings: state.obeeSettings,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearObeeOnboard: clearObeeOnboardAction,
      // clearObeeUpgrade: clearObeeUpgradeAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModal);
