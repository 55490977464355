import React from 'react';
import { Box, Grid } from '@mui/material';
import { useLocation, Route } from 'react-router-dom';
import RestaurantPicker from '../../restaurantPicker/RestaurantPicker';
import CreateDeal from '../../deals/CreateDealButton';
import DatePicker from '../../datePicker/DatePicker';
import Favourited from '../../analytics/favourited/Favourited';
import useStyles from './PageHeaderStyles';
import PlatformPicker from '../../platformPicker/PlatformPicker';
import DailyOpposButton from '../../deals/dailyOppos/DailyOpposButton';
import DailyOppoPopup from '../../dailyOppoPopup/DailyOppoPopup';

const PageHeader = (activeRestaurant) => {
  const classes = useStyles();
  const location = useLocation();

  const getColumnWidth = () => {
    if (location.pathname === '/support') {
      return 8;
    }
    return 12;
  };

  return (
    <>
      {/* <DailyOppoPopup activeRestaurant={activeRestaurant} /> */}
      <Grid container spacing={3} justifyContent='center'>
        <Grid item sm={getColumnWidth()} xs={12}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            className={classes.root}
          >
            <div className={classes.actionsLeft}>
              <Route exact path={['/analytics', '/transactions']}>
                <DatePicker />
              </Route>
              <Route exact path={['/deals']}>
                <CreateDeal />
                <DailyOpposButton />
              </Route>
              <Route exact path='*'>
                <RestaurantPicker />
              </Route>
              <Route exact path={['/analytics', '/menus']}>
                <PlatformPicker />
              </Route>
            </div>
            <div className={classes.actionsRight}>
              <Route exact path='/analytics'>
                <Favourited />
              </Route>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PageHeader;
