import { createTheme } from '@mui/material/styles';
import { isChrome, isOpera } from 'react-device-detect';
import { COLORS, FONT_SIZES, FONT_WEIGHTS, LINE_HEIGHTS } from './EatClubTheme';

// TODO uncomment this and fix all breakpoints. Too risky right now
// const breakpoints = createBreakpoints({
//   values: {
//     xs: 0,
//     sm: 768 + 1,
//     md: 1280 + 1, // Adding 1 to each since 1280 would otherwise be missed by down('md')
//     lg: 1500 + 1,
//     xl: 1920 + 1,
//   },
// });

// TODO delete this one and replace with the above when ready
const breakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, // Mobile - not supported
      md: 1025, // Smallest tablet
      lg: 1280, // Normal laptop size
      xl: 1920, // Full width
    },
  },
});

// Only use overlay scrollbars on chrome, they don't work in safari or firefox :(
export const overlay = isChrome || isOpera ? 'overlay' : 'auto';

const theme = createTheme(breakpointsTheme, {
  overlay,
  typography: {
    body1: {
      fontFamily: "Gordita, 'Open Sans', 'Helvetica', 'sans-serif' !important",
      // fontSize: '22px',
    },
  },
  components: {
    scrollbarWide: {
      overflowY: overlay,

      '&::-webkit-scrollbar': {
        paddingLeft: '1px',
        width: '10px',
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-track-piece': {
        background: COLORS.YET_ANOTHER_GRAY_9,
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-thumb': {
        '&:hover': {
          background: COLORS.CHARCOAL,
        },

        background: COLORS.YET_ANOTHER_GRAY_10,
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-button:end:increment': {
        display: 'block',
        background: 'transparent',
      },
    },
    scrollbar: {
      overflowY: overlay,

      '&::-webkit-scrollbar': {
        paddingLeft: '1px',
        width: '6px',
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-track-piece': {
        background: COLORS.YET_ANOTHER_GRAY_9,
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-thumb': {
        '&:hover': {
          background: COLORS.CHARCOAL,
        },

        background: COLORS.YET_ANOTHER_GRAY_10,
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-button:end:increment': {
        display: 'block',
        background: 'transparent',
      },
    },

    MuiPaper: {
      styleOverrides: { root: { backgroundImage: 'unset' } },
    },

    MuiTooltip: {
      styleOverrides: {
        root: {
          tooltip: {
            fontSize: '.875em',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          button: {
            '&.Mui-selected': {
              backgroundColor: '#F5F2ED',
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#313131',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused fieldset': {
            borderColor: '#313131 !important',
            outline: 'none',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        transitionDuration: {
          enter: 150,
          exit: 0,
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#ff456a',
      main: '#E54439',
      dark: '#b21030',
      mainShades: {
        200: 'hsl(348, 100%, 85%)',
        300: 'hsl(348, 100%, 75%)',
        400: 'hsl(348, 100%, 65%)',
        500: 'hsl(348, 100%, 55%)',
        600: 'hsl(348, 100%, 45%)',
      },
      contrastText: '#fff',
    },
    secondary: {
      light: '#959595',
      main: '#757575',
      dark: '#555555',
      contrastText: '#fff',
    },
    error: {
      light: '#ff456a',
      main: '#E54439',
      dark: '#b21030',
      contrastText: '#fff',
    },
    warning: {
      light: '#fab85c',
      main: '#f9a32c',
      dark: '#df8507',
      contrastText: '#fff',
    },
  },
  eatclub: {
    typography: {
      // New styles, 2023
      h2: {
        fontSize: FONT_SIZES.H2_NEW,
        fontWeight: FONT_WEIGHTS.BLACK,
        lineHeight: 'normal',
      },
      default: {
        fontSize: FONT_SIZES.DEFAULT,
        fontWeight: FONT_WEIGHTS.REGULAR,
      },
      paragraph: {
        fontSize: FONT_SIZES.DEFAULT,
        fontWeight: FONT_WEIGHTS.REGULAR,
        lineHeight: '1.7',
      },
      paragraphLarge: {
        fontSize: FONT_SIZES.L,
        fontWeight: FONT_WEIGHTS.REGULAR,
        lineHeight: '1.7',
      },
      // OLD STYLES
      heading: {
        HXL: {
          fontSize: FONT_SIZES.HXL,
          fontWeight: FONT_WEIGHTS.BLACK,
          lineHeight: 'normal',
          [breakpointsTheme.breakpoints.down('sm')]: {
            fontSize: '40px',
          },
        },
        H1: {
          fontSize: FONT_SIZES.H1,
          fontWeight: FONT_WEIGHTS.BLACK,
          lineHeight: 'normal',

          [breakpointsTheme.breakpoints.down('sm')]: {
            fontSize: '32px',
          },
        },
        H2: {
          fontSize: FONT_SIZES.H2,
          fontWeight: FONT_WEIGHTS.BLACK,
          lineHeight: 'normal',
          [breakpointsTheme.breakpoints.down('sm')]: {
            fontSize: '28px',
          },
        },
        H3: {
          fontSize: FONT_SIZES.H3,
          fontWeight: FONT_WEIGHTS.BLACK,
          lineHeight: 'normal',
        },
        H4: {
          fontSize: FONT_SIZES.H4,
          fontWeight: FONT_WEIGHTS.BOLD,
          lineHeight: 'normal',
        },
        H5: {
          fontSize: FONT_SIZES.H5,
          fontWeight: FONT_WEIGHTS.REGULAR,
          lineHeight: 'normal',
        },
        SUBHEAD: {
          fontSize: '12px',
          fontWeight: FONT_WEIGHTS.REGULAR,
          lineHeight: 'normal',
        },
      },
      body: {
        PXL: {
          fontSize: '22px',
          lineHeight: '26px',
          [breakpointsTheme.breakpoints.down('xs')]: {
            fontSize: '18px',
            lineHeight: '26px',
          },
        },
        PL: {
          fontSize: '18px',
        },
        P: {
          fontSize: '16px',
          lineHeight: '16px',
        },
        PS: {
          fontSize: '14px',
          lineHeight: '14px',
        },
        // Old styles below TODO REPLACE ALL USAGES WITH THE ABOVE
        PXS: {
          // NOTE: I added this one for the existing tiny text
          fontSize: '12px',
        },
        bodyText: {
          fontSize: FONT_SIZES.M,
          fontWeight: FONT_WEIGHTS.REGULAR,
          lineHeight: LINE_HEIGHTS.L,
        },
        textMedium: {
          fontSize: FONT_SIZES.L,
          fontWeight: FONT_WEIGHTS.MEDIUM,
          lineHeight: LINE_HEIGHTS.XL,
        },
        // heading: {
        //   fontSize: FONT_SIZES.XL2,
        //   fontWeight: FONT_WEIGHTS.BOLD,
        //   lineHeight: LINE_HEIGHTS.XXL,
        // },
        // headingLarge: {
        //   fontSize: FONT_SIZES['3XL'],
        //   fontWeight: FONT_WEIGHTS.BLACK,
        //   lineHeight: LINE_HEIGHTS['3XL'],
        // },
        subheading: {
          fontSize: FONT_SIZES.L,
          fontWeight: FONT_WEIGHTS.BOLD,
          lineHeight: LINE_HEIGHTS.L2,
        },
        small: {
          fontWeight: FONT_WEIGHTS.REGULAR,
          fontSize: FONT_SIZES.S,
          lineHeight: LINE_HEIGHTS.XL,
          color: COLORS.CHARCOAL,
          verticalAlign: 'baseline',
        },
        smallBold: {
          fontWeight: FONT_WEIGHTS.BOLD,
          fontSize: FONT_SIZES.S,
          lineHeight: LINE_HEIGHTS.XL,
        },
        labelText: {
          fontSize: FONT_SIZES.XS,
          fontWeight: FONT_WEIGHTS.REGULAR,
          textTransform: 'uppercase',
          letterSpacing: '0.13em',
          width: 'fit-content',
        },
        textLarge: {
          fontSize: FONT_SIZES.L,
          fontWeight: FONT_WEIGHTS.REGULAR,
          // lineHeight: LINE_HEIGHTS.L2,
          lineHeight: '1.75',
        },
        // textExtraLarge: {
        //   fontSize: FONT_SIZES.XL2,
        //   fontWeight: FONT_WEIGHTS.REGULAR,
        //   lineHeight: LINE_HEIGHTS.XXL,
        // },
        textModalHeading: {
          fontSize: FONT_SIZES.XXL2,
          fontWeight: FONT_WEIGHTS.BLACK,
          lineHeight: LINE_HEIGHTS.XXL,
        },
        // textPageHeading: {
        //   fontSize: FONT_SIZES['4XL'],
        //   fontWeight: FONT_WEIGHTS.BLACK,
        //   lineHeight: LINE_HEIGHTS['4XL'],
        // },
        buttonText: {
          fontWeight: FONT_WEIGHTS.MEDIUM,
          fontSize: '15px',
          lineHeight: 'auto',
        },
      },
    },
  },
});

export default theme;
