import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Launch } from '@mui/icons-material';
import BookingsOrderDialog from './BookingsOrderDialog';

const BookingsOrder = ({ orderId, region }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const handleClickOpen = () => {
    if (!openDialog) {
      setOpenDialog(true);
      setShowDialog(true);
    } else {
      setOpenDialog(false);
      setShowDialog(false);
      setShowLoading(false);
    }
  };

  const handleClose = (clearOrder) => {
    setOpenDialog(false);
    setTimeout(() => {
      setShowDialog(false);
      setShowLoading(false);
      clearOrder();
    }, 300);
  };

  const handleShowLoading = (loading) => {
    setShowLoading(loading);
  };

  return (
    <>
      <Tooltip title='View Order'>
        <IconButton size='large' variant='contained' color='primary' onClick={handleClickOpen}>
          {showLoading ? <CircularProgress color='inherit' size={22} /> : <Launch />}
        </IconButton>
      </Tooltip>

      {showDialog && (
        <BookingsOrderDialog
          open={openDialog}
          onClose={handleClose}
          orderId={orderId}
          region={region}
          handleShowLoading={handleShowLoading}
        />
      )}
    </>
  );
};

BookingsOrder.propTypes = {
  orderId: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
};

export default BookingsOrder;
