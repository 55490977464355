import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  topOpportunityRow: {
    paddingTop: '24px',
    paddingBottom: '24px',
    width: '100%',
    margin: '0',
  },
  topOpportunityCard: {
    border: '1px solid #DCDCDB',
    borderRadius: '6px',
    color: '#313131',
    maxWidth: '380px',
  },
  opportunityCardContent: {
    padding: '24px 20px',
  },
  topOpportunityCardPoints: {
    fontSize: '16px',
    marginTop: '28px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  cardBottom: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '16px',
    // height: '48px',
    cursor: 'pointer',
    borderTop: '1px solid #DCDCDB',
    '&:hover': {
      backgroundColor: '#DCDCDB',
    },
  },
  desktopOnly: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  mobileOnly: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  opportunityName: {
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#313131',

    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '18px',
    // },
  },
  opportunityDescription: {
    paddingTop: '8px',
    fontSize: '18px',

    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '14px',
    // },
  },
  opportunityDetailsContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    rowGap: '15px',
  },
  opportunityDetails: {
    ...theme.eatclub.typography.H1,
    paddingTop: '20px',
    // fontSize: '16px',
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    gap: '8px 20px',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  opportunityDetailRow: {
    display: 'flex',
    gap: '10px 20px',
    flexWrap: 'wrap',
  },
  opportunityDetailColumnLeft: {
    display: 'flex',
    gap: '5px',
    whiteSpace: 'nowrap',
  },
  opportunityDetailColumnRight: {
    display: 'flex',
    gap: '5px',
  },
  opportunityDetailValue: {
    color: COLORS.SMOKE,
  },
  postButtonContainer: {
    width: '125px',
  },
  estimatesContainer: {
    display: 'flex',
    marginRight: '40px',
    columnGap: '30px',
  },
  labelsContainer: {
    width: '160px',
  },
  largeNumber: {
    color: 'transparent',
    WebkitTextStroke: '1px #6E6E6D',
    fontSize: '44px',
    fontWeight: '900',
    position: 'absolute',
    left: '15px',
    top: '0',
  },
  largeNumberBackground: {
    height: '48px',
    width: '48px',
    backgroundColor: '#F0F5F4',
    borderRadius: '50%',
    display: 'inline-block',
  },
  label: {
    textTransform: 'uppercase',
    letterSpacing: '0.13em',
    fontSize: '12px',
  },
  labelValueContainer: (props) => ({
    display: 'inline-flex',
    gap: '5px',
    padding: '2px 8px',
    borderRadius: '4px',
    backgroundColor: '#F8E4E0',
    marginTop: props?.compact ? '8px' : '16px',
    fontSize: props?.compact ? '16px' : '18px',
  }),
  redLabel: {
    color: '#E7554B',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
