import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { statusValues } from '../../../data/models/Deal';
import { getLabelForValue } from '../../../utils/helpers';
import useStyles from './OfferCardStyles';

const OfferStatus = ({ status }) => {
  const classes = useStyles({ status });
  return <Box className={classes.statusLabel}>{getLabelForValue(status, statusValues)}</Box>;
};

OfferStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OfferStatus);
