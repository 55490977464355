import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Modal,
  CenteredContent,
  Button,
  RadioButtonGroup,
} from '@eatclub-apps/ec-component-library';
import { useSelector } from 'react-redux';
import { dealPropTypes } from '../../../data/models/Deal';
import { COLORS } from '../../../EatClubTheme';
import { trackEvent } from '../../../utils/analytics';
import { getDayFromInt, formatCurrency, getOfferTime } from '../../../utils/helpers';
import useStyles, { modalInputStyle, modalStyle } from './ModalStyles';
import Disabled from '../../../assets/disabled.svg';
import ModalSectionHeader from './ModalSectionHeader';

const DisableOfferModal = ({ isOpen, offer, onClose, onSave }) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const goals = useSelector((state) => state.goals);

  const goalForOffer = goals.data.find((goal) => goal?.objectId === offer?.goalId);

  const [typeToDisable, setTypeToDisable] = useState('dineInAndTakeaway');

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: disable_offer');
    }
  }, [isOpen]);

  return (
    <Modal
      inputStyle={modalInputStyle}
      fullWidth={false}
      style={modalStyle}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
      footer={[
        <Button
          onClick={onClose}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='tertiary'
        >
          Cancel
        </Button>,
        <Button
          onClick={() => {
            trackEvent('button_click: confirm_disable_offer');
            onSave(null, typeToDisable);
          }}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='primary'
        >
          Disable offer
        </Button>,
      ]}
    >
      {isOpen && (
        <Box className={classes.modalBody}>
          <Box className={classes.modalContent}>
            <Box display='flex' alignItems='center' style={{ gap: '8px' }}>
              <Disabled height='24px' width='24px' />
              <Box className={classes.modalHeader}>Disable offer</Box>
            </Box>
            <Box>
              <Box className={classes.inlineText}>
                <Box className={classes.discountLabel}>{offer?.discount}</Box>
                <CenteredContent>
                  on {getDayFromInt(offer?.dayOfWeek)} {getOfferTime(offer)}
                </CenteredContent>
              </Box>
            </Box>
            <Box className={classes.radioButtonContainer}>
              <RadioButtonGroup
                items={[
                  { label: 'Disable both dine-in and takeaway', value: 'dineInAndTakeaway' },
                  { label: 'Disable dine-in only', value: 'dineInOnly' },
                  { label: 'Disable takeaway only', value: 'takeawayOnly' },
                ]}
                onSelect={setTypeToDisable}
                value={typeToDisable}
                style={{
                  buttonCircleInnerSelected: {
                    backgroundColor: COLORS.OLIVE,
                  },
                }}
              />
            </Box>
            {goalForOffer && offer?.revenueExpected > 0 && (
              <Box>
                <ModalSectionHeader header='Impact' />
                <Box className={classes.impactRow}>
                  <Box>Change in weekly revenue</Box>
                  <Box className={classes.redLabel}>
                    -{formatCurrency(offer?.revenueExpected, false)}
                  </Box>
                </Box>
              </Box>
            )}
            <Box>This will be disabled until end of business tonight.</Box>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

DisableOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  offer: dealPropTypes.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DisableOfferModal;
