import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { contactBilling } from '../graphql/mutations';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

export const contactBillingAction = (message, reason, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.CONTACT_BILLING_PENDING,
  });

  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'CONTACT_BILLING',
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: contactBilling,
          variables: { message, reason, restId },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      const parsedResponse = JSON.parse(response.data.contactBilling);

      devLog('success', 'contact billing', parsedResponse);

      if (parsedResponse.result !== 'success') {
        devLog('error', 'contact billing', 'no success');

        dispatch({
          type: type.CONTACT_BILLING_FAILURE,
          payload: `Unable to contact billing.`,
        });
      }

      dispatch({
        type: type.ADD_MESSAGE,
        payload: {
          id: `CONTACT_BILLING_${new Date().getTime()}`,
          message: `Message sent!`,
          severity: 'success',
        },
      });

      dispatch({
        type: type.CONTACT_BILLING_SUCCESS,
        payload: response.data.contactBilling,
      });
    } catch (error) {
      devLog('error', 'contact billing', error);

      dispatch({
        type: type.ADD_MESSAGE,
        payload: {
          id: `CONTACT_BILLING_${new Date().getTime()}`,
          message: `Unable to contact billing: ${error}`,
          severity: 'error',
        },
      });

      dispatch({
        type: type.CONTACT_BILLING_FAILURE,
        payload: `Unable to contact billing: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'CONTACT_BILLING',
      });
    }
  })();
};
