import * as type from '../actions/types';

const initialState = {
  data: [],
  error: '',
  pending: false,
  success: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.REQUEST_CALL_PENDING: {
      return {
        ...state,
        pending: true,
        error: '',
        success: false,
      };
    }

    case type.REQUEST_CALL_SUCCESS: {
      return {
        ...state,
        pending: false,
        success: true,
      };
    }

    case type.REQUEST_CALL_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        pending: false,
        success: false,
        error,
      };
    }

    case type.CLEAR_REQUEST_CALL:
      return {
        ...state,
        data: [],
        pending: false,
        success: false,
        error: '',
      };

    default:
      return state;
  }
};
