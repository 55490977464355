import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Hub } from 'aws-amplify';
import {
  Authenticator,
  Greetings,
  SignUp,
  ForgotPassword,
  SignIn,
  VerifyContact,
} from 'aws-amplify-react';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import partnerConfig from '../../partner-aws-exports';
import staffConfig from '../../staff-aws-exports';
import { devLog } from '../../utils';
import CustomSignIn from './CustomSignIn';
import App from '../../App';
import { fetchSsoCredsAction } from '../../actions/loginAction';
import { revertObscureString, isEmpty } from '../../utils/helpers';

const AppWithAuth = ({ ssoCreds, fetchSsoCreds }) => {
  const partnerSession = localStorage.getItem('mode') === 'partner';
  const staffSession = localStorage.getItem('mode') === 'staff';

  const location = useLocation();
  const staffLogin = location?.pathname === '/staff';

  const params = new URLSearchParams(window.location.search);
  const ssoToken = params.get('ssoToken');

  const [authSignedIn, setAuthSignedIn] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);

  const staffSignOut = () => {
    window.location.href = `${import.meta.env.VITE_LOGIN_URL}?auth=signOut&callback_url=${
      window.location.origin
    }/staff${params.get('restId') ? `&restId=${params.get('restId')}` : ''}`;
  };

  useEffect(() => {
    if (params.get('origin') === 'hub' && partnerSession) {
      // localStorage.clear(); // TODO don't want to wipe all local storage. Check what Obee FOH is doing
    }

    if (staffLogin && ssoToken) {
      fetchSsoCreds(ssoToken);
    }

    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          setAuthSignedIn(true);
          break;
        case 'signOut':
          if (staffSession) {
            staffSignOut();
          } else {
            setAuthSignedIn(false);
            setAuthLoading(false);
          }
          break;
        case 'customOAuthState':
          break;
        case 'signIn_failure':
          break;
        default:
          break;
      }
    });
  }, [fetchSsoCreds, ssoToken]);

  useEffect(() => {
    if (ssoCreds?.success) {
      const creds = revertObscureString(ssoCreds?.data).split(';');

      let keyPrefix = `CognitoIdentityServiceProvider.${
        import.meta.env.VITE_AWS_STAFF_USER_POOLS_WEB_CLIENT_ID
      }`;
      const cognitoLastAuthUser = creds[1];

      localStorage.setItem(`${keyPrefix}.LastAuthUser`, cognitoLastAuthUser);

      keyPrefix = `${keyPrefix}.${cognitoLastAuthUser}`;

      localStorage.setItem(`${keyPrefix}.accessToken`, creds[0]);
      localStorage.setItem(`${keyPrefix}.idToken`, creds[2]);
      localStorage.setItem(`${keyPrefix}.refreshToken`, creds[3]);
      localStorage.setItem(`mode`, 'staff');

      params.delete('ssoToken');
      const paramsString = params.toString();
      window.location.href = `${window.location.origin}${paramsString ? `?${paramsString}` : ''}`;
    }
  }, [ssoCreds?.success]);

  const mapErrorMessages = (message) => {
    if (message === 'Custom auth lambda trigger is not configured for the user pool.') {
      return 'Please enter your password';
    }
    return message;
  };

  const signUpConfig = {
    header: 'Sign Up to EatClub',
    hiddenDefaults: ['phone_number'],
  };

  const customTheme = {
    button: {
      backgroundColor: '#E54439',
      fontFamily: 'Gordita',
      width: '100%',
      borderRadius: '4px',
    },
    a: {
      color: '#E54439',
    },
    navButton: {
      backgroundColor: '#E54439',
      fontFamily: 'Gordita',
    },
    sectionFooter: {
      display: 'block',
    },
    sectionFooterPrimaryContent: {
      display: 'block',
      marginBottom: '12px',
    },
    toast: {
      backgroundColor: '#E54439',
    },
  };

  // On successful login, if a query param is present, redirect to that url
  useEffect(() => {
    const internalRedirect = params.get('redirectTo');
    if (authSignedIn && !isEmpty(internalRedirect)) {
      devLog('info', 'Redirecting after login', internalRedirect);
      const params = new URLSearchParams(location.search);
      params.delete('redirectTo');

      // Note: This assumes the internal redirect begins with a /
      window.location.href = `${import.meta.env.VITE_URL_PREFIX}${internalRedirect}`;
    }
  }, [authSignedIn]);

  const staffRedirect = () => {
    if (!ssoToken) {
      window.location.href = `${import.meta.env.VITE_LOGIN_URL}?callback_url=${
        window.location.origin
      }/staff${params.get('restId') ? `&restId=${params.get('restId')}` : ''}`;
    }
  };

  return (
    <Authenticator
      onStateChange={(authState) => {
        if (authState === 'signedIn') {
          // NOTE: this will trigger when refreshing and logged in
          // show app here to prevent showing both login screen, and app
          setAuthSignedIn(true);
          setAuthLoading(false);
          return;
        }
        if (authState === 'signIn') {
          setAuthLoading(false);
        }
      }}
      hide={[Greetings, SignIn, SignUp, ForgotPassword, VerifyContact]}
      includeGreetings={false}
      amplifyConfig={(staffLogin || staffSession) && !partnerSession ? staffConfig : partnerConfig}
      theme={customTheme}
      signUpConfig={signUpConfig}
      usernameAttributes='email'
      errorMessage={mapErrorMessages}
    >
      <>
        {ssoCreds?.error && staffSignOut()}

        {authSignedIn && <App />}
        {!authLoading && !authSignedIn && staffLogin && staffRedirect()}
        {!authLoading && !authSignedIn && !staffLogin && (
          <CustomSignIn authSignedIn={authSignedIn} />
        )}
      </>
    </Authenticator>
  );
};

AppWithAuth.propTypes = {
  fetchSsoCreds: PropTypes.func.isRequired,
  ssoCreds: PropTypes.shape({
    data: PropTypes.shape({}),
    error: PropTypes.string,
    pending: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  ssoCreds: state.ssoCreds,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSsoCreds: fetchSsoCredsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppWithAuth);
