import { makeStyles } from '@mui/styles';
import { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '8px',
    color: '#313131',
    padding: '40px 22px 22px 22px',

    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px 20px 20px',
    },
  },
  bannerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    flexWrap: 'wrap',
    rowGap: '20px',
  },
  analyticsTotals: {
    gap: '22px',
    paddingRight: '57px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  redText: {
    color: COLORS.PRIMARY_RED_MATTE,
    fontSize: '14px',
  },
  greenText: {
    color: COLORS.DARKER_SUNNY_GREEN,
    fontSize: '14px',
  },
  heading: {
    ...theme.eatclub.typography.heading.H3,
  },
  target: {
    marginBottom: '20px',
    marginTop: '20px',
    ...theme.eatclub.typography.body.PXL,
    color: COLORS.SMOKE,
  },
  totalImage: {
    minWidth: '60px',
    minHeight: '60px',
    width: '60px',
    height: '60px',
  },
  label: {
    ...theme.eatclub.typography.heading.SUBHEAD,
    color: COLORS.SMOKE,
    letterSpacing: '1.5px',
  },
  totalValue: {
    fontSize: '22px',
    fontWeight: FONT_WEIGHTS.MEDIUM,
    // ...theme.eatclub.typography.body.PXL
  },
  card: {
    borderRadius: '8px',
    border: '1px solid #DCDCDB',
    width: '100%',
    fontSize: '18px',
    color: '#313131',
    // minHeight: open ? '150px' : '75px',
    // transition: 'height 0.2s',
    cursor: 'pointer',
    userSelect: 'none',
  },
  cardInner: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  cardTitle: {
    alignItems: 'center',
    fontWeight: 'bold',
  },
  cardDate: {
    alignItems: 'center',
    color: COLORS.SMOKE,
  },
  arrowContainer: {
    display: 'flex',
    gap: '85px',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
