import { Box, FormHelperText, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Modal,
  CenteredContent,
  Button,
  Dropdown,
  RadioButtonGroup,
  TextArea,
} from '@eatclub-apps/ec-component-library';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { dealPropTypes } from '../../../data/models/Deal';
import { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';
import { trackEvent } from '../../../utils/analytics';
import {
  getDayFromInt,
  formatCurrency,
  getOfferTime,
  isVoid,
  isStaffUser,
} from '../../../utils/helpers';
import useStyles, { modalInputStyle, modalStyle } from './ModalStyles';
import ModalSectionHeader from './ModalSectionHeader';
import Bin from '../../../assets/bin.svg';

const DeleteOfferModal = ({ isOpen, offer, onClose, onSave }) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const goals = useSelector((state) => state.goals);
  const user = useSelector((state) => state.user);

  const goalForOffer = goals?.data?.find((goal) => goal?.objectId === offer?.goalId);

  const [allOccurrences, setAllOccurrences] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState('dineInAndTakeaway');

  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteReasonMessage, setDeleteReasonMessage] = useState(null);

  const [deleteReasonError, setDeleteReasonError] = useState(null);

  const goalEndDate = moment(new Date(goalForOffer?.endDate), 'DD/MM/YYYY');
  const currentDate = moment(moment(new Date()).format('DD/MM/YYYY'), 'DD/MM/YYYY');
  const diffInWeeks = goalEndDate.diff(currentDate, 'weeks');

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      setDeleteReason(null);
      setDeleteReasonMessage(null);
      setDeleteReasonError(null);
      trackEvent('modal_view: delete_offer');
    }
  }, [isOpen]);

  return (
    <Modal
      inputStyle={modalInputStyle}
      fullWidth={false}
      style={modalStyle}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
      footer={[
        <Button
          onClick={onClose}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='tertiary'
        >
          Cancel
        </Button>,
        <Button
          onClick={() => {
            trackEvent('button_click: confirm_delete_offer');

            if (!isStaffUser(user) && isVoid(deleteReason)) {
              setDeleteReasonError('A reason for deleting the offer is required');
            } else {
              setDeleteReasonError(null);
              onSave(deleteReason, deleteReasonMessage, allOccurrences, typeToDelete);
            }
          }}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='primary'
        >
          Delete offer
        </Button>,
      ]}
    >
      {isOpen && (
        <Box className={classes.modalBody}>
          <Box className={classes.modalContent}>
            <Box display='flex' alignItems='center' style={{ gap: '8px' }}>
              <Bin height='24px' width='24px' />
              <Box className={classes.modalHeader}>Delete offer</Box>
            </Box>
            <Box>
              <Box className={classes.inlineText}>
                <Box>
                  <Box className={classes.discountLabel}>{offer?.discount}</Box>
                </Box>
                <CenteredContent>
                  on {getDayFromInt(offer?.dayOfWeek)} {getOfferTime(offer)}
                  {offer.recurring ? ', repeats weekly' : ''}
                </CenteredContent>
              </Box>
            </Box>
            {offer?.recurring && (
              <Box className={classes.buttonGroupContainer}>
                <RadioButtonGroup
                  items={[
                    { label: 'Remove just this offer', value: false },
                    { label: 'Remove this and all future occurrences', value: true },
                  ]}
                  onSelect={setAllOccurrences}
                  value={allOccurrences}
                  style={{
                    buttonCircleInnerSelected: {
                      backgroundColor: COLORS.OLIVE,
                    },
                  }}
                />
              </Box>
            )}
            <Box>
              <Dropdown
                items={[
                  { label: 'Dine-in and takeaway', value: 'dineInAndTakeaway' }, // TODO only show if deal is both dinein and takeaway
                  { label: 'Takeaway only', value: 'takeaway' },
                  { label: 'Dine-in only', value: 'dineIn' },
                ]}
                onSelect={setTypeToDelete}
                style={{ fontSize: '16px' }}
                value={typeToDelete}
              />
            </Box>
            <Box>
              {offer?.revenueExpected > 0 && (
                <Box mt='24px'>
                  <Box mt='24px'>
                    <ModalSectionHeader header='Impact' />

                    <Box className={classes.impactRow}>
                      <Box>Change in weekly revenue</Box>
                      <Box className={classes.redLabel}>
                        -{formatCurrency(offer?.revenueExpected, false)}
                      </Box>
                    </Box>
                    {!goalForOffer && allOccurrences && (
                      <Box className={classes.impactRow}>
                        <Box>Change in 12 month revenue</Box>
                        <Box className={classes.redLabel}>
                          -{formatCurrency(offer?.revenueMonthlyExpected * 12, false)}
                        </Box>
                      </Box>
                    )}
                    {goalForOffer && (
                      <>
                        <Box className={classes.impactRow}>
                          <Box>Goal period change</Box>
                          <Box className={classes.redLabel}>
                            -
                            {formatCurrency(
                              offer?.revenueExpected * (allOccurrences ? diffInWeeks : 1),
                              false,
                            )}
                          </Box>
                        </Box>
                        <Box className={classes.impactRow}>
                          <Box>Adjusted revenue goal</Box>
                          <Box style={{ fontWeight: FONT_WEIGHTS.MEDIUM }}>
                            {formatCurrency(
                              goalForOffer?.targetRevenue -
                                offer?.revenueExpected * (allOccurrences ? diffInWeeks : 1),
                              false,
                            )}
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </Box>

            {!isStaffUser(user) && (
              <Box>
                <ModalSectionHeader header='Let us know why' />

                <Box mt='20px' display='flex' flexDirection='column' style={{ gap: '15px' }}>
                  <Box>
                    <Dropdown
                      allowDeselect
                      items={[
                        { label: 'Restaurant became busy', value: 'busy' },
                        { label: 'Staffing issue', value: 'staffIssue' },
                        { label: "Time isn't suitable", value: 'badTime' },
                        { label: 'Offer percentage too high', value: 'offerTooHigh' },
                        { label: 'Other', value: 'other' },
                      ]}
                      onSelect={(value) => {
                        setDeleteReasonError(null);
                        setDeleteReason(value);
                      }}
                      placeholder='Select a reason for deleting the offer'
                      value={deleteReason}
                    />
                    <FormHelperText error>{deleteReasonError}</FormHelperText>
                  </Box>

                  <TextArea
                    placeholder='Additional Reason?'
                    resizable={false}
                    value={deleteReasonMessage}
                    onChange={setDeleteReasonMessage}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Modal>
  );
};

DeleteOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  offer: dealPropTypes.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DeleteOfferModal;
