import PropTypes from 'prop-types';

export const userPropTypes = PropTypes.shape({
  userInfo: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    objectId: PropTypes.string,
    userType: PropTypes.string,
    restaurants: PropTypes.arrayOf(PropTypes.shape({})), // TODO fix this
  }),
  userFetching: PropTypes.bool,
  userError: PropTypes.bool,
  userErrorMessage: PropTypes.string,
});
