/* eslint-disable */
import React from 'react';
import MenuTabPanelV2 from './v2/MenuTabPanel';
import MenuTabPanel from './MenuTabPanel';

const MenuTabPanelContainer = ({ index, value, menu }) => (
  <div role='tabpanel' hidden={value !== index}>
    {value === index &&
      (menu?.version === 1 ? <MenuTabPanelV2 menu={menu} /> : <MenuTabPanel menu={menu} />)}
  </div>
);

MenuTabPanelContainer.propTypes = {};

export default MenuTabPanelContainer;
