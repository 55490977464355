import React from 'react';
// import PropTypes from "prop-types";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Add } from '@mui/icons-material';

const CreateDealButton = () => (
  <Button
    component={Link}
    variant='contained'
    color='primary'
    // size="small"
    to='/deals/create'
    startIcon={<Add />}
  >
    Create Deal
  </Button>
);

CreateDealButton.propTypes = {};

export default CreateDealButton;
