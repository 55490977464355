/* eslint-disable */
import React from 'react';
import { IconButton, Popover, Typography, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AddCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    color: '#fff',
    backgroundColor: theme.palette.secondary.dark,
  },
  buttonRoot: {
    backgroundColor: '#fff',
  },
}));

const CreateDealTimeButton = ({ deal, selectedDay, timePeriods }) => {
  const classes = useStyles();
  const theme = useTheme();
  const breakpointMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const timePeriod = timePeriods.find((timePeriod) => timePeriod.name === deal.time);

  const getStartDate = () => {
    const today = moment().weekday();
    const dayOffset = selectedDay < today ? 7 : 0;
    const startDate = moment()
      .weekday(selectedDay + dayOffset)
      .format('YYYY-MM-DD');
    return startDate;
  };

  return (
    <>
      {breakpointMdDown ? (
        <IconButton
          color='primary'
          variant='contained'
          size='medium'
          style={{ position: 'absolute', left: deal.xAxis - 25, bottom: 80 }}
          onTouchStart={handlePopoverOpen}
          onTouchEnd={handlePopoverClose}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          component={Link}
          to={`/deals/create?startTime=${timePeriod.start.format(
            'hh:mm a',
          )}&endTime=${timePeriod.end.format('hh:mm a')}&startDate=${getStartDate()}`}
        >
          <AddCircle />
        </IconButton>
      ) : (
        <Button
          color='primary'
          variant='text'
          className={classes.buttonRoot}
          size='medium'
          component={Link}
          to={`/deals/create?startTime=${timePeriod.start.format(
            'hh:mm a',
          )}&endTime=${timePeriod.end.format('hh:mm a')}&startDate=${getStartDate()}`}
          style={{ position: 'absolute', left: deal.xAxis - 30, bottom: 67 }}
        >
          <Box display='flex' flexDirection='column' alignItems='center'>
            <AddCircle style={{ marginBottom: '.5rem' }} />
            <span>Deal</span>
          </Box>
        </Button>
      )}
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>Create Deal</Typography>
      </Popover>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedDay: state.dealsByDay.selectedDay,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CreateDealTimeButton);
