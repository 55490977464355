import * as type from '../actions/types';

const initialState = {
  data: {},
  error: '',
  pending: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_SSO_CREDS_PENDING: {
      return {
        ...state,
        ...initialState,
        pending: true,
      };
    }

    case type.FETCH_SSO_CREDS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        pending: false,
        success: true,
      };
    }

    case type.FETCH_SSO_CREDS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
