import * as type from './types';

export const bookingsForAnalytics = /* GraphQL */ `
  query BookingsForAnalytics(
    $endDate: AWSDate
    $offset: Int!
    $pageSize: Int!
    $platform: String!
    $region: String!
    $restId: String!
    $startDate: AWSDate
  ) {
    bookingsForAnalytics(
      endDate: $endDate
      offset: $offset
      pageSize: $pageSize
      platform: $platform
      region: $region
      restId: $restId
      startDate: $startDate
    ) {
      arrivalTime
      created
      dealId
      discount
      inAppOrder
      issueStatus
      name
      objectId
      orderId
      origTotal
      partySize
      platform
      promoTitle
      restId
      status
      tableNum
      tableOrders
      takeaway
      total
      transactionId
      revenue
    }
  }
`;
export const dealsByDay = /* GraphQL */ `
  query DealsByDay($day: Int, $restId: String!) {
    dealsByDay(day: $day, restId: $restId) {
      created
      customDiscount
      dayOfWeek
      dineInOnly
      disabledForDate
      discount
      enabled
      endDate
      endTime
      iaoOnly
      lastBookingDate
      lastUpdated
      lightning
      objectId
      origin
      qtyLeft
      recurring
      recurringQty
      redeemedLastWeek
      restId
      startDate
      startTime
      takeRate
      takeawayOnly
    }
  }
`;
export const dealsByDayBookings = /* GraphQL */ `
  query DealsByDayBookings($day: Int, $restId: String!) {
    dealsByDayBookings(day: $day, restId: $restId) {
      objectId
      dealId
      arrivalTime
    }
  }
`;
export const dealsByIds = /* GraphQL */ `
  query DealsByIds($dealIds: [String]!, $region: String!) {
    dealsByIds(dealIds: $dealIds, region: $region) {
      created
      customDiscount
      dayOfWeek
      dineInOnly
      disabledForDate
      discount
      enabled
      endDate
      endTime
      iaoOnly
      lastBookingDate
      lastUpdated
      lightning
      objectId
      origin
      qtyLeft
      recurring
      recurringQty
      redeemedLastWeek
      restId
      startDate
      startTime
      takeRate
      takeawayOnly
      goalId
      partySizeLower
      partySizeUpper
      partySizeActual
      revenueExpected
      revenueActual
    }
  }
`;
export const inAppOrderById = /* GraphQL */ `
  query InAppOrderById($orderId: String!, $region: String!) {
    inAppOrderById(orderId: $orderId, region: $region) {
      created
      discount
      items {
        category
        itemOptions {
          choices
          objectId
          optionTitle
          qty
        }
        itemTitle
        objectId
        origPrice
        price
        qty
      }
      address
      bookingId
      custTransFee
      custTransFeePercent
      deliveryFee
      email
      name
      name
      objectId
      origTotal
      partySize
      paymentMethod
      phone
      pickupTime
      pickupDate
      platform
      refundAmount
      refundReason
      refundType
      restId
      subtotal
      tableNum
      tip
      total
      transactionId
    }
  }
`;
export const invoiceByInvoiceId = /* GraphQL */ `
  query InvoiceByInvoiceId($invoiceId: String!) {
    invoiceByInvoiceId(invoiceId: $invoiceId) {
      invoiceCreated
      invoiceDueDate
      invoiceId
      invoiceNumber
      invoiceTotal
      invoiceUrl
      objectId
      restId
    }
  }
`;
export const invoicesByRestaurantId = /* GraphQL */ `
  query InvoicesByRestaurantId($offset: Int!, $pageSize: Int!, $restId: String!) {
    invoicesByRestaurantId(offset: $offset, pageSize: $pageSize, restId: $restId) {
      invoiceCreated
      invoiceDueDate
      invoiceId
      invoiceNumber
      invoiceTotal
      invoiceUrl
      objectId
      restId
    }
  }
`;
export const menuItemOptionsForMenuItem = /* GraphQL */ `
  query MenuItemOptionsForMenuItem($menuItemId: String!, $offset: Int, $pageSize: Int) {
    menuItemOptionsForMenuItem(menuItemId: $menuItemId, offset: $offset, pageSize: $pageSize) {
      choices
      enabled
      mandatory
      maxSelections
      menuItemId
      minSelections
      objectId
      optionDescription
      optionIndex
      optionTitle
    }
  }
`;
export const menuItemsForMenu = /* GraphQL */ `
  query MenuItemsForMenu($includeOptions: Boolean, $menuId: String!, $offset: Int, $pageSize: Int) {
    menuItemsForMenu(
      includeOptions: $includeOptions
      menuId: $menuId
      offset: $offset
      pageSize: $pageSize
    ) {
      category
      disableDate
      enabled
      flagReason
      flags
      imageLink
      itemDescription
      itemIndex
      itemTitle
      menuId
      noDiscount
      objectId
      options
      price
    }
  }
`;
export const categoriesForMenu = /* GraphQL */ `
  query CategoriesForMenu($menuId: String!) {
    categoriesForMenu(menuId: $menuId) {
      objectId
      categoryTitle
      categoryIndex
      menuId
      restId
      maxQty
      products {
        objectId
        enabled
        productTitle
        productDescription
        productIndex
        imageLink
        price
        noDiscount
        disableDate
        disableUntilDate
        restId
        posId
        options {
          objectId
          enabled
          optionTitle
          optionDescription
          optionIndex
          mandatory
          minSelections
          maxSelections
          restId
          posId
          choices {
            objectId
            enabled
            choiceTitle
            choiceIndex
            price
            disableDate
            disableUntilDate
            restId
            posId
          }
        }
      }
    }
  }
`;
export const menusForRestaurant = /* GraphQL */ `
  query MenusForRestaurant($restId: String!) {
    menusForRestaurant(restId: $restId) {
      enabled
      endTime
      issueId
      menuDescription
      menuIndex
      menuTitle
      objectId
      platform
      restIds
      startTime
      type
      version
    }
  }
`;
export const numFavsByRestaurantId = /* GraphQL */ `
  query NumFavsByRestaurantId($restId: String!) {
    numFavsByRestaurantId(restId: $restId)
  }
`;
export const payoutsByRestaurantId = /* GraphQL */ `
  query PayoutsByRestaurantId(
    $offset: Int!
    $pageSize: Int!
    $platform: String!
    $restId: String!
  ) {
    payoutsByRestaurantId(
      offset: $offset
      pageSize: $pageSize
      platform: $platform
      restId: $restId
    ) {
      amount
      objectId
      payoutDate
      pdfLink
      platform
      salesPeriod
      summaryNumber
    }
  }
`;
export const restaurantById = /* GraphQL */ `
  query RestaurantById($restId: String!) {
    restaurantById(restId: $restId) {
      ${type.Restaurant}
    }
  }
`;
export const restaurantNameById = /* GraphQL */ `
  query RestaurantById($restId: String!) {
    restaurantById(restId: $restId) {
      ${type.RestaurantName}
    }
  }
`;
export const transactionsForRestaurant = /* GraphQL */ `
  query TransactionsForRestaurant(
    $endDate: AWSDate
    $offset: Int!
    $pageSize: Int!
    $region: String!
    $restId: String!
    $startDate: AWSDate
  ) {
    transactionsForRestaurant(
      endDate: $endDate
      offset: $offset
      pageSize: $pageSize
      region: $region
      restId: $restId
      startDate: $startDate
    ) {
      arrivalTime
      created
      dealId
      discount
      inAppOrder
      issueStatus
      name
      objectId
      orderId
      origTotal
      partySize
      platform
      promoTitle
      refundAmount
      refundReason
      refundType
      restId
      status
      tableNum
      tableOrders
      takeaway
      total
      transactionId
    }
  }
`;
export const transactionById = /* GraphQL */ `
  query transactionById($bookingId: String!) {
    transactionById(bookingId: $bookingId) {
      arrivalTime
      created
      dealId
      discount
      inAppOrder
      issueStatus
      name
      objectId
      orderId
      origTotal
      partySize
      platform
      promoTitle
      refundAmount
      refundReason
      refundType
      restId
      status
      tableNum
      tableOrders
      takeaway
      total
      transactionId
    }
  }
`;
export const userById = /* GraphQL */ `
  query UserById($userId: String!) {
    userById(userId: $userId) {
      email
      name
      objectId
      restaurants
    }
  }
`;
export const predictionsForDeal = /* GraphQL */ `
  query PredictionsForDeal(
    $dineIn: Boolean!
    $discount: String!
    $endTime: Int!
    $lightning: Boolean!
    $quantity: Int!
    $recurring: Boolean!
    $recurringDays: AWSJSON
    $restId: String!
    $startDate: AWSDate!
    $startTime: Int!
    $takeaway: Boolean!
  ) {
    predictionsForDeal(
      dineIn: $dineIn
      discount: $discount
      endTime: $endTime
      lightning: $lightning
      quantity: $quantity
      recurring: $recurring
      recurringDays: $recurringDays
      restId: $restId
      startDate: $startDate
      startTime: $startTime
      takeaway: $takeaway
    )
  }
`;
export const opposForToday = /* GraphQL */ `
  query OpposForTodayUserById($restId: String!) {
    opposForToday(restId: $restId) {
      alternates
      created
      dayOfWeek
      dineInOnly
      discount
      endTime
      iaoOnly
      lightning
      objectId
      prediction
      qty
      recurring
      startTime
      takeawayOnly
      name
      description
      revenueExpected
    }
  }
`;
export const dailyOrderSummaries = /* GraphQL */ `
  query DailyOrderSummaries($restId: String!, $pageSize: Int!, $offset: Int!, $platform: String) {
    dailyOrderSummaries(
      restId: $restId
      pageSize: $pageSize
      offset: $offset
      platform: $platform
    ) {
      amount
      objectId
      pdfLink
      platform
      salesPeriod
      summaryNumber
    }
  }
`;

export const monthlyOrderSummaries = /* GraphQL */ `
  query MonthlyOrderSummaries($restId: String!, $pageSize: Int!, $offset: Int!, $platform: String) {
    monthlyOrderSummaries(
      restId: $restId
      pageSize: $pageSize
      offset: $offset
      platform: $platform
    ) {
      amount
      objectId
      pdfLink
      platform
      salesPeriod
      summaryNumber
    }
  }
`;

export const potentialRevenues = /* GraphQL */ `
  query PotentialRevenues($restId: String!, $filter: String) {
    potentialRevenues(restId: $restId, filter: $filter) {
      restId
      offPeakOptimized
      offPeakRevenueLower
      offPeakRevenueUpper
      offPeakCustomersLower
      offPeakCustomersUpper
      lowerOffersOptimized
      lowerOffersRevenueLower
      lowerOffersRevenueUpper
      lowerOffersCustomersLower
      lowerOffersCustomerUpper
    }
  }
`;

export const potentialOffers = /* GraphQL */ `
  query PotentialOffers($restId: String!, $strategy: String!, $target: Int!, $filter: String) {
    potentialOffers(restId: $restId, strategy: $strategy, target: $target, filter: $filter) {
      objectId
      restId
      discount
      offerDate
      lightning
      startTime
      endTime
      dayOfWeek
      inPeak
      takeawayOnly
      dineInOnly
      iaoOnly
      qty
      targetVal
      targetValLower
      targetValUpper
      targetValMonthly
      targetValMonthlyLower
      targetValMonthlyUpper
      optimal
      optimality
      bookingsPrediction
      bookingsExpected
      bookingsLower
      bookingsUpper
      partySizePrediction
      partySizeExpected
      partySizeLower
      partySizeUpper
      partySizeMonthlyExpected
      partySizeMonthlyLower
      partySizeMonthlyUpper
      revenueExpected
      revenueLower
      revenueUpper
      contributionExpected
      contributionLower
      contributionUpper
      contributionADExpected
      contributionADLower
      contributionADUpper
      discountExpected
      discountLower
      discountUpper
      foodCostExpected
      foodCostLower
      foodCostUpper
      commissionExpected
      commissionLower
      commissionUpper
    }
  }
`;

export const goalsForRestaurant = /* GraphQL */ `
  query GoalsForRestaurant($restId: String!) {
    goalsForRestaurant(restId: $restId) {
      objectId
      startDate
      endDate
      origEndDate
      revenue
      targetRevenue
      targetRevenueLower
      targetRevenueUpper
      redemptions
      customers
      targetCustomersLower
      targetCustomersUpper
      aov
      dailyHistory {
        date
        dow
        goalId
        revenue
        revenueExpectedLower
        revenueExpectedUpper
        redemptions
        customers
        aov
        revenueExpected
        customersExpectedLower
        customersExpectedUpper
        orders
        bookings
      }
      duration
      autoAdjust
    }
  }
`;

export const dealsForGoal = /* GraphQL */ `
  query DealsForGoal($restId: String!, $goalId: String!) {
    dealsForGoal(restId: $restId, goalId: $goalId) {
      objectId
      created
      lastUpdated
      enabled
      restId
      discount
      customDiscount
      lightning
      takeawayOnly
      iaoOnly
      dineInOnly
      qtyLeft
      dayOfWeek
      recurring
      recurringQty
      startTime
      endTime
      startDate
      endDate
      takeRate
      redeemedLastWeek
      lastBookingDate
      disabledForDate
      origin
      goalId
      partySizeLower
      partySizeUpper
      revenueExpected
      partySizeActual
      revenueActual
    }
  }
`;

export const activeDealsForRestaurant = /* GraphQL */ `
  query ActiveDealsForRestaurant($restId: String!) {
    activeDealsForRestaurant(restId: $restId) {
      objectId
      created
      lastUpdated
      enabled
      restId
      discount
      customDiscount
      lightning
      takeawayOnly
      iaoOnly
      dineInOnly
      qtyLeft
      dayOfWeek
      recurring
      recurringQty
      startTime
      endTime
      startDate
      endDate
      takeRate
      redeemedLastWeek
      lastBookingDate
      disabledForDate
      origin
      goalId
      partySizeLower
      partySizeUpper
      revenueExpected
      partySizeActual
      revenueActual
    }
  }
`;

export const activeOffersForRestaurant = /* GraphQL */ `
  query ActiveOffersForRestaurant($restId: String!) {
    activeOffersForRestaurant(restId: $restId) {
      goalOfferId
      restId
      enabled
      discount
      lightning
      recurring
      startTime
      endTime
      startDate
      endDate
      dayOfWeek
      goalId
      partySizeLower
      partySizeUpper
      partySizeMonthlyLower
      partySizeMonthlyUpper
      revenueExpected
      revenueLower
      revenueUpper
      revenueMonthlyExpected
      revenueMonthlyLower
      revenueMonthlyUpper
      partySizeActual
      revenueActual
      onPeak
      deals {
        objectId
        created
        lastUpdated
        enabled
        restId
        discount
        customDiscount
        lightning
        takeawayOnly
        iaoOnly
        dineInOnly
        qtyLeft
        dayOfWeek
        recurring
        recurringQty
        startTime
        endTime
        startDate
        endDate
        takeRate
        redeemedLastWeek
        lastBookingDate
        disabledForDate
        origin
        goalId
      }
    }
  }
`;

export const offersForGoal = /* GraphQL */ `
  query OffersForGoal($restId: String!, $goalId: String!) {
    offersForGoal(restId: $restId, goalId: $goalId) {
      goalOfferId
      restId
      enabled
      discount
      lightning
      recurring
      startTime
      endTime
      startDate
      endDate
      dayOfWeek
      goalId
      partySizeLower
      partySizeUpper
      partySizeMonthlyLower
      partySizeMonthlyUpper
      revenueExpected
      revenueLower
      revenueUpper
      revenueMonthlyExpected
      revenueMonthlyLower
      revenueMonthlyUpper
      partySizeActual
      revenueActual
      onPeak
      deals {
        objectId
        created
        lastUpdated
        enabled
        restId
        discount
        customDiscount
        lightning
        takeawayOnly
        iaoOnly
        dineInOnly
        qtyLeft
        dayOfWeek
        recurring
        recurringQty
        startTime
        endTime
        startDate
        endDate
        takeRate
        redeemedLastWeek
        lastBookingDate
        disabledForDate
        origin
        goalId
      }
    }
  }
`;
export const staffById = /* GraphQL */ `
  query StaffById($staffId: String!) {
    staffById(staffId: $staffId) {
      email
      name
      objectId
    }
  }
`;
export const restaurantSearchByName = /* GraphQL */ `
  query RestaurantSearchByName($name: String!) {
    restaurantSearchByName(name: $name) {
      ${type.Restaurant}
    }
  }
`;

export const ssoCreds = /* GraphQL */ `
  query SsoCreds($ssoToken: String!) {
    ssoCreds(ssoToken: $ssoToken)
  }
`;

export const settlementsByRestId = /* GraphQL */ `
  query SettlementsByRestId($restId: String!, $pageSize: Int!, $page: Int!) {
    settlementsByRestId(restId: $restId, pageSize: $pageSize, page: $page) {
      ${type.Settlement}
    }
  }
`;

export const obeeSettings = /* GraphQL */ `
  query ObeeSettings($restId: String!) {
    obeeSettings(restId: $restId) {
      ${type.OBSettings}
    }
  }
`;

export const obeeSlugExists = /* GraphQL */ `
  query ObeeSlugExists($slug: String!) {
    obeeSlugExists(slug: $slug)
  }
`;

export const obeePricing = /* GraphQL */ `
  query ObeePricing($restId: String!) {
    obeePricing(restId: $restId)
  }
`;

export const affiliatedRestaurants = /* GraphQL */ `
  query AffiliatedRestaurants($restId: String!) {
    affiliatedRestaurants(restId: $restId) {
      ${type.Restaurant}
    }
  }
`;
