import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  clearPotentialRevenuesAction,
  fetchPotentialRevenuesAction,
} from '../../../actions/potentialRevenuesAction';
import { potentialRevenuesPropTypes } from '../../../reducers/potentialRevenuesReducer';
import DesktopRevenueBanner from './DesktopRevenueBanner';
import MobileRevenueBanner from './MobileRevenueBanner';

const RevenueBanner = ({
  fetchPotentialRevenues,
  clearPotentialRevenues,
  potentialRevenues,
  restId,
  userId,
  userType,
}) => {
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));

  useEffect(() => {
    clearPotentialRevenues();
  }, [restId]);

  // Fetch potential revenue
  useEffect(() => {
    if (!potentialRevenues?.success) {
      fetchPotentialRevenues(restId, userId, userType);
    }
  }, [potentialRevenues?.success]);

  if (breakpointXsDown) {
    return (
      <MobileRevenueBanner
        potentialRevenues={potentialRevenues}
        restId={restId}
        userId={userId}
        userType={userType}
      />
    );
  }

  return (
    <DesktopRevenueBanner
      potentialRevenues={potentialRevenues}
      restId={restId}
      userId={userId}
      userType={userType}
    />
  );
};

RevenueBanner.propTypes = {
  fetchPotentialRevenues: PropTypes.func.isRequired,
  clearPotentialRevenues: PropTypes.func.isRequired,
  potentialRevenues: potentialRevenuesPropTypes.isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  potentialRevenues: state.potentialRevenues,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPotentialRevenues: fetchPotentialRevenuesAction,
      clearPotentialRevenues: clearPotentialRevenuesAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RevenueBanner);
