import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '100px',
  },
  offersContainer: {
    paddingTop: '60px',
  },
  heading: {
    ...theme.eatclub.typography.heading.H3,
    marginBottom: '30px',
    lineHeight: '24px',
  },
}));

export default useStyles;
