import { makeStyles } from '@mui/styles';
import { COLORS, FONT_SIZES } from '../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingBottom: '20px',
    ...theme.eatclub.typography.body.PL,
  },
  twoColumn: {
    display: 'flex',
    flexBasis: '50%',
    justifyContent: 'space-between',
    maxWidth: '1000px', // Arbitrary number
    flexWrap: 'wrap',
    rowGap: '12px',
  },
  settings: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '410px',
  },
  iconLabel: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
}));

export default useStyles;

export const textFieldStyles = {
  label: {
    color: COLORS.SMOKE,
    marginBottom: '0',
    width: '150px',
    fontSize: FONT_SIZES.DEFAULT,
  },
  itemHover: {
    color: COLORS.CHARCOAL,
  },
  placeholder: {},
  textFieldInner: {
    borderRadius: '6px',
  },
  input: {
    color: COLORS.SMOKE,
    fontSize: FONT_SIZES.DEFAULT,
  },
};

export const dropdownStyles = {
  label: {
    color: COLORS.SMOKE,
    marginBottom: '0',
    width: '150px',
    fontSize: FONT_SIZES.DEFAULT,
  },
  itemHover: {
    color: COLORS.CHARCOAL,
  },
  disabled: {
    color: COLORS.SMOKE,
  },
  dropdownContentInner: {
    fontSize: FONT_SIZES.DEFAULT,
  },
};
