// TODO experimenting with having models to represent the different objects.
// This will hopefully allow re-usability of data (such as proptypes) and logic (such as data mapping)

// Export prop types for easy import in components that use this data
import PropTypes from 'prop-types';

export const goalPropTypes = PropTypes.shape({
  objectId: PropTypes.string.isRequired,
  targetRevenue: PropTypes.number.isRequired,
  revenue: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  origEndDate: PropTypes.string,
  endDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  customers: PropTypes.number.isRequired,
  aov: PropTypes.number.isRequired,
  dailyHistory: PropTypes.arrayOf(PropTypes.shape({})),
});

export const goalsPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(goalPropTypes),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

// TODO maybe value lists should be populated in the reducer?
export const statusValues = [
  { label: 'In progress', value: 'active' },
  { label: 'Complete', value: 'complete' },
  { label: 'On Track', value: 'ontrack' },
  { label: 'Off Track', value: 'offtrack' },
];

export const statuses = () => statusValues.map((statusValue) => statusValue?.value);

export const getStatus = (goal) => statuses[goal?.status] || null;
