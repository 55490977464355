import { Box, CircularProgress, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  Modal,
  CenteredContent,
  Button,
  Dropdown,
  SegmentedControl,
  TimePicker,
  ToggleGroup,
  TextSelect,
} from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash.debounce';
import { clearDealPredictAction, fetchDealPredictAction } from '../../../actions/dealPredictAction';
import { COLORS, FONT_WEIGHTS } from '../../../EatClubTheme';
import { dealPropTypes } from '../../../data/models/Deal';
import { trackEvent } from '../../../utils/analytics';
import { getDayFromInt, formatCurrency, emptyObject, isSet } from '../../../utils/helpers';
import useStyles, { modalInputStyle, modalStyle } from './ModalStyles';
import Pencil from '../../../assets/pencil.svg';
import { predictionPropTypes } from '../../../data/models/Prediction';
import ModalSectionHeader from './ModalSectionHeader';

const EditStartingOfferModal = ({
  clearDealPredict,
  isOpen,
  offer,
  onClose,
  onSave,
  revenueMonthly,
  restaurantHours,
  fetchDealPredict,
  predict,
  userId,
  userType,
}) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const getInitialPeriod = () => {
    if (offer?.lightning) {
      return 'timeLimited';
    }
    return 'allDay';
  };

  const getTypes = () => {
    if (offer?.dineInOnly) {
      return ['dineIn'];
    }
    if (offer?.takeawayOnly) {
      return ['takeaway'];
    }
    return ['dineIn', 'takeaway'];
  };

  const [period, setPeriod] = useState(getInitialPeriod());
  const [startTime, setStartTime] = useState(offer?.startTime);
  const [endTime, setEndTime] = useState(offer?.endTime);
  const [discount, setDiscount] = useState(offer?.discount);
  const [quantity, setQuantity] = useState(offer?.qty);
  const [type, setType] = useState(getTypes());
  const [errors, setErrors] = useState({});
  const [todayHours, setTodayHours] = useState([]);

  const { data: prediction, pending } = predict;

  const openHoursByDay = useCallback(
    (dayName) => {
      const hours = Object.keys(restaurantHours)
        .map((day, index) => (day.substr(0, 3) === dayName ? index : null))
        .filter((e) => e !== null)
        .map((index) => Object.values(restaurantHours)[index]);

      if (hours[0] > hours[1]) {
        hours[1] = 1439; // cap tomorrow to midnight
      }

      return hours;
    },
    [restaurantHours],
  );

  useEffect(() => {
    setPeriod(getInitialPeriod());
    setStartTime(offer?.startTime);
    setEndTime(offer?.endTime);
    setDiscount(offer?.discount);
    setQuantity(offer?.qty);
    setType(getTypes());
    setTodayHours(openHoursByDay(moment(offer?.offerDate).format('ddd').toLowerCase()));
  }, [offer]);

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: edit_starting_offer_modal');
    }
  }, [isOpen]);

  const validate = () => {
    const newErrors = {};

    // Time period
    if (period !== 'allDay' && period !== 'timeLimited') {
      newErrors.period = 'You must choose a time period';
    }

    // Start and end times
    if (period === 'timeLimited') {
      if (!isSet(startTime)) {
        newErrors.startTime = 'You must set a start time';
      }

      if (!isSet(endTime)) {
        newErrors.endTime = 'You must set an end time';
      }

      if (isSet(startTime) && isSet(endTime) && startTime >= endTime) {
        newErrors.startTime = 'Start time must be before end time';
        newErrors.endTime = 'Start time must be before end time';
      }
    }

    // Discount
    if (!isSet(discount)) {
      newErrors.discount = 'You must set an offer percentage';
    }

    // Quantity
    if (!isSet(quantity) || quantity <= 0) {
      newErrors.quantity = 'You must set a quantity';
    }

    // Type
    if (type?.length === 0) {
      newErrors.type = 'You must choose one of takeaway or delivery';
    }

    setErrors(newErrors);

    return emptyObject(newErrors);
  };

  const changed = () =>
    period !== getInitialPeriod() ||
    startTime !== offer?.startTime ||
    endTime !== offer?.endTime ||
    discount !== offer?.discount ||
    quantity !== offer?.qty ||
    (type.length === 1 && type.includes('dineIn')) !== offer?.dineInOnly ||
    (type.length === 1 && type.includes('takeaway')) !== offer?.takeawayOnly;

  const abortController = useRef();
  const debouncedFetch = useRef(
    debounce((offer, period, startTime, endTime, discount, quantity, type, valid, changed) => {
      if (offer === null || !valid || !changed) {
        return;
      }

      const controller = new window.AbortController();
      abortController.current = controller;

      fetchDealPredict({
        dineIn: type.includes('dineIn'),
        discount: `${discount}%`,
        endTime,
        lightning: period === 'timeLimited',
        quantity,
        recurring: true,
        restId: offer.restId,
        startDate: offer.offerDate,
        startTime,
        takeaway: type.includes('takeaway'),
        userId,
        userType,
        controllerSignal: abortController.current.signal,
      });
    }, 500),
  );

  const abortLatest = () => abortController.current && abortController.current.abort();

  useEffect(() => {
    if (!emptyObject(prediction)) {
      clearDealPredict();
    }

    abortLatest();
    debouncedFetch.current(
      offer,
      period,
      startTime,
      endTime,
      discount,
      quantity,
      type,
      validate(),
      changed(),
    );
  }, [period, startTime, endTime, discount, quantity, type]);

  const save = () => {
    if (!changed()) {
      // no changes were made, let's just close the modal
      onClose();
      return;
    }
    if (!validate()) {
      return;
    }
    trackEvent('button_click: confirm_edit_starting_offer');

    onSave(offer?.tempId, prediction, period, startTime, endTime, discount, quantity, type);
  };

  const isValid = emptyObject(errors);

  const labelStyles = {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 'bold',
  };

  const styles = {
    dropdown: {
      label: labelStyles,
      padding: '0',
      width: '100%',
      container: {
        height: 'auto',
      },
    },
    segmentedControl: {
      label: labelStyles,
      groupContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
      },
      option: {
        width: '100%',
      },
    },
    toggleGroup: {
      groupContainer: {
        gap: '20px',
      },
      groupOptionContainer: {
        flex: '1',
      },
      label: labelStyles,
      option: {
        flexGrow: 1,
        textAlign: 'left',
        borderColor: COLORS.GRAY_BORDER,
        borderRadius: '4px',
        padding: '10px',
        fontSize: '18px',
        width: '135px',
      },
      selectedOption: {
        borderColor: COLORS.OLIVE,
      },
    },
    textSelect: {
      container: { flexGrow: 1 },
      label: labelStyles,
      textFieldContainer: { width: '100%' },
      textFieldRoot: { width: '100%' },
    },
  };

  const changeOverGoalPeriod = () => {
    if (!isValid) {
      return (
        <Box className={classes.redLabel}>
          {formatCurrency(-Math.round(offer?.targetValMonthly))}
        </Box>
      );
    }

    if (!changed()) {
      return <Box className={classes.greenLabel}>+{formatCurrency(0)}</Box>;
    }

    return (
      <Box
        className={
          prediction?.targetValMonthly >= Math.round(offer?.targetValMonthly)
            ? classes.greenLabel
            : classes.redLabel
        }
      >
        {prediction?.targetValMonthly >= Math.round(offer?.targetValMonthly) && '+'}
        {formatCurrency(prediction?.targetValMonthly - Math.round(offer?.targetValMonthly))}
      </Box>
    );
  };

  const adjustedTotalRevenue = () => {
    if (!isValid) {
      return formatCurrency(revenueMonthly - Math.round(offer?.targetValMonthly));
    }

    if (!changed()) {
      return formatCurrency(revenueMonthly);
    }

    return formatCurrency(
      revenueMonthly + prediction?.targetValMonthly - Math.round(offer?.targetValMonthly),
    );
  };

  const handleOnClose = () => {
    clearDealPredict();

    abortLatest();
    onClose();
  };

  return (
    <Modal
      inputStyle={modalInputStyle}
      fullWidth={false}
      style={modalStyle}
      isOpen={isOpen}
      onClose={handleOnClose}
      showCloseText={false}
      footer={[
        <Button
          onClick={handleOnClose}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='tertiary'
        >
          Cancel
        </Button>,
        <Button
          disabled={!isValid || pending || (changed() && emptyObject(prediction))}
          onClick={save}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='primary'
        >
          Update offer
        </Button>,
      ]}
    >
      {isOpen && (
        <Box className={classes.modalBody}>
          <Box mb='20px'>
            <Box
              className={classes.modalHeader}
              style={{
                alignItems: 'baseline',
                display: 'flex',
                gap: '15px',
              }}
            >
              <Pencil height={20} width={20} /> Edit offer
            </Box>
            <Box mt='20px'>
              <Box className={classes.inlineText}>
                <Box className={classes.discountLabel}>{offer?.discount}% off</Box>
                <CenteredContent>
                  on {getDayFromInt(offer?.dayOfWeek)} from{' '}
                  {`${offer?.startTimeReadable} - ${offer?.endTimeReadable}`}
                </CenteredContent>
              </Box>
            </Box>
          </Box>
          <Box className={classes.modalContent} style={{ maxWidth: '380px' }}>
            <Dropdown
              label='Offer percentage'
              value={discount}
              items={[
                { label: '50%', value: '50' },
                { label: '45%', value: '45' },
                { label: '40%', value: '40' },
                { label: '35%', value: '35' },
                { label: '30%', value: '30' },
                { label: '25%', value: '25' },
                { label: '20%', value: '20' },
              ]}
              placeholder={discount}
              onSelect={setDiscount}
              style={styles.dropdown}
              error={errors?.dropdown}
            />
            <SegmentedControl
              label='Time period'
              value={period}
              items={[
                { label: 'All day', value: 'allDay' },
                { label: 'Time limited', value: 'timeLimited' },
              ]}
              onSelect={setPeriod}
              style={styles.segmentedControl}
              error={errors?.period}
            />
            {period === 'timeLimited' && (
              <Box className={classes.modalContent}>
                <TimePicker
                  className={classes.formControl}
                  label='Start time'
                  value={startTime}
                  onSelect={setStartTime}
                  startTime={todayHours[0]}
                  endTime={todayHours[1]}
                  increment={15}
                  style={styles.dropdown}
                  error={errors?.startTime}
                />

                <TimePicker
                  className={classes.formControl}
                  label='End time'
                  value={endTime}
                  onSelect={setEndTime}
                  startTime={todayHours[0]}
                  endTime={todayHours[1]}
                  increment={15}
                  style={styles.dropdown}
                  error={errors?.endTime}
                />
              </Box>
            )}
            <ToggleGroup
              value={type}
              onSelect={setType}
              items={[
                { label: 'Dine-in', value: 'dineIn' },
                { label: 'Takeaway', value: 'takeaway' },
              ]}
              style={styles.toggleGroup}
              error={errors?.type}
              singleChoice={false}
            />
            <Box display='flex' gap='20px' mb='20px'>
              <TextSelect
                label='Quantity'
                value={quantity}
                onChange={setQuantity}
                style={styles.textSelect}
                minNumber={0}
                increment
                error={errors?.quantity}
                fullWidth
              />
            </Box>
          </Box>
          <Box mt='20px' style={{ minHeight: '101px' }}>
            <ModalSectionHeader header='Impact' />
            {isValid && changed() && (pending || emptyObject(prediction)) ? (
              <Box mt='12px' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                <Box className={classes.impactRow}>
                  <Box>Total change over goal period</Box>
                  {changeOverGoalPeriod()}
                </Box>
                <Box className={classes.impactRow}>
                  <Box>Adjusted total revenue goal</Box>
                  <Box style={{ fontWeight: FONT_WEIGHTS.MEDIUM }}>{adjustedTotalRevenue()}</Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Modal>
  );
};

EditStartingOfferModal.defaultProps = {
  offer: null,
  onSave: () => {},
};

EditStartingOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  offer: dealPropTypes,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  revenueMonthly: PropTypes.number.isRequired,
  restaurantHours: PropTypes.shape({}).isRequired,
  fetchDealPredict: PropTypes.func.isRequired,
  predict: predictionPropTypes.isRequired,
  clearDealPredict: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  restaurantHours: state.restaurantActive.restaurant.hoursParsed,
  predict: state.dealPredict,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearDealPredict: clearDealPredictAction,
      fetchDealPredict: fetchDealPredictAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditStartingOfferModal);
