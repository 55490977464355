import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  // Overview
  headingContainer: {
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    gap: '50px',
  },
  heading: {
    ...theme.eatclub.typography.heading.HXL,
    maxWidth: '350px',
  },
  revenueTargetHeading: {
    ...theme.eatclub.typography.heading.H1,
  },
  description: {
    ...theme.eatclub.typography.body.PXL,
    // lineHeight: '20px',
    maxWidth: '600px',
  },
  overviewDescription: {
    ...theme.eatclub.typography.body.PXL,
    lineHeight: '36px',
    maxWidth: '600px',
  },
  revenueTargetDescription: {
    ...theme.eatclub.typography.body.PXL,
  },
  goalStepContainer: {
    display: 'flex',
    marginTop: '150px',
    flexWrap: 'wrap',
    gap: '50px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
      gap: '30px',
    },
  },
  goalStep: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    maxWidth: '350px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      gap: '10px',
    },
  },
  goalStepNumber: {
    color: COLORS.PRIMARY_RED_MATTE,
    fontSize: '14px',
    fontWeight: '700',

    [theme.breakpoints.down('sm')]: {
      marginTop: '3px',
    },
  },
  goalStepText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  goalStepHeading: {
    fontSize: '18px',
    fontWeight: '700',
    marginBottom: '9px',
  },
  goalStepDescription: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  goalsFooter: {
    boxSizing: 'unset',
    alignItems: 'center',
    background: '#FFFFFF',
    bottom: '0',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '18px',
    justifyContent: 'center',
    left: '295px',
    position: 'fixed',
    right: '0',
    boxShadow: '0px -2px 7px rgba(212, 212, 212, 0.5)',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      left: '0',
    },
  },
  footerTargets: {
    gap: '36px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      gap: '18px',
    },
  },
  revenueValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  revenueLabel: {
    color: COLORS.SMOKE,
    fontSize: '12px',
    letterSpacing: '1.5px',
  },
  revenueValue: {
    background: COLORS.GRAPEFRUIT,
    borderRadius: '4px',
    color: COLORS.PRIMARY_RED_MATTE,
    fontSize: '18px',
    fontWeight: '500',
    padding: '5px',
  },
  goalsFooterPadding: {
    display: 'flex',
    maxWidth: '1424px',
    padding: '25px 72px',
    flexWrap: 'wrap',
    gap: '20px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '25px 24px',
    },
  },
  goalsFooterProjection: {
    display: 'flex',
    maxWidth: '1424px',
    padding: '25px 72px',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    ...theme.eatclub.typography.body.PL,
    rowGap: '15px',
    columnGap: '40px',
    paddingBottom: '13px',
    paddingTop: '13px',

    [theme.breakpoints.down('sm')]: {
      padding: '13px 20px',
    },
  },
  extraRevenueContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    gap: '10px',
  },
  extraRevenueHeading: {
    ...theme.eatclub.typography.heading.H4,
  },
  extraRevenueDescription: {
    ...theme.eatclub.typography.body.PS,
  },
  revenueLabelContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '30px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  // Revenue Target
  revenueTargetContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#313131',
    marginBottom: '125px',
  },
  sliderStyles: {
    '& .MuiSlider-rail': {
      borderRadius: '100px',
      color: '#243D39',
      height: '16px',
      opacity: '1',
    },
    '& .MuiSlider-thumb': {
      borderRadius: '100px',
      boxShadow: '0px 4px 8px rgba(86, 86, 86, 0.25)',
      color: '#ECB8B1',
      height: '36px',
      marginTop: '0',
      // top: '0px',
      width: '7px',
    },
    '& .MuiSlider-track': {
      borderRadius: '100px',
      height: '16px',
    },

    [theme.breakpoints.down('sm')]: {
      margin: 'auto 20px',
    },
  },
  sliderTitleContainer: {
    marginBottom: '20px',
    fontSize: '28px',
    fontWeight: 'bold',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  sliderTitle: {
    ...theme.eatclub.typography.heading.H2,
    fontWeight: 400,
  },
  sliderBackground: {
    display: 'flex',
    background: COLORS.OLIVE,
    borderRadius: '12px',
    height: '73px',
    width: '100%',
  },
  potentialRevenueContainer: {
    marginTop: '40px',
    display: 'inline-flex',
    gap: '10px',
    alignItems: 'flex-start',
    fontSize: '18px',
    lineHeight: '35px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  potentialRevenueRow: {
    display: 'inline-flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'flex-start',
  },
  tooltipContainer: {
    height: '21px',
    paddingTop: '4px',
  },
  selectButton: {
    border: '1px solid #DCDCDB',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    padding: '8px 16px',
    textTransform: 'uppercase',
    letterSpacing: '0.13em',
    cursor: 'pointer',
    lineHeight: '19px',
  },
  footerEstimateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
  },
  footerEstimate: {
    display: 'flex',
    gap: '4px',
    backgroundColor: '#F8F0E3',
    justifyContent: 'center',
  },

  // Success
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#313131',
    height: '100%',
  },
  successBanner: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '60px',
    width: '100%',
    backgroundColor: COLORS.LINEN,
    borderRadius: '16px',
    padding: '25px 30px',
    color: COLORS.OLIVE_2,
  },
  successContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    ...theme.eatclub.typography.body.PXL,
  },
}));

export default useStyles;
