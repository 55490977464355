import React from 'react';
import Lockdown from './Lockdown';
import floorplanImage from '../../../assets/images/floorplan_image.png';

const FloorplanLockdown = () => (
  <Lockdown
    header='Floor plan'
    content='Manage bookings with a visual and interactive interface of your floor plan'
    image={floorplanImage}
  />
);

export default FloorplanLockdown;
