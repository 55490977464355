import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Box,
  Collapse,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { usePageViews } from '../../../utils/analytics';
import useStyles from './CreateDealsStyles';
import DealDiscount from './DealDiscount';
import DealQuantity from './DealQuantity';
import DealTime from './DealTime';
import DealValidFor from './DealValidFor';
import DealDays from './DealDays';
import DealFrequency from './DealFrequency';
import DealType from './DealType';
import DealDate from './DealDate';
import { createDealAction } from '../../../actions/dealCreateAction';
import PredictedResults from '../predictedResults/PredictedResults';
import { momentAsMinutes, getTodayHours, minutesToMoment } from '../../../utils';
import Error from '../../error/Error';

// useLocation to parse the query string
const useQuery = () => new URLSearchParams(useLocation().search);

const CreateDeal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  usePageViews();

  const [creating, setCreating] = useState(false);

  const restaurantHours = useSelector((state) => state.restaurantActive.restaurant.hours);
  const selectedDay = useSelector((state) => state.dealsByDay.selectedDay);
  const showPredictions = useSelector((state) => state.restaurantActive.restaurant.showPredictions);

  const getInitialDate = (queryParam) => {
    const dateParam = query.get(queryParam);
    const today = moment().isoWeekday();

    // NOTE: if no date from URL params, then use date from date picker
    if (!dateParam) {
      // this weeks date
      if (today <= selectedDay) {
        return moment().isoWeekday(selectedDay);
      }

      // next weeks date
      return moment().add(1, 'weeks').isoWeekday(selectedDay);
    }

    return moment.utc(dateParam, 'YYYY-MM-DD');
  };

  const getRestaurantTime = (isEnd = false) => {
    const startDay = getInitialDate('startDate').format('ddd').toLowerCase();

    const todayHours = getTodayHours(JSON.parse(restaurantHours), startDay);

    if (todayHours.start > todayHours.end) {
      todayHours.end = 1439; // cap tomorrow to midnight
    }

    return minutesToMoment(todayHours[isEnd ? 'end' : 'start']);
  };

  const getInitialTime = (queryParam, isEnd = false) => {
    const timeParam = query.get(queryParam);

    if (!timeParam) {
      return getRestaurantTime(isEnd);
    }

    const time = moment(timeParam, 'hh:mm a');
    return time;
  };

  const getInitialType = () => {
    if (query.get('startTime') || query.get('endTime')) {
      return 'timeLimited';
    }
    return '';
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const [newDeal, setNewDeal] = useState({
    frequency: {
      index: 1,
      name: 'frequency',
      component: DealFrequency,
      enabled: true,
      value: '',
      // errors: { frequency: "" },
    },
    type: {
      index: 2,
      name: 'type',
      component: DealType,
      enabled: false,
      value: getInitialType(),
      // errors: { type: "" },
    },
    discount: {
      index: 3,
      name: 'discount',
      component: DealDiscount,
      enabled: false,
      value: '',
      // errors: { discount: "" },
    },
    quantity: {
      index: 4,
      name: 'quantity',
      component: DealQuantity,
      enabled: false,
      value: null,
      // errors: { quantity: "" },
    },
    startDate: {
      index: 5,
      name: 'startDate',
      component: DealDate,
      enabled: false,
      value: getInitialDate('startDate'),
      errors: { startDate: '' },
    },
    time: {
      index: 6,
      name: 'time',
      component: DealTime,
      enabled: false,
      times: {
        start: getInitialTime('startTime'),
        end: getInitialTime('endTime', true),
      },
      defaultTimes: {
        start: getRestaurantTime(),
        end: getRestaurantTime(true),
      },
      errors: { start: '', end: '' },
    },
    validFor: {
      index: 7,
      name: 'validFor',
      component: DealValidFor,
      enabled: false,
      checks: { dineIn: true, takeAway: true },
      // errors: { validFor: "" },
    },
    days: {
      index: 8,
      name: 'days',
      component: DealDays,
      enabled: false,
      values: [],
      errors: { days: '' },
    },
  });

  const isStepValid = (step) => {
    const errors = [];

    if (!step.enabled) {
      return false;
    }

    // check errors
    if (step.errors) {
      errors.push(!Object.values(step.errors).some((el) => el !== ''));
    }

    // Test object
    if (step.checks) {
      errors.push(Object.values(step.checks).some((el) => el !== false));
    }

    // Test times
    if (step.times) {
      errors.push(!Object.values(step.times).some((el) => el === null));
    }

    // Test array
    if (step.values) {
      errors.push(step.values.length > 0);
    }

    // Test string
    errors.push(!(step.value === '' || step.value === null));

    return !errors.some((el) => el === false);
  };

  const areStepsInvalid = () => {
    const check = Object.values(newDeal).filter((step) => step.enabled && !isStepValid(step));

    return check.length > 0;
  };

  const getStepIndex = (stepName) => {
    const enabledSteps = Object.values(newDeal).filter((step) => step.enabled);
    return enabledSteps.findIndex((enabledStep) => enabledStep.name === stepName) + 1;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setCreating(true);

    const deal = {
      discount: newDeal.discount.value,
      lightning: newDeal.type.value === 'timeLimited',
      takeaway: newDeal.validFor.checks.takeAway,
      dineIn: newDeal.validFor.checks.dineIn,
      quantity: newDeal.quantity.value,
      startTime: momentAsMinutes(newDeal.time.times.start),
      endTime: momentAsMinutes(newDeal.time.times.end),
      startDate: newDeal.startDate.value.format('YYYY-MM-DD'),
      recurring: newDeal.frequency.value === 'recurring',
      recurringDays:
        newDeal.frequency.value === 'recurring' ? JSON.stringify(newDeal.days.values) : null,
    };

    dispatch(createDealAction(deal))
      .then(() => history.push('/offers'))
      .catch((err) => {
        setErrorMessage(`${err}`);
        setError(true);
      })
      .finally(() => setCreating(false));
  };

  // Reset error if form changes
  useEffect(() => {
    setErrorMessage('');
    setError(false);
  }, [newDeal]);

  return (
    <div className={classes.root}>
      <Card style={{ marginTop: '1rem' }}>
        <CardContent>
          <form onSubmit={handleSubmit} id='createDealForm'>
            {Object.values(newDeal)
              .sort((a, b) => a.index - b.index) // NOTE: explicitly sort to prevent unexpected safari iOS13.3 sort order
              .map((step, index) => (
                <Collapse key={step.name} in={Boolean(step.enabled)} mountOnEnter unmountOnExit>
                  {index !== 0 && <hr style={{ margin: '30px 0', opacity: 0.2 }} />}
                  <Grid container>
                    <Grid item>
                      <Box className={`${classes.indexLabel} ${isStepValid(step) && 'hasEdit'}`}>
                        {getStepIndex(step.name)}
                      </Box>
                    </Grid>
                    <Grid item md='auto'>
                      <step.component newDeal={newDeal} setNewDeal={setNewDeal} />
                    </Grid>
                  </Grid>
                </Collapse>
              ))}
          </form>
          {showPredictions && !areStepsInvalid() && (
            <PredictedResults
              dineIn={newDeal.validFor.checks.dineIn}
              discount={newDeal.discount.value}
              endTime={momentAsMinutes(newDeal.time.times.end)}
              lightning={newDeal.type.value === 'timeLimited'}
              quantity={newDeal.quantity.value}
              recurring={newDeal.frequency.value === 'recurring'}
              startDate={newDeal.startDate.value.format('YYYY-MM-DD')}
              startTime={momentAsMinutes(newDeal.time.times.start)}
              takeaway={newDeal.validFor.checks.takeAway}
              recurringDays={
                newDeal.frequency.value === 'recurring' ? JSON.stringify(newDeal.days.values) : null
              }
            />
          )}
        </CardContent>

        {error && <Error error={error} message={errorMessage} />}

        <CardActions style={{ padding: '8px 16px 16px 16px' }}>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            form='createDealForm'
            disabled={areStepsInvalid()}
          >
            {creating ? <CircularProgress color='inherit' size={23} /> : 'Publish Deal'}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default CreateDeal;
