import * as type from '../actions/types';

const initialState = {
  saving: false,
  error: false,
  errorMessage: '',
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FLAG_MENU_PENDING: {
      return {
        ...state,
        saving: true,
        error: false,
        errorMessage: '',
      };
    }
    case type.FLAG_MENU_SUCCESS: {
      return {
        ...state,
        saving: false,
        success: true,
      };
    }
    case type.FLAG_MENU_FAILURE: {
      return {
        ...state,
        saving: false,
        error: true,
        errorMessage: action.payload,
      };
    }
    case type.CLEAR_FLAG_MENU: {
      return {
        ...state,
        saving: false,
        error: false,
        errorMessage: '',
        success: false,
      };
    }

    default: {
      return state;
    }
  }
};
