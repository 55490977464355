import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  paperBooking: {
    padding: theme.spacing(2),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  cardTitle: {
    marginBottom: theme.spacing(1),
  },
  hr: {
    marginBottom: theme.spacing(2),
    opacity: 0.3,
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
  tablePagination: {
    display: 'flex',

    '& .MuiTablePagination-toolbar': {
      flexDirection: 'column-reverse',
      padding: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
        flexDirection: 'row-reverse',
      },
    },
    '& .MuiTablePagination-spacer': {
      display: 'none',
    },
  },
  paginationContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      marginRight: theme.spacing(2.5),
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  tableStyles: {
    '& .MuiTable-root': {
      borderCollapse: 'separate',
    },
    // fix border causing weird 1px spacing
    '& .MuiTableFooter-root > .MuiTableRow-footer > .MuiTableCell-footer': {
      borderBottom: 0,
    },
    '& .MuiTableBody-root > .MuiTableRow-root:first-child > .MuiTableCell-body > .MuiFormControl-root > .MuiInput-formControl':
      {
        width: '126px',
      },
    '& .MuiTableBody-root > .MuiTableRow-root:first-child > .MuiTableCell-body > .MuiFormControl-root > .MuiInput-formControl.MuiInput-underline:before':
      {
        borderBottom: 0,
      },
    '& .MuiTableBody-root > .MuiTableRow-root:first-child > .MuiTableCell-body > .MuiFormControl-root':
      {
        marginTop: '-14px',
      },
    '& .MuiTableBody-root > .MuiTableRow-root:first-child > .MuiTableCell-body': {
      paddingBottom: 0,
    },
    '& ::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '& ::-webkit-scrollbar:vertical': {
      width: '12px',
    },
    '& ::-webkit-scrollbar:horizontal': {
      height: '12px',
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, .25)',
      borderRadius: '10px',
      border: '2px solid #ffffff',
    },
    '& ::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: '#ffffff',
    },
  },
}));

export default useStyles;
