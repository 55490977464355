import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { potentialRevenues } from '../graphql/queries';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

export const fetchPotentialRevenuesAction = (restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'POTENTIAL_REVENUES',
  });
  dispatch({
    type: type.FETCH_POTENTIAL_REVENUES_PENDING,
  });

  const getData = async () => {
    try {
      const response = await API.graphql(
        {
          query: potentialRevenues,
          variables: {
            restId,
            filter: 'all',
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      const {
        data: { potentialRevenues: potentialRevenuesResponse },
      } = response;

      devLog('success', 'potentialRevenues', potentialRevenuesResponse);

      dispatch({
        type: type.FETCH_POTENTIAL_REVENUES_SUCCESS,
        payload: potentialRevenuesResponse,
      });
    } catch (error) {
      devLog('error', 'potentialRevenues', error);

      dispatch({
        type: type.FETCH_POTENTIAL_REVENUES_FAILURE,
        payload: `Unable to retrieve potentialRevenues: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'POTENTIAL_REVENUES',
      });
    }
  };
  getData();
};

export const clearPotentialRevenuesAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_POTENTIAL_REVENUES,
  });
};
