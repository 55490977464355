import { makeStyles } from '@mui/styles';
import { FONT_WEIGHTS, COLORS } from '../../../EatClubTheme';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    width: '100%',
    gap: '15px',
    justifyContent: 'space-between',
    display: 'flex',
    marginTop: '15px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  modalHeader: {
    ...theme.eatclub.typography.body.textModalHeading,
  },
  modalSubheader: {
    ...theme.eatclub.typography.body.small,
    color: COLORS.SMOKE,
  },
  modalBody: {
    ...theme.eatclub.typography.body.textLarge,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  modalContentSmall: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  inlineText: {
    display: 'flex',
    gap: '10px',
    marginTop: '10px',
  },
  discountLabel: {
    padding: '5px',
    backgroundColor: COLORS.CLOUD,
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    height: '100%',
  },
  buttonGroupContainer: {
    marginTop: '10px',
  },
  subheading: {
    ...theme.eatclub.typography.body.subheading,
  },
  impactRow: {
    display: 'flex',
    gap: '20px',
    marginTop: '10px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  impactRowSmall: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
  },
  redLabel: {
    padding: '4px 8px',
    borderRadius: '4px',
    backgroundColor: COLORS.GRAPEFRUIT,
    color: COLORS.PRIMARY_RED_MATTE,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    whiteSpace: 'nowrap',
    lineHeight: '20px',
    height: '100%',
  },
  greenLabel: {
    padding: '4px 8px',
    borderRadius: '4px',
    backgroundColor: '#EBF2EB',
    color: COLORS.ROQUETTE,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    whiteSpace: 'nowrap',
    lineHeight: '20px',
    height: '100%',
  },
  radioButtonContainer: {
    marginTop: '10px',
    maxWidth: '350px',
  },
  modalDetailsWithWidth: {
    maxWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: '10px',
  },
  impactCurrency: {
    fontWeight: FONT_WEIGHTS.MEDIUM,
  },
  formControl: {
    width: '300px',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  impactValue: {
    ...theme.eatclub.typography.body.textMedium,
  },
  // Daily oppo modal
  oppoModal: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    alignItems: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
  },
  oppoSuccess: {
    color: '#313131',
    fontWeight: '900',
    fontSize: '32px',
    lineHeight: '34px',
  },
  oppoDescription: {
    marginTop: '20px',
    fontSize: '16px',
    lineHeight: '20px',
  },
  oppoButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
    flexWrap: 'wrap',
  },
  modalSectionHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '15px',
    whiteSpace: 'nowrap',
  },
  modalSectionHeaderDivider: {
    width: '100%',
    height: '1px',
    background: '#D3D3D3',
  },
}));

export default useStyles;

export const modalInputStyle = {
  maxWidth: '640px',
  width: '100%',
};

export const modalStyle = {
  container: { padding: '20px' },
  content: { padding: '32px 40px' },
  header: {
    background: COLORS.LINEN,
    padding: '12px 20px',
    position: 'sticky',
    top: 0,
  },
  footer: { padding: 0 },
  footerInner: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap-reverse',
    gap: '8px',
    justifyContent: 'space-between',
    padding: '12px 20px',
  },
};
