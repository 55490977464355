/* eslint-disable */
import React from 'react';
// import PropTypes from "prop-types";
import { List, ListSubheader, Box, Divider } from '@mui/material';
import MenuItem from './MenuItem';

const MenuCategory = ({ categoriesLength, category, handleToggleItem, index, openItemId }) => (
  <>
    <List
      dense={false}
      style={{ paddingTop: '1rem' }}
      subheader={
        <ListSubheader component='div' color='primary'>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <span>{category.category}</span>
          </Box>
        </ListSubheader>
      }
    >
      {category.menuItems
        .sort((a, b) => a.itemIndex - b.itemIndex)
        .map((menuItem) => (
          <MenuItem
            handleToggleItem={handleToggleItem}
            key={menuItem.objectId}
            menuItem={menuItem}
            openItem={openItemId === menuItem.objectId}
          />
        ))}
    </List>
    {index < categoriesLength - 1 && <Divider />}
  </>
);

// MenuCategory.propTypes = {};

export default MenuCategory;
