import * as type from '../actions/types';

const initialState = {
  orderFetching: false,
  orderData: {},
  orderError: false,
  orderErrorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_ORDER_PENDING:
      return {
        ...state,
        orderFetching: true,
        orderError: false,
        orderErrorMessage: '',
      };
    case type.FETCH_ORDER_SUCCESS:
      return {
        ...state,
        orderData: action.payload,
        orderFetching: false,
        orderError: false,
        orderErrorMessage: '',
      };
    case type.FETCH_ORDER_FAILURE:
      return {
        ...state,
        orderError: true,
        orderErrorMessage: action.payload,
        orderFetching: false,
      };
    case type.CLEAR_ORDER:
      return {
        ...state,
        orderFetching: false,
        orderData: {},
        orderError: false,
        orderErrorMessage: '',
      };
    default:
      return state;
  }
};
