/* eslint-disable */
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import DealIncreasePercentDialog from './DealIncreasePercentDialog';

const DealIncreasePercentButton = ({ deal }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <Button variant='text' color='primary' onClick={handleOpenDialog}>
        <AddCircle
          style={{
            marginRight: '.5rem',
            width: '21px',
            height: '21px',
            fill: '#E54439',
          }}
        />
        {deal.actions}
      </Button>
      <DealIncreasePercentDialog
        deal={deal}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
      />
    </>
  );
};

export default DealIncreasePercentButton;
