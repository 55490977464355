/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuCategories from './MenuCategories';
import { clearCategoriesAction, fetchCategoriesAction } from '../../../actions/categoriesAction';
import FlagButton from '../FlagButton';
import LoadingBox from '../../loading/LoadingBox';

const MenuItemsLoading = () => <LoadingBox />;

const MenuTabPanel = ({
  clearCategories,
  fetchCategories,
  menu,
  categories,
  restId,
  userId,
  userType,
}) => {
  // fetch categories for v2 menus
  useEffect(() => {
    fetchCategories(menu.objectId, restId, userId, userType);

    // remove menu items on unmount
    return () => {
      clearCategories();
    };
  }, [clearCategories, fetchCategories, menu.objectId]);

  return (
    <>
      {categories.fetching ? (
        <Paper style={{ marginTop: '1rem' }}>
          <MenuItemsLoading />
        </Paper>
      ) : (
        <>
          <Paper style={{ marginTop: '1rem', marginBottom: '4rem' }}>
            <MenuCategories categories={categories.data} />
          </Paper>
          <FlagButton menu={menu} />
        </>
      )}
    </>
  );
};

MenuTabPanel.propTypes = {
  clearCategories: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  categories: PropTypes.shape({}).isRequired,
  restId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { clearCategories: clearCategoriesAction, fetchCategories: fetchCategoriesAction },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuTabPanel);
