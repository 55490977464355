import * as type from '../actions/types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case type.ADD_API_REQUEST: {
      return [...state, action.payload];
    }
    case type.REMOVE_API_REQUEST: {
      return [...state.filter((request) => request.id !== action.payload)];
    }
    default:
      return state;
  }
};
