export const subscribeToPubSubEvents = /* GraphQL */ `
  subscription SubscribeToPubSubEvents($restId: String) {
    subscribeToPubSubEvents(restId: $restId) {
      arrivalTime
      created
      dealId
      discount
      inAppOrder
      issueStatus
      name
      objectId
      orderId
      origTotal
      partySize
      platform
      promoTitle
      restId
      status
      tableNum
      tableOrders
      takeaway
      total
      transactionId
    }
  }
`;
