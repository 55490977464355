import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, CircularProgress } from '@mui/material';
import debounce from 'lodash.debounce';
import { clearDealPredictAction, fetchDealPredictAction } from '../../../actions/dealPredictAction';
import { emptyObject, formatCurrency, isSet } from '../../../utils/helpers';
import useStyles from '../../deals/createDeal/CreateDealsStyles';
import { predictionPropTypes } from '../../../data/models/Prediction';

const PredictedResults = ({
  clearDealPredict,
  dineIn,
  discount,
  endTime,
  fetchDealPredict,
  lightning,
  predict,
  dineInQuantity,
  takeawayQuantity,
  recurring,
  recurringDays,
  restId,
  startDate,
  startTime,
  takeaway,
  userId,
  userType,
  changed,
  partySizeLower,
  partySizeUpper,
  revenueExpected,
  belongsToGoal,
}) => {
  const classes = useStyles();
  const { data: prediction, pending } = predict;

  const abortController = useRef();
  const debouncedFetch = useRef(
    debounce(
      (
        dineIn,
        discount,
        endTime,
        lightning,
        dineInQuantity,
        takeawayQuantity,
        recurring,
        recurringDays,
        restId,
        startDate,
        startTime,
        takeaway,
        userId,
        userType,
        dismounting,
        valid,
        belongsToGoal,
        changed,
      ) => {
        if (dismounting || !valid || (belongsToGoal && !changed)) {
          return;
        }

        const controller = new window.AbortController();
        abortController.current = controller;

        fetchDealPredict({
          dineIn,
          discount,
          endTime,
          lightning,
          quantity: dineInQuantity + takeawayQuantity,
          recurring,
          recurringDays,
          restId,
          startDate,
          startTime,
          takeaway,
          userId,
          userType,
          controllerSignal: abortController.current.signal,
        });
      },
      500,
    ),
  );

  const abortLatest = () => abortController.current && abortController.current.abort();

  const validate = () => {
    // Start and end times
    if (lightning) {
      if (!isSet(startTime)) {
        return false;
      }

      if (!isSet(endTime)) {
        return false;
      }

      if (isSet(startTime) && isSet(endTime) && startTime >= endTime) {
        return false;
      }
    }

    // Quantity
    if (
      (!isSet(dineInQuantity) && !isSet(takeawayQuantity)) ||
      (dineIn && dineInQuantity <= 0) ||
      (takeaway && takeawayQuantity <= 0)
    ) {
      return false;
    }

    // Type
    if (!dineIn && !takeaway) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!emptyObject(prediction)) {
      clearDealPredict();
    }

    abortLatest();
    debouncedFetch.current(
      dineIn,
      discount,
      endTime,
      lightning,
      dineInQuantity,
      takeawayQuantity,
      recurring,
      recurringDays,
      restId,
      startDate,
      startTime,
      takeaway,
      userId,
      userType,
      false,
      validate(),
      belongsToGoal,
      changed,
    );
  }, [
    dineIn,
    discount,
    endTime,
    lightning,
    dineInQuantity,
    takeawayQuantity,
    recurring,
    recurringDays,
    restId,
    startDate,
    startTime,
    takeaway,
    userId,
    userType,
    belongsToGoal,
    changed,
  ]);

  // on dismount
  useEffect(
    () => () => {
      clearDealPredict();

      abortLatest();
      debouncedFetch.current(
        dineIn,
        discount,
        endTime,
        lightning,
        dineInQuantity,
        takeawayQuantity,
        recurring,
        recurringDays,
        restId,
        startDate,
        startTime,
        takeaway,
        userId,
        userType,
        true,
        validate(),
        belongsToGoal,
        changed,
      );
    },
    [],
  );

  const calculateRevenue = () => {
    if (!validate()) {
      return 0;
    }

    if (belongsToGoal && !changed) {
      return revenueExpected;
    }

    return prediction?.targetVal;
  };

  const calculateCustomers = () => {
    if (!validate()) {
      return 0;
    }

    if (belongsToGoal && !changed) {
      return `${Math.max(1, partySizeLower)} - ${Math.max(2, partySizeUpper)} ppl`;
    }

    return `${Math.max(1, prediction?.partySizeLower)} - ${Math.max(
      2,
      prediction?.partySizeUpper,
    )} ppl`;
  };

  return (
    <Box className={classes.offerChangePrediction}>
      {validate() && (!belongsToGoal || changed) && (pending || emptyObject(prediction)) ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className={classes.predictedResultsText}>Predicted results</Box>
          <Box className={classes.predictedResultsContainer}>
            <Box className={classes.predictedResult}>
              <Box className={classes.predictedResultLabel}>Customers</Box>
              <Box>{calculateCustomers()}</Box>
            </Box>
            <Box className={classes.predictedResult}>
              <Box className={classes.predictedResultLabel}>Revenue</Box>
              <Box>{formatCurrency(calculateRevenue(), false)}</Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

PredictedResults.defaultProps = {
  recurringDays: null,
};

PredictedResults.propTypes = {
  clearDealPredict: PropTypes.func.isRequired,
  dineIn: PropTypes.bool.isRequired,
  discount: PropTypes.string.isRequired,
  endTime: PropTypes.number.isRequired,
  fetchDealPredict: PropTypes.func.isRequired,
  lightning: PropTypes.bool.isRequired,
  predict: predictionPropTypes.isRequired,
  dineInQuantity: PropTypes.number.isRequired,
  takeawayQuantity: PropTypes.number.isRequired,
  recurring: PropTypes.bool.isRequired,
  recurringDays: PropTypes.string,
  restId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
  takeaway: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  changed: PropTypes.bool.isRequired,
  partySizeLower: PropTypes.number.isRequired,
  partySizeUpper: PropTypes.number.isRequired,
  revenueExpected: PropTypes.number.isRequired,
  belongsToGoal: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  predict: state.dealPredict,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { clearDealPredict: clearDealPredictAction, fetchDealPredict: fetchDealPredictAction },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PredictedResults);
