/* eslint-disable */
import React, { useState } from 'react';
import { Button } from '@mui/material';

import { AddCircle } from '@mui/icons-material';
import DealIncreaseQtyDialog from './DealIncreaseQtyDialog';

const DealIncreaseQtyButton = ({ deal }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <div>
      <Button variant='text' color='primary' onClick={handleOpenDialog}>
        <AddCircle
          style={{
            marginRight: '.5rem',
            width: '21px',
            height: '21px',
            fill: '#E54439',
          }}
        />
        Quantity
      </Button>
      <DealIncreaseQtyDialog deal={deal} setOpenDialog={setOpenDialog} openDialog={openDialog} />
    </div>
  );
};

export default DealIncreaseQtyButton;
