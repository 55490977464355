import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Box, Modal, Button } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackEvent } from '../../../utils/analytics';
import { formatCurrency } from '../../../utils/helpers';
import useStyles, { modalInputStyle, modalStyle } from './ModalStyles';

const RevenueGoalModal = ({ isOpen, onClose, onSave, targetRevenue }) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('modal_view: revenue_goal');
    }
  }, [isOpen]);

  return (
    <Modal
      inputStyle={modalInputStyle}
      fullWidth={false}
      style={modalStyle}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
      footer={[
        <Button
          onClick={onClose}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='tertiary'
        >
          Cancel
        </Button>,
        <Button
          onClick={onSave}
          style={{ button: { width: '100%' }, container: { width: breakpointSmDown && '100%' } }}
          type='primary'
        >
          Continue to create new goal
        </Button>,
      ]}
    >
      <Box className={classes.modalBody}>
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeader}>Update your revenue goal amount</Box>

          <Box mt='10px'>
            <Box className={classes.impactRow}>
              <Box>Current revenue goal</Box>
              <Box className={classes.impactCurrency}>{formatCurrency(targetRevenue, false)}</Box>
            </Box>
          </Box>

          <Box mt='10px'>
            <p>
              As this will impact your underlying offers, you will need to create a new revenue
              goal.
            </p>
            <br />
            <p>
              This process only takes a few minutes to complete. Once you have finished creating a
              new revenue goal, your current one will be removed.
            </p>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

RevenueGoalModal.defaultProps = {
  onSave: () => {},
};

RevenueGoalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  targetRevenue: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RevenueGoalModal);
