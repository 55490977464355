import PropTypes from 'prop-types';
import * as type from '../actions/types';
import { formatTimeFromInt, generateUUID } from '../utils/helpers';

const initialState = {
  data: [],
  error: '',
  fetching: false,
  success: false,
};

// Export prop types for easy import in components that use this data
export const offerPropTypes = PropTypes.shape({
  objectId: PropTypes.string,
  goalId: PropTypes.string,
  restId: PropTypes.string,
  dealId: PropTypes.string,
  discount: PropTypes.string.isRequired,
  lightning: PropTypes.bool.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  dayOfWeek: PropTypes.number.isRequired,
  inPeak: PropTypes.bool,
  onPeak: PropTypes.bool,
  takeawayOnly: PropTypes.bool,
  dineInOnly: PropTypes.bool,
  iaoOnly: PropTypes.bool,
  qty: PropTypes.number,
  qtyLeft: PropTypes.number,
  targetVal: PropTypes.number,
  optimal: PropTypes.bool,
  optimality: PropTypes.number,
  bookingsPrediction: PropTypes.number,
  bookingsExpected: PropTypes.number,
  bookingsLower: PropTypes.number,
  bookingsUpper: PropTypes.number,
  partySizePrediction: PropTypes.number,
  partySizeExpected: PropTypes.number,
  partySizeLower: PropTypes.number,
  partySizeUpper: PropTypes.number,
  revenueExpected: PropTypes.number,
  revenueLower: PropTypes.number,
  revenueUpper: PropTypes.number,
  contributionExpected: PropTypes.number,
  contributionLower: PropTypes.number,
  contributionUpper: PropTypes.number,
  contributionADExpected: PropTypes.number,
  contributionADLower: PropTypes.number,
  contributionADUpper: PropTypes.number,
  discountExpected: PropTypes.number,
  discountLower: PropTypes.number,
  discountUpper: PropTypes.number,
  foodCostExpected: PropTypes.number,
  foodCostLower: PropTypes.number,
  foodCostUpper: PropTypes.number,
  commissionExpected: PropTypes.number,
  commissionLower: PropTypes.number,
  commissionUpper: PropTypes.number,
});

export const offersPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(offerPropTypes),
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  fetching: PropTypes.bool,
  success: PropTypes.bool,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_GENERATED_OFFERS_PENDING:
      return {
        ...state,
        fetching: true,
        error: '',
      };
    case type.FETCH_GENERATED_OFFERS_SUCCESS:
      const updatedData = action.payload?.map((offer) => {
        const startTime = formatTimeFromInt(offer.startTime);
        const endTime = formatTimeFromInt(offer.endTime);

        return {
          ...offer,
          tempId: generateUUID(),
          startTimeReadable: startTime,
          endTimeReadable: endTime,
        };
      });

      return {
        ...state,
        data: updatedData,
        fetching: false,
        success: true,
      };
    case type.FETCH_GENERATED_OFFERS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case type.CLEAR_GENERATED_OFFERS:
      return {
        ...state,
        data: [],
        fetching: false,
        error: '',
        success: false,
      };

    default:
      return state;
  }
};
