/* eslint-disable */
import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Skeleton } from '@mui/material';

const LoadingBox = ({ rows = 1, bars = true }) => (
  <List>
    {Array(rows)
      .fill()
      .map((row, index) => (
        <React.Fragment key={index}>
          <ListItem>
            <ListItemAvatar>
              <Skeleton variant='circular' width={40} height={40} />
            </ListItemAvatar>
            {bars && (
              <ListItemText>
                <Skeleton height={10} width='100%' style={{ marginBottom: 6 }} />
                <Skeleton height={10} width='80%' />
              </ListItemText>
            )}
          </ListItem>
          {index < rows.length - 1 && (
            <Skeleton height={1} width='100%' style={{ margin: '.5rem 0' }} />
          )}
        </React.Fragment>
      ))}
  </List>
);

export default LoadingBox;
