/* eslint-disable */
import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { canEditAll } from '../../../utils/helpers';
import DealDeleteDialog from '../DealDeleteDialog';
import DealDisableDialog from '../DealDisableDialog';
import DealEditDialog from '../DealEditDialog';

const ITEM_HEIGHT = 48;

const DealOptionsButton = ({ deal, selectedDay, isDisabled, userType }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDisableDialog, setOpenDisableDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickOption = (action) => {
    action();
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleOpenDisableDialog = () => {
    setOpenDisableDialog(true);
  };

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const disableDeal = () => {
    handleOpenDisableDialog();
  };

  const editDeal = () => {
    if (deal.origin === 'eatclub' && !canEditAll(userType)) {
      handleOpenEditDialog();
    } else {
      history.push(`/deals/edit/${deal.dealId}`);
    }
  };

  const deleteDeal = () => {
    handleOpenDeleteDialog();
  };

  const today = moment().weekday();

  const options = [
    {
      name: isDisabled ? 'Enable' : 'Disable for Today',
      type: 'disable',
      action: disableDeal,
      visible: today === selectedDay,
    },
    { name: 'Edit', type: 'edit', action: editDeal, visible: true },
    { name: 'Delete', type: 'delete', action: deleteDeal, visible: true },
  ];

  return (
    <div>
      <IconButton size='large' onClick={(e) => handleOpenMenu(e)}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={() => handleCloseMenu()}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            // width: "20ch",
          },
        }}
      >
        {options
          .filter((option) => option.visible)
          .map((option) => (
            <MenuItem key={option.name} onClick={() => handleClickOption(option.action)}>
              {option.name}
            </MenuItem>
          ))}
      </Menu>

      <DealEditDialog setOpenDialog={setOpenEditDialog} openDialog={openEditDialog} deal={deal} />
      <DealDeleteDialog
        setOpenDialog={setOpenDeleteDialog}
        openDialog={openDeleteDialog}
        deal={deal}
      />
      <DealDisableDialog
        setOpenDialog={setOpenDisableDialog}
        openDialog={openDisableDialog}
        isDisabled={isDisabled}
        deal={deal}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedDay: state.dealsByDay.selectedDay,
  userType: state.user.userInfo.userType,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DealOptionsButton);
