import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { numFavsByRestaurantId } from '../graphql/queries';
import { devLog } from '../utils';
import packageInfo from '../../package.json';

export const fetchFavouritesAction = (restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'FAVOURITES',
  });

  const getData = async () => {
    try {
      const response = await API.graphql(
        {
          query: numFavsByRestaurantId,
          variables: {
            restId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      );

      const {
        data: { numFavsByRestaurantId: numFavs },
      } = response;

      devLog('success', 'resturant favourites', numFavs);

      dispatch({
        type: type.FETCH_FAVOURITES_SUCCESS,
        payload: numFavs,
      });
    } catch (error) {
      devLog('error', 'restaurant favourites', error);

      dispatch({
        type: type.FETCH_FAVOURITES_FAILURE,
        payload: `Unable to retrieve Restaurant Favourites: ${error.message}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'FAVOURITES',
      });
    }
  };
  getData();
};
