/* eslint-disable */
import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import OrderChoice from './OrderChoice';

const OrderItem = ({ item }) => {
  const formatPrice = (price, qty, showEmpty) => {
    if (price === undefined) {
      return showEmpty;
    }
    let output = parseFloat(price) * qty;
    output = output === 0 ? showEmpty : `$${output.toFixed(2)}`;
    return output;
  };

  const itemPrice = formatPrice(item.price, item.qty, '');

  return (
    <>
      <ListItem>
        <ListItemIcon style={{ width: '70px' }}>
          <>{item.qty} x</>
        </ListItemIcon>
        <ListItemText primary={item.itemTitle} />
        <ListItemIcon
          style={{
            width: '70px',
            justifyContent: 'flex-end',
          }}
        >
          <>{itemPrice}</>
        </ListItemIcon>
      </ListItem>

      {item.itemOptions && (
        <>
          {item.itemOptions.map((option) => {
            const choices = JSON.parse(option.choices);
            return choices.map((choice, index) => (
              <OrderChoice key={index} choice={choice} item={item} />
            ));
          })}
        </>
      )}
    </>
  );
};

export default OrderItem;
