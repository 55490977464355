import { Box } from '@mui/material';
import React from 'react';
import { opportunityPropTypes } from '../../../reducers/dealDailyOpposReducer';
import { formatCurrency } from '../../../utils/helpers';
import { OpportunityDetails } from './OpportunityDetails';
import EstimateLabel from './EstimateLabel';
import useStyles from './PostedOpportunityStyles';
import LargeCheck from '../../../assets/large_check.svg';

const PostedOpportunity = ({ opportunity }) => {
  const classes = useStyles();

  const styles = {
    check: {
      top: '16px',
      left: '14px',
      position: 'absolute',
      width: '22px',
    },
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.postedOpportunityRow}>
        <Box className={classes.checkContainer}>
          <LargeCheck style={styles.check} />
        </Box>
        <Box className={classes.checkBackground} />
        <Box className={classes.content}>
          <Box>
            <OpportunityDetails opportunity={opportunity} />
          </Box>

          <Box style={{ display: 'flex', gap: '20px' }}>
            <EstimateLabel compact type='Customers' value={opportunity?.prediction} />

            <EstimateLabel
              compact
              type='Revenue'
              value={formatCurrency(opportunity?.revenueExpected, false)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

PostedOpportunity.propTypes = {
  opportunity: opportunityPropTypes.isRequired,
};

export default PostedOpportunity;
