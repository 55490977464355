import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Box, Divider, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CenteredContent } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  disableOfferForDateAction,
  deleteOfferAction,
  reEnableOfferForDateAction,
} from '../../../actions/offersForGoalAction';
import { calculateStatusForDeal } from '../../../data/models/Deal';
import { COLORS } from '../../../EatClubTheme';
import { offerPropTypes } from '../../../reducers/generatedOffersReducer';
import { trackEvent } from '../../../utils/analytics';
import {
  formatCurrency,
  getDayOfWeek,
  pluralise,
  eatClubDayOfWeekToMoment,
  canEditAll,
  isEmpty,
} from '../../../utils/helpers';
import ActionMenu from '../ActionMenu/ActionMenu';
import SmallCheck from '../../../assets/small_check.svg';
import Bin from '../../../assets/bin.svg';
import Pencil from '../../../assets/pencil.svg';
import Disabled from '../../../assets/disabled.svg';
import Bag from '../../../assets/bag.svg';
import Chair from '../../../assets/chair.svg';
import Target from '../../../assets/target.svg';
import DeleteOfferModal from '../Modals/DeleteOfferModal';
import DisableOfferModal from '../Modals/DisableOfferModal';
import OfferStatus from './OfferStatus';
import useStyles from './OfferCardStyles';
import RequestEditOfferModal from '../Modals/RequestEditOfferModal';
import RequestDeleteOfferModal from '../Modals/RequestDeleteOfferModal';
import {
  requestOfferDeleteAction,
  requestOfferEditAction,
} from '../../../actions/requestCallAction';

const OfferCard = ({
  offer,
  disableOfferForDate,
  reEnableOfferForDateAction,
  deleteOfferAction,
  restId,
  userId,
  userType,
  occurrences,
  requestOfferDelete,
  requestOfferEdit,
}) => {
  // TODO handle disableForDate and disableUntilDate
  const history = useHistory();
  const muiTheme = useTheme();
  const breakpointMdDown = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));
  const classes = useStyles({ status: offer?.status });

  const [disablingOffer, setDisablingOffer] = useState(false);
  const [deletingOffer, setDeletingOffer] = useState(false);
  const [requestEditingOffer, setRequestEditingOffer] = useState(false);
  const [requestDeletingOffer, setRequestDeletingOffer] = useState(false);
  const [actions, setActions] = useState([]);

  const belongsToGoal = offer?.goalId;
  const originIsEatClub = offer?.deals?.filter((deal) => deal?.origin === 'eatclub').length > 0;
  const canEditOffer = !originIsEatClub || canEditAll(userType);

  const getOfferDate = () => {
    const days = (7 + eatClubDayOfWeekToMoment(offer?.dayOfWeek) - getDayOfWeek(moment())) % 7;
    return moment().add(days, 'days');
  };

  const getDineInDeal = () => {
    const dineInDeals = offer?.deals?.filter((offerToCheck) => !offerToCheck?.takeawayOnly);

    // TODO an offer can have many deals, e.g. future ones. Make sure we are getting the right one
    if (dineInDeals?.length > 0) {
      return dineInDeals[0];
    }

    return null;
  };

  const getTakeawayDeal = () => {
    const takeawayDeals = offer?.deals?.filter((offerToCheck) => !offerToCheck?.dineInOnly);

    // TODO an offer can have many deals, e.g. future ones. Make sure we are getting the right one
    if (takeawayDeals?.length > 0) {
      return takeawayDeals[0];
    }

    return null;
  };

  const hasDineIn = () =>
    offer?.deals?.filter((offerToCheck) => !offerToCheck?.takeawayOnly).length > 0;

  const hasTakeaway = () =>
    offer?.deals?.filter((offerToCheck) => !offerToCheck?.dineInOnly).length > 0;

  const enableOffer = (date) => {
    reEnableOfferForDateAction(
      offer?.deals?.map((deal) => deal?.objectId),
      date,
      offer?.id,
      restId,
      userId,
      userType,
    );
  };

  const disableOffer = (disableReason, typeToDisable) => {
    const date = getOfferDate().format('YYYY-MM-DD'); // TODO get as restaurant's timezone

    let dealsToDisable = offer?.deals || [];
    if (typeToDisable === 'dineInOnly') {
      dealsToDisable = offer?.deals?.filter((offerToCheck) => !offerToCheck?.takeawayOnly);
    } else if (typeToDisable === 'takeawayOnly') {
      dealsToDisable = offer?.deals?.filter((offerToCheck) => !offerToCheck?.dineInOnly);
    }

    disableOfferForDate(
      dealsToDisable.map((deal) => deal?.objectId),
      date,
      offer?.goalId,
      offer?.goalOfferId,
      disableReason || '',
      '',
      offer?.id,
      restId,
      userId,
      userType,
    );
    setDisablingOffer(false); // TODO this should be set in redux
  };

  const deleteOffer = (deleteReason, deleteReasonMessage, allOccurrences, typeToDelete) => {
    let deals = offer?.deals;

    if (typeToDelete === 'takeaway') {
      deals = deals?.filter((deal) => deal.takeawayOnly === true);
    } else if (typeToDelete === 'dineIn') {
      deals = deals?.filter((deal) => deal.dineInOnly === true);
    }

    deleteOfferAction(
      deals?.map((deal) => deal?.objectId),
      offer?.goalId,
      offer?.goalOfferId,
      deleteReason,
      deleteReasonMessage,
      offer?.id,
      restId,
      userId,
      userType,
      allOccurrences,
    );
    setDeletingOffer(false);
  };

  const requestEditOffer = (reason, reasonMessage, contact, phone) => {
    trackEvent('button_click: confirm_edit_offer_request');

    requestOfferEdit(
      restId,
      userId,
      userType,
      offer?.deals?.map((deal) => deal?.objectId),
      reason,
      reasonMessage,
      contact,
      phone,
    );
    setRequestEditingOffer(false);
  };

  const requestDeleteOffer = (reason, reasonMessage, contact, phone) => {
    trackEvent('button_click: confirm_delete_offer_request');

    requestOfferDelete(
      restId,
      userId,
      userType,
      offer?.deals?.map((deal) => deal?.objectId),
      reason,
      reasonMessage,
      contact,
      phone,
    );
    setRequestDeletingOffer(false);
  };

  useEffect(() => {
    const dayOfWeek = getDayOfWeek(moment());

    setActions([
      {
        name: 'Edit',
        icon: <Pencil />,
        type: 'button',
        onClick: !canEditOffer
          ? () => {
              trackEvent(`button_click: edit_offer_admin_origin`);
              setRequestEditingOffer(true);
            }
          : () => {
              trackEvent(`button_click: edit_offer_partner_origin`);
              history.push(`/offers/edit/${offer?.id}`);
            },
      },
      // Only show disable for today on deals that are running today
      ...(eatClubDayOfWeekToMoment(offer?.dayOfWeek) === dayOfWeek && offer?.status !== 'disabled'
        ? [
            {
              name: 'Disable for today',
              icon: <Disabled />,
              type: 'button',
              onClick: () => {
                trackEvent(`button_click: disable_offer`);
                setDisablingOffer(true);
              },
            },
          ]
        : []),
      // Allow re-enabling offer if one or more of its deals are disabled for the day
      ...(offer?.status === 'disabled' ||
      offer?.deals?.some((deal) => !isEmpty(deal.disabledForDate))
        ? [
            {
              name: 'Enable',
              icon: <Disabled />,
              type: 'button',
              onClick: () => {
                trackEvent(`button_click: enable_offer`);
                enableOffer(getOfferDate().format('YYYY-MM-DD'));
              },
            },
          ]
        : []),
      {
        type: 'divider',
      },
      {
        name: 'Delete',
        icon: (
          <Box pt='3px'>
            <Bin />
          </Box>
        ),
        type: 'button',
        onClick: !canEditOffer
          ? () => {
              trackEvent(`button_click: edit_offer_admin_origin`);
              setRequestDeletingOffer(true);
            }
          : () => {
              trackEvent(`button_click: edit_offer_partner_origin`);
              setDeletingOffer(true);
            },
      },
    ]);
  }, [offer]);

  const getAmountRelativeToGoal = (amount, goalAmount) => {
    // Avoid division by zero
    if (goalAmount <= 0) {
      return '';
    }

    if (Math.round(amount) === Math.round(goalAmount)) {
      return 'Perfectly on target';
    }

    const percentage = Math.round((amount / goalAmount) * 100);

    if (amount < goalAmount) {
      return `${100 - percentage}% below ${formatCurrency(goalAmount, false)}`;
    }

    return `${percentage - 100}% above ${formatCurrency(goalAmount, false)}`;
  };

  const getCustomers = () => {
    if (!belongsToGoal) {
      return <></>;
    }

    const getBookingLabel = () => {
      if (offer?.partySizeLower === offer?.partySizeUpper) {
        return Math.max(1, offer?.partySizeLower);
      }
      return `${Math.max(1, offer?.partySizeLower)} - ${Math.max(2, offer?.partySizeUpper)}`;
    };

    // Cancelled offers don't show data
    if (offer?.status === 'cancelled') {
      return <Box className={classes.predictionValue}>N/A</Box>;
    }

    // Show a prediction if we should
    if (offer?.status !== 'complete') {
      return (
        <Box>
          <Box className={classes.predictionLabel}>Customers</Box>
          <Box>
            <span className={classes.predictionValue}>{getBookingLabel()}</span> Predicted
          </Box>
        </Box>
      );
    }

    // Otherwise, show the actual result
    return (
      <Box style={{ minWidth: '140px' }}>
        <span className={classes.predictionValue}>{offer?.partySizeActual}</span>{' '}
        {pluralise('Customer', offer?.partySizeActual)}
      </Box>
    );
  };

  const getRevenue = () => {
    if (!belongsToGoal) {
      return <></>;
    }

    // Cancelled offers don't show data
    if (offer?.status === 'cancelled') {
      return <Box className={classes.predictionValue}>N/A</Box>;
    }

    // Show a prediction if we should
    if (offer?.status !== 'complete') {
      return (
        <Box>
          <Box className={classes.predictionLabel}>Revenue</Box>
          <Box>
            <span className={classes.predictionValue}>
              {formatCurrency(offer?.partySizeUpper !== 0 ? offer?.revenueExpected : 0, false)}
            </span>{' '}
            Predicted
          </Box>
        </Box>
      );
    }

    // Otherwise, show the actual result
    return (
      <Box style={{ minWidth: '140px' }}>
        <span className={classes.predictionValue}>
          {formatCurrency(offer?.revenueActual, false)}
        </span>{' '}
        Revenue
      </Box>
    );
  };

  const getRepeatText = () => {
    if (!offer?.recurring) {
      return 'One-off';
    }

    // Don't show count on mobile due to screen width
    if (breakpointXsDown) {
      return 'Repeats';
    }

    // Don't try to show occurrences if it doesn't belong to a goal
    if (!belongsToGoal) {
      return 'Repeats weekly';
    }

    return `Repeats ${
      occurrences > 0 ? `${occurrences} ${pluralise('time', occurrences)}` : 'weekly'
    }`;
  };

  const dineInStatus = calculateStatusForDeal(getDineInDeal(), getOfferDate());
  const takeawayStatus = calculateStatusForDeal(getTakeawayDeal(), getOfferDate());

  const getNumberOfDineInDeals = () => {
    const dineInDeal = getDineInDeal();

    if (!dineInDeal.recurring || dineInStatus === 'active') {
      return dineInDeal.qtyLeft;
    }

    return dineInDeal.recurringQty;
  };

  const getNumberOfTakeawayDeals = () => {
    const takeawayDeal = getTakeawayDeal();

    if (!takeawayDeal.recurring || takeawayStatus === 'active') {
      return takeawayDeal.qtyLeft;
    }

    return takeawayDeal.recurringQty;
  };

  return (
    <Box>
      <Box className={classes.container}>
        <Box className={classes.leftBar} />
        <Grid container className={classes.innerContainer}>
          <Grid item xs={12} sm={6} display='flex' style={{ gap: '40px' }}>
            <Box>
              <Box className={classes.headingRow}>
                <Box className={classes.subHeading}>
                  {!offer?.lightning
                    ? 'All day'
                    : `${!breakpointXsDown ? 'From' : ''} ${offer?.startTimeReadable} - ${
                        offer?.endTimeReadable
                      }`}
                </Box>
                <Box className={classes.statusAndPeakContainer}>
                  <Box>
                    <OfferStatus status={offer?.status} />
                  </Box>
                  {offer.lightning && !breakpointMdDown && (
                    <Box>
                      <Box className={classes.peak}>
                        {offer?.inPeak || offer?.onPeak ? 'Peak' : 'Off-peak'}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box className={classes.details}>
                <Box className={classes.detail}>
                  <SmallCheck style={{ marginTop: '5px', color: COLORS.BLACK }} />
                  <Box>{offer?.discount}</Box>
                </Box>

                <Box className={classes.detail}>
                  <SmallCheck style={{ marginTop: '5px', color: COLORS.BLACK }} />
                  <Box>{getRepeatText()}</Box>
                </Box>

                <Box display='flex' gap='6px'>
                  {hasDineIn() && (
                    <Box style={{ display: 'inline-flex', gap: '4px', alignItems: 'center' }}>
                      <Box className={classes.iconBackground}>
                        <Box left='6px' top='3px' style={{ position: 'absolute' }}>
                          <Chair />
                        </Box>

                        {dineInStatus === 'disabled' && (
                          <Disabled
                            title='disabled for this date'
                            style={{
                              position: 'absolute',
                              color: COLORS.DISABLED,
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        )}
                      </Box>

                      {dineInStatus !== 'disabled' && <Box>{getNumberOfDineInDeals()} left</Box>}
                    </Box>
                  )}
                  {hasTakeaway() && (
                    <Box style={{ display: 'inline-flex', gap: '4px', alignItems: 'center' }}>
                      <Box className={classes.iconBackground}>
                        <Box left='4px' top='3px' style={{ position: 'absolute' }}>
                          <Bag />
                        </Box>
                        {takeawayStatus === 'disabled' && (
                          <Disabled
                            title='disabled for this date'
                            style={{
                              position: 'absolute',
                              color: COLORS.DISABLED,
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        )}
                      </Box>

                      {takeawayStatus !== 'disabled' && (
                        <Box>{getNumberOfTakeawayDeals()} left</Box>
                      )}
                    </Box>
                  )}
                  {belongsToGoal && (
                    <Box className={classes.iconBackground}>
                      <Box left='-1px' top='3px' style={{ position: 'absolute' }}>
                        <Target />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} className={classes.predictionsContainer}>
            {belongsToGoal && breakpointXsDown && offer?.status === 'complete' && (
              <Divider style={{ color: '#F0F0F0', marginBottom: '12px' }} />
            )}

            {belongsToGoal && (
              <Box style={{ display: 'flex', gap: '12px' }}>
                <CenteredContent>{getCustomers()}</CenteredContent>
                <CenteredContent>{getRevenue()}</CenteredContent>
              </Box>
            )}

            {offer?.status === 'complete' && belongsToGoal && (
              <Box className={classes.goalTarget}>
                {getAmountRelativeToGoal(offer?.revenueActual, offer?.revenueExpected)}
              </Box>
            )}
          </Grid>
        </Grid>

        {actions?.length > 0 && (
          <Box className={classes.actionMenu}>
            <ActionMenu options={actions} disabled={offer?.status === 'cancelled'} />
          </Box>
        )}
      </Box>

      <DisableOfferModal
        isOpen={disablingOffer}
        onClose={() => setDisablingOffer(false)}
        offer={offer}
        onSave={disableOffer}
      />
      <DeleteOfferModal
        isOpen={deletingOffer}
        onClose={() => setDeletingOffer(false)}
        offer={offer}
        onSave={deleteOffer}
      />
      <RequestEditOfferModal
        isOpen={requestEditingOffer}
        onClose={() => setRequestEditingOffer(false)}
        offer={offer}
        onSave={requestEditOffer}
      />
      <RequestDeleteOfferModal
        isOpen={requestDeletingOffer}
        onClose={() => setRequestDeletingOffer(false)}
        offer={offer}
        onSave={requestDeleteOffer}
      />
    </Box>
  );
};

OfferCard.defaultProps = {
  occurrences: 0,
};

OfferCard.propTypes = {
  offer: offerPropTypes.isRequired,
  disableOfferForDate: PropTypes.func.isRequired,
  reEnableOfferForDateAction: PropTypes.func.isRequired,
  deleteOfferAction: PropTypes.func.isRequired,
  requestOfferDelete: PropTypes.func.isRequired,
  requestOfferEdit: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  restId: PropTypes.string.isRequired,
  occurrences: PropTypes.number,
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      disableOfferForDate: disableOfferForDateAction,
      reEnableOfferForDateAction,
      deleteOfferAction,
      requestOfferDelete: requestOfferDeleteAction,
      requestOfferEdit: requestOfferEditAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OfferCard);
