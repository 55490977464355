// @ts-nocheck - To be depreciated soon so ignore ts for now
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ListPopover, Box } from '@eatclub-apps/ec-component-library';
import { useExpanded, usePagination, useSortBy, useTable } from 'react-table';
import useStyles from './ReactTableWrapper.styles';
import { COLORS } from '../../EatClubTheme';
import DownArrow from './down_arrow.svg';
import DownCaret from './icon_down_caret.svg';
import LeftCaret from './icon_left_caret.svg';
import LeftDblCaret from './icon_left_dbl_caret.svg';
import MagnifyingGlass from './icon_magnifying_glass.svg';
import RightCaret from './icon_right_caret.svg';
import RightDblCaret from './icon_right_dbl_caret.svg';

/**
 * TODO replace this. This wasn't working from the component library due to virtual folders
 * @param columns
 * @param data
 * @param initialState
 * @param loading
 * @param noRecordsMsg
 * @param pagination
 * @param style
 * @param totals
 * @param fixFirstColumn
 * @returns {JSX.Element}
 * @constructor
 */
export const ReactTableWrapper = ({
  columns = [],
  data = [],
  initialState = {},
  loading = false,
  noRecordsMsg = '',
  pagination = false,
  style = {},
  totals = [],
  fixFirstColumn = true,
}) => {
  const classes = useStyles({ style });
  const tableRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(false);
  const [newData, setNewData] = useState(data);

  const [scrollLeft, setScrollLeft] = useState(0);

  const handleScroll = () => {
    setScrollLeft(tableRef.current?.scrollLeft);
  };

  useEffect(() => {
    tableRef.current?.addEventListener('scroll', () => handleScroll());

    return () => {
      tableRef.current?.removeEventListener('scroll', () => handleScroll());
    };
  }, [tableRef.current]);

  useEffect(() => {
    setNewData(data);
  }, [data]);

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: useMemo(
        () =>
          columns.map((column) => ({
            ...(column.format && { Cell: column.format }),
            ...(column.sortType && { sortType: column.sortType }),
            Header: column.label || (() => null),
            accessor: column.id,
            disableSortBy: column.disableSortBy,
            appendToHeader: column.appendToHeader,
            sortDescFirst: true,
            width: column.width,
          })),
        [columns],
      ),
      data: useMemo(() => newData, [newData]),
      autoResetExpanded: false,
      autoResetHiddenColumns: false,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: { ...initialState },
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  const getTableBody = () => (
    <tbody {...getTableBodyProps()}>
      {(pagination ? page : rows).map((row) => {
        prepareRow(row);

        return (
          <tr {...row.getRowProps()} className={classes.dataRow}>
            {row.cells.map((cell) => (
              <td
                {...cell.getCellProps()}
                className={classes.dataCell}
                style={{
                  background: row.depth > 0 ? COLORS.LIGHT_CLOUD : COLORS.WHITE,
                  maxWidth: cell.column.width,
                  width: cell.column.width,
                }}
              >
                <Box className={cell.column.width ? classes.dataCellOverflow : ''}>
                  {cell.render('Cell')}
                </Box>
              </td>
            ))}
          </tr>
        );
      })}
      {totals.length > 0 && (
        <tr className={classes.totalsRow}>
          {totals.map((total) => (
            <td className={classes.dataCell}>{total.label}</td>
          ))}
        </tr>
      )}
    </tbody>
  );

  const scrollToTop = () => {
    tableRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Box
        className={`${classes.container} ${
          scrollLeft > 0 && fixFirstColumn && classes.fixFirstColumn
        }`}
        ref={tableRef}
      >
        {loading && null}

        {!loading && newData.length === 0 && (
          <Box className={classes.noRecords}>
            <MagnifyingGlass style={{ marginBottom: '26px' }} />
            <Box style={{ fontSize: '18px' }}>Sorry, no results were found.</Box>
            <Box style={{ fontSize: '16px' }}>{noRecordsMsg}</Box>
          </Box>
        )}

        {!loading && newData.length > 0 && (
          <table {...getTableProps()} className={classes.table}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className={classes.headerRow}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                      className={classes.headerCell}
                      style={{
                        color: column.isSorted ? COLORS.BRAND_PRIMARY : 'inherit',
                        cursor: !column.disableSortBy && 'pointer',
                        maxWidth: column.width,
                        width: column.width,
                      }}
                    >
                      <Box className={classes.headerCellInner}>
                        {column.render('Header')}

                        {!column.disableSortBy && (
                          <DownArrow
                            className={classes.iconArrow}
                            style={{
                              opacity: column.isSorted && 1,
                              transform:
                                column.isSorted && !column.isSortedDesc && 'rotate(180deg)',
                            }}
                          />
                        )}

                        {column.appendToHeader}
                      </Box>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {getTableBody()}
          </table>
        )}
      </Box>
      {!loading && newData.length > 0 && pagination && (
        <>
          <Box className={classes.paginationContainer}>
            <Box onClick={(e) => setAnchorEl(e.currentTarget)} className={classes.pageSizeBtn}>
              <Box>{`Show ${pageSize}`}</Box>
              <DownCaret
                style={{
                  transform: anchorEl && 'rotate(180deg)',
                  transition: 'transform 0.1s',
                }}
              />
            </Box>

            <Box>
              Page{' '}
              <b>
                {pageIndex + 1} of {Math.max(1, pageOptions.length)}
              </b>
            </Box>

            <Box style={{ display: 'flex', gap: '8px' }}>
              <Box
                className={classes.paginationBtn}
                disabled={!canPreviousPage}
                onClick={() => {
                  gotoPage(0);
                  scrollToTop();
                }}
              >
                <LeftDblCaret />
              </Box>
              <Box
                className={classes.paginationBtn}
                disabled={!canPreviousPage}
                onClick={() => {
                  previousPage();
                  scrollToTop();
                }}
                style={{ width: '24px' }}
              >
                <LeftCaret />
              </Box>
              <Box
                className={classes.paginationBtn}
                disabled={!canNextPage}
                onClick={() => {
                  nextPage();
                  scrollToTop();
                }}
                style={{ width: '24px' }}
                type='icon'
              >
                <RightCaret />
              </Box>
              <Box
                className={classes.paginationBtn}
                disabled={!canNextPage}
                onClick={() => {
                  gotoPage(pageCount - 1);
                  scrollToTop();
                }}
              >
                <RightDblCaret />
              </Box>
            </Box>
          </Box>

          <ListPopover
            items={[
              { label: 'Show 10', onClick: () => setPageSize(10) },
              { label: 'Show 20', onClick: () => setPageSize(20) },
              { label: 'Show 30', onClick: () => setPageSize(30) },
              { label: 'Show 40', onClick: () => setPageSize(40) },
              { label: 'Show 50', onClick: () => setPageSize(50) },
              { label: 'Show 100', onClick: () => setPageSize(100) },
            ]}
            anchor={anchorEl}
            isOpen={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            style={{
              item: {
                fontSize: '14px',
                height: 'auto',
                padding: '5px 10px',
              },
            }}
          />
        </>
      )}
    </>
  );
};
