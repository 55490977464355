import { Box, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './OppoDiscountStyles';

const OppoDiscount = (props) => {
  const classes = useStyles();
  const { discountAmount, isOptimal } = props;
  return (
    <Paper className={`${classes.paper} ${classes.oppoBox}`} style={{ marginRight: '.5rem' }}>
      <Box className={classes.oppoBoxContent}>
        <Typography>Deal</Typography>
        <Typography color='primary' variant='h4' component='div' style={{ fontWeight: 600 }}>
          {discountAmount}
        </Typography>
      </Box>
      {isOptimal && (
        <Box className={classes.optimalBanner}>
          <Typography className={classes.optimalBannerText} component='div'>
            optimal
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

OppoDiscount.propTypes = {
  discountAmount: PropTypes.string.isRequired,
  isOptimal: PropTypes.bool,
};

OppoDiscount.defaultProps = {
  isOptimal: false,
};

export default OppoDiscount;
