/* eslint-disable react/prop-types */
import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { formatNumber } from '../../utils';

const OrderTotal = ({ order }) => {
  const {
    custTransFee,
    deliveryFee,
    paymentMethod,
    platform,
    refundAmount,
    refundReason,
    refundType,
    tip,
    total,
  } = order;

  return (
    <List>
      {order.platform === 'whitelabel' ? (
        <>
          {order.tip && (
            <ListItem dense>
              <Typography variant='subtitle2' color='textPrimary'>
                Tip
              </Typography>
              <ListItemText style={{ textAlign: 'right' }}>${tip}</ListItemText>
            </ListItem>
          )}
          {order.deliveryFee && (
            <ListItem dense>
              <Typography variant='subtitle2' color='textPrimary'>
                Delivery Fees
              </Typography>
              <ListItemText style={{ textAlign: 'right' }}>${deliveryFee}</ListItemText>
            </ListItem>
          )}
          {order.custTransFee && (
            <ListItem dense>
              <Typography variant='subtitle2' color='textPrimary'>
                Card Processing Fee ({order.custTransFeePercent}%)
              </Typography>
              <ListItemText style={{ textAlign: 'right' }}>${custTransFee}</ListItemText>
            </ListItem>
          )}
        </>
      ) : (
        <ListItem dense>
          <Typography variant='subtitle2' color='textPrimary'>
            Payment
          </Typography>
          <ListItemText style={{ textAlign: 'right' }}>{paymentMethod}</ListItemText>
        </ListItem>
      )}

      <ListItem dense>
        <Typography variant='subtitle2' color='textPrimary'>
          Total
        </Typography>
        <ListItemText style={{ textAlign: 'right' }}>${formatNumber(Number(total))}</ListItemText>
      </ListItem>

      {platform === 'whitelabel' && (
        <>
          {refundAmount && (
            <ListItem dense>
              <Box>
                <Typography variant='subtitle2' color='textPrimary' display='inline'>
                  {refundType === 'partial' ? 'Partial ' : ''}Refund{' '}
                </Typography>
                <Typography variant='subtitle2' color='textSecondary' display='inline'>
                  ({refundReason})
                </Typography>
              </Box>
              <ListItemText style={{ textAlign: 'right' }}>-${refundAmount}</ListItemText>
            </ListItem>
          )}
        </>
      )}
    </List>
  );
};

export default OrderTotal;
