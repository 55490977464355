/* eslint-disable */
import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

const OrderChoice = ({ choice, item, mode }) => {
  const formatPrice = (price, qty, showEmpty) => {
    if (price === undefined) {
      return showEmpty;
    }
    let output = parseFloat(price) * qty;
    output = output === 0 ? showEmpty : `$${output.toFixed(2)}`;
    return output;
  };

  const choicePrice = formatPrice(choice.price, item.qty, '-');
  return (
    <ListItem>
      <ListItemIcon style={{ width: '70px' }}>
        <>&nbsp;</>
      </ListItemIcon>
      <ListItemText secondary={choice.title} />
      <ListItemIcon
        style={{
          marginRight: mode === 'refund' ? 50 : 0,
          width: '70px',
          justifyContent: 'flex-end',
        }}
      >
        <>{choicePrice}</>
      </ListItemIcon>
    </ListItem>
  );
};

export default OrderChoice;
