import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { editDeal } from '../graphql/mutations';
import { devLog } from '../utils';

export const updateDealAction = (deal, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'UPDATE_DEAL',
  });
  dispatch({
    type: type.UPDATE_DEAL_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: editDeal,
          variables: {
            ...deal,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'update deal', response.data.editDeal);

      dispatch({
        type: type.UPDATE_DEAL_SUCCESS,
        payload: { updatedDeal: response.data.editDeal },
      });

      dispatch({
        type: type.ADD_MESSAGE,
        payload: {
          id: `UPDATE_DEAL_SUCCESS_${new Date().getTime()}`,
          message: `Success, the deal has been updated`,
          severity: 'success',
        },
      });
    } catch (error) {
      devLog('error', 'update deal', error);

      dispatch({
        type: type.UPDATE_DEAL_FAILURE,
        payload: `Unable to update deal: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'UPDATE_DEAL',
      });
    }
  })();
};
