import * as type from '../actions/types';

const initialState = {
  pending: false,
  error: '',
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.CONTACT_BILLING_PENDING:
      return {
        ...state,
        pending: true,
        error: '',
      };
    case type.CONTACT_BILLING_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
      };
    case type.CONTACT_BILLING_FAILURE:
      return {
        ...state,
        error: action.payload,
        pending: false,
      };

    case type.CLEAR_CONTACT_BILLING:
      return {
        ...state,
        pending: false,
        error: '',
        success: false,
      };

    default:
      return state;
  }
};
