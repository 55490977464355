import PropTypes from 'prop-types';
import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Button } from '@eatclub-apps/ec-component-library';
import { formatCurrency } from '../../../utils/helpers';
import useStyles from './CreateGoalStyles';

const GoalsOverview = ({ customersLower, customersUpper, maxRevenue, onNext }) => {
  const classes = useStyles();
  const theme = useTheme();
  const breakpointXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const renderMonthly = (heading, content) => (
    <Box className={classes.revenueValueContainer}>
      <Box className={classes.revenueLabel}>{heading}</Box>
      <Box className={classes.revenueValue}>{content} monthly</Box>
    </Box>
  );

  const renderGoalStep = (number, heading, description) => (
    <Box className={classes.goalStep}>
      <Box className={classes.goalStepNumber}>{number}</Box>
      <Box className={classes.goalStepText}>
        <Box className={classes.goalStepHeading}>{heading}</Box>
        <Box className={classes.goalStepDescription}>{description}</Box>
      </Box>
    </Box>
  );

  return (
    <Box display='flex' flexDirection='column' mb='150px'>
      <Box className={classes.headingContainer}>
        <Box className={classes.heading}>Welcome to goals</Box>
        <Box className={classes.overviewDescription}>
          Get more from the EatClub Marketplace with control and clarity to how many customers you
          want to reach and revenue you want to make.
        </Box>
      </Box>

      <Box className={classes.goalStepContainer}>
        {renderGoalStep(
          1,
          'Set your monthly revenue goal',
          'Select how much revenue you want to make each month, after the offer amount given to the customer.',
        )}
        {renderGoalStep(
          2,
          'Review AI suggested offers',
          'Our AI has evaluated thousands of data points to suggest your optimal selection of offers to launch to the Marketplace.',
        )}
        {renderGoalStep(
          3,
          'Launch and stay on track',
          "Shape your starting offers to suit your needs, access more data to see how you're tracking\n" +
            '            and make changes at anytime.',
        )}
      </Box>

      <Box className={classes.goalsFooter}>
        <Box className={classes.goalsFooterPadding}>
          {!breakpointXsDown && (
            <Box className={classes.extraRevenueContainer}>
              <Box className={classes.extraRevenueHeading}>Your potential extra revenue</Box>
              <Box className={classes.extraRevenueDescription}>
                Estimated revenue after offer amount
              </Box>
            </Box>
          )}

          <Box className={classes.revenueLabelContainer}>
            <Box className={classes.footerTargets}>
              {renderMonthly('CUSTOMERS', `${customersLower}-${customersUpper}`)}
              {renderMonthly('REVENUE', formatCurrency(maxRevenue, false))}
            </Box>

            <Button
              onClick={onNext}
              style={{
                container: { width: breakpointXsDown && '100%' },
                button: { width: breakpointXsDown && '100%' },
              }}
            >
              Get started
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

GoalsOverview.propTypes = {
  customersLower: PropTypes.number.isRequired,
  customersUpper: PropTypes.number.isRequired,
  maxRevenue: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default GoalsOverview;
