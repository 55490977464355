/**
 * Formats the `string` to title case from camel case.
 * Eg. thisIsASentence => This Is A Sentence
 * @param string String to format
 */
export const titleCaseFromCamel = (string) => {
  let titleCase = string.replace(/([A-Z])/g, ' $1').trim();
  titleCase = titleCase.charAt(0).toUpperCase() + titleCase.slice(1);
  return titleCase;
};
