import { API } from 'aws-amplify';
import moment from 'moment';
import { onboardToObee, requestObeeSignup } from '../graphql/mutations';
import { obeePricing, obeeSlugExists } from '../graphql/queries';
import store from '../store';
import { devLog } from '../utils';
import { getAppVersion, safeParse } from '../utils/helpers';
import { makeApiAction } from './actionHelper';
import * as type from './types';

export const onboardToObeeAction = (slug, operationsEmail, premium) => (dispatch) => {
  makeApiAction(
    'OBEE_ONBOARD',
    onboardToObee,
    { slug, operationsEmail, premium },
    {},
    'onboardToObee',
    true,
    dispatch,
    'Venue successfully onboarded!',
    {
      obeeEnabled: true,
      premium,
      obeeGoLiveDate: moment().startOf('day').add('1', 'week').format('YYYY-MM-DD'),
      operationsEmail,
    }, // so we dont have to refetch the restaurant on success
    false,
    'Unable to onboard venue.',
  );
};

export const clearObeeOnboardAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_OBEE_ONBOARD,
  });
};

export const requestObeeSignupAction = (premium, name, phone, email, comments) => (dispatch) => {
  makeApiAction(
    'OBEE_REQUEST',
    requestObeeSignup,
    { premium, name, phone, email, comments },
    {},
    'requestObeeSignup',
    true,
    dispatch,
    'Thank you, your request has been sent!',
    null,
    false,
    'Unable to send request.',
  );
};

export const clearObeeRequestAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_OBEE_REQUEST,
  });
};

export const obeeSlugExistsAction = (slug) =>
  new Promise((resolve, reject) => {
    const state = store?.getState();
    const restId = state.restaurantActive.restaurant.objectId;
    const userId = state.user.userInfo.objectId;
    const { userType } = state.user.userInfo;

    (async () => {
      try {
        const response = await API.graphql(
          {
            query: obeeSlugExists,
            variables: { slug },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            'user-id': userId,
            'user-type': userType,
            'rest-id': restId,
            'app-version': getAppVersion(),
          },
        ).catch((error) => {
          throw new Error(error.errors[0].message);
        });

        devLog('success', 'obeeSlugExists', response.data.obeeSlugExists);
        const { exists } = safeParse(response.data.obeeSlugExists);

        // Success resolve
        resolve(exists);
      } catch (error) {
        devLog('error', 'obeeSlugExists', error);

        reject(error);
      }
    })();
  });

export const fetchObeePricingAction = () => (dispatch) => {
  makeApiAction(
    'OBEE_PRICING',
    obeePricing,
    {},
    {},
    'obeePricing',
    true,
    dispatch,
    null,
    null,
    false,
    'Unable to fetch pricing.',
  );
};
