import PropTypes from 'prop-types';
import * as type from '../actions/types';
import { updateItemInArray } from './reducerHelper';

const initialState = {
  data: [],
  error: '',
  pending: false,
  success: false,
};

// Export prop types for easy import in components that use this data
export const opportunityPropTypes = PropTypes.shape({
  objectId: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  dayOfWeek: PropTypes.number.isRequired,
  dineInOnly: PropTypes.bool.isRequired,
  takeawayOnly: PropTypes.bool.isRequired,
  iaoOnly: PropTypes.bool.isRequired,
  lightning: PropTypes.bool.isRequired,
  discount: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  prediction: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  qty: PropTypes.number.isRequired,
  revenueExpected: PropTypes.number.isRequired,
  recurring: PropTypes.bool.isRequired,
});

export const opportunitiesPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(opportunityPropTypes),
  error: PropTypes.string,
  pending: PropTypes.bool,
  success: PropTypes.bool,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_DAILY_OPPOS_PENDING:
      return {
        ...state,
        pending: true,
        error: '',
      };
    case type.FETCH_DAILY_OPPOS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        pending: false,
        success: true,
      };
    case type.FETCH_DAILY_OPPOS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };

    case type.CLEAR_DAILY_OPPOS:
      return {
        ...state,
        data: [],
        pending: false,
        error: '',
        success: false,
      };

    case type.UPDATE_DAILY_OPPO_SUCCESS: {
      const { prediction, oppoId } = action.payload;

      const newData = updateItemInArray(state.data, 'objectId', oppoId, (item) => ({
        ...item,
        prediction,
      }));

      return {
        ...state,
        data: newData,
      };
    }

    case type.POST_DAILY_OPPO_SUCCESS: {
      const { oppoId } = action.payload;

      const newData = updateItemInArray(state.data, 'objectId', oppoId, (item) => ({
        ...item,
        _posted: true,
      }));

      return {
        ...state,
        data: newData,
      };
    }

    default:
      return state;
  }
};
